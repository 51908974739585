<script>
  import Capacity from '../../charts/Capacity.svelte'
  import Panel from './Panel.svelte'
  import { _ } from 'svelte-i18n'

  export let technology
  export let techGroup
</script>

<Panel>
  <Capacity technology={technology.key} {techGroup} />
</Panel>
