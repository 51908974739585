<script>
  import { slide } from 'svelte/transition'
  import { track as trck } from './../track'

  export let title = undefined
  export let collapsed = true
  export let borderBottom = true
  export let containerPadding = true
  export let track = false

  // $: collapsed = true
</script>

<div class:borderBottom>
  <div>
    <button
      type="button"
      class="cmp-accordion__item-title"
      on:click={() => {
        collapsed = !collapsed

        if (track) {
          trck(title)
        }
      }}
    >
      <div class="chevron" class:collapsed />
      <!-- alternative to the title prop, for more customizaiton -->
      <slot name="titleSlot" />
      {#if title}
        <h2>{title}</h2>
      {/if}
    </button>
    <slot name="helpSlot" />
  </div>
  {#if !collapsed}
    <div transition:slide>
      <div class:containerPadding>
        <slot />
      </div>
    </div>
  {/if}
</div>

<style>
  button,
  button:active {
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    padding: 0;
    padding-left: 10px;
  }
  .borderBottom {
    border-bottom: solid 1px var(--color-gray-200);
  }

  .borderBottom:last-child {
    border: none;
  }
  .containerPadding {
    padding: 0 40px;
  }

  h2 {
    display: inline-block;
    margin: 10px;
  }
  .chevron {
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding-left: 1rem;
    background: transparent;
    border: 0;
    padding: 0;
    font: inherit;
    text-align: left;
    -webkit-appearance: none;
    /* display: block; */
    /* width: 100%; */
    padding: 20px 0 0;
    transform: rotate(180deg);
  }

  .chevron:active {
    background: transparent;
  }

  .chevron.collapsed:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .chevron:before {
    left: auto;
    right: 0;
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1),
      -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    -webkit-transform-origin: center 0.25rem;
    transform-origin: center 0.25rem;
  }

  .chevron:before,
  .chevron:focus:before,
  .chevron:hover:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='8' xmlns='http://www.w3.org/2000/svg' fill='%230b0b0c'%3E%3Cpath d='M15.587.165a.567.567 0 00-.8 0L8.25 6.755 1.712.165a.567.567 0 00-.799 0C.696.385.696.75.913.897l6.901 6.957A.553.553 0 008.177 8c.146 0 .29-.073.364-.146L15.587.97a.578.578 0 000-.805z' fill-rule='nonzero'/%3E%3C/svg%3E");
  }
  .chevron:before {
    width: 1rem;
    height: 0.5rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
</style>
