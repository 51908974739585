export function draw(node, params) {
    let x;
    let y;
    function transformPoint(event) {
        const point = params.svg.createSVGPoint();
        point.y = event.clientY;
        point.x = event.clientX;
        return [
            point.matrixTransform(params.svg.getScreenCTM().inverse()).x -
                params.margin.left,
            point.matrixTransform(params.svg.getScreenCTM().inverse()).y -
                params.margin.bottom,
        ];
    }
    function pauseEvent(e) {
        if (e.stopPropagation)
            e.stopPropagation();
        if (e.preventDefault)
            e.preventDefault();
        e.cancelBubble = true;
        e.returnValue = false;
        return false;
    }
    function handleMousedown(event) {
        y = event.clientY;
        x = event.clientX;
        node.dispatchEvent(new CustomEvent('drawstart', {
            detail: { x: transformPoint(event)[0], y: transformPoint(event)[1] },
        }));
        window.addEventListener('mousemove', handleMousemove);
        window.addEventListener('mouseup', handleMouseup);
        pauseEvent(event);
    }
    function handleMousemove(event) {
        y = event.clientY;
        x = event.clientX;
        node.dispatchEvent(new CustomEvent('draw', {
            detail: { x: transformPoint(event)[0], y: transformPoint(event)[1] },
        }));
    }
    function handleMouseup(event) {
        y = event.clientY;
        x = event.clientX;
        node.dispatchEvent(new CustomEvent('drawend', {
            detail: { x: transformPoint(event)[0], y: transformPoint(event)[1] },
        }));
        window.removeEventListener('mousemove', handleMousemove);
        window.removeEventListener('mouseup', handleMouseup);
    }
    node.addEventListener('mousedown', handleMousedown);
    return {
        destroy() {
            node.removeEventListener('mousedown', handleMousedown);
        },
    };
}
