<script>
  import { _, locale } from 'svelte-i18n'
</script>

<!-- <footer>
  <div class="container">
    <nav>
      <a href="/methodology" use:link>Methodology</a>
    </nav>
  </div>
</footer> -->
<footer>
  <div class="container">
    <div class="report">
      <a
        class="report-download"
        title="202205_Final Report_A quantitative assessment of the Axpo Power Switcher.pdf"
        href="resources/202205_Final Report_A quantitative assessment of the Axpo Power Switcher.pdf"
        target="_blank"
      >
        {$_('page.sections.footer.eth_review')}
      </a>
    </div>
    <!-- <div class="footer__head">
      <p class="footer__contact-email">
        <a href="mailto:info@axpo.com" class="footer__contact-email-link">
          Write to us
        </a>
      </p>
      <p class="footer__contact-phone">
        <a
          href="tel:+41%2056%20200%2031%2011"
          class="footer__contact-phone-link"
        >
          +41 56 200 31 11
        </a>
      </p>
      <p class="footer__top">
        <a href="#top" class="footer__top-link"> Top </a>
      </p>
    </div> -->
    <div class="footer__main">
      <div class="footer__text">
        <div class="footer__contact">
          <h3>{$_('page.sections.footer.contact.title')}</h3>
          <p>{$_('page.sections.footer.contact.line1')}</p>
          <p>
            {$_('page.sections.footer.contact.line2')}
            <a
              href="mailto:info@axpo.com"
              class="footer__meta-link link link--external"
              target="_blank">info@axpo.com</a
            >
          </p>
          <p>{$_('page.sections.footer.contact.line3')}</p>
        </div>
      </div>
      <div>
        <div class="footer__social">
          <h3 class="footer__title">{$_('page.sections.footer.followUs')}</h3>
          <ul class="footer__social-items">
            <li class="footer__social-item">
              <a
                href="https://www.linkedin.com/company/axpo-group"
                target="_blank"
                class="footer__social-link footer__social-link--linkedin link--icon"
              />
            </li>
            <li class="footer__social-item">
              <a
                href="https://www.facebook.com/AxpoCH/"
                target="_blank"
                class="footer__social-link footer__social-link--facebook link--icon"
              />
            </li>
            <li class="footer__social-item">
              <a
                href="https://twitter.com/axpo"
                target="_blank"
                class="footer__social-link footer__social-link--twitter link--icon"
              />
            </li>
            <li class="footer__social-item">
              <a
                href="https://www.youtube.com/channel/UCIQTHVsIP1tasGnD3MS3b9g"
                target="_blank"
                class="footer__social-link footer__social-link--youtube link--icon"
              />
            </li>
            <li class="footer__social-item">
              <a
                href="https://www.instagram.com/axpogroup/"
                target="_blank"
                class="footer__social-link footer__social-link--instagram link--icon"
              />
            </li>
            <li class="footer__social-item">
              <a
                href="https://www.xing.com/companies/axpo"
                target="_blank"
                class="footer__social-link footer__social-link--xing link--icon"
              />
            </li>
          </ul>
        </div>
        <div class="footer__credits">
          <p>
            Credits: Axpo & <a
              href="http://tulpinteractive.com"
              target="_blank"
              class="link link--external">TULP interactive</a
            >
          </p>
        </div>
      </div>
      <div class="footer__links">
        <ul class="footer__links-items">
          <li class="footer__links-item">
            <a href="https://axpo.com" class="link link--external">
              {$_('page.sections.footer.axpoLink')}
            </a>
          </li>
        </ul>
      </div>

      <!-- <div class="footer__foot"> -->
      <p class="footer__copyright">© Copyright {new Date().getFullYear()}</p>
      <div class="footer__meta">
        <ul class="footer__meta-items">
          <li class="footer__meta-item">
            <a
              href={$locale.startsWith('en')
                ? 'https://www.axpo.com/ch/en/about-us/impressum.html'
                : 'https://www.axpo.com/ch/de/ueber-uns/impressum.html'}
              class="footer__meta-link link link--external"
            >
              Impressum
            </a>
          </li>
          <li class="footer__meta-item">
            <a
              href={$locale.startsWith('en')
                ? 'https://www.axpo.com/ch/en/about-us/legal-information.html'
                : 'https://www.axpo.com/ch/de/ueber-uns/rechtliche-hinweise.html'}
              class="footer__meta-link link link--external"
            >
              {$_('page.sections.footer.legalInformation')}
            </a>
          </li>
          <li class="footer__meta-item">
            <a
              href={$locale.startsWith('en')
                ? 'https://www.axpo.com/ch/en/about-us/privacy-policy.html'
                : 'https://www.axpo.com/ch/de/ueber-uns/datenschutzerklaerung.html'}
              class="footer__meta-link link link--external"
            >
              {$_('page.sections.footer.privacyPolicy')}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- </div> -->
  </div>
</footer>

<!-- <style>
  footer {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
    background-color: #bfb1ad;
    width: 100%;
    height: 80px;
    margin-top: 8rem;
  }

  .container {
    margin: 0 auto;
    width: 90%;
    max-width: 1200px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 1rem;
  }
</style> -->
<style>
  .report {
    margin-bottom: var(--size-4);
  }

  a.report-download {
    color: white;
    position: relative;
    margin-left: 30px;
  }

  a.report-download::before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' fill='white'%3E%3Cpath d='M34.9 21.3v8c0 .6-.4 1-1 1H14.3c-.6 0-1-.4-1-1v-8' fill='none' stroke='white' stroke-width='1' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10'/%3E%3Cpath fill='none' stroke='white' stroke-width='1' stroke-linecap='round' stroke-linejoin='round' d='M24.3 26V15.6'/%3E%3Cpath d='M27.9 20.9c-.2-.1-.4-.1-.5.1l-3.1 4.8-3.1-4.8c-.1-.2-.3-.2-.5-.1s-.2.3-.1.5l3.4 5.3c.1.1.2.2.3.2s.2-.1.3-.2l3.4-5.3c0-.1.1-.1.1-.2s-.1-.2-.2-.3z'/%3E%3C/svg%3E");
    background-size: calc(100% - 0.5rem);
    top: auto;
    bottom: -10px;
    -webkit-transform: none;
    transform: none;
    height: 40px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    left: -40px;
  }

  footer {
    margin-top: 100px;
    padding-bottom: 0;
    color: white;
    background-image: url('/img/Footer-desktop.png');
    background-size: cover;
  }

  @media (max-width: 768px) {
    footer {
      background-image: url('/img/Footer-tablet.png');
    }
  }

  footer .link {
    color: white;
  }

  *,
  :after,
  :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 100px 1.5rem 20px 1.5rem;
  }

  .footer__main {
    /* display: -webkit-box; */
    /* display: -ms-flexbox; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    /* -webkit-box-orient: vertical; */
    /* -webkit-box-direction: normal; */
    /* -ms-flex-direction: column; */
    /* flex-direction: column; */
  }

  @media (max-width: 768px) {
    .footer__main {
      grid-template-columns: 1fr;
    }
  }

  p {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.1875;
    margin-top: 0;
    margin-bottom: 1em;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .footer__links,
  .footer__social,
  .footer__text {
    margin-bottom: 3rem;
  }

  .footer__text {
    margin-bottom: 2rem;
  }

  .footer__social {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .footer__copyright {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.166666667;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .footer__meta {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1;
  }

  a {
    background-color: transparent;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
  }

  a {
    color: #e10019;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0.625rem;
    color: white;
  }

  h3 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 1.2;
  }

  .footer__title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.1875;
    margin-top: 0;
  }

  .footer__title.footer__title {
    color: inherit;
  }

  .footer__social-items {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: -1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style-position: initial;
    list-style-image: initial;
    list-style-type: none;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .footer__links-items {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: -0.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style-position: initial;
    list-style-image: initial;
    list-style-type: none;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .footer__meta-items {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style-position: initial;
    list-style-image: initial;
    list-style-type: none;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .footer__credits {
    margin-bottom: 20px;
  }

  .footer__social-item {
    margin-bottom: 1rem;
  }

  .footer__social-item:not(:last-child) {
    margin-right: 1rem;
  }

  .footer__links-item {
    width: 50%;
    margin-bottom: 0.5rem;
  }

  .link--external:not(.btn) {
    display: inline-block;
    position: relative;
    /* padding-left: 1.5em; */
    line-height: 1.5;
    /* padding-left: 1.2em; */
    /* color: #e10019; */
    color: white;
    /* border-bottom: 1px solid white; */
  }

  .link {
    color: white;
  }

  .link--external:not(.btn):before {
    width: 0.75em;
    height: 0.75em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .link--external:not(.btn):before {
    top: 0.325em;
    -webkit-transform: none;
    transform: none;
  }

  .footer__meta-item {
    margin-bottom: 2rem;
    width: 50%;
  }
  .footer__meta-item:not(:last-child) {
    margin-right: 0;
  }

  .footer__social-link {
    display: inline-block;
    padding-left: 100%;
    display: block;
    width: 2rem;
    height: 2rem;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .footer__social-link:before {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-position-x: 50%;
    background-position-y: center;
  }

  .footer__social-link:before,
  .footer__social-link:focus:before,
  .footer__social-link:hover:before,
  .footer__social-link:before,
  .footer__social-link:focus:before,
  .footer__social-link:hover:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='40' height='27' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff'%3E%3Cg fill-rule='evenodd'%3E%3Cpath d='M35.8 1h-32C2.2 1 1 2.2 1 3.8v19c0 1.5 1.2 2.8 2.8 2.8h32c1.5 0 2.8-1.2 2.8-2.8v-19C38.5 2.2 37.3 1 35.8 1z' fill-rule='nonzero'/%3E%3Cpath d='M2 2l18 11L38 2'/%3E%3C/g%3E%3C/svg%3E");
  }

  .footer__social-link:before {
    text-indent: 0;
  }

  .footer__social-link--linkedin:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='33' height='32' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff'%3E%3Cpath d='M16.447 0c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16zm-3.7 23.5h-3.4V12.6h3.3v10.9h.1zm.2-14.4c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8c.9 0 1.8.8 1.8 1.8zm12.9 14.4h-3.2v-5.7c0-1.3-.3-2.1-1.8-2.1-1.3 0-1.9.2-1.9 2v5.8h-3.3V12.6h3.2v1.8c.9-1 2.1-1.6 3.5-1.6 1.8 0 3.6.8 3.6 3.3l-.1 7.4z' fill-rule='nonzero'/%3E%3C/svg%3E");
  }

  .footer__social-link--linkedin:hover:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='33' height='32' xmlns='http://www.w3.org/2000/svg' fill='%233D3D3D'%3E%3Cpath d='M16.447 0c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16zm-3.7 23.5h-3.4V12.6h3.3v10.9h.1zm.2-14.4c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8c.9 0 1.8.8 1.8 1.8zm12.9 14.4h-3.2v-5.7c0-1.3-.3-2.1-1.8-2.1-1.3 0-1.9.2-1.9 2v5.8h-3.3V12.6h3.2v1.8c.9-1 2.1-1.6 3.5-1.6 1.8 0 3.6.8 3.6 3.3l-.1 7.4z' fill-rule='nonzero'/%3E%3C/svg%3E");
  }

  .footer__social-link--facebook:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='33' height='32' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff'%3E%3Cpath d='M20.378 16.6h-2.6v9.3h-3.9v-9.3h-1.8v-3.3h1.8v-2.1c-.2-2 1.3-3.7 3.3-3.9h3.5v3.2h-2.1c-.4 0-.8.3-.8.8v2h2.9l-.3 3.3zm-4-16.6c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16z' fill-rule='nonzero'/%3E%3C/svg%3E");
  }

  .footer__social-link--facebook:hover:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='33' height='32' xmlns='http://www.w3.org/2000/svg' fill='%233D3D3D'%3E%3Cpath d='M20.378 16.6h-2.6v9.3h-3.9v-9.3h-1.8v-3.3h1.8v-2.1c-.2-2 1.3-3.7 3.3-3.9h3.5v3.2h-2.1c-.4 0-.8.3-.8.8v2h2.9l-.3 3.3zm-4-16.6c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16z' fill-rule='nonzero'/%3E%3C/svg%3E");
  }

  .footer__social-link--twitter:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='33' height='32' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff'%3E%3Cpath d='M24.622 12.4v.5c0 6.2-5 11.2-11.2 11.2-2.2 0-4.3-.6-6.1-1.8h.9c1.8 0 3.5-.6 4.9-1.7-1.7 0-3.1-1.1-3.7-2.7.2 0 .5.1.7.1.3 0 .7-.1 1-.1-1.8-.4-3.1-2-3.1-3.9.5.3 1.2.5 1.8.5-1.1-.7-1.7-2-1.8-3.3 0-.7.2-1.4.5-2 2 2.5 5 4 8.1 4.1-.1-.3-.1-.6-.1-.9 0-2.2 1.8-3.9 4-3.9 1.1 0 2.1.5 2.8 1.2.9-.2 1.7-.5 2.5-1-.3.9-.9 1.7-1.7 2.2.8-.1 1.5-.3 2.3-.6-.4.9-1.1 1.5-1.8 2.1m-8-12.4c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16' fill-rule='nonzero'/%3E%3C/svg%3E");
  }

  .footer__social-link--twitter:hover:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='33' height='32' xmlns='http://www.w3.org/2000/svg' fill='%233D3D3D'%3E%3Cpath d='M24.622 12.4v.5c0 6.2-5 11.2-11.2 11.2-2.2 0-4.3-.6-6.1-1.8h.9c1.8 0 3.5-.6 4.9-1.7-1.7 0-3.1-1.1-3.7-2.7.2 0 .5.1.7.1.3 0 .7-.1 1-.1-1.8-.4-3.1-2-3.1-3.9.5.3 1.2.5 1.8.5-1.1-.7-1.7-2-1.8-3.3 0-.7.2-1.4.5-2 2 2.5 5 4 8.1 4.1-.1-.3-.1-.6-.1-.9 0-2.2 1.8-3.9 4-3.9 1.1 0 2.1.5 2.8 1.2.9-.2 1.7-.5 2.5-1-.3.9-.9 1.7-1.7 2.2.8-.1 1.5-.3 2.3-.6-.4.9-1.1 1.5-1.8 2.1m-8-12.4c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16' fill-rule='nonzero'/%3E%3C/svg%3E");
  }

  .footer__social-link--youtube:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='33' height='32' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff'%3E%3Cpath d='M16.622 0c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16zm.674 9h-.587c-1.004.005-4.353.032-6.71.202-.392.046-1.243.05-2.005.842-.599.604-.794 1.976-.794 1.976S7 13.63 7 15.24v1.51c0 1.612.2 3.222.2 3.222s.195 1.372.794 1.976c.762.793 1.761.768 2.206.85 1.6.154 6.8.201 6.8.201s4.203-.006 7.001-.208c.39-.046 1.243-.05 2.004-.843.6-.604.795-1.976.795-1.976s.2-1.61.2-3.221v-1.51c0-1.611-.2-3.222-.2-3.222s-.196-1.372-.795-1.976c-.76-.793-1.613-.796-2.004-.842-2.357-.17-5.706-.197-6.705-.201zM15 13l5 3.01L15 19v-6z' fill-rule='nonzero'/%3E%3C/svg%3E");
  }

  .footer__social-link--youtube:hover:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='33' height='32' xmlns='http://www.w3.org/2000/svg' fill='%233D3D3D'%3E%3Cpath d='M16.622 0c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16zm.674 9h-.587c-1.004.005-4.353.032-6.71.202-.392.046-1.243.05-2.005.842-.599.604-.794 1.976-.794 1.976S7 13.63 7 15.24v1.51c0 1.612.2 3.222.2 3.222s.195 1.372.794 1.976c.762.793 1.761.768 2.206.85 1.6.154 6.8.201 6.8.201s4.203-.006 7.001-.208c.39-.046 1.243-.05 2.004-.843.6-.604.795-1.976.795-1.976s.2-1.61.2-3.221v-1.51c0-1.611-.2-3.222-.2-3.222s-.196-1.372-.795-1.976c-.76-.793-1.613-.796-2.004-.842-2.357-.17-5.706-.197-6.705-.201zM15 13l5 3.01L15 19v-6z' fill-rule='nonzero'/%3E%3C/svg%3E");
  }

  .footer__social-link--instagram:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='32' height='32' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff'%3E%3Cg fill-rule='evenodd'%3E%3Cpath d='M25.94 20.123c-.05 1.064-.218 1.791-.465 2.427a4.892 4.892 0 01-1.153 1.772 4.919 4.919 0 01-1.772 1.153c-.636.247-1.362.416-2.427.465-1.066.048-1.407.06-4.123.06s-3.057-.012-4.123-.06c-1.064-.049-1.791-.218-2.427-.465a4.919 4.919 0 01-1.772-1.153 4.905 4.905 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427C6.012 19.056 6 18.716 6 16s.012-3.057.06-4.123c.049-1.065.218-1.791.465-2.427a4.905 4.905 0 011.153-1.772A4.905 4.905 0 019.45 6.525c.636-.247 1.363-.416 2.427-.465C12.943 6.012 13.284 6 16 6s3.057.012 4.123.06c1.065.049 1.79.218 2.427.465a4.905 4.905 0 011.772 1.153 4.892 4.892 0 011.153 1.772c.247.636.416 1.362.465 2.427.048 1.066.06 1.407.06 4.123s-.012 3.056-.06 4.123zM16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0z'/%3E%3Cpath d='M21.338 11.862a1.2 1.2 0 110-2.4 1.2 1.2 0 010 2.4M16 21.135a5.135 5.135 0 110-10.27 5.135 5.135 0 010 10.27m7.796-11.032a3.086 3.086 0 00-.75-1.15 3.088 3.088 0 00-1.148-.748c-.352-.137-.882-.301-1.857-.344C18.986 7.81 18.67 7.8 16 7.8c-2.67 0-2.986.01-4.041.06-.975.043-1.504.207-1.856.344a3.084 3.084 0 00-1.15.748c-.35.349-.567.683-.748 1.15-.138.352-.3.882-.345 1.856-.048 1.055-.058 1.37-.058 4.04s.01 2.988.058 4.042c.045.975.207 1.505.345 1.857.18.466.398.8.748 1.15.349.35.683.566 1.15.748.352.137.88.299 1.856.344 1.055.048 1.37.058 4.04.058 2.672 0 2.987-.01 4.042-.058.975-.045 1.505-.207 1.857-.344a3.1 3.1 0 001.149-.748c.35-.35.567-.684.749-1.15.137-.352.299-.882.344-1.857.048-1.054.058-1.371.058-4.041 0-2.67-.01-2.986-.058-4.041-.045-.974-.207-1.504-.344-1.856'/%3E%3Cpath d='M16 12.666a3.333 3.333 0 100 6.667 3.333 3.333 0 000-6.666'/%3E%3C/g%3E%3C/svg%3E");
  }

  .footer__social-link--instagram:hover:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='32' height='32' xmlns='http://www.w3.org/2000/svg' fill='%233D3D3D'%3E%3Cg fill-rule='evenodd'%3E%3Cpath d='M25.94 20.123c-.05 1.064-.218 1.791-.465 2.427a4.892 4.892 0 01-1.153 1.772 4.919 4.919 0 01-1.772 1.153c-.636.247-1.362.416-2.427.465-1.066.048-1.407.06-4.123.06s-3.057-.012-4.123-.06c-1.064-.049-1.791-.218-2.427-.465a4.919 4.919 0 01-1.772-1.153 4.905 4.905 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427C6.012 19.056 6 18.716 6 16s.012-3.057.06-4.123c.049-1.065.218-1.791.465-2.427a4.905 4.905 0 011.153-1.772A4.905 4.905 0 019.45 6.525c.636-.247 1.363-.416 2.427-.465C12.943 6.012 13.284 6 16 6s3.057.012 4.123.06c1.065.049 1.79.218 2.427.465a4.905 4.905 0 011.772 1.153 4.892 4.892 0 011.153 1.772c.247.636.416 1.362.465 2.427.048 1.066.06 1.407.06 4.123s-.012 3.056-.06 4.123zM16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0z'/%3E%3Cpath d='M21.338 11.862a1.2 1.2 0 110-2.4 1.2 1.2 0 010 2.4M16 21.135a5.135 5.135 0 110-10.27 5.135 5.135 0 010 10.27m7.796-11.032a3.086 3.086 0 00-.75-1.15 3.088 3.088 0 00-1.148-.748c-.352-.137-.882-.301-1.857-.344C18.986 7.81 18.67 7.8 16 7.8c-2.67 0-2.986.01-4.041.06-.975.043-1.504.207-1.856.344a3.084 3.084 0 00-1.15.748c-.35.349-.567.683-.748 1.15-.138.352-.3.882-.345 1.856-.048 1.055-.058 1.37-.058 4.04s.01 2.988.058 4.042c.045.975.207 1.505.345 1.857.18.466.398.8.748 1.15.349.35.683.566 1.15.748.352.137.88.299 1.856.344 1.055.048 1.37.058 4.04.058 2.672 0 2.987-.01 4.042-.058.975-.045 1.505-.207 1.857-.344a3.1 3.1 0 001.149-.748c.35-.35.567-.684.749-1.15.137-.352.299-.882.344-1.857.048-1.054.058-1.371.058-4.041 0-2.67-.01-2.986-.058-4.041-.045-.974-.207-1.504-.344-1.856'/%3E%3Cpath d='M16 12.666a3.333 3.333 0 100 6.667 3.333 3.333 0 000-6.666'/%3E%3C/g%3E%3C/svg%3E");
  }

  .footer__social-link--xing:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='%23ffffff'%3E%3Cg data-name='Ebene 2'%3E%3Cpath class='cls-1' d='M16 0a16 16 0 1016 16A16 16 0 0016 0zm-4.79 20H8.47a.41.41 0 01-.36-.19.4.4 0 010-.42L11 14.2 9.17 11a.37.37 0 01.35-.59h2.75a.85.85 0 01.76.51l1.89 3.29c-.12.2-3 5.24-3 5.24a.85.85 0 01-.71.55zM24.65 7.22L18.57 18l3.87 7a.44.44 0 010 .43.41.41 0 01-.37.17h-2.73a.86.86 0 01-.76-.52L14.68 18c.2-.34 6.1-10.82 6.1-10.82a.8.8 0 01.74-.52h2.77a.38.38 0 01.36.59z' data-name='Ebene 1'/%3E%3C/g%3E%3C/svg%3E");
  }

  .footer__social-link--xing:hover:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='%233D3D3D'%3E%3Cg data-name='Ebene 2'%3E%3Cpath class='cls-1' d='M16 0a16 16 0 1016 16A16 16 0 0016 0zm-4.79 20H8.47a.41.41 0 01-.36-.19.4.4 0 010-.42L11 14.2 9.17 11a.37.37 0 01.35-.59h2.75a.85.85 0 01.76.51l1.89 3.29c-.12.2-3 5.24-3 5.24a.85.85 0 01-.71.55zM24.65 7.22L18.57 18l3.87 7a.44.44 0 010 .43.41.41 0 01-.37.17h-2.73a.86.86 0 01-.76-.52L14.68 18c.2-.34 6.1-10.82 6.1-10.82a.8.8 0 01.74-.52h2.77a.38.38 0 01.36.59z' data-name='Ebene 1'/%3E%3C/g%3E%3C/svg%3E");
  }

  .footer__contact {
    padding-right: 0.75rem;
    margin-bottom: 3.25rem;
  }

  .footer__additional-links {
    padding: 0;
    list-style: none;
  }
</style>
