<script>
  import HouseholdCostChart from './HouseholdCostChart.svelte'
  import { LayerCake, Svg } from 'layercake'
  import { groups, extent } from 'd3-array'
  import {
    calculatedData,
    selectedLCOE,
    selectedPowerPrice,
    selectedCostView,
  } from '../../stores'
  import { trueCosts } from '../../costCalculation.js'
  import { stack } from 'd3-shape'

  const costTypes = ['workingPrice', 'gridAndFixedPrice', 'subsidy', 'tax']

  $: trueCostData = $trueCosts.getAnnualTimeSeries((year) => {
    const result = {
      year: year.year,
      gridAndFixedPrice: year.trueCost.calculated.household.gridAndFixedPrice,
      subsidy: year.trueCost.calculated.household.subsidy,
      workingPrice: year.trueCost.calculated.household.workingPrice,
      tax: year.trueCost.calculated.household.tax,
    }

    return result
  })

  $: data = stack().keys(costTypes)(trueCostData)

  $: xDomain = extent(trueCostData, (d) => d.year)
  $: yDomain = extent(data.map((d) => d.flat()).flat())
</script>

<div class="container">
  <div class="chart-container">
    {#if data}
      <LayerCake
        {data}
        padding={{ top: 30, right: 20, bottom: 20, left: 30 }}
        {yDomain}
        {xDomain}
      >
        <Svg>
          <HouseholdCostChart />
        </Svg>
      </LayerCake>
    {/if}
  </div>
</div>

<style>
  .container {
    width: 100%;
    height: 100%;
  }

  .chart-container {
    width: 100%;
    height: calc(100% - 20px);
  }

  h4 {
    margin: 0;
    padding: 0 4px;
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
</style>
