<script>
  import {
    calculatedData,
    activeMonths,
    selectedYear as selectedYear1,
    selectedMonth as selectedMonth1,
    selectedYearIsLocked,
    showPopover,
  } from './../../stores.ts'
  import { months3, summerMonths, winterMonths } from './../../constants.ts'
  import SurplusBubble from './SurplusBubble.svelte'
  import { _ } from 'svelte-i18n'
  import { range, sum } from 'd3-array'
  import { scaleLinear } from 'd3-scale'
  import { getContext } from 'svelte'

  // export let selectedYear = 2050
  export let selectedMonth = undefined
  export let selectedDate = undefined
  export let popover

  const { width, height } = getContext('LayerCake')

  const R = 7
  const cellSize = 18

  let hover

  $: xScale = scaleLinear().domain([2020, 2050]).range([0, $width])
  $: yScale = scaleLinear().domain([0, 12]).range([0, $height])

  let svg

  $: data = $calculatedData
    .getMonthlyTimeSeries((month) => {
      const demand = month.calculated.totalDemand.CH
      const totalProduction = sum(
        Object.values(month.calculated.generation.generation.byTechnology)
      )
      const maxImportPotential = sum(
        Object.values(month.calculated.maxImportability)
      )

      const requiredImport = Math.max(
        0,
        Math.min(demand - totalProduction, maxImportPotential)
      )

      return {
        year: month.parentYear.year,
        month: month.month - 1,
        date: month.parentYear.year * 100 + month.month,
        value: month.calculated.generation.surplus, // TODO: remove: same as surplus prop
        totalProduction,
        production: {
          ...month.calculated.generation.generation.byTechnology,
          import: month.calculated.generation.import.total,
        },
        import: month.calculated.generation.import.total,
        surplus: month.calculated.generation.surplus,
        deficit: month.calculated.generation.deficit,
        demand: month.calculated.totalDemand.CH,
        selfSufficientDays: month.calculated.selfSufficientDays,
        speicherReserve: month.calculated.generation.generation.speicherReserve,
      }
    })
    .map((d, i) => ({
      ...d,
      stacked: [xScale(d.year), yScale(i % 12)],
    }))
</script>

<slot name="defs">
  <pattern
    id="diagonalHatch-import"
    patternUnits="userSpaceOnUse"
    width="4"
    height="4"
  >
    <path
      d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
      style="stroke:#000; stroke-width:1"
    />
  </pattern>
</slot>

<g transform="translate(20, 20)">
  {#each months3 as month, i}
    <g class="month-label">
      <text
        dy={-1}
        dx={-cellSize - 2}
        style="text-anchor: end; dominant-baseline: central; font-size: 1.2rem;"
        transform="translate(0, {yScale(i)})"
        fill={$selectedMonth1 === i ? 'var(--accent-color)' : 'black'}
        opacity={!$selectedMonth || $selectedMonth === i ? 1 : 0.3}
        >{$_(`page.sections.other.months3.${month}`)}</text
      >
      <rect
        x={-40 - cellSize + 3}
        y={i * 2 * (cellSize - 0.5) - cellSize}
        width={40}
        height={2 * cellSize}
        fill={i === $selectedMonth ? 'rgba(255, 93, 100, 0.2)' : 'transparent'}
      />
    </g>
  {/each}

  {#each data as d, i (`${d.year}-${d.month}`)}
    <g
      class="date-label"
      style="outline: none; cursor: pointer;"
      transform="translate({d.stacked[0]}, {d.stacked[1]})"
    >
      <rect
        x={-cellSize + 2}
        y={-cellSize + 2}
        width={2 * cellSize - 4}
        height={2 * cellSize - 4}
        fill={hover === i ? 'rgb(255 93 100 / 30%)' : 'var(--color-grey-100)'}
        rx={2}
        ry={2}
      />
      <SurplusBubble {d} />
      <rect
        x={-cellSize - 1}
        y={-cellSize - 1}
        width={2 * cellSize + 2}
        height={2 * cellSize + 2}
        fill="transparent"
        on:mouseover={() => {
          hover = i

          if (!$selectedYearIsLocked) {
            $selectedMonth1 = d.month
            $selectedYear1 = d.year
          }
          // $showPopover = true
          popover = d
        }}
        on:mouseout={() => {
          hover = undefined
          // $showPopover = false
          $selectedMonth1 = undefined
          popover = undefined
        }}
        on:click={() => {
          $selectedYearIsLocked = !$selectedYearIsLocked
          $selectedMonth1 = d.month
          $selectedYear1 = d.year
        }}
      />
    </g>
  {/each}

  {#if $selectedYear1}
    <g transform="translate({xScale($selectedYear1) - cellSize}, {-cellSize})">
      <text
        fill="red"
        dx={$selectedYearIsLocked ? cellSize - 6 : cellSize}
        dy={-6}
        style="font-size: var(--scale-1); text-anchor: middle; font-weight: bold; user-select: none;"
        >{$selectedYear1}</text
      >

      {#if $selectedYearIsLocked}
        <g transform="translate({24}, {-20})">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16px"
            height="16px"
          >
            <path
              fill="red"
              d="M 12 1 C 8.6761905 1 6 3.6761905 6 7 L 6 8 C 4.9069372 8 4 8.9069372 4 10 L 4 20 C 4 21.093063 4.9069372 22 6 22 L 18 22 C 19.093063 22 20 21.093063 20 20 L 20 10 C 20 8.9069372 19.093063 8 18 8 L 18 7 C 18 3.6761905 15.32381 1 12 1 z M 12 3 C 14.27619 3 16 4.7238095 16 7 L 16 8 L 8 8 L 8 7 C 8 4.7238095 9.7238095 3 12 3 z M 6 10 L 18 10 L 18 20 L 6 20 L 6 10 z M 12 13 C 10.9 13 10 13.9 10 15 C 10 16.1 10.9 17 12 17 C 13.1 17 14 16.1 14 15 C 14 13.9 13.1 13 12 13 z"
            />
          </svg>
        </g>
      {/if}

      <rect
        style="pointer-events: none;"
        width={2 * cellSize}
        height={11 * cellSize * 2 + 16}
        fill="none"
        stroke="var(--accent-color)"
        stroke-width={2}
        rx={3}
        ry={3}
      />
    </g>
  {/if}

  {#each range(2020, 2051) as year, i}
    <text
      dx={10}
      style="dominant-baseline: central; text-anchor: end; font-weight: {i %
        10 ===
      0
        ? 'bold'
        : 'normal'}; font-size: {i % 10 === 0
        ? 'var(--scale-2)'
        : 'var(--scale-1)'}; fill: {year === $selectedYear1
        ? 'var(--accent-color)'
        : 'black'};"
      class="year-tick"
      transform="translate({xScale(year)}, {$height}) rotate(-90)">{year}</text
    >
  {/each}
</g>

<style>
  .inactive {
    opacity: 0;
    pointer-events: none;
  }
</style>
