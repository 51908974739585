<script>
  import Router from 'svelte-spa-router'
  import Home from './routes/Home.svelte'
  import Methodology from './routes/Methodology.svelte'
  import Redesign from './routes/Redesign.svelte'
  import Test from './routes/Test.svelte'
  import Test2 from './routes/Test2.svelte'
  import Test3 from './routes/Test3.svelte'
  import Alternative from './routes/Alternative.svelte'
  import __REFERENCE_Redesign from './routes/__REFERENCE_Redesign.svelte'

  const routes = {
    '/': Test2,
    // '/methodology': Methodology,
    // '/redesign': Redesign,
    // '/Test': Test,
    // '/Test2': Test2,
    // '/Test3': Test3,
    // '/REF': __REFERENCE_Redesign,
    // '/Alternative': Alternative,
  }
</script>

<Router {routes} />
