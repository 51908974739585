<script>
  import { stressors } from './../stores'
  import Stressor from './../components/Stressor.svelte'
</script>

<div class="stressors">
  {#each $stressors as stressor}
    <!-- <img src="icons/icons8-windsock-50.png" alt="icon" class="icon" /> -->
    <Stressor {stressor} />
  {/each}
</div>

<style>
  .stressors {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 100%;
  }
</style>
