<script>
  import { mousePosition, popoverContent, popoverTitle } from '../stores'
  import { locale } from 'svelte-i18n'

  export let width = 400
</script>

<div
  class="popover"
  style="top: {$mousePosition.y + 10}px; left: {$mousePosition.x + 10}px;"
>
  {#if $popoverTitle}
    <h4>
      <slot name="title">
        {$popoverTitle}
      </slot>
    </h4>
  {/if}
  <div
    style="word-break: break-word;  -webkit-hyphens: auto; -moz-hyphens: auto; -ms-hyphens: auto; hyphens: auto;"
    lang={$locale}
  >
    <slot name="content">
      {@html $popoverContent}
    </slot>
  </div>
</div>

<style>
  .popover {
    padding: var(--size-6);
    margin-top: 10px;
    position: absolute;
    font-size: var(--scale-3);
    background-color: white;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    z-index: 10000;
    max-width: var(--size-80);
    transform: translate(-50%, 0);
  }

  h4 {
    margin-top: 0;
    margin-bottom: var(--size-2);
  }
</style>
