<script>
  import { max, range } from 'd3-array'
  import { format } from 'd3-format'
  import { scaleLinear, scaleQuantize } from 'd3-scale'
  import { pointer } from 'd3-selection'
  import { area, curveCatmullRom, line } from 'd3-shape'
  import { _ } from 'svelte-i18n'
  import { cubicInOut } from 'svelte/easing'
  import { tweened } from 'svelte/motion'
  import Panel from '../Panel.svelte'
  import Availability from './../charts/Availability.svelte'
  import { calculatedData, selectedDemandTech, userSettings } from './../stores'
  import OutlineText from './OutlineText.svelte'
  import Reset from './../ui/Reset.svelte'
  import InfoIcon from '../ui/InfoIcon.svelte'

  export let selectedTech

  let mousePosition
  let hoverValue = undefined

  const f = format(',.2f')

  // let selectedTech = 'klass'
  let selectedView = { type: 'absolute' }
  let selectedChart = 'demand'
  $: collapsed = true

  $: data = $calculatedData.getAnnualTimeSeries((year) => ({
    demand: year.calculated.demand.CH[selectedView.type],
  }))

  const domain = tweened(0, {
    easing: cubicInOut,
  })

  $: $domain = max(data, (d) => d.demand.max[selectedTech])

  const width = 250
  const height = 130

  const margin = {
    top: 20,
    right: 15,
    bottom: 20,
    left: 40,
  }

  const w = width - margin.left - margin.right
  const h = height - margin.top - margin.bottom

  $: availabilityData = $calculatedData.years[0].months.map(
    (month) => month.input.CH.availability.category[selectedTech].avg
  )

  const xA = scaleLinear().domain([0, 11]).range([0, w])
  const yA = scaleLinear().domain([0, 1.2]).range([h, 0])

  const aA = area()
    .x((d, i) => xA(i))
    .y1((d) => yA(d))
    .y0((d) => yA(0))
    .curve(curveCatmullRom)

  const x = scaleLinear().domain([0, 30]).range([0, w])
  const xq = scaleQuantize().domain([0, w]).range(range(31))
  $: y = scaleLinear().domain([0, $domain]).range([h, 0])

  const demandGroups = [
    {
      key: 'klass',
      label: 'Classic',
      color: 'hsl(0, 0%, 50%)',
    },
    {
      key: 'ev',
      label: 'Electromobility',
      color: 'hsl(0, 0%, 60%)',
    },
    {
      key: 'h2',
      label: 'H2',
      color: 'hsl(0, 0%, 70%)',
    },
    {
      key: 'hp',
      label: 'Heatpump',
      color: 'hsl(0, 0%, 80%)',
    },
  ]
</script>

<div>
  <div class="panel-top">
    <div on:click={() => (collapsed = !collapsed)} style="cursor: pointer;">
      <label
        style="cursor: pointer; display: inline-block; font-weight: bold;"
        for="renewable"
      >
        <InfoIcon
          title={$_(
            `page.sections.userControls.switzerland.demand.demandTypes.${selectedTech}.label`
          )}
          description={$_(
            `page.sections.userControls.switzerland.demand.demandTypes.${selectedTech}.description`
          )}
        />
        {$_(
          `page.sections.userControls.switzerland.demand.demandTypes.${selectedTech}.label`
        )}{#if $userSettings.switzerland.powerDemand[selectedTech].edited}
          <span style="display: inline-block; margin-left: var(--size-2);">
            <Reset
              on:click={() => {
                const tmp = $userSettings

                tmp.switzerland.powerDemand[selectedTech].value = 0
                tmp.switzerland.powerDemand[selectedTech].edited = false

                $userSettings = tmp
              }}
            />
          </span>{/if}
        <!-- {#if isEdited}<span class="edited">*</span>{/if} -->
      </label>
    </div>
    <div style="position: relative; margin-top: 7px;" />
  </div>

  <!-- {#if !collapsed} -->
  <div>
    <div class="tab-group">
      {#each ['demand', 'utilization'] as tab}
        <button
          class="tab"
          class:selected={selectedChart === tab}
          on:click={() => (selectedChart = tab)}
        >
          {$_(
            `page.sections.userControls.switzerland.demand.controls.tabs.${tab}`
          )}
        </button>
      {/each}
    </div>
  </div>

  <div class="demand-panel">
    <div style="background-color: rgb(247, 245, 244); position: relative;">
      {#if selectedChart === 'demand'}
        <input
          type="range"
          min={-1}
          max={1}
          step="0.001"
          style="width: 100%; padding: 0; margin: 0; background: none;"
          bind:value={$userSettings.switzerland.powerDemand[selectedTech].value}
          on:mousedown={() => {
            $selectedDemandTech = selectedTech
            $userSettings.switzerland.powerDemand[selectedTech].edited = true
          }}
          on:mouseup={() => {
            $selectedDemandTech = undefined
          }}
        />

        <div class="units">
          {#each $userSettings.switzerland.powerDemand[selectedTech].views as view}
            <button
              on:click={() => (selectedView = view)}
              class="unit-toggle"
              class:selected-view={selectedView.type === view.type}
            >
              {view.unit}
            </button>
          {/each}
        </div>

        <svg {width} {height} style="margin-top: 16px;">
          <g transform="translate({margin.left}, {margin.top})">
            <path
              on:mouseover={(e) => {
                mousePosition = pointer(e)
                hoverValue = {
                  year: xq(mousePosition[0]),
                  value:
                    data[xq(mousePosition[0])].demand.userValues[selectedTech],
                }
              }}
              on:mousemove={(e) => {
                mousePosition = pointer(e)
                hoverValue = {
                  year: xq(mousePosition[0]),
                  value:
                    data[xq(mousePosition[0])].demand.userValues[selectedTech],
                }
              }}
              on:mouseout={() => (hoverValue = undefined)}
              on:focus={() => {}}
              on:blur={() => {}}
              d={area()
                .x((d, i) => x(i))
                .y1((d) => y(d.demand.userValues[selectedTech]))
                .y0((d) => y(0))(data)}
              fill={demandGroups.find((d) => d.key === selectedTech).color}
            />
            <path
              d={line()
                .x((d, i) => x(i))
                .y((d) => y(d.demand.min[selectedTech]))(data)}
              fill="none"
              stroke-dasharray="2 2"
              stroke="black"
            />
            <path
              d={line()
                .x((d, i) => x(i))
                .y((d) => y(d.demand.max[selectedTech]))(data)}
              fill="none"
              stroke-dasharray="2 2"
              stroke="black"
            />
            <path
              d={line()
                .x((d, i) => x(i))
                .y((d) => y(d.demand.scenario[selectedTech]))(data)}
              fill="none"
              stroke="black"
              stroke-width={2}
            />
            <path
              d={line()
                .x((d, i) => x(i))
                .y((d) => y(d.demand.userValues[selectedTech]))(data)}
              fill="none"
              class="scenario"
              on:mouseover={(e) => {
                mousePosition = pointer(e)
                hoverValue = {
                  year: xq(mousePosition[0]),
                  value:
                    data[xq(mousePosition[0])].demand.userValues[selectedTech],
                }
              }}
              on:mousemove={(e) => {
                mousePosition = pointer(e)
                hoverValue = {
                  year: xq(mousePosition[0]),
                  value:
                    data[xq(mousePosition[0])].demand.userValues[selectedTech],
                }
              }}
              on:mouseout={() => (hoverValue = undefined)}
              on:focus={() => {}}
              on:blur={() => {}}
            />

            <OutlineText
              backColor="rgba(247, 245, 244, 0.7)"
              x={x(30)}
              class="max"
              y={y(data[30].demand.max[selectedTech])}
              dy={-2}>max</OutlineText
            >

            <OutlineText
              backColor="rgba(247, 245, 244, 0.7)"
              x={x(30)}
              class="min"
              y={y(data[30].demand.min[selectedTech])}
              dy={-2}>min</OutlineText
            >

            {#if !hoverValue}
              {#each range(2020, 2051) as year, i}
                {#if i % 5 === 0}
                  <text
                    transform="translate({x(i)}, {h})"
                    dy={14}
                    class="x-tick">{year}</text
                  >
                {/if}
              {/each}
            {:else}
              <text
                transform="translate({x(xq(mousePosition[0]))}, {h})"
                dy={14}
                class="x-tick">{xq(mousePosition[0]) + 2020}</text
              >
            {/if}

            {#each y.nice().ticks(5) as tick}
              {#if tick > 0}
                {#if selectedView?.unit?.startsWith('%')}
                  <text dx={-4} class="y-tick" y={y(tick)}>
                    {format('.0%')(tick)}
                  </text>
                {:else}
                  <text dx={-4} class="y-tick" y={y(tick)}>{tick}</text>
                {/if}
              {/if}
            {/each}

            {#if hoverValue}
              <OutlineText
                backColor="rgb(247, 245, 244)"
                strokeWidth={5}
                x={Math.min(w - 20, Math.max(20, x(hoverValue.year)))}
                y={y(hoverValue.value)}
                dy={-8}
                style="text-anchor: middle; font-size: var(--scale-1);"
                >{f(hoverValue.value)}</OutlineText
              >
            {/if}
          </g>
        </svg>
        <!-- {#if $userSettings.switzerland.powerDemand[selectedTech].edited}
          <div style="display: flex; justify-content: flex-end;">
            <button
              class="reset"
              on:click={() => {
                const tmp = $userSettings

                tmp.switzerland.powerDemand[selectedTech].value = 0
                tmp.switzerland.powerDemand[selectedTech].edited = false

                $userSettings = tmp
              }}>Reset</button
            >
          </div>
        {/if} -->
      {:else if selectedChart === 'utilization'}
        <Availability
          key={selectedTech}
          color={demandGroups.find((d) => d.key === selectedTech).color}
          maxPct={Math.max(1, Math.max(...availabilityData))}
          data={availabilityData}
        />
      {/if}
    </div>
  </div>
</div>

<!-- </Panel> -->
<style>
  .tab {
    background: var(--color-grey-100);
    border: none;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 3px solid var(--color-grey-100);
    margin: 0;
    cursor: pointer;
    font-size: 1em;
    margin-bottom: 0px;
    margin-left: 1px;
    margin-right: 1px;
    padding: var(--size-1) var(--size-3);
  }

  .tab:active {
    background: var(--color-grey-100);
    border-bottom: 3px solid var(--color-grey-100);
  }

  .tab:hover {
    background: var(--color-grey-200);
    border-bottom: 3px solid var(--color-grey-200);
  }

  .tab.selected {
    border-bottom: 3px solid rgb(255 93 100);
    background: var(--color-grey-200);
    color: black;
    /* box-shadow: 0px -4px 10px 0px rgba(255, 93, 100, 0.2); */
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    font-weight: 500;
  }

  .tab.selected:hover {
    background: var(--color-grey-200);
    border-bottom: 3px solid rgb(255 93 100);
  }
  text {
    font-size: var(--scale-1);
  }
  .reset {
    border: none;
    outline: none;
    /* background: none; */
    background: var(--accent-color);
    color: white;
    cursor: pointer;
    height: 20px;
    border-radius: 20px;
    font-size: 1.2rem;
    padding: 0 10px;
    font-size: 1.2rem;
  }

  .units {
    position: absolute;
  }

  .unit-toggle {
    background: none;
    outline: none;
    cursor: pointer;
    border: none;
    font-size: 1.4rem;
    margin-left: var(--size-1);
  }

  .unit-toggle:hover {
    text-decoration: underline;
  }

  .unit-toggle:active {
    outline: none;
    background: none;
  }

  .selected-view {
    color: var(--accent-color);
    font-weight: bold;
  }

  .scenario {
    stroke: var(--accent-color);
    stroke-width: 3;
  }

  .demand-panel {
    /* background-color: #f7f5f4; */
  }

  .tab-group {
    width: 100%;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: center;
  }

  .selectedChart {
    color: var(--accent-color);
    border-bottom: 2px solid var(--accent-color);
  }
  .panel-top {
    display: grid;
    grid-template-columns: 190px 1fr;
    align-items: center;
    padding: 8px 0;
  }

  .edited {
    color: var(--accent-color);
  }

  svg {
    max-width: 370px;
  }
</style>
