<script>
</script>

<button class="reset" on:click|stopPropagation>
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="width: 14px; height: 14px; transform: translate(0, 40%)"
  >
    <path
      d="M14.5 20L15.3182 19.1429C18.9628 15.3247 16.2565 9 10.9781 9H6M6 9L10 13M6 9L10 5"
      stroke="var(--accent-color)"
      stroke-width={3}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</button>

<style>
  .reset {
    font-size: var(--scale-4);
    width: var(--size-5);
    height: var(--size-5);
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: var(--accent-color); */
    background: #ff5d6433;
    outline: none;
    border: none;
    border-radius: 15px;
    justify-self: center;
    cursor: pointer;
    padding-bottom: 12px;
  }

  .reset:active {
    background: var(--accent-color);
    color: white;
  }

  .reset:hover {
    background: #ff5d6466;
  }

  .reset span {
    transform-origin: calc(50% - 0px) calc(50% + 2px);
    transform: rotate(45deg);
    transition: transform 0.5s ease;
  }

  .reset:hover span {
    transform-origin: calc(50% - 0px) calc(50% + 2px);
    transform: rotate(-45deg);
    transition: transform 0.5s ease;
  }
</style>
