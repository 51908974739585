﻿<script>
  import Collapsable from '../../components/Collapsable.svelte'

  export let title
</script>

<Collapsable {title}>
  <Collapsable title="Axpo Erneuerbare">
    <p>
      Das Axpo-Szenario einer mögliche Stromzukunft. Die wichtigsten Trends
      sind:
    </p>

    <ul>
      <li>Ausbau erneuerbarer Energien</li>

      <li>Gas-to-Power mit CO2-neutralem Gas</li>

      <li>Anstieg der Nachfrage trotz Effizienzsteigerungen</li>

      <li>Kein Zubau von fossilen Kraftwerken in der Schweiz</li>

      <li>Stromimporte wenn möglich und notwendig</li>
    </ul>

    <h4>Ausbau Erneuerbarer Energien</h4>
    <p>
      Aus technischer Sicht können erneuerbare Energien ausgebaut werden, sofern
      Bewilligungen und Finanzierung sichergestellt sind. Zur Deckung der
      Nachfrage ist ein Ausbau von Photovoltaik auf ungefähr 34 GW (etwa 36 TWh)
      bis 2050 unterstellt. Die meisten Anlagen sind auf Dächern angesiedelt und
      ein Teil wird an alpinen Lagen zugebaut, Freiflächenanlagen im Mittelland
      wird es nur wenige geben. Bei Windenergie wird bis 2050 ein Ausbau auf
      etwa 10 TWh Erzeugung angesetzt. Für Geothermie gehen wir in diesem
      Szenario von rund 2 TWh Erzeugung aus. Ein stärkerer Ausbau ist aufgrund
      von technologischen Restriktionen (Erdbeben, wärmeführende Schichten)
      nicht realistisch. Biomasse wird auf rund 5 TWh Gesamterzeugung ausgebaut.
      Die Stromerzeugung aus Wasserkraft verbleibt auf heutigem Niveau, da die
      Annahme unterstellt ist, dass sich Ausbau und Verluste aus
      Restwassersanierungen aufheben.
    </p>

    <h4>Gas-to-Power mit CO2-neutralem Gas</h4>

    <p>
      Nach der Ausserbetriebnahme der Kernkraftwerke Gösgen und Leibstadt sind
      Gaskraftwerke nötig, um insbesondere im Winter die Stromnachfrage zu
      decken. Um Klimaziele einzuhalten, nutzen wir CO2-neutrales Gas, welches
      mehrheitlich aus Importen stammt. Es kommen Grün- und Synthesegase sowie
      grüner Wasserstoff in Frage. Im Szenario werden dazu 2.6 GW Leistung von
      Gas-to-Power-Anlagen hinterlegt.
    </p>

    <h4>Anstieg der Nachfrage trotz Effizienzsteigerungen</h4>

    <p>
      Die Dekarbonisierung von zusätzlichen Bereichen wie Elektromobilität,
      Wärmepumpen oder zur Erzeugung grünen Wasserstoffs führt zu einer
      steigenden Nachfrage nach erneuerbaren Energien. Zusätzlich führt die
      Bevölkerungsentwicklung zu einer Zunahme der klassischen Stromnachfrage.
      Trotz angenommener Effizienzsteigerungen der technischen Geräte ergibt
      sich insgesamt bis 2050 ein Anstieg der Stromnachfrage auf rund 88 TWh.
    </p>

    <h4>Kein Zubau von fossilen Kraftwerken in der Schweiz</h4>

    <p>
      Das Szenario unterstellt keine neuen fossilen Kraftwerke. Stattdessen wird
      die Stromnachfrage insbesondere im Winter durch Importe und Gas-to-Power
      Anlagen auf Basis CO2-neutralen Gases gedeckt, sofern dies notwendig sein
      sollte.
    </p>

    <h4>Wichtigste Eckdaten auf einen Blick</h4>
    <ul>
      <li>Stromproduktion</li>
      <ul>
        <li>Ausbau von Photovoltaik auf etwa 34 GW bis 2050</li>
        <li>Ausbau von Wind auf etwa 5 GW bis 2050</li>
        <li>Ausbau von Gas-to-Power-Anlagen auf 2.6 GW in 2050</li>
        <li>Ausstieg Kernenergie nach 60 Jahren Laufzeit</li>
        <li>
          Rückgang bestehender fossiler Kleinkraftwerke entsprechend ihrer
          Lebensdauer
        </li>
        <li>Keine neuen fossilen Kraftwerke</li>
      </ul>
      <li>Nachfrage</li>
      <ul>
        <li>Gesamtstromnachfrage von etwa 88 TWh in 2050</li>

        <li>Nachfrage durch Elektromobilität von 15 TWh in 2050</li>

        <li>Nachfrage durch Wärmepumpen von knapp 10 TWh in 2050</li>

        <li>Nachfrage durch Wasserstoffelektrolyse von 9 TWh in 2050</li>
      </ul>
      <li>Importe</li>
      <ul>
        <li>
          Importe in die Schweiz werden zugelassen, Leitungskapazitäten
          orientieren sich an den Leitungsausbauplänen der europäischen
          Netzbetreiber (ENTSO-E)
        </li>
      </ul>
      <ul />
    </ul></Collapsable
  >

  <Collapsable title="Axpo Landschaft">
    <p>
      Das Szenario “Landschaft” berücksichtigt die Realität, dass Projekte
      erneuerbarer Energien oft mittels Einsprachen oder durch die Ablehnung an
      der Urne verhindert oder um Jahrzehnte verzögert werden. Widerstand ist
      häufig durch das Argument der unverbauten und naturbelassenen Landschaft
      oder Argumente der Biodiversität motiviert. Doch soll das Ziel einer
      klimaneutralen und gesicherten Stromversorgung bis 2050 bei vertretbaren
      Importen weiterhin gelten, so bleibt aus heutiger technologischer Optik
      nur die Option neue Kernkraftwerke. Dieses Szenario sieht denn auch kaum
      Eingriffe in die Natur vor und setzt stattdessen auf neue Kernkraftwerke
      sowie auf PV auf Dächern und bestehenden Infrastrukturen. Der Bau neuer
      Kernkraftwerke in der Schweiz ist gesetzlich verboten, daher bleibt das
      Szenario hypothetisch. Zudem werden die hohen wirtschaftlichen und
      regulatorischen Kosten und Risiken für Investoren ausgeblendet. Selbst in
      diesem hypothetischen Fall ist ein erheblicher Ausbau erneuerbarer
      Energien, insbesondere Photovoltaik auf Dächern, notwendig. Denn ein
      realistischer Neubau von Kernkraftwerken würde mehr als 20 Jahre dauern,
      währenddessen die steigende Nachfrage nach Strom trotzdem gedeckt werden
      müsste.
    </p>

    <h4>Langer Betrieb und Bau von neuen Kernkraftwerken</h4>

    <p>
      Um die Winterstromlücke zu schliessen, setzt dieses Szenario “Landschaft”
      einerseits auf eine Betriebsdauer der Kernkraftwerke Gösgen und Leibstadt
      von je 70 Jahren, sofern sie sicher betrieben werden können. Beznau läuft
      in diesem Szenario 60 Jahre. Andererseits enthält es zwei neue Reaktoren
      von je 1.3 GW Leistung, welche 2045 und 2050 ans Netz gehen. Es sind
      Reaktoren der aktuell verfügbaren Technologie EPR 3rd Gen, weil die
      Entscheidung zum Bau von neuen Werken möglichst rasch getroffen werden
      müsste. Strom aus Kernkraft wird im Winter 2050 15.7 TWh beisteuern, was
      rund 35% des Verbrauchs entspricht (ganzes Jahr: 29.6 TWh / 34 %).
    </p>

    <h4>Photovoltaik (fast) nur auf Dächern</h4>

    <p>
      Ein weiteres unerlässliches Puzzlestück zur Schliessung der
      Winterstromlücke ist Photovolatik. Um die Eingriffe in das Landschaftsbild
      zu minimieren, werden in diesem Szenario keine PV-Freiflächenanlagen
      gebaut und nur wenige alpine Solaranlagen realisiert. Durch die tiefere
      Anzahl an Solaranlagen in den Bergen mit ihrem wertvollen Anteil an
      Winterstrom fehlt ein wichtiger Beitrag zur Versorgungssicherheit in der
      Schweiz. Das Szenario setzt stattdessen fast ausschliesslich auf
      PV-Anlagen auf Dächern. Sie liefern zwar deutlich weniger Winterstrom als
      alpine Anlagen, sind jedoch breit akzeptiert und stellen keinen Eingriff
      in die Landschaft dar. Um den Importbedarf im Winter gemäss Zielsetzung
      auf rund 6 TWh zu beschränken, ist ein massiver Zubau von PV Dachanlagen
      nötig. Mit einem Ausbau bis auf rund 33 GW Leistung im Jahr 2050 und einer
      jährlichen Erzeugung von rund 32 TWh schöpft dieses Szenario das vom BFE
      geschätzte Potential zu etwa 60% aus.
    </p>

    <ul>
      <li>Installierte Leistung Solar 2050: 33 GW</li>
      <ul>
        <li>davon Dach: 32.3 GW</li>

        <li>davon Alpin: 0.7 GW</li>

        <li>davon Freifläche: 0 GW</li>
      </ul>

      <li>Erzeugung Winter 2050: 10.1 TWh</li>
      <ul>
        <li>davon Dach: 9.6 TWh</li>

        <li>davon Alpin: 0.5TWh</li>
      </ul>

      <li>Erzeugung ganzes Jahr 2050: 31.9 TWh</li>
      <ul>
        <li>davon Dach: 30.9 TWh</li>

        <li>davon Alpin: 1 TWh</li>
      </ul>
    </ul>

    <p>
      Falls das ambitionierte Ausbauziel bei Dach-PV nicht erreicht wird, müssen
      mehrere fossile Gaskraftwerke gebaut werden, um eine Versorgungslücke im
      Winter zu verhindern.
    </p>

    <h4>Rückgrat Wasserkraft stark halten</h4>

    <p>
      Die Wasserkraft ist schon heute das Rückgrat der Schweizer Stromversorgung
      und wird auch in diesem Szenario “Landschaft” ein zentraler Pfeiler
      bleiben. Für Axpo als grösste Wasserkraftproduzentin der Schweiz hat sie
      eine lange Tradition und einen hohen Stellenwert. Besonders die
      Flexibilität durch Speicherwasserkraft ist für den kurzfristigen Ausgleich
      zentral und schützt die Schweiz gegen Ausfälle und Engpässe. Sie nimmt
      eine wichtige Rolle im Winter ein. Das Ausbaupotential ist aber beschränkt
      und die Produktion der bestehenden Werke droht aufgrund von Umweltauflagen
      abzunehmen. Insgesamt muss das Ziel für die Schweiz darum sein, die
      Produktion stabil zu halten. Heute produziert sie rund 36 TWh netto pro
      Jahr. Dies wird auch für 2050 ungefähr erwartet, was gut 42% des Schweizer
      Jahresverbrauchs in 2050 entspricht. Im Winter kann sie 15 TWh
      produzieren.
    </p>

    <h4>Verzicht auf breite Nutzung von Wind</h4>

    <p>
      Aufgrund des teilweise vehementen Widerstands in Teilen der Gesellschaft
      gegen Windkraftwerke kommt dieser Technologie im Szenario “Landschaft”
      kaum Bedeutung zu – obwohl sie gerade im Winter viel Strom produzieren
      könnte. In diesem Szenario werden 2050 lediglich 80 Windturbinen in der
      Schweiz stehen, also eine knappe Verdoppelung von aktuell 47 Anlagen.
      Strom aus Wind wird im Winter 2050 gerade einmal 0.2 TWh beisteuern, was
      rund 0.5% des Verbrauchs entspricht (ganzes Jahr: 0.4 TWh / 0.4 %).
    </p>

    <h4>Stromimporte bleiben wichtig, sind aber zu begrenzen</h4>

    <p>
      Die Vorstellung einer autarken Stromproduktion in der Schweiz ist
      unrealistisch. Die Schweiz ist mit dem europäischen Stromnetz bestens
      vernetzt, der Strom fliesst in beide Richtungen. Auch in diesem Szenario
      “Landschaft” gehen wir davon aus, dass die Schweiz ein Stromabkommen mit
      der EU oder zumindest entsprechende bilaterale Abkommen mit den Nachbarn
      schliessen konnte und daher auch 2050 im europäischen Strommarkt
      integriert sein wird. In diesem Szenario ist der Importbedarf im Winter in
      keinem Jahr höher als 5.9 TWh. Im Jahr 2050 ist die Versorgungslage
      komfortabel und zeigt einen leichten Export. Es ist jedoch zu beachten,
      dass vier Jahre später das Kernkraftwerk Leibstadt (-1.3 GW oder -10 TWh/a
      ) stillgelegt wird. Entsprechend wird dann auch der Importbedarf im Winter
      wieder steigen, nämlich auf rund zwei TWh.
    </p>

    <h4>Klein aber fein: Biomasse und Geothermie</h4>

    <p>
      Bereits heute wird ein guter Teil der Bioabfälle unter anderem zur
      Stromproduktion genutzt. Die Nutzung der Abfälle erlaubt einen
      nachhaltigen Kreislauf. Ihr Beitrag zur Stromproduktion ist zwar
      mengenmässig klein, aber in einem breiten und stabilen Mix dennoch
      wichtig. Im Winter 2050 wird die Biomasse im Erneuerbare-Szenario ungefähr
      2.7 TWh oder 6% des Schweizer Jahresverbrauchs abdecken, während es heute
      2.3 TWh sind. (ganzes Jahr: 5 TWh / 6%) Geothermie hat den Vorteil,
      ganzjährig verfügbar zu sein. Die Technologie hat besonders viel Potential
      für die Wärmegewinnung, jedoch nur begrenzt für die Stromproduktion:
      Zurzeit gibt es Pilotprojekte, auch innerhalb der Axpo Gruppe. In unserem
      Szenario gehen wir von einer Stromproduktion im Winter 2050 von ungefähr 1
      TWh oder 2% des Schweizer Jahresverbrauchs 2050 aus (ganzes Jahr: 2.1 TWh
      / 2%).
    </p>

    <h4>Wichtigste Eckdaten auf einen Blick</h4>
    <ul>
      <li>Stromproduktion</li>
      <ul>
        <li>Ausbau von PV Dach auf etwa 36 GW bis 2050</li>
        <li>Keine PV Alpin, PV-Freifläche und Windanlagen</li>
        <li>Verlängerung Laufzeit KKW Gösgen und Leibstadt auf 70 Jahre</li>
        <li>Bau von zwei neuen Reaktoren mit je 1.3 GW Leistung</li>
      </ul>
      <li>Nachfrage</li>
      <ul>
        <li>Gesamtstromnachfrage von etwa 88 TWh in 2050</li>

        <li>Nachfrage durch Elektromobilität von 15 TWh in 2050</li>

        <li>Nachfrage durch Wärmepumpen von knapp 10 TWh in 2050</li>

        <li>Nachfrage durch Wasserstoffelektrolyse von 9 TWh in 2050</li>
      </ul>
      <li>Importe</li>
      <ul>
        <li>
          Importe in die Schweiz werden zugelassen, Leitungskapazitäten
          orientieren sich an den Leitungsausbauplänen der europäischen
          Netzbetreiber (ENTSO-E)
        </li>
      </ul>
      <ul />
    </ul></Collapsable
  >

  <Collapsable title="Energieperspektiven 2050+">
    <p>
      Die Energieperspektiven 2050+ der Schweizer Regierung stellen
      unterschiedliche Wege dar, wie Klimaneutralität in 2050 mit
      Stromversorgungssicherheit vereint werden kann. Wir nutzen das
      Basisszenario, in dem das letzte Atomkraftwerk nach 50 Jahren Laufzeit
      abgestellt wird. Die Hauptbestandteile dieses Szenarios sind:
    </p>

    <ul>
      <li>Ausbau erneuerbarer Energien</li>

      <li>Gesteigerte Effizienz</li>

      <li>Elektrifizierung von Verkehr und Gebäuden</li>

      <li>Ausgleich von jährlichen Im- und Exporten</li>

      <li>Einsatz neuer Technologien</li>
    </ul>

    <h4>Ausbau erneuerbarer Energien</h4>

    <p>
      Der Ausbau von allen erneuerbaren Energien, so wie Photovoltaik oder
      Biomasse, ist ein Hauptfokus dieses Szenarios (1). Bis 2050 soll fast der
      gesamte Strom in der Schweiz aus erneuerbaren Quellen stammen.
      Wasserkraft, die im Vergleich zu 2019 um 10% ausgebaut werden soll, und
      Photovoltaik (Kapazität 37.5 GW in 2050, etwa 35 TWh Produktion) sind
      hierfür die zwei wichtigsten Technologien (2).
    </p>

    <h4>Gesteigerte Effizienz</h4>

    <p>
      Gesteigerte Effizienz aller Geräte tragen dazu bei, den Anstieg der
      Nachfrage nach Strom trotz Elektrifizierung gering zu halten (3). Die
      Effizienz von anderen Prozessen soll auch weiter erhöht werden (4).
      Entsprechend nimmt die Stromnachfrage pro Person ab, auch wenn die
      Bevölkerung und gesamte Stromnachfrage wächst (5).
    </p>

    <h4>Elektrifizierung von Verkehr und Gebäuden</h4>

    <p>
      In Gebäuden sollen Wärmepumpen und Fernwärme gas- und ölbasierte Wärme
      ablösen (6). Fahrzeuge sollen mit Batterien angetrieben werden, mit
      Ausnahme von Lastwagen. Hier bleibt die Option, Brennstoffe wie
      Wasserstoff zu nutzen, offen. Batterien sind momentan nicht auf dem
      technischen Stand, Fahrzeuge ab einer gewissen Schwere gut antreiben zu
      können. Deshalb können andere erneuerbare Brennstoffe ein besserer Ersatz
      für fossile Brennstoffe sein.
    </p>

    <h4>Ausgleich von jährlichen Im- und Exporten</h4>

    <p>
      Dieses Szenario der Energieperspektiven 2050+ nimmt an, dass die Schweiz
      in den Sommermonaten Strom exportiert und im Winter Strom importiert. Im
      Winter kann der gesamten Nachfrage durch Stromspeicher im Szenario
      voraussichtlich nicht nachgekommen werden. Dennoch nimmt das Szenario an,
      dass die Im- und Exporte in der Jahresbilanz ausgeglichen sein werden (7).
    </p>

    <h4>Einsatz neuer Technologien</h4>

    <p>
      Zwei weitere neue Technologien spielen in diesem Szenario eine Rolle:
      Carbon Capture and Storage (CCS) und die Herstellung von Wasserstoff durch
      erneuerbaren Strom. Das Szenario sieht vor, CCS in Zement- und
      Chemiewerken einzusetzen (8). Wasserstoff soll durch erneuerbaren Strom
      hergestellt werden. Im Szenario wird angenommen, dass dieser Prozess erst
      in den 2040er Jahren vor allem im Schwerverkehr als Treibstoff zum Einsatz
      kommen wird (9).
    </p>

    <h4>Wichtigste Eckdaten auf einen Blick</h4>

    <ul>
      <li>Stromproduktion</li>

      <ul>
        <li>Ausbau von Photovoltaik auf 37.5 GW bis 2050</li>

        <li>Ausbau von Wind auf 2.2 GW bis 2050</li>

        <li>Ausbau Kapazität von Wasserkraft um etwa 10%</li>

        <li>Ausstieg Kernenergie nach 50 Jahren Laufzeit</li>

        <li>
          Abnahme der Kapazität von fossilen Kraftwerken auf 0.1 GW in 2050
        </li>
      </ul>

      <li>Nachfrage</li>

      <ul>
        <li>Stromnachfrage insgesamt etwa 77 TWh in 2050</li>

        <li>E-Mobilität: von fast 0 TWh/a auf etwa 13 TWh/a</li>

        <li>Wärmepumpen: knapp 12 TWh/a in 2050</li>

        <li>Wasserstoffherstellung: knapp 3 TWh in 2050</li>
      </ul>

      <li>Importe</li>

      <ul>
        <li>
          Importkapazitäten orientieren sich an dem TYNDP 2018 Sustainable
          Transition Scenario
        </li>
      </ul>
    </ul>

    <p>…</p>

    <ol>
      <li>
        Bundesamt für Energie (a), “<a
          href="https://www.bfe.admin.ch/bfe/de/home/politik/energieperspektiven-2050-plus.html"
          title="Energieperspektiven 2050+ - 

          Zusammenfassung Zusammenfassung der 

      wichtigsten Ergebnisse” (Bern: Bundesamt für 

          Energie, November 26, 2020), Seite 25–27"
          class="link link--external"
          target="_blank"
          >Energieperspektiven 2050+ - Zusammenfassung der wichtigsten
          Ergebnisse” (Bern: Bundesamt für Energie, November 26, 2020), Seite
          25–27.</a
        >
      </li>

      <li>Bundesamt für Energie (a), Seite 21.</li>

      <li>
        Bundesamt für Energie (b), “<a
          href="https://www.bfe.admin.ch/bfe/de/home/politik/energieperspektiven-2050-plus.html"
          title="Energieperspektiven 2050+ Kurzbericht” 

          (Bern: Bundesamt für Energie, November 26, 2020), Seite 14"
          target="_blank"
          class="link link--external"
          >Energieperspektiven 2050+ Kurzbericht” (Bern: Bundesamt für Energie,
          November 26, 2020), Seite 14</a
        >
      </li>

      <li>
        Bundesamt für Energie (a), “Energieperspektiven 2050+ - Zusammenfassung
        der wichtigsten Ergebnisse,” Seite 3, 14.
      </li>

      <li>
        Bundesamt für Energie (b), “Energieperspektiven 2050+ Kurzbericht,”
        Seite 18.
      </li>

      <li>
        Bundesamt für Energie (a), “Energieperspektiven 2050+ - Zusammenfassung
        der wichtigsten Ergebnisse,” Seite 7.
      </li>

      <li>Bundesamt für Energie (a), Seite 22.</li>

      <li>Bundesamt für Energie (a), Seite 21.</li>
      <li>
        Bundesamt für Energie (a), “Energieperspektiven 2050+ - Zusammenfassung
        der wichtigsten Ergebnisse,” Seite 7.
      </li>
    </ol>
  </Collapsable>
  <Collapsable title="Weiter wie bisher">
    <p>
      Das Szenario Weiter wie bisher stellt dar, wie die Schweizer
      Stromversorgung sich entwickeln könnte, wenn derzeitige Aus- und
      Abbautrends hauptsächlich linear weiterlaufen und Restriktionen für den
      Ausbau von Stromquellen hoch bleiben. Die wichtigsten Eckpunkte dieses
      Szenarios sind:
    </p>
    <ol>
      <li>Ausbau Wind & Sonne gemäss historischen Trends</li>
      <li>Kein Ausbau neuer Stromquellen wie Wasserstoff</li>
      <li>Leichte Abnahme Wasserkraft</li>
      <li>Keine neuen Gaskraftwerke & Phase-Out fossiler Kraftwerke</li>
    </ol>
    <h4>Ausbau Wind & Sonne gemäss historischen Trends</h4>
    <p>
      Photovoltaik und Windenergie entwickeln sich gemäss dem historischen
      Ausbau bis 2020 weiter und liegen bei einer zusätzlichen Kapazität von 475
      MW pro Jahr für Photovoltaik und 12 MW pro Jahr für Wind. Windenergie
      erfährt somit einen ähnlichen Ausbau wie Biomasse mit 10 MW pro Jahr.
      Besonderen Einfluss nehmen hier Bewilligungen für den Bau von neuen
      Anlagen, die Ausbau verlangsamen oder verhindern können.
    </p>
    <h4>Kein Ausbau neuer Stromquellen wie Wasserstoff</h4>
    <p>
      Neue Stromquellen werden nicht eingeführt. Dies bedeutet, dass in der
      Schweiz Technologien wie Wasserstoffnutzung für Stromproduktion
      (Power-to-Gas) oder Carbon Capture and Storage keine Rolle spielen. Auch
      Geothermie wird nicht mit dem Zweck der Stromproduktion eingeführt.
    </p>
    <h4>Leichte Abnahme Wasserkraft</h4>
    <p>
      Bei der Entwicklung der Stromproduktion durch Wasserkraft nehmen wir an,
      dass aufgrund von Restwasserbestimmungen weniger Ausbau als Abbau
      stattfindet. Insgesamt führt dies zu einer Abnahme von fast 3 TWh in der
      Stromproduktion im Jahr 2050, die sich auf Laufwasser- und Speicherkraft
      verteilt.
    </p>
    <h4>Keine neuen Gaskraftwerke & Phase-Out fossiler Kraftwerke</h4>
    <p>
      Wir nehmen an, dass die Kapazität der Gaskraftwerke zur Stromproduktion
      nicht ausgebaut wird. Gleichzeitig läuft der Trend das Phase-Outs fossiler
      Kraftwerke weiter; der Bestandteil fossiler Energie in der Stromproduktion
      fällt demnach, entsprechend der Altersstruktur, ab.
    </p>
    <h4>Wichtigste Eckdaten auf einen Blick</h4>
    <ul>
      <li>Stromproduktion</li>
      <ul>
        <li>Ausbau von Photovoltaik auf etwa 17 GW bis 2050</li>
        <li>Ausbau von Wind auf etwa 0.4 GW bis 2050</li>
        <li>Weder Gas-to-Power noch neue Erdgasanlagen zur Stromproduktion</li>
        <li>
          Ausstieg Kernenergie nach 50 Jahren Laufzeit für Gösgen und Leibstadt,
          und nach 55 Jahren Laufzeit für Beznau
        </li>
        <li>
          Rückgang bestehender fossiler Kleinkraftwerke entsprechend ihrer
          Lebensdauer
        </li>
      </ul>
      <li>Nachfrage</li>
      <ul>
        <li>Gesamtstromnachfrage von etwas über 80 TWh in 2050</li>
        <li>E-Mobilität: von fast 0 TWh/a auf etwa 13 TWh in 2050</li>
        <li>Wärmepumpen: etwa 9 TWh in 2050</li>
        <li>Wasserstoffherstellung: 3 TWh in 2050</li>
      </ul>
      <li>Importe</li>
      <ul>
        <li>
          Ausbau der Netzinfrastruktur entsprechend ENTSO-E 2020 bis 2034,
          danach keine weiteren Interkonnektoren zu den Nachbarstaaten
        </li>
      </ul>
    </ul>
  </Collapsable>

  <Collapsable title="Nationalrat Grossen">
    <h3>Grossen</h3>

    <p>
      In der Roadmap Grossen zeigt Nationalrat Jürgen Grossen ein Szenario auf,
      wie die Schweiz bis 2050 Emissionen minimieren, keine fossilen Brennstoffe
      benötigen und bilanziell unabhängig von Stromimporten werden kann. Dazu
      hat er fünf Wegmarker definiert (1):
    </p>

    <ul>
      <li>Steigerung der Stromeffizienz um 40 Prozent</li>

      <li>Elektrifizierung der Sektoren Verkehr und Gebäude</li>

      <li>Ausbau von Photovoltaik</li>

      <li>Saisonspeicher durch Wasserstoff</li>

      <li>
        Harmonisierung von Stromverbrauch und –produktion (nicht Teil des
        Modells)
      </li>
    </ul>

    <h4>Steigerung der Stromeffizienz um 40 Prozent</h4>

    <p>
      Bei effizienterer Nutzung von Elektrizität wird für dieselben Prozesse
      weniger Strom gebraucht oder weniger Elektrizität «verschwendet».
      Letzteres passiert beispielsweise, wenn Geräte auf Stand-by sind oder wenn
      sie viel Abwärme produzieren. Grossen will die Stromeffizienz steigern,
      indem intelligente Systeme in Gebäuden und allgemein effizientere Geräte
      genutzt werden (zum Beispiel: Geräte werden automatisch vollständig
      abgeschaltet, weniger Abwärme wird produziert) (2).
    </p>

    <h4>Elektrifizierung von Gebäuden und Verkehr</h4>

    <p>
      In Gebäuden wird auf fossilen Brennstoffen basiertes Heizen durch
      Wärmepumpen abgelöst (3).
    </p>

    <p>
      In Gebäuden wird auf fossilen Brennstoffen basiertes Heizen durch
      Wärmepumpen abgelöst (3). Im Verkehr werden individueller Personenverkehr
      und der lokale Güterverkehr elektrifiziert. Schwere Fahrzeuge wie
      Lastwagen werden mit CO2-neutralem Brennstoff betrieben. Dies kann
      Wasserstoff sein, der mithilfe von erneuerbaren Energien hergestellt wurde
      (4).
    </p>

    <h4>Ausbau von Photovoltaik</h4>

    <p>
      Grossen nimmt an, dass bis 2050 knapp 44 GW Photovoltaik jährlich 40 TWh
      Strom produzieren werden. Das bedeutet, dass zwei Drittel aller geeigneten
      Dächer und Fassaden mit Photovoltaiksystemen bedeckt sein werden (5).
      Wasserkraft und Photovoltaik werden somit die zwei wichtigsten Lieferanten
      für Strom in der Schweiz.
    </p>

    <h4>Saisonspeicher durch Wasserstoff</h4>

    <p>
      Wenn Elektrizität saisonal gespeichert wird, bedeutet das, dass sie in
      Form eines anderen Brennstoffs für Rückverstromung zu einem späteren
      Zeitpunkt gespeichert wird. In diesem Fall wird überschüssige Elektrizität
      von Photovoltaik im Sommer genutzt, um durch einen Prozess wie Elektrolyse
      Wasserstoff zu produzieren. Dieser kann dann später rückverstromt oder als
      Treibstoff für Schwertransporter genutzt werden. Während Batterien und
      Pumpspeicher die tägliche Versorgung mit Elektrizität sicherstellen, soll
      Wasserstoff die jahresübergreifende Versorgung gewährleisten (6).
    </p>

    <h4>Harmonisierung von Stromverbrauch und –produktion</h4>

    <p>
      Die Harmonisierung von Elektrizitätsnachfrage und -herstellung wird durch
      Investitionen in intelligente Netzwerke (Smart Grids) und dezentralisierte
      Speicher erreicht (7). Das kann etwa heissen, dass überflüssige
      Elektrizität von der Photovoltaikanlage auf dem Dach in der Batterie des
      Autos in der Garage gespeichert wird. Der Power Switcher nimmt allerdings
      keine volle Integration an, da es zurzeit hohe Unsicherheiten mit Blick
      auf Regulierung und Implementierung gibt.
    </p>

    <h4>Wichtigste Eckdaten auf einen Blick</h4>

    <ul>
      <li>Stromproduktion</li>

      <ul>
        <li>Ausbau von Photovoltaik auf knapp 44 GW bis 2050</li>

        <li>Keine Veränderung in Kapazitäten von Wasserkraft</li>

        <li>Ausstieg Kernenergie nach 60 Jahren Laufzeit</li>

        <li>Bestand fossiler Kraftwerke bleibt konstant</li>
      </ul>

      <li>Nachfrage</li>

      <ul>
        <li>Gesamtstromnachfrage fast 90 TWh in 2050</li>

        <li>E- Mobilität: von fast 0 TWh/a auf knapp 23 TWh/a</li>

        <li>Wärmepumpen: 5 TWh/a in 2050</li>

        <li>Wasserstoffherstellung: knapp 17 TWh in 2050</li>
      </ul>

      <li>Importe</li>

      <ul>
        <li>
          Importkapazitäten orientieren sich an den TYNDP 2018 Sustainable
          Transition Scenario
        </li>
      </ul>
    </ul>

    <p>…</p>

    <ol>
      <li>
        <a
          href="https://roadmap-grossen.ch/wp-content/uploads/2021/03/202102_Roadmap-Grossen-Fassung-2.pdf"
          title="Jürg Grossen, “Die Roadmap Grossen,” February 2021, page 3"
          target="_blank"
          class="link link--external"
          >Jürg Grossen, “Die Roadmap Grossen,” February 2021, Seite 3.</a
        >
      </li>

      <li>Grossen, Seite 5.</li>

      <li>Grossen, Seite 5.</li>

      <li>Grossen, Seite 6.</li>

      <li>Grossen, Seite 6.</li>

      <li>Grossen, Seite 7.</li>

      <li>Grossen, Seite 7.</li>
    </ol>
  </Collapsable>

  <Collapsable title="Nationalrat Nordmann">
    <p>
      Nationalrat Roger Nordmann setzt in seinem zweiten Buch zur Schweizer
      Energiewende bei der Stromerzeugung stark auf Photovoltaik und beim
      Verbrauch von Energie auf Elektrifizierung, um die  Treibhausgasemissionen
      im Land bis 2050 zu minimieren (1). Die wichtigsten Bestandteile seines
      Szenarios sind:
    </p>

    <ul>
      <li>Starker Ausbau von Photovoltaik</li>

      <li>Ausbau von Wind und Hydro-Speicher</li>

      <li>Power-to-Gas als Saisonalspeicher</li>

      <li>Elektrifizierung des Strassenverkehrs und der Gebäudewärme</li>

      <li>Dekarmonisierung der Industrie</li>
    </ul>

    <p>
      Im Vergleich zu seinem ersten Buch (2) umfasst die Dekarbonisierung des
      Landes zusätzlich auch die Industrie und ist damit deutlich
      ambitionierter. Zudem wird auf die Verwendung von fossilem Erdgas komplett
      verzichtet und stattdessen auf Erzeugung und Verwendung von synthetischem
      Gas unter Einsatz von Strom gesetzt. Das Szenario im PowerSwitcher ist als
      Näherung zur im Buch vorgeschlagenen Strategie zu betrachten. Der
      PowerSwitcher beschränkt sich auf die Analyse des Elektrizitätssystems und
      bietet keine Sicht auf den gesamten Energiesektor oder die Emissionen.
    </p>

    <h4>Ausbau von Photovoltaik</h4>

    <p>
      Im Jahr 2050 sieht Nordmann eine Stromerzeugung aus Photovoltaik von rund
      76 TWh, wo zu 72 GW an Leistung benötigt wird. Das bedeutet, dass das
      aktuell geschätzte Potential auf den Dächern und an Fassaden vollständig
      ausgeschöpft wird sowie zusätzlich alpine Solaranlagen im Umfang von 7 GW
      realisiert werden. Rund 10 TWh können nicht genutzt werden, da sie
      Kapazitäten zur Speicherung (Power-to-Gas, Batterien, Pumpspeicher) zum
      Zeitpunkt ihrer Produktion überschreiten. Im besten Fall kann der
      Überschuss exportiert werden, im schlechten Fall muss die Produktion
      abgeregelt werden (Peak-Shaving). Photovoltaik wird so eine der
      Hauptquellen für Strom in der Schweiz. Der Solarstrom liefert laut
      Nordmann den Hauptbeitrag zur Dekarbonisierung sowie den Ausstieg aus der
      Kernenergie.
    </p>

    <h4>Flankierung durch Wind und Wasser</h4>

    <p>
      Das Szenario enthält einen Zubau von Windkraft im Umfang von 4 GW Leistung
      und eine jährliche Bruttoproduktion von 6 TWh. Davon fallen rund 4 TWh im
      Winter an, was ein wichtiger Beitrag zur Versorgungssicherheit ist. Die
      Stromerzeugung mittels Wasserkraftspeicher steigt durch die Erhöhung von
      Staumauern um 2 TWh. Das entspricht der zusätzlichen Stromproduktion bei
      Realisierung der Projekte, welche beim runden Tisch Wasserkraft 2021
      identifiziert wurden.
    </p>

    <h4>Power-to-Gas als Saisonalspeicher</h4>

    <p>
      Überschüssiger Solarstrom soll genutzt werden, um
      Power-to-Gas-Technologien voranzutreiben. Dadurch produzierte Brennstoffe,
      wie Wasserstoff, können dann genutzt werden, um wieder Strom herzustellen,
      wenn es mehr Nachfrage als Produktion gibt. Eine Lücke zwischen erhöhter
      Nachfrage und Herstellung wird es besonders im Winter geben. Deshalb wird
      der Brennstoff, der im Sommer produziert wird, in saisonalen Speichern für
      die Rückverstromung im Winter eingelagert. Aufgrund der hohen
      Effizienzverluste wird die Elektrolyse, Speicherung und Rückverstromung
      auf das Minimum beschränkt.
    </p>

    <h4>Elektrifizierung des Strassenverkehrs und des Heizens in Gebäuden</h4>

    <p>
      Die Elektrifizierung des Strassenverkehrs bedeutet, dass keine fossilen
      Brennstoffe mehr in diesem Bereich eingesetzt werden. Der Autor nimmt eine
      vollständige Renovierung und effiziente Elektrifizierung des Heizens in
      Gebäuden durch den Einsatz von Wärmepumpen an. Die effiziente
      Elektrifizierung bedeutet, dass die Steigerung der Nachfrage nach Strom so
      klein wie möglich gehalten wird.
    </p>

    <h4>Wichtigste Eckdaten auf einen Blick</h4>

    <p>
      Das Buch beschreibt das Zielbild für 2050. Wir stellen die Entwicklung
      dahin in einem linearen Pfad dar, soweit keine anderen Informationen
      vorliegen.
    </p>

    <ul>
      <li>Stromproduktion</li>

      <ul>
        <li>Photovoltaik 72 GW (Bruttoproduktion 76 TWh/Jahr)</li>

        <li>Windkraft 4 GW (Bruttoproduktion 6 TWh/Jahr)</li>

        <li>Wasserkraftspeicher Ausbau um 2 TWh</li>

        <li>GAusstieg Kernenergie nach 60 Jahren Laufzeit</li>

        <li>Renewable Gas-to-Power 2 GW</li>
      </ul>

      <li>Nachfrage</li>

      <ul>
        <li>Stromnachfrage etwa 121 TWh (inkl. Verluste) in 2050</li>

        <li>davon E- Mobilität 17 TWh/a</li>

        <li>davon Wärmepumpen: 6 TWh/a</li>

        <li>davon Wasserstoffherstellung: 24 TWh/a</li>
      </ul>

      <li>Importe</li>

      <ul>
        <li>Kapazitäten werden auf dem jetztigen Stand gehalten</li>

        <li>Beschränkung im Winter auf 1 TWh pro Wintermonat</li>
      </ul>
    </ul>
    <p>…</p>
    <ol>
      <li>
        Roger Nordmann, Klimaschutz und Energiesicherheit, (Basel: Zytglogge
        Verlag, Schwabe Verlagsgruppe AG, 20223)
      </li>

      <li>
        <a
          href="https://rogernordmann.ch/buch-sonne-fur-den-klimaschutz-ein-solarplan-fur-die-schweiz/"
          title="Roger Nordmann, “Kapitel 1. Das Wichtigste in Kürze,” in Sonne für den Klimaschutz: Ein Solarplan für die Schweiz, 2te Ausgabe (Basel: Zytglogge Verlag AG, 2019)."
          target="_blank"
          class="link link--external"
          >Roger Nordmann, “Kapitel 1. Das Wichtigste in Kürze,” in Sonne für
          den Klimaschutz: Ein Solarplan für die Schweiz, 2te Ausgabe (Basel:
          Zytglogge Verlag AG, 2019).</a
        >
      </li>
    </ol>
  </Collapsable>

  <Collapsable title="Nationalrat Wasserfallen" />

  <Collapsable title="Ehem. stv. ENSI Direktor Schwarz">
    <p>
      Der ehemalige stellvertretende Direktor des Eidgenössischen
      Nuklearsicherheitsinspektorates Georg Schwarz, zeigt in seinem Energieblog
      georgschwarz.ch auf, mit welchen Produktionsmitteln eine klimaneutrale
      Schweiz mit Energie versorgt werden könnte.
    </p>

    <p>
      In seinem Szenario werden die fossilen Energieträger mittels Wärmepumpen
      zur Heizung und Elektrofahrzeuge bei der Mobilität ersetzt. Im Unterschied
      zu den meisten anderen Szenarien, werden die erzielbaren
      Verbrauchseinsparungen und Effizienzgewinne weniger optimistisch
      beurteilt. Im vorliegenden Szenario wird deshalb konservativ von einem um
      80% höheren Stromverbrauch ausgegangen. Die Hauptbestandteile dieses
      Szenarios sind:
    </p>

    <ul>
      <li>Konservative angesetzte Effizienzgewinne</li>

      <li>Wärmepumpen für Heizung und Warmwasser</li>

      <li>Elektrifizierung des Strassenverkehrs</li>

      <li>Dekarbonisierung des Industrieverbrauchs</li>

      <li>Maximierung der Stromproduktion im Winter</li>

      <li>Versorgungssicherheit</li>

      <li>Negativemissionen</li>
    </ul>

    <h4>Konservative angesetzte Effizienzgewinne</h4>

    <p>
      Die zukünftige Entwicklung des Energieverbrauches hängt stark von den
      gegenläufigen Parametern Bevölkerungszahl und Energieeffizienz ab und ist
      mit grossen Unsicherheiten behaftet. Da die Umstellung des Energiesystems
      sehr lange dauert, sollte eine Energiestrategie nicht auf das Eintreffen
      optimistischer Annahmen hoffen, sondern auf einer konservativen Basis
      stehen. Im vorliegenden Szenario wird für das Jahr 2050 eine Reduktion des
      Endenergieverbrauches von rund 20% angenommen. Bei einem prognostizierten
      Bevölkerungswachstum von 20% entspricht dies einem pro Kopf
      Effizienzgewinn von 33%.
    </p>

    <h4>Wärmepumpen für Heizung und Warmwasser</h4>

    <p>
      Es wird angenommen, dass die Heizungen aller Gebäude sowie die
      Warmwasserproduktion mittels Wärmepumpen erfolgt. Für den
      Wärmenutzungsgrad wurden heute erzielbare Werten angenommen. Beim
      zukünftigen Wärmebedarf wird von einer Reduktion von 20% pro Quadratmeter
      Energiebezugsfläche ausgegangen.
    </p>

    <h4>Elektrifizierung des Strassenverkehrs</h4>

    <p>
      Der Strassenverkehr wird weitgehend auf Elektrofahrzeuge umgestellt.
      Einzig im Bereich des Schwerverkehrs wird ein Teil der Lastwagen mit
      inländischem Biogas betrieben.
    </p>

    <h4>Dekarbonisierung des Industrieverbrauchs</h4>

    <p>
      Fossile Energieträger werden in der Industrie mehrheitlich zur Erzeugung
      von Prozesswärme eingesetzt. Ein Teil davon kann durch Biomasse ersetzt
      werden. Der fehlende Rest muss elektrisch erzeugt werden, was den
      Strombedarf erhöht.
    </p>

    <h4>Maximierung der Stromproduktion im Winter</h4>

    <p>
      Der Stromverbrauch im Winter ist bereits heute höher als im Sommer und
      wird aufgrund der zusätzlichen Wärmepumpen und Elektrifizierung des
      Verkehrs noch weiter steigen. Weil die Gebäudephotovoltaik im Winter nur
      einen geringen Beitrag zur Bedarfsdeckung liefert, kommen im vorliegenden
      Szenario Produktionstechnologien mit einem hohen Winterstromanteil,
      insbesondere Windenergie und alpine Solaranlagen zum Tragen. Um den Bedarf
      zu decken werden 43 TWh/a aus Windenergie und 36 TWh/a aus alpinen
      Solaranagen benötigt. Dies entspricht 5'400 Windturbinen und alpine
      Freiflächenanlagen mit einer Gesamtfläche von 140 km2.Damit werden die zur
      Verfügung stehenden Produktionspotenziale weitgehend ausgeschöpft, aber
      nicht überschritten.
    </p>

    <h4>Versorgungssicherheit</h4>

    <p>
      Keines unserer Nachbarländer setzt zur Erreichung seiner Klimaziele auf
      eine Exportstrategie, die es der Schweiz erlauben würde im Winter grössere
      Energiemengen aus dem Ausland zu beziehen. Im vorliegenden Szenario wird
      deshalb davon ausgegangen, dass die Stromimporte im Winter gegenüber heute
      nicht gesteigert werden können und auch kein Biogas oder strombasierte
      Energieträger zur Deckung des Winterbedarfs importiert werden können.
    </p>

    <h4>Negativemissionen</h4>

    <p>
      Gewisse Treibhausgasemissionen, insbesondere in der Industrie und der
      Landwirtschaft, lassen sich nicht vermeiden und müssen mittels sogenannter
      Negativemissionen kompensiert werden. Dazu wird aus Rauchgasen von
      Verbrennungsprozessen oder auch direkt aus der Atmosphäre CO2 abgeschieden
      und anschliessend dauerhaft im Untergrund gespeichert. Der für die
      CO2-Abscheidung benötigte Strombedarf ist im vorliegenden Szenario
      berücksichtigt.
    </p>

    <h4>Wichtigste Eckdaten auf einen Blick</h4>

    <ul>
      <li>Stromproduktion</li>

      <ul>
        <li>Ausbau der alpinen Photovoltaik auf 27 GW bis 2050</li>

        <li>Massiver Ausbau der Windenergie auf 22 GW bis 2050</li>

        <li>Moderate Steigerung der Kapazitäten von Wasserkraft</li>

        <li>Ausstieg aus der Kernenergie nach 60 Jahren Laufzeit</li>

        <li>
          Dank der hohen Winterstromproduktion werden keine Gaskraftwerke
          benötigt
        </li>
      </ul>

      <li>Nachfrage</li>

      <ul>
        <li>Stromnachfrage insgesamt etwa 112 TWh in 2050</li>

        <li>E- Mobilität: von fast 16 TWh/a in 2050</li>
        <li>Wärmepumpen: 22 TWh/a in 2050</li>
        <li>Negativemissionen: 8 TWh in 2050</li>
      </ul>

      <li>Importe</li>
      <ul>
        <li>Kapazitäten werden auf dem jetzigen Stand gehalten</li>
      </ul>
    </ul>
  </Collapsable>

  <Collapsable title="Helion">
    <p>
      Das Helion-Modell fokussiert sich auf die Bedeutung der Photovoltaik für
      den Umbau des Energiesystems bis 2050 und für die Gestaltung einer
      sicheren Stromversorgung auf Basis erneuerbarer Energien. Die wichtigsten
      Eckpunkte sind:
    </p>

    <ul>
      <li>
        Beschleunigter Ausbau der Photovoltaik bis 2050 ohne Wachstumssprünge
        und ohne alpine oder andere Freiflächen. Der maximale Ausbau lässt sich
        mit der Hälfte der verfügbaren Dachflächen realisieren.
      </li>

      <li>
        Verlässliche Finanzierungsinstrumente basierend auf Zielen und
        Ausbaupfad
      </li>

      <li>
        Kontinuierlicher Aufbau von Fachkräften dank verlässlichem Ausbau der
        Photovoltaik
      </li>

      <li>
        Direkte und indirekte Nutzung der Photovoltaik garantiert solide
        Versorgungssicherheit.
      </li>

      <li>
        Elektrifizierung der Mobilität und Batteriespeicher als Teil der
        Versorgungssicherheit
      </li>
    </ul>

    <h4>Beschleunigter Ausbau der Photovoltaik ohne Wachstumssprünge (1)</h4>

    <p>
      Im Helion Szenario erfolgt der Zubau der Photovoltaik kontinuierlich stark
      wachsend und produziert bis 2050 52 TWh Strom, was 52 Prozent des laut
      Helion verfügbaren Potenzials entspricht. Die Anteile der einzelnen
      Marktsegmente betragen: EFH (23%); MFH (22%), Industrie/Gewerbe (29%),
      Landwirtschaft (8%), öffentliche Dienste (2%), Parkplätze/ Lärmschutzwände
      Strassen/ weitere Infrastruktur (16%). Alpine oder sonstige Freiflächen
      sind nicht zwingend nötig, die bestehenden Infrastrukturen genügen für die
      Bereitstellung einer sicheren Winterversorgung. Helion sieht einen rasch
      einsetzenden beschleunigten Zubau der Photovoltaik als wichtig an, weil
      laut Helion mehr PV-Kapazität an sich PV-Winterdefizite kompensiert und
      damit die allgemeine Versorgungssicherheit erhöht.
    </p>

    <h4>
      Verlässlicher Finanzierungsrahmen basierend auf Zielen und Ausbaupfad
    </h4>

    <p>
      Ein PV-Ausbau bis 2050 in der Grössenordnung von 52 TWh ist laut diesem
      Szenario technisch machbar und grösstenteils auf bestehenden
      Liegenschaften und Infrastrukturen umsetzbar. Mit dem bestehenden
      Netzzuschlag von 2.3 Rp./kWh ist der Ausbau aber nur bis zu einem
      jährlichen Zubau von 700 MW finanzierbar, was weit unter der nötigen
      jährlichen Zubaumenge von 2'200 MW liegt. Die Möglichkeit, den
      Netzzuschlagsfonds zu verschulden, ist ein gangbarer Weg, der aber bereits
      ab 2023 funktionieren muss.
    </p>

    <h4>Aufbau von Fachkräften braucht Stabilität und Perspektiven</h4>

    <p>
      Helion nimmt an, dass es für einen nachhaltigen und soliden Ausbau der
      Photovoltaik ein kontinuierliches Wachstum braucht, das mit dem
      Rekrutierungspotential neuer Fachkräfte Schritt hält. Entsprechend sind
      laut ihnen Entwicklungssprünge beim Ausbau sind deshalb dringend zu
      vermeiden. Nur wenn eine Branche Stabilität und Perspektive vermittelt,
      ist eine wachstumsorientierte Rekrutierungsstrategie von Fachkräften
      möglich (Beispiel Helion: in 1.5 Jahren 250 neue Stellen geschaffen).
      Schweizweit geht Helion von 21'000 zusätzlichen Fachkräften aus, die sich
      über Quereinsteiger oder den Aufbau einer eigenen Berufslehre generieren
      lassen. Sollen Fachkräfte dereinst in genügender Zahl verfügbar sein, muss
      mit der Rekrutierung sofort gestartet werden und damit auch mit einem
      beschleunigten Zubau der Photovoltaik.
    </p>

    <h4>
      Direkte und indirekte Nutzung der Photovoltaik soll solide
      Versorgungssicherheit garantieren
    </h4>

    <p>
      Der im Helion Szenario anfallende PV-Überschuss im Sommer ist konsequent
      in synthetische Brenn- und Treibstoffe umzuwandeln, einsetzbar
      beispielsweise für Prozesswärme in der Industrie, für eine klimaneutrale
      Fliegerei oder für einen erneuerbaren Betrieb von dezentralen
      Wärme-Kraft-Kopplungsanlagen (WKK) in den Wintermonaten, die nur bei
      Bedarf und bei Knappheit betrieben werden sollen. Im Szenario erhöhen 52
      TWh Strom aus Photovoltaik die Versorgungssicherheit der Schweiz doppelt
      durch die Bereit-stellung von genügenden Strommengen an sich und durch die
      Umwandlung und Speicherung von überschüssigem Sommerstrom in vielfältig
      einsetzbare synthetische Brenn- und Treibstoffe.
    </p>

    <h4>
      Elektrifizierung der Mobilität und Batteriespeicher als Teil der
      Versorgungssicherheit
    </h4>

    <p>
      Der Anteil der E-Autos am gesamten Neuwagenmarkt nimmt rasant zu. Mit den
      Elektroautos stehen in Zukunft viele kleine, dezentral platzierte und
      steuerbare Lasten und Speicher zur Verfügung. Bidirektional einsetzbare
      Fahrzeugspeicher schaffen Flexibilität für Stunden oder wenige Tage. VW
      steht kurz vor der Markteinführung dieser neuen Technologie. Damit
      erreicht die E-Mobilität eine Tagesspeicherkapazität im Umfang einer
      heutigen Tagesproduktion der Schweizer Atomkraftwerke mit dem Unterschied,
      dass die Lasten steuerbar und flexibel einsetzbarer sind.
    </p>
    <h4>Wichtigste Eckdaten auf einen Blick</h4>

    <ul>
      <li>Stromproduktion</li>

      <ul>
        <li>Ausbau von PV auf 57 GW in 2050</li>

        <li>Ausbau von Wind auf 1.7 GW bis 2050</li>

        <li>Kapazität von Wasserkraft bleibt gleich</li>

        <li>Ausstieg aus der Kernenergie nach 60 Jahren Laufzeit</li>

        <li>
          Abnahme der Kapazität von fossilen Kraftwerken auf 0.2 GW in 2050
        </li>
      </ul>

      <li>Nachfrage</li>

      <ul>
        <li>Stromnachfrage insgesamt etwa 88 TWh in 2050</li>

        <li>E-Mobilität: von fast 0 TWh/a auf 22 TWh/a</li>
        <li>Wärmepumpen: 6 TWh/a in 2050</li>
        <li>Wasserstoffherstellung: 10 TWh in 2050</li>
      </ul>

      <li>Importe</li>
      <ul>
        <li>
          Importe in die Schweiz werden zugelassen, Leitungskapazitäten
          orientieren sich an den Leitungsausbauplänen der europäischen
          Netzbetreiber (ENTSO-E)
        </li>
      </ul>
    </ul>

    <p>…</p>

    <ol>
      <li>
        Remund, J., Albrecht, S., Stickelberger, D.: Das Schweizer PV-Potenzial
        basierend auf jedem Gebäuden, 2019 (Basis: Meteotest Studie im Auftrag
        von BFE (April 2019)
      </li>
    </ol>
  </Collapsable>
  <Collapsable title="Swissolar">
    <p>
      Das Modell von Swissolar, dem schweizerischen Fachverband für
      Sonnenenergie, zeigt auf, wie die Schweiz bis 2050 auf fossile Brennstoffe
      verzichten und zugleich bilanziell wie bereits heute unabhängig von
      Stromimporten sein kann. Wichtigste Eckpunkte sind (1):
    </p>

    <ul>
      <li>Elektrifizierung der Sektoren Verkehr und Gebäude</li>

      <li>Beschleunigter Ausbau der Photovoltaik</li>

      <li>Verlässliche Rahmenbedingungen für den Ausbau schaffen</li>

      <li>Ausstieg aus der Atomkraft bis 2035</li>

      <li>
        Saisonspeicher durch Wasserstoff; Gas-to-Power als Backup in der
        Übergangsphase
      </li>
    </ul>

    <h4>Elektrifizierung der Sektoren Verkehr und Gebäude</h4>
    <p>
      Die aktuelle Entwicklung zeigt deutlich, dass die bisherigen Prognosen zum
      Vormarsch von Wärmepumpen und Elektromobilität zu pessimistisch waren.
      Diese beiden Technologien werden trotz Stromeffizienzmassnahmen zu einem
      steigenden Strombedarf führen. Sie schaffen jedoch auch Chancen für eine
      optimale Kombination mit der Produktion von Solarstrom. Die
      Elektromobilität wird dank bidirektionalem Laden massive zusätzliche
      Speicherkapazitäten schaffen. Wärmepumpen sind dann in Betrieb, wenn
      Solarstrom zur Verfügung steht.
    </p>

    <h4>Beschleunigter Ausbau der Photovoltaik</h4>

    <p>
      Swissolar nimmt an, dass bis 2050 knapp 50 GW Photovoltaik jährlich 46 TWh
      Strom produzieren werden. Das bedeutet, dass zwei Drittel aller geeigneten
      Dächer und Fassaden mit Photovoltaiksystemen bedeckt sein werden. Rund 3
      TWh würden auf alpinen Freiflächenanlagen produziert. Wasserkraft und
      Photovoltaik werden somit die zwei wichtigsten Lieferanten für Strom in
      der Schweiz. Wichtig ist, dass bereits bis 2030 der jährliche
      Photovoltaik-Zubau auf rund 2 GW gesteigert wird (Verdreifachung gegenüber
      2021). Damit kann Photovoltaik in der kritischen Zeit nach Stilllegung der
      AKW massgeblich zur Versorgungssicherheit beitragen. Dies auch im Winter,
      da rund 1/3 der Produktion im Winterhalbjahr erfolgt, und zum Zeitpunkt
      der Tiefststände der Stauseen im April PV-Anlagen bereits hohe Erträge
      liefern. Bei alpinen, senkrecht aufgestellten bifacialen
      Freiflächenanlagen erfolgt sogar die Hälfte der Produktion im
      Winterhalbjahr, mit Spitzenwerten im April.
    </p>

    <h4>Verlässliche Rahmenbedingungen für den Ausbau schaffen</h4>

    <p>
      Das Ausbautempo der Photovoltaik ist heute mit jährlichen Wachstumsraten
      von 30-40% vielversprechend. Für ein kontinuierliches Wachstum sind jedoch
      verschiedene Massnahmen notwendig, wie sie der 11-Punkte-Plan von
      Swissolar (1) benennt. Dazu gehören gesetzlich festgelegte Ausbauziele,
      schweizweit einheitliche Abnahmetarife (basierend auf den vierteljährlich
      gemittelten Marktpreisen, mit einer Untergrenze), Massnahmen gegen den
      Fachkräftemangel, eine Förderung auch nach 2030 und eine Erhöhung des
      Netzzuschlags von heute 2.3 um mindestens 0.5 Rappen pro Kilowattstunde.
    </p>

    <h4>Ausstieg aus der Atomkraft bis 2035</h4>

    <p>
      Der monatelange Stillstand von 27 AKW in Frankreich zeigt auf, wie
      unzuverlässig die Produktion aus älteren Reaktoren sein kann. Ein
      gleichzeitiger ungeplanter Stillstand von 2 AKW in der Schweiz könnte
      gravierende Folgen haben. Im Swissolar-Szenario wird deshalb damit
      gerechnet, dass bis 2035 sämtliche Reaktoren der Schweiz ausser Betrieb
      sein werden. Damit dies nicht zu einem stärkeren Stromimportbedarf führt,
      muss der Photovoltaik-Ausbau beschleunigt werden.
    </p>

    <h4>
      Saisonspeicher durch Wasserstoff; Gas-to-Power als Backup in der
      Übergangsphase
    </h4>

    <p>
      Aufgrund des raschen Photovoltaik-Ausbaus werden Solarstrom-Überschüsse im
      Sommer zum Thema. Mittels Elektrolyse lässt sich daraus Wasserstoff
      produzieren. Dieser kann dann später rückverstromt oder als Treibstoff für
      LKW sowie für industrielle Prozesse genutzt werden. Während Batterien und
      Pumpspeicher die tägliche Versorgung mit Elektrizität sicherstellen, soll
      Wasserstoff die jahresübergreifende Versorgung gewährleisten. Insbesondere
      kann Wasserstoff in WKK-Anlagen oder im Fall von Versorgungsengpässen
      eingesetzt werden.
    </p>

    <h4>Wichtigste Eckdaten auf einen Blick</h4>
    <ul>
      <li>Stromproduktion</li>
      <ul>
        <li>
          Ausbau von Photovoltaik auf rund 50 GW (ca. 45 TWh Jahresproduktion)
          bis 2050
        </li>
        <li>Keine Veränderung in den Kapazitäten der Wasserkraft</li>
        <li>Ausstieg Kernenergie bis 2035</li>
        <li>
          Wichtige Rolle von Gas-to-Power v.a. in der Übergangsphase (1283 MW
          Produktionskapazität 2038)
        </li>
        <li>Bestand fossiler Kraftwerke wird halbiert</li>
      </ul>
      <li>Nachfrage</li>
      <ul>
        <li>Gesamtstromnachfrage von fast 90 TWh in 2050</li>

        <li>E- Mobilität: von fast 0 TWh/a auf 17 TWh/a</li>

        <li>
          Wärmepumpen: knapp 12 TWh/a in 2050 (analog Energieperspektiven 2050+)
        </li>

        <li>Wasserstoffherstellung: 12 TWh in 2050</li>
      </ul>
      <li>Importe</li>
      <ul>
        <li>
          Importkapazitäten orientieren sich an den TYNDP 2018 Sustainable
          Transition Scenario
        </li>
      </ul>

      <li>
        (1) Der 11-Punkte-Plan von Swissolar: www.swissolar.ch/11-Punkte-Plan/

        <a
          href="http://www.swissolar.ch/11-Punkte-Plan/"
          title="Der 11-Punkte-Plan von Swissolar"
          target="_blank"
          class="link link--external">Der 11-Punkte-Plan von Swissolar.</a
        >
      </li>
      <ul />
    </ul></Collapsable
  >

  <Collapsable title="ESC Nexus-e">
    <h4>Nexus-e</h4>

    <p>
      Nexus-e ist eine Plattform der ETH Zürich zur Modellierung des Schweizer
      Energiesystems [1]. Für dieses Szenario verknüpfen wir zwei Modelle dieser
      Plattform - CentIv [2], ein Modell für den zentralen Teil des Stromsystem,
      und DistIv [3], ein Modell für den dezentralen Teil - und simulieren den
      Ausbaupfad unter geringsten Kosten. Die wichtigsten Annahmen und Trends in
      diesem Szenario sind:
    </p>

    <ul>
      <li>Kosteneffizienter Ausbaupfad für die Stromversorgung</li>

      <li>Ausstieg aus der Kernenergie</li>

      <li>Anstieg der Stromnachfrage</li>

      <li>Stromimporte im Winter unklar</li>
    </ul>

    <p>Weitere Annahmen sind im Projektreport [4] zu finden</p>

    <h4>Kosteneffizienter Ausbaupfad für die Stromversorgung</h4>
    <p>
      Das gezeigte Nexus-e Szenario bildet den Ausbaupfad für das Schweizer
      Stromsystem ab, welcher zu geringsten Gesamtkosten führt. Die Kosten der
      jeweiligen Stromerzeugungseinheiten setzen sich hierbei zusammen aus
      Investitionskosten, Instandhaltungskosten, und Brennstoffkosten [5]. Wir
      berücksichtigen Lerneffekte (über die Zeit sinkende Preise der
      Technologien) und aktuelle Subventionen (zum Beispiel Rückliefertarife bis
      2050 und Einmalvergütungen für Photovoltaik Anlagen bis 2030). Das maximal
      mögliche Potential der Photovoltaik für Anlagen auf Dächern beträgt
      ungefähr 47 GW bis 2050 [6] (im kostenoptimalen Ausbaupfad wird nicht das
      ganze Potential installiert). Photovoltaik-Freiflächenanlagen im alpinen
      Bereich werden ebenfalls berücksichtigt, erhalten jedoch nur bis 2030 und
      bis zu einer Gesamtkapazität von 2 TWh Subventionen. Der Ausbau der
      Windkraft ist auf 30 GW beschränkt und Biomasse auf 0.4 GW. Wir gehen
      ausserdem davon aus, dass die Stromerzeugung aus Wasserkraft )basierend
      auf den Projekten des Runden Tisch der Wasserkraft) im Vergleich zum
      heutigen Stand leicht zunimmt. Geothermie zur Stromerzeugung
      berücksichtigen wir nicht.
    </p>

    <h4>Ausstieg aus der Kernenergie</h4>
    <p>
      Das Szenario unterstellt einen generellen Ausstieg aus der Kernenergie
      nach 60 Jahren. Beznau 1 und Beznau 2 bleiben demnach bis 2029 und 2031 am
      Netz, Gösgen und Leibstadt bis 2039 und 2045.
    </p>
    <h4>Anstieg der Stromnachfrage</h4>
    <p>
      Die Elektrifizierung des Transport- und Wärmesektors mit Hilfe von
      Technologien wie Batteriespeichern und Wärmepumpen führt trotz generellen
      Effizienzgewinnen zu einem steigenden Strombedarf. Zusätzlich benötigt die
      Dekarbonisierung der Industrie mit Hilfe von CO2-neutralem Gas weiteren
      Strom. Auch die Bevölkerungsentwicklung führt zu einem Anstieg des
      klassischen Strombedarfs. In diesem Szenario folgen wir der jährlichen
      Entwicklung der Stromnachfrage aus dem Basisszenario “Zero” der
      Energieperspektiven 2050+.
    </p>
    <h4>Stromimporte im Winter unklar</h4>
    <p>
      In den meisten Jahren hat die Schweiz eine ausgeglichene
      Stromhandelsbilanz [7]. Trotzdem greift die Schweiz schon heute im Winter
      auf Netto-Importe zurück, vor allem in den Monaten November bis Februar,
      in denen der Strombedarf hoch und die Stromproduktion niedrig ist [8]. In
      diesem Szenario berücksichtigen wir die Netto-Null Ziele der Nachbarländer
      Deutschland, Frankreich, Italien, und Österreich [9] und die aktuellen
      Entwicklungen der Importkapazitäten [10]. Wir berücksichtigen die
      Netto-Transferkapazitäten (NTCs) für 2030 aus den Studien der ENTSO-E,
      nehmen dann aber an, dass es keine weitere Erhöhung der NTCs bis 2050
      gibt.
    </p>
    <h4>Quellen</h4>
    <p>
      [1] Gjorgiev, B., Garrison, J.B., Han, X., Landis, F., van Nieuwkoop, R.,
      Raycheva, E., Schwarz, M., Yan, X., Demiray, T., Hug, G., Sansavini, G.,
      Schaffner, C. (2021). Nexus-e: A platform of interfaced high-resolution
      models for energy-economic assessments of future electricity systems.
      Applied Energy, 177, 118193. DOI: 10.1016/j.apenergy.2021.118193
    </p>
    <p>
      [2] Raycheva, E., Schaffner, C., & Hug, G. (2020). Nexus-e: CentIv Module
      Documentation. DOI: 10.3929/ethz-b-000471885
    </p>
    <p>
      [3] Han, X., & Hug, G. (2020). Nexus-e: DistIv Module Documentation. DOI:
      10.3929/ethz-b-000471890
    </p>
    <p>
      [4] Schwarz M., Renggli S., Gjorgiev, B., Garrison, J.B. (2023). Swiss
      Electricity Supply after the “Mantelerlass” – Quo vadis? A perspective on
      Nuclear Power. https://nexus-e.org/role-of-nuclear-power/ (wir beziehen
      uns hier auf das Szenario KKW60)
    </p>
    <p>
      [5] Garrison, J.B., Gjorgiev, B., Han, X., Landis, F., van Nieuwkoop, R.,
      Raycheva, E., Schwarz, M., Yan, X., Demiray, T., Hug, G., Sansavini, G.,
      Schaffner, C. (2021). Nexus-e Input Data Report.
      https://doi.org/10.3929/ethz-b-000474810
    </p>
    <p>
      [6] Portmann, M., Galvagno-Erny, D., Lorenz, P., & Schacher, D. (2019).
      Sonnendach.ch und Sonnenfassade.ch: Berechnung von Potenzialen in
      Gemeinden. Bundesamt für Energie (BFE).
    </p>
    <p>
      [7] Bundesamt für Energie (2021). Schweizerische Elektrizitätsstatistik
      2020.
      https://www.bfe.admin.ch/bfe/de/home/versorgung/statistik-und-geodaten/energiestatistiken/elektrizitaetsstatistik.html
    </p>
    <p>[8] Swiss Energy Charts. https://www.energy-charts.info</p>
    <p>
      [9] European Network of Transmission System Operators for Electricity
      (ENTSO-E). Ten-year network development plan 2018 (2018).
      https://tyndp.entsoe.eu/tyndp2018/
    </p>
    <p>
      [10] European Network of Transmission System Operators for Electricity
      (ENTSO-E). European Resource Adequacy Assessment 2021 Edition (2021).
      https://www.entsoe.eu/outlooks/eraa/eraa-downloads/
    </p>
  </Collapsable>

  <Collapsable title="Economiesuisse">
    <p>
      Das economiesuisse-Szenario stellt einerseits den Ausbaupfad der
      erneuerbaren Energien angesichts der jüngsten politischen Entscheide
      (Mantelerlass Energie- und Stromversorgungsgesetz) dar. Andererseits
      analysiert es die Bedeutung der Kernenergie in einem System mit hohen
      Anteilen erneuerbarer Energien. Die Auswirkungen auf die
      Versorgungssicherheit werden anhand der Systemkosten und der Netto-Importe
      im Winterhalbjahr beurteilt.
    </p>

    <p>Die Leitplanken des Szenarios sind:</p>

    <ul>
      <li>
        Beschleunigter Zubau erneuerbarer Energien bis 2050 entlang den
        Eckpunkten des Mantelerlasses, unter anderem:
      </li>
      <ul>
        <li>Ausbauziele von 35 TWh (2035) und 45 TWh (2050)</li>

        <li>Zusätzliche Fördermittel für Investitionen</li>

        <li>Massiver Zubau von Dach-Photovoltaik</li>
        <li>Zubau von mind. 2 TWh Wasserkraft</li>
        <li>
          Zubau von mind. 2 TWh alpinen Solaranlagen (Subvention von 60% der
          Investitionskosten bis 2025)
        </li>
      </ul>

      <li>
        Laufzeitverlängerung Beznau I und II auf 65 Jahre sowie 80 Jahre für
        Gösgen und Leibstadt
      </li>

      <li>Uneingeschränkte Importmöglichkeiten im Winterhalbjahr</li>
      <li>Vernachlässigung der Netzkosten</li>
    </ul>

    <h4>Starker Ausbau der Erneuerbaren</h4>

    <p>
      Im economiesuisse-Szenario erfolgt dank den neuen Förderinstrumenten ein
      massiver Ausbau der erneuerbaren Energien. Vor allem die Photovoltaik legt
      stark zu und erreicht 2050 eine Produktion von 37 TWh. Der Zubau bei
      alpinen Grossanlagen stagniert aufgrund der bis 2025 begrenzten
      Förderinstrumente. Die Wind-Produktion bleibt marginal. Insgesamt werden
      die Zubauziele des Manterlasses bis 2035 klar verfehlt (25 statt 35 TWh)
      und bis 2050 knapp (43 statt 45 TWh).
    </p>

    <h4>Deutliche Zunahme der Winterimporte ohne Gegenmassnahmen</h4>

    <p>
      Es wird erwartet, dass trotz dieser hohen Ziele für die erneuerbaren
      Energien die Nettoimporte im Winter auf bis zu 10 TWh ansteigen. Dies aus
      drei Gründen:
    </p>

    <p>
      1. Die Photovoltaik auf den Dächern - die Stromerzeugungstechnologie mit
      der derzeit höchsten jährlichen Installationsrate - produziert nur 20-30
      Prozent ihres jährlichen Stroms im Winterhalbjahr.
    </p>
    <p>
      2. Die Elektrifizierung des Wärmesektors erhöht die Nachfrage,
      insbesondere im Winter.
    </p>
    <p>
      3. Der geplante Ausstieg aus der Kernenergie, welche heute für fast die
      Hälfte der Schweizer Stromerzeugung im Winter verantwortlich ist (siehe
      z.B. Swiss Energy-Charts).
    </p>

    <h4>
      Längere KKW-Laufzeiten machen die Versorgung günstiger und reduzieren den
      Importbedarf im Winter
    </h4>

    <p>
      Das economiesuisse-Szenario rechnet mit verlängerten KKW-Laufzeiten, um
      deren Auswirkungen auf die Versorgungssicherheit darzulegen. Dank der
      langen Laufzeiten steuert die Kernenergie noch bis 2050 noch rund 14.5 TWh
      zur Stromproduktion bei, vor allem im Winter. Sie füllt damit die Lücke,
      welche Erneuerbaren voraussichtlich nicht schliessen können. Dabei stehen
      Kernenergie und Erneuerbare nicht in Konkurrenz zueinander, sondern
      ergänzen sich. Die KKW passen ihre Betriebsmuster an, so dass eine
      Symbiose zu den irregulär produzierenden Erneuerbaren entsteht. Damit
      können die Systemkosten um einen Netto-Betrag von rund 10 Mrd. CHF
      reduziert werden.
    </p>
  </Collapsable>

  <Collapsable title="Alt Nationalrat Rechsteiner">
    <p>
      Ehem. Nationalrat Rudolf Rechsteiner fokussiert sich in seinem Szenario
      auf eine Schweiz, die weiterhin in das europäische Stromsystem integriert
      und weitgehend dekarbonisiert ist. Die wichtigsten Bestandteile seines
      Szenarios sind:
    </p>

    <ul>
      <li>Ausbau von Photovoltaik</li>

      <li>Elektrifizierung des Strassenverkehrs und des Heizens in Gebäuden</li>

      <li>Speicher für Tages- und Wochenschwankungen</li>

      <li>
        Importe und – für den Notfall – CO2-neutrale Gaskraftwerke – für die
        Versorgungssicherheit
      </li>
    </ul>

    <h4>Ausbau von Photovoltaik</h4>

    <p>
      Im Szenario von Rechsteiner steigt die Kapazität für Photovoltaik bis 2035
      auf etwa 37 GW oder, laut ihm, auf 30 TWh nutzbare Solarstromproduktion
      an. In 2050 ist Photovoltaik auf 50 GW Kapazität ausgebaut. Der Ausbau von
      Solarstrom und ein Ausstieg aus der Kernenergie nach 50 Jahren Laufzeit
      passieren parallel.
    </p>

    <h4>Elektrifizierung des Strassenverkehrs und des Heizens in Gebäuden</h4>

    <p>
      Elektrifizierung spielt in diesem Modell eine entscheidende Rolle, um die
      Nutzung fossiler Energieträger zu minimieren. Der Strassenverkehr wird
      soweit möglich auf elektrisch betriebene Fahrzeuge umgestellt; auch das
      Heizen von Gebäuden durch elektrische Wärmepumpen wird durch Sanierungen
      stark vorangetrieben.
    </p>

    <h4>Speicher für Tages- und Wochenschwankungen</h4>

    <p>
      Für das Ausbalancieren von Schwankungen in der Stromproduktion auf Tages-
      und Wochenbasis sollen Batterien, Wärmespeicher und intelligente
      Anwendungen sorgen. Batterien und Wärmespeicher können die Energie aus
      überschüssig produzierter erneuerbarer Elektrizität zu einem späteren
      Zeitpunkt (zum Beispiel in der Nacht oder an Tagen mit Bewölkung) wieder
      verfügbar machen. Intelligente Anwendungen können bei Bedarf ihren
      Stromverbrauch verringern, wenn kurzfristig wenig Strom verfügbar ist.
    </p>
    <p>Ein Teil der Strom-Überschüsse wird für Power-to-gas verwendet.</p>

    <h4>Importe und Gaskraftwerke für die Versorungssicherheit</h4>

    <p>
      Rechsteiner nimmt an, dass eine Kombination von Stromimporten aus
      Windenergie und Gaskraftwerken optimal für das Sicherstellen der
      Versorgung mit Strom ist. Im Winter werden Importe immer dann bevorzugt,
      wenn die europäischen Preise tief liegen. Dies ist periodisch der Fall,
      wenn es stürmt, wie sich bereits heute an den Tiefpreisphasen mit viel
      Windenergie im Norden ablesen lässt.
    </p>
    <p>
      Die Schweiz bleibt punkto Stromhandel fest in das europäische Stromsystem
      eingebunden. Für den Fall, dass nicht genügend Strom für den Import zur
      Verfügung steht, sieht Rechsteiner Back-up Gaskraftwerke innerhalb der
      Schweiz vor. „Back-up“ bedeutet, dass diese eingesetzt werden können, wenn
      nicht genügend erneuerbarer oder importierter Strom zur Verfügung steht.
      Als Backup dient die saisonal präzis erfolgende Bewirtschaftung der
      Speicherseen sowie ein Pflichtlager mit Biogas (gewonnen aus
      PV/Wasserstoff).
    </p>

    <h4>Wichtigste Eckdaten auf einen Blick</h4>

    <ul>
      <li>Stromproduktion</li>

      <ul>
        <li>Ausbau von PV auf 50 GW in 2050</li>

        <li>Ausbau Wasserkraft auf 2 TWh zusätzliche Produktion in 2050</li>

        <li>Ausstieg aus der Kernenergie nach 50 Jahren Laufzeit</li>

        <li>Keine Nutzung fossiler Energieträger in 2050</li>

        <li>
          Keine Saisonale Speicherung durch Wasserstoff mit Ausnahme eines
          Pflichtlagers (ausserhalb des Strommarktes, von ElCom/Swissgrid
          einsetzbar in Notlagen)
        </li>

        <li>CO2-neutrale Gaskraftwerke für Stromversorgungssicherheit</li>
      </ul>

      <li>Nachfrage</li>

      <ul>
        <li>Stromnachfrage insgesamt etwa 78 TWh in 2050</li>

        <li>E-Mobilität: von fast 0 TWh/a auf 14.5 TWh/a</li>
        <li>Wärmepumpen: ungefähr 12 TWh/a in 2050</li>
        <li>Wasserstoffherstellung: knapp 3 TWh in 2050</li>
      </ul>

      <li>Importe</li>
      <ul>
        <li>
          Im Winter u.a. wichtig für Stromversorgungssicherheit. Annahme ist,
          dass er aus erneuerbaren Energien stammen und nur während
          Tiefpreisphasen erfolgen muss.
        </li>
        <li>
          Importkapazitäten orientieren sich an dem TYNDP 2018 National Trends
          Scenario
        </li>
      </ul>
    </ul>

    <p>…</p>

    <ul>
      <li>
        Daten sind angelehnt an <a
          href=" https://www.rechsteiner-basel.ch/"
          title=">Rudolf Rechsteiner, Die Energiewende Im Wartesaal (Zürich: Zocher & Peter, 2021)."
          target="_blank"
          class="link link--external"
        >
          Rudolf Rechsteiner, Die Energiewende Im Wartesaal (Zürich: Zocher &
          Peter, 2021).</a
        >
      </li>
    </ul>
  </Collapsable>
</Collapsable>
