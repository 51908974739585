<script>
  import { range, sum } from 'd3-array'
  import { scaleLinear } from 'd3-scale'
  import { area, curveCatmullRom, line, stack } from 'd3-shape'
  import { _ } from 'svelte-i18n'
  import { months3 } from './../../constants'
  import {
    techGroups,
    selectedYear,
    selectedMonth,
    calculatedData,
  } from './../../stores'
  import HoverValues from './HoverValues.svelte'

  export let cellSize
  export let data

  // TODO: should use LayerCake
  const width = 450

  const height = 400

  const margin = {
    top: 20,
    right: 30,
    bottom: 40,
    left: 44,
  }

  const w = width - margin.left - margin.right
  const h = height - margin.top - margin.bottom

  const x = scaleLinear().domain([0, 11]).range([0, w])
  const y = scaleLinear().domain([0, 20]).range([h, 0])

  const a = area()
    .x((_, i) => x(i))
    .y0((d) => y(d[0]))
    .y1((d) => y(d[1]))
    .curve(curveCatmullRom)

  $: keys = $techGroups
    .map((d) => d.technologies)
    .flat()
    .reverse()
    .concat(['speicherReserveUsed', 'import', 'deficit'])

  $: productionByYear = $selectedYear
    ? stack().keys(keys)(
        data
          .filter((d) => d.year === $selectedYear)
          .map((d) =>
            Object.assign(d.production, {
              speicherReserveUsed: Math.max(0, d.speicherReserve.used),
            })
          )
      )
    : []

  $: selectedYearData = $calculatedData
    .getAnnualTimeSeries()
    .find((d) => d.year === $selectedYear)

  $: stacked = stack().keys(keys)(
    selectedYearData.months.map((month) => {
      const g = month.calculated.generation

      return {
        ...g.generation.byTechnology,
        speicherReserveUsed: Math.max(0, g.generation.speicherReserve.used),
        import: g.import.total,
        deficit: g.deficit,
      }
    })
  )

  $: demandByYear = data
    .filter((d) => d.year === $selectedYear)
    .map((d) => d.demand)

  $: ticks = y
    .ticks(10)
    .filter(
      (tick) => tick < Math.max(...stacked[stacked.length - 1].map((d) => d[1]))
    )

  function getFillColor(p) {
    return $techGroups.find((d) => d.technologies.includes(p.key))
      ? $techGroups.find((d) => d.technologies.includes(p.key)).color
      : p.key === 'speicherReserveUsed'
      ? 'var(--speicherreserve-color)'
      : p.key === 'import'
      ? 'url(#diagonalHatch-import)'
      : p.key === 'deficit'
      ? 'var(--color-energy-deficit)'
      : 'red' // if red, it means that a key is used for which there is no color defined
  }
</script>

<svg viewBox="0 0 {width} {height}">
  <defs>
    <pattern
      id="diagonalHatch-import"
      patternUnits="userSpaceOnUse"
      width="4"
      height="4"
    >
      <path
        d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
        style="stroke:#000; stroke-width:1"
      />
    </pattern>
  </defs>
  <g transform="translate({margin.left}, {margin.top})">
    {#each stacked as p, i}
      {#if ['import', 'speicherReserveUsed', 'deficit'].includes(p.key)}
        <clipPath id="demand-clip-path-import-small-chart">
          <path
            d={area()
              .x((_, i) => x(i))
              .y0((d) => y.range()[0])
              .y1((d) => y(d))
              .curve(curveCatmullRom)(demandByYear)}
          />
        </clipPath>

        <path
          d={a(p)}
          fill={getFillColor(p)}
          stroke="rgba(0, 0, 0, 0.2)"
          stroke-width={0.5}
          clip-path="url(#demand-clip-path-import-small-chart)"
        />
      {:else}
        <path
          d={a(p)}
          fill={getFillColor(p)}
          stroke="rgba(0, 0, 0, 0.2)"
          stroke-width={0.5}
        />
      {/if}
    {/each}

    {#each range(12) as i}
      <rect
        on:mouseover={() => ($selectedMonth = i)}
        on:mouseout={() => ($selectedMonth = undefined)}
        on:focus={() => {}}
        on:blur={() => {}}
        x={x(i) - w / 11 / 2}
        y={y(
          Math.max(
            ...productionByYear[productionByYear.length - 1].map((d) => d[1])
          )
        )}
        height={y(0) -
          y(
            Math.max(
              ...productionByYear[productionByYear.length - 1].map((d) => d[1])
            )
          )}
        width={w / 11}
        fill="white"
        style="outline: none;"
        opacity={$selectedMonth === undefined || $selectedMonth === i ? 0 : 0.3}
      />
    {/each}

    <path
      d={line()
        .x((d, i) => x(i))
        .y((d, i) => y(d))
        .curve(curveCatmullRom)(demandByYear)}
      stroke="black"
      stroke-width={3}
      fill="none"
      style="pointer-events: none;"
    />

    {#each range(12) as d}
      <line
        x1={20 + 30 * 2 * cellSize}
        x2={w}
        y1={d * 2 * cellSize}
        y2={d * 2 * cellSize}
        stroke="rgba(255, 255, 255, 0.2)"
      />
    {/each}

    {#each months3 as month, i}
      <text
        style="text-anchor: middle; dominant-baseline-central; font-size: 1.6rem; opacity: {$selectedMonth ===
          undefined || $selectedMonth === i
          ? 1
          : 0}"
        dy={20}
        y={y.range()[0]}
        transform="translate({x(i)}, 0)"
        >{$_(`page.sections.other.months3.${month}`)}</text
      >
    {/each}

    <text
      x={x.range()[0]}
      dx={-16}
      y={y(ticks[ticks.length - 1] + 1)}
      style="text-anchor: middle; font-size: 1.4rem;"
    >
      TWh
    </text>
    {#each ticks as tick}
      <text dx={-20} class="tick" x={x.range()[0]} y={y(tick)}>{tick}</text>
    {/each}
  </g>
</svg>

<style>
  .hover-instructions {
    font-size: 1.6rem;
    color: #9e9294;
  }

  .tick {
    font-size: var(--scale-3);
    dominant-baseline: central;
  }
</style>
