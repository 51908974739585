import {
  selectedLCOE,
  selectedPowerPrice,
  selectedCostView,
  calculatedData,
} from './stores'
import { derived } from 'svelte/store'
import { technologies } from './constants.js'
import { sum } from 'd3-array'
import { getHouseholdPrice } from './constants.js'
import { lcoeMap } from './fetchers.js'

function ccs2020(year, powerPrice) {
  const maxCCS = year.trueCost.input.adap3.maxCCS
  const minCCS = year.trueCost.input.adap3.minCCS
  const CCS2020 = year.trueCost.input.adap3.CCS2020
  const ccsFitting = year.trueCost.input.adap3.ccsFitting

  return Math.min(
    maxCCS,
    Math.max(minCCS, CCS2020 + (powerPrice - 60) * ccsFitting)
  )
}

function ccs2050(year, powerPrice) {
  const maxCCS = year.trueCost.input.adap3.maxCCS
  const minCCS = year.trueCost.input.adap3.minCCS
  const CCS2050 = year.trueCost.input.adap3.CCS2050
  const ccsFitting = year.trueCost.input.adap3.ccsFitting

  return Math.min(
    maxCCS,
    Math.max(minCCS, CCS2050 + (powerPrice - 60) * ccsFitting)
  )
}

function calculateCO2FuelPrice(year, powerPrice, years) {
  let ccsCalculation = 0

  if (year.year === 2020) {
    ccsCalculation = ccs2020(year, powerPrice)
  } else {
    const previousYearCCSCalculation =
      years[year.year - 2020 - 1].trueCost.input.fuelCostLHV.ccsCalculation
    ccsCalculation =
      (ccs2050(years[30], powerPrice) - ccs2020(year, powerPrice)) / 30 +
      previousYearCCSCalculation
  }

  year.trueCost.input.fuelCostLHV.ccsCalculation = ccsCalculation

  const minCO2 = year.trueCost.input.adap1.minCO2
  const variableOM = year.trueCost.input.adap1.CCGT_GA_varOM
  const efficiency = year.trueCost.input.adap1.CCGT_GA_efficiency
  const backgroundModel = year.trueCost.input.adap1.CCGT_GA_CO2
  const contents = year.trueCost.input.adap1.CCGT_GA_CO2_contents
  const minGas = year.trueCost.input.adap1.minGas

  return Math.max(
    minCO2,
    ((powerPrice - ccsCalculation - variableOM) * efficiency -
      Math.max(
        (powerPrice - ccsCalculation - variableOM) * efficiency -
          backgroundModel * contents,
        minGas
      )) /
      contents
  )
}

function calculateGasPrice(year, powerPrice) {
  const minGas = year.trueCost.input.adap1.minGas
  const contents = year.trueCost.input.adap1.CCGT_GA_CO2_contents
  const efficiency = year.trueCost.input.adap1.CCGT_GA_efficiency
  const ccsCalculation = year.trueCost.input.fuelCostLHV.ccsCalculation // assuming this has already been calculated for CO2
  const variableOM = year.trueCost.input.adap1.CCGT_GA_varOM
  const backgroundModel = year.trueCost.input.adap1.CCGT_GA_CO2

  return Math.max(
    (powerPrice - ccsCalculation - variableOM) * efficiency -
      backgroundModel * contents,
    minGas
  )
}

function cds2020(year, powerPrice) {
  const maxCDS = year.trueCost.input.adap3.maxCDS
  const minCDS = year.trueCost.input.adap3.minCDS
  const CDS2020 = year.trueCost.input.adap3.CDS2020
  const cdsFitting = year.trueCost.input.adap3.cdsFitting

  return Math.min(
    maxCDS,
    Math.max(minCDS, CDS2020 + (powerPrice - 60) * cdsFitting)
  )
}

function cds2050(year, powerPrice) {
  const maxCDS = year.trueCost.input.adap3.maxCDS
  const minCDS = year.trueCost.input.adap3.minCDS
  const CDS2050 = year.trueCost.input.adap3.CDS2050
  const cdsFitting = year.trueCost.input.adap3.cdsFitting

  return Math.min(
    maxCDS,
    Math.max(minCDS, CDS2050 + (powerPrice - 60) * cdsFitting)
  )
}

function calculateHCPrice(year, powerPrice, years) {
  let cdsCalculation = 0

  if (year.year === 2020) {
    cdsCalculation = cds2020(year, powerPrice)
  } else {
    const previousYearCDSCalculation =
      years[year.year - 2020 - 1].trueCost.input.fuelCostLHV.cdsCalculation
    cdsCalculation =
      (cds2050(years[30], powerPrice) - cds2020(year, powerPrice)) / 30 +
      previousYearCDSCalculation
  }

  year.trueCost.input.fuelCostLHV.cdsCalculation = cdsCalculation

  const ppHCVariableOM = year.trueCost.input.adap1.PP_HC_varOM
  const efficiency = year.trueCost.input.adap1.PP_HC_efficiency
  const backgroundModel = year.trueCost.input.adap1.CCGT_GA_CO2
  const contents = year.trueCost.input.adap1.PP_HC_CO2_contents
  const minHC = year.trueCost.input.adap1.minHC

  return Math.max(
    (powerPrice - cdsCalculation - ppHCVariableOM) * efficiency -
      backgroundModel * contents,
    minHC
  )
}

function calculateH2Price(year, powerPrice) {
  const blueLCOH = year.trueCost.input.adap3.h2BlueLCOH
  const blueFuel = year.trueCost.input.adap3.h2BlueFuel

  const newGasPrice = year.trueCost.input.fuelCost.GA.medium
  const blueEfficiency = year.trueCost.input.adap3.h2BlueEfficiency

  const greenLCOH = year.trueCost.input.adap3.h2GreenLCOH
  const greenFuel = year.trueCost.input.adap3.h2GreenFuel
  const greenEfficiency = year.trueCost.input.adap3.h2GreenEfficiency
  const generationPowerPctOfBasePrice =
    year.trueCost.input.adap2.generationPowerPctOfBasePrice
  const shareBlueH2GreenH2 = year.trueCost.input.adap2.shareBlueH2GreenH2

  const blueH2 = blueLCOH - blueFuel + newGasPrice / blueEfficiency
  const greenH2 =
    greenLCOH -
    greenFuel +
    (generationPowerPctOfBasePrice * powerPrice) / greenEfficiency

  return blueH2 * shareBlueH2GreenH2 + (1 - shareBlueH2GreenH2) * greenH2
}

function calculateEnvPrice(year, powerPrice) {
  const co2ContentH2 = 0
  const co2ContentGas = 0.2

  const newGas = year.trueCost.input.fuelCost.GA.medium
  const newCO2 = year.trueCost.input.fuelCost.CO2.medium
  const newGasAndCO2 = newGas + 0.2 * newCO2

  const h2Price = calculateH2Price(year, powerPrice)

  const h2QuotaPctOfHHV =
    (((1 - year.trueCost.input.adap2.h2QuotaPctOfVolume) *
      (newGas + 0.2 * newCO2) *
      10.4 +
      year.trueCost.input.adap2.h2QuotaPctOfVolume * h2Price * 3.54) /
      ((1 - year.trueCost.input.adap2.h2QuotaPctOfVolume) * 10.4 +
        year.trueCost.input.adap2.h2QuotaPctOfVolume * 3.54) -
      (newGas + 0.2 * newCO2)) /
    (h2Price - (newGas + 0.2 * newCO2))

  const newEnvFriendlyGasCO2 =
    (1 - h2QuotaPctOfHHV) * newGasAndCO2 + h2QuotaPctOfHHV * h2Price

  const CO2ContentEnvFriendlyGas =
    (1 - h2QuotaPctOfHHV) * co2ContentGas + h2QuotaPctOfHHV * co2ContentH2
  const newEnvFriendlyCO2 = year.trueCost.input.fuelCost.CO2.medium // could be low or high to, as it is the same value

  return newEnvFriendlyGasCO2 - CO2ContentEnvFriendlyGas * newEnvFriendlyCO2
}

function calculateNonFixedFuelCosts(year, years, powerPrice) {
  year.trueCost.input.fuelCost = { ...year.trueCost.input.fuelCostLHV }
  year.trueCost.input.fuelCost.no = {
    low: 0,
    medium: 0,
    high: 0,
  }

  const co2FuelPrice = calculateCO2FuelPrice(year, powerPrice, years)
  year.trueCost.input.fuelCost.CO2 = {
    low: co2FuelPrice,
    medium: co2FuelPrice,
    high: co2FuelPrice,
  }

  const gasPrice = calculateGasPrice(year, powerPrice)
  year.trueCost.input.fuelCost.GA = {
    low: gasPrice,
    medium: gasPrice,
    high: gasPrice,
  }

  const hcPrice = calculateHCPrice(year, powerPrice, years)
  year.trueCost.input.fuelCost.HC = {
    low: hcPrice,
    medium: hcPrice,
    high: hcPrice,
  }

  const h2Price = calculateH2Price(year, powerPrice)
  year.trueCost.input.fuelCost.H2 = {
    low: h2Price,
    medium: h2Price,
    high: h2Price,
  }

  const envPrice = calculateEnvPrice(year, powerPrice)
  year.trueCost.input.fuelCost.Env = {
    low: envPrice,
    medium: envPrice,
    high: envPrice,
  }
}

// function calculatePVIncome2020(years) {
//   const year2020 = years[0]
//   const year2021 = years[1]
//   const year2022 = years[2]

//   year2020.trueCost.technologies.T12.calculated.income =
// }

export const trueCosts = derived(
  [calculatedData, selectedLCOE, selectedPowerPrice],
  ([$calculatedData, $selectedLCOE, $selectedPowerPrice]) => {
    console.time('trueCosts [$calculatedData]')
    if (!$calculatedData) return

    $calculatedData.years.forEach((year) => {
      const totalDemand = sum(
        Object.values(year.calculated.demand.CH.absolute.userValues)
      )

      calculateNonFixedFuelCosts(
        year,
        $calculatedData.years,
        $selectedPowerPrice
      )

      technologies.forEach((T) => {
        T.calculatedGeneration = year.trueCost.technologies[T.key].generation(
          $selectedLCOE,
          $calculatedData.years,
          year.year,
          year.trueCost.input
        )

        year.trueCost.technologies[T.key].calculatedGeneration =
          T.calculatedGeneration

        year.trueCost.technologies[T.key].totalCalculatedGeneration = sum(
          T.calculatedGeneration
        )
      })

      technologies.forEach((T) => {
        T.calculated = {}

        T.calculated.social = year.trueCost.technologies[T.key].social(
          year.trueCost.input,
          $selectedLCOE
        )

        T.calculated.external = year.trueCost.technologies[T.key].external(
          year.trueCost.input,
          $selectedLCOE
        )

        let systemCost = year.trueCost.technologies[T.key].system(
          year.trueCost.input,
          $selectedLCOE
        )

        if (['T12', 'T13', 'T14'].includes(T.key)) {
          const demand =
            sum(Object.values(year.calculated.demand.CH.absolute.userValues)) *
            1000
          const T12generationTotal =
            year.trueCost.technologies.T12.intermediate.generationTotal
          const T13generationTotal =
            year.trueCost.technologies.T13.intermediate.generationTotal
          const T14generationTotal =
            year.trueCost.technologies.T14.intermediate.generationTotal

          const generation =
            Math.round(
              Math.min(
                1,
                (T12generationTotal + T13generationTotal + T14generationTotal) /
                  demand
              ) * 100
            ) / 100

          const value = year.trueCost.input.systemCostLander1.find(
            (d) =>
              d.pct === generation &&
              d.lcoe.split(' ')[1].toLowerCase() === $selectedLCOE &&
              d.technology === 'PH'
          ).value

          const ratio = year.trueCost.input.systemCostLander2.find(
            (d) =>
              d.technology === T.key && lcoeMap[d.lcoe].key === $selectedLCOE
          ).ratio

          systemCost = value * ratio
        } else if (T.key === 'T15') {
          const demand =
            sum(Object.values(year.calculated.demand.CH.absolute.userValues)) *
            1000

          const T15generationTotal =
            year.trueCost.technologies.T15.intermediate.generationTotal

          const generation =
            Math.round(Math.min(1, T15generationTotal / demand) * 100) / 100

          const value = year.trueCost.input.systemCostLander1.find(
            (d) =>
              d.pct === generation &&
              d.lcoe.split(' ')[1].toLowerCase() === $selectedLCOE &&
              d.technology === 'WN'
          ).value

          const ratio = year.trueCost.input.systemCostLander2.find(
            (d) =>
              d.technology === T.key && lcoeMap[d.lcoe].key === $selectedLCOE
          ).ratio

          systemCost = value * ratio
        }

        T.calculated.system = systemCost

        T.calculated.investorTotal = year.trueCost.technologies[
          T.key
        ].investorTotal(year.trueCost.input, $selectedLCOE)

        T.calculated.income = year.trueCost.technologies[T.key].income(
          year.trueCost.input,
          $selectedLCOE,
          $selectedPowerPrice,
          totalDemand,
          year
        )

        T.calculated.investorFixed =
          year.trueCost.technologies[T.key].investorFixed($selectedLCOE)

        T.calculated.investorVariable = year.trueCost.technologies[
          T.key
        ].investorVariable($selectedLCOE, year.trueCost.input)

        T.calculated.netBenefit = year.trueCost.technologies[T.key].netBenefit(
          year,
          $selectedLCOE,
          T.calculated,
          $calculatedData.years,
          year.trueCost.input,
          $selectedPowerPrice
        )

        year.trueCost.technologies[T.key].calculated = T.calculated

        // console.log(year.year, T.key, T.calculated)
      })

      year.trueCost.calculated = {
        household: getHouseholdPrice(
          year.trueCost.householdPrice,
          $selectedPowerPrice,
          year.trueCost.technologies,
          year,
          $calculatedData.years,
          $selectedLCOE
        ),
      }
    })
    console.timeEnd('trueCosts [$calculatedData]')
    // console.log($calculatedData)
    return $calculatedData
  }
)

export function calculateTrueCost(data, selectedLCOE, selectedPowerPrice) {
  console.time('trueCosts [comparison]')

  if (!data) return

  data.years.forEach((year) => {
    const totalDemand = sum(
      Object.values(year.calculated.demand.CH.absolute.userValues)
    )

    calculateNonFixedFuelCosts(year, data.years, selectedPowerPrice)

    technologies.forEach((T) => {
      T.calculatedGeneration = year.trueCost.technologies[T.key].generation(
        selectedLCOE,
        data.years,
        year.year,
        year.trueCost.input
      )

      year.trueCost.technologies[T.key].calculatedGeneration =
        T.calculatedGeneration

      year.trueCost.technologies[T.key].totalCalculatedGeneration = sum(
        T.calculatedGeneration
      )
    })

    technologies.forEach((T) => {
      T.calculated = {}

      T.calculated.social = year.trueCost.technologies[T.key].social(
        year.trueCost.input,
        selectedLCOE
      )

      T.calculated.external = year.trueCost.technologies[T.key].external(
        year.trueCost.input,
        selectedLCOE
      )

      let systemCost = year.trueCost.technologies[T.key].system(
        year.trueCost.input,
        selectedLCOE
      )

      if (['T12', 'T13', 'T14'].includes(T.key)) {
        const demand =
          sum(Object.values(year.calculated.demand.CH.absolute.userValues)) *
          1000
        const T12generationTotal =
          year.trueCost.technologies.T12.intermediate.generationTotal
        const T13generationTotal =
          year.trueCost.technologies.T13.intermediate.generationTotal
        const T14generationTotal =
          year.trueCost.technologies.T14.intermediate.generationTotal

        const generation =
          Math.round(
            Math.min(
              1,
              (T12generationTotal + T13generationTotal + T14generationTotal) /
                demand
            ) * 100
          ) / 100

        const value = year.trueCost.input.systemCostLander1.find(
          (d) =>
            d.pct === generation &&
            d.lcoe.split(' ')[1].toLowerCase() === selectedLCOE &&
            d.technology === 'PH'
        ).value

        const ratio = year.trueCost.input.systemCostLander2.find(
          (d) => d.technology === T.key && lcoeMap[d.lcoe].key === selectedLCOE
        ).ratio

        systemCost = value * ratio
      } else if (T.key === 'T15') {
        const demand =
          sum(Object.values(year.calculated.demand.CH.absolute.userValues)) *
          1000

        const T15generationTotal =
          year.trueCost.technologies.T15.intermediate.generationTotal

        const generation =
          Math.round(Math.min(1, T15generationTotal / demand) * 100) / 100

        const value = year.trueCost.input.systemCostLander1.find(
          (d) =>
            d.pct === generation &&
            d.lcoe.split(' ')[1].toLowerCase() === selectedLCOE &&
            d.technology === 'WN'
        ).value

        const ratio = year.trueCost.input.systemCostLander2.find(
          (d) => d.technology === T.key && lcoeMap[d.lcoe].key === selectedLCOE
        ).ratio

        systemCost = value * ratio
      }

      T.calculated.system = systemCost

      T.calculated.investorTotal = year.trueCost.technologies[
        T.key
      ].investorTotal(year.trueCost.input, selectedLCOE)

      T.calculated.income = year.trueCost.technologies[T.key].income(
        year.trueCost.input,
        selectedLCOE,
        selectedPowerPrice,
        totalDemand,
        year
      )

      T.calculated.investorFixed =
        year.trueCost.technologies[T.key].investorFixed(selectedLCOE)

      T.calculated.investorVariable = year.trueCost.technologies[
        T.key
      ].investorVariable(selectedLCOE, year.trueCost.input)

      T.calculated.netBenefit = year.trueCost.technologies[T.key].netBenefit(
        year,
        selectedLCOE,
        T.calculated,
        data.years,
        year.trueCost.input,
        selectedPowerPrice
      )

      year.trueCost.technologies[T.key].calculated = T.calculated

      // console.log(year.year, T.key, T.calculated)
    })

    year.trueCost.calculated = {
      household: getHouseholdPrice(
        year.trueCost.householdPrice,
        selectedPowerPrice,
        year.trueCost.technologies,
        year,
        data.years,
        selectedLCOE
      ),
    }
  })

  console.timeEnd('trueCosts [comparison]')
  console.log(data)

  return data
}
