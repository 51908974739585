import { model } from './model';
import { csv } from 'd3-fetch';
import { autoType } from 'd3-dsv';
import { range, groups, map, group } from 'd3-array';
import { arr2Map, arr2Map2 } from './common';
import { IGNORE_TECH, temperatureKeys, costTechMapping, technologies, variables, getInvestorFixed, getInvestorVariable, getExternalCost, getProfilePrice, getFLHPrice, getSharedGenerationPrice, getNetBenefit, getGeneration, getGenerationTotal, getGenerationRenewable, getProfileAndMin1, getProfileAndMin2, getHouseholdPrice, } from './constants';
// faster than .flat() and supported by IE
// https://melkornemesis.medium.com/6-different-array-flattening-methods-in-javascript-performance-testing-included-cbadd928cf62
function flat(arr, target) {
    arr.forEach(function (el) {
        if (Array.isArray(el))
            flat(el, target);
        else
            target.push(el);
    });
}
function flatten(arr) {
    const flattened = [];
    flat(arr, flattened);
    return flattened;
}
const idMap = new Map(Object.entries({
    ph: 'pv',
    t_bm: 'biomasse',
    t_bx: 'ccs_biomasse',
    gtp_total: 'gtp',
    e_ae: 'oth_ee_total',
}));
const asId = (str) => {
    let id = str
        .toLowerCase()
        .replace(/ /g, '_')
        .replace(/-/g, '_')
        .replace(/\./g, '');
    id = idMap.has(id) ? idMap.get(id) : id;
    return id;
};
async function fetchCostLookupData(data) {
    const [lcoe, netBenefit, powerPriceHousehold] = await Promise.all([
        csv('data/input/cost_lookup_lcoe.csv', autoType),
        csv('data/input/cost_lookup_net_benefit.csv', autoType),
        csv('data/input/cost_lookup_power_price_household.csv', autoType),
    ]);
    data.costLookup.lcoe = lcoe;
    data.costLookup.net_benefit = netBenefit;
    data.costLookup.power_price_household = powerPriceHousehold;
}
// async function fetchCostCompareLookup(data) {
//   const costCompareLookup = await csv(
//     'data/input/cost_compare_lookup.csv',
//     autoType
//   )
//   data.costCompareLookup = costCompareLookup.map((d) => ({
//     ...d,
//     old_key: costTechMapping[d.technology],
//   }))
// }
async function fetchDemandData(scenarioCH, scenarioINT, data) {
    const promises = model.countries
        .map((country) => {
        const scenario = country.id === 'CH' ? scenarioCH : scenarioINT;
        return [
            csv(`data/input/${country.id}/${scenario}/demand.csv`, (d) => (Object.assign(Object.assign({}, autoType(d)), { scenario: scenario, country: country.id }))),
            csv(`data/input/${country.id}/Min/demand.csv`, (d) => (Object.assign(Object.assign({}, autoType(d)), { scenario: 'Min', country: country.id }))),
            csv(`data/input/${country.id}/Max/demand.csv`, (d) => (Object.assign(Object.assign({}, autoType(d)), { scenario: 'Max', country: country.id }))),
        ];
    })
        .flat();
    const result = await Promise.all(promises);
    flatten(result).forEach((d) => {
        data.years[d.year - 2020].input[d.country].demand[
        // d.scenario === scenarioCH ? 'scenario' : d.scenario.toLowerCase()
        ['Min', 'Max'].includes(d.scenario)
            ? d.scenario.toLowerCase()
            : 'scenario'][asId(d.energy_type)] = d.demand;
    });
    const keys = Object.keys(data.years[0].input.CH.demand.scenario);
    keys.forEach((key) => {
        data.years.forEach((year) => {
            const min = year.input.CH.demand.min[key];
            const max = year.input.CH.demand.max[key];
            const scenario = year.input.CH.demand.scenario[key];
            year.input.CH.demand.userSettingRatios[key] =
                max - min === 0 ? 0 : (scenario - min) / (max - min);
        });
    });
}
async function fetchInputDemandSlidersData(data) {
    const result = await csv('data/input/input_demand_sliders.csv', autoType);
    groups(result, (d) => d.year)
        .map((d) => (Object.assign({}, d[1].reduce((acc, cur) => {
        acc[cur.item] = cur.value;
        return acc;
    }, {}))))
        .forEach((d, i) => (data.years[i].input.inputDemandSliders = d));
}
async function fetchImportData(scenarioCH, data) {
    const promises = model.countries
        .filter((d) => d.id !== 'CH')
        .map((country) => {
        const scenario = scenarioCH;
        return [
            csv(`data/input/${country.id}/${scenario}/import.csv`, (d) => (Object.assign(Object.assign({}, autoType(d)), { scenario: scenario, country: country.id }))),
            csv(`data/input/${country.id}/Min/import.csv`, (d) => (Object.assign(Object.assign({}, autoType(d)), { scenario: 'Min', country: country.id }))),
            csv(`data/input/${country.id}/Max/import.csv`, (d) => (Object.assign(Object.assign({}, autoType(d)), { scenario: 'Max', country: country.id }))),
            csv(`data/input/${country.id}/70%/import.csv`, (d) => (Object.assign(Object.assign({}, autoType(d)), { scenario: '70%', country: country.id }))),
        ];
    })
        .flat();
    const result = await Promise.all(promises);
    flatten(result).forEach((d) => {
        const month = data.years[d.year - 2020].months[d.month - 1];
        const countryInput = month.input[d.country];
        countryInput.exportCapacity[['Min', 'Max', '70%'].includes(d.scenario)
            ? d.scenario.toLowerCase()
            : 'scenario'] = d.export_capacity;
        if (d.scenario === '70%') {
            countryInput.exportCapacity.ntc_start_year = d.ntc_start_year;
        }
    });
}
async function fetchCapacityData(scenarioCH, scenarioINT, data) {
    const promises = model.countries
        .map((country) => {
        const scenario = country.id === 'CH' ? scenarioCH : scenarioINT;
        return csv(`data/input/${country.id}/${scenario}/capacity.csv`, (d) => (Object.assign(Object.assign({}, autoType(d)), { scenario, country: country.id })));
    })
        .concat([
        csv(`data/input/CH/Min/capacity.csv`, (d) => (Object.assign(Object.assign({}, autoType(d)), { scenario: 'Min', country: 'CH' }))),
    ])
        .concat([
        csv(`data/input/CH/Max/capacity.csv`, (d) => (Object.assign(Object.assign({}, autoType(d)), { scenario: 'Max', country: 'CH' }))),
    ]);
    const result = await Promise.all(promises);
    flatten(result).forEach((d) => {
        data.years[d.year - 2020].input[d.country].capacity[
        // d.scenario === scenarioCH ? 'scenario' : d.scenario.toLowerCase()
        ['Min', 'Max'].includes(d.scenario)
            ? d.scenario.toLowerCase()
            : 'scenario'][asId(d.category_0)] = d.capacity;
    });
    const keys = Object.keys(data.years[0].input.CH.capacity.scenario);
    keys.forEach((key) => {
        data.years.forEach((year) => {
            const min = year.input.CH.capacity.min[key];
            const max = year.input.CH.capacity.max[key];
            const scenario = year.input.CH.capacity.scenario[key];
            year.input.CH.capacity.userSettingRatios[key] =
                max - min === 0 ? 0 : (scenario - min) / (max - min);
        });
    });
}
async function fetchAvailabilityData(data) {
    const promises = model.countries.map((country) => csv(`data/input/${country.id}/availability.csv`, (d) => (Object.assign(Object.assign({}, autoType(d)), { country: country.id }))));
    const result = await Promise.all(promises);
    flatten(result).forEach((d) => {
        data.years[d.year - 2020].months[d.month - 1].input[d.country].availability[d.variable][asId(d.value)] = {
            min: d.Min,
            avg: d.Avg,
            max: d.Max,
        };
    });
}
async function fetchTemperatureData(data) {
    const result = await csv(`data/input/temperature.csv`, (d) => (Object.assign({}, autoType(d))));
    data.temperature = {
        CH: {
            temperature: [],
            klass: [],
            hp: [],
        },
        DE: {
            temperature: [],
            klass: [],
            hp: [],
        },
        FR: {
            temperature: [],
            klass: [],
            hp: [],
        },
        IT: {
            temperature: [],
            klass: [],
            hp: [],
        },
        AT: {
            temperature: [],
            klass: [],
            hp: [],
        },
    };
    result.forEach((d) => {
        const item = {
            month: d.month,
        };
        temperatureKeys.forEach((value) => {
            item[value] = d[value];
        });
        data.temperature[d.country][d.category].push(item);
    });
}
async function fetchSurplusData(data) {
    const result = await csv(`data/input/assumed_production_surplus.csv`, (d) => (Object.assign({}, autoType(d))));
    result.forEach((d) => {
        data.years[d.year - 2020].months[d.month - 1].input[d.country].surplus = d.value;
    });
}
async function fetchSpeicherReserve(data) {
    const result = await csv('data/input/CH/speicher_reserve.csv', (d) => (Object.assign({}, autoType(d))));
    data.speicherReserve = {
        absoluteValue: result[0].value,
        shareOfHSGenerationThatCanBeUsed: result[1].value,
        waterTax: result[2].value,
    };
    // result.forEach((d) => {
    //   data.years[d.year - 2020].input.CH.speicherReserve = d.value
    // })
}
async function fetchLosses(data, scenario) {
    const result = await csv(`data/input/CH/${scenario}/losses.csv`, (d) => (Object.assign({}, autoType(d))));
    result.forEach((d) => {
        data.years[d.year - 2020].input.CH.losses[d.loss_type.toLowerCase()] =
            d.value;
    });
}
const stackKeyMapping = {
    T1: 'bc_total',
    T2: 'hc_total',
    T3: 'ga_total',
    T4: 'oth_fossil_total',
    T5: 'nu',
    T6: 'gtp',
    T7: 'ccs_fossil',
    T8: 'ccs_biomasse',
    T9: 'biomasse',
    T10: 'ror',
    T11: 'hs',
    T12: 'ps',
    T13: 'pv',
    T14: 'pv_alpin',
    T15: 'wind_on',
    T16: 'wind_off',
    T17: 'oth_ee_total',
    'Import DE': 'import_DE',
    'Import FR': 'import_FR',
    'Import IT': 'import_IT',
    'Import AT': 'import_AT',
};
async function fetchGenerationStack(data) {
    const result = await csv('data/input/CH/generation_stack.csv', (d) => (Object.assign(Object.assign({}, autoType(d)), { potentialSelection: d.potential_selection === 'True', key: stackKeyMapping[d.technology], isImport: d.technology.toLowerCase().startsWith('import') })));
    data.generationStack = result
        .filter((d) => !IGNORE_TECH.includes(d.key))
        .sort((a, b) => a.order - b.order)
        .reduce((acc, cur, i) => {
        if (i === 0 || acc[acc.length - 1].order !== cur.order) {
            acc.push(cur);
        }
        return acc;
    }, []);
}
const techToKeyMapping = {
    Braunkohle: 'bc_total',
    Steinkohle: 'hc_total',
    Erdgas: 'ga_total',
    'New Gas': 'ga_total',
    'andere Fossile': 'oth_fossil_total',
    'Existing Fossil': 'oth_fossil_total',
    Kernenergie: 'nu',
    'Gas-to-Power (H2)': 'gtp',
    'CCS Fossil': 'ccs_fossil',
    'CCS Biomasse': 'ccs_biomasse',
    Biomasse: 'biomasse',
    Laufwasserkraft: 'ror',
    Speicherkraft: 'hs',
    Pumpspeicherkraft: 'ps',
    PV: 'pv_small',
    'PV ground': 'pv_large',
    'PV alpin': 'pv_alpin',
    'Wind on': 'wind_on',
    'Wind off': 'wind_off',
    'andere Erneuerbare': 'oth_ee_total',
};
async function fetchCostTimeSeries(data) {
    const result = await csv('data/input/cost_timeseries.csv', (d) => (Object.assign({}, autoType(d))));
    data.years.forEach((datum) => {
        result
            .filter((d) => d.year === datum.year)
            .forEach((yearDatum) => {
            if (!datum.input.cost[yearDatum.category]) {
                datum.input.cost[yearDatum.category] = {};
            }
            datum.input.cost[yearDatum.category][techToKeyMapping[yearDatum.tech]] =
                yearDatum.value;
        });
    });
}
async function fetchReceivingSubsidies(data) {
    const result = await csv('data/input/receiving_subsidies.csv', (d) => (Object.assign(Object.assign({}, autoType(d)), { tech: techToKeyMapping[d.tech] })));
    data.years.forEach((datum) => {
        result
            .filter((d) => d.year === datum.year)
            .forEach((yearDatum) => {
            if (!datum.input.cost.receiving_subsidies) {
                datum.input.cost.receiving_subsidies = {};
            }
            datum.input.cost.receiving_subsidies[yearDatum.tech] = yearDatum.value;
        });
    });
}
async function fetchPVSmallScale(data) {
    const result = await csv('data/input/pv_small_scale.csv', (d) => (Object.assign({}, autoType(d))));
    data.years.forEach((datum) => {
        result
            .filter((d) => d.year === datum.year)
            .forEach((yearDatum) => {
            datum.input.pv_small_scale[yearDatum.indicator] = yearDatum.value;
        });
    });
}
async function fetchChfToEur(data) {
    const result = await csv('data/input/chf_to_eur.csv', (d) => (Object.assign({}, autoType(d))));
    data.years.forEach((datum) => {
        result
            .filter((d) => d.year === datum.year)
            .forEach((yearDatum) => {
            datum.input.cost[yearDatum.indicator] = yearDatum.value;
        });
    });
}
async function fetchGridPremium(data) {
    const result = await csv('data/input/grid_premium.csv', (d) => (Object.assign({}, autoType(d))));
    data.years.forEach((datum) => {
        datum.input.grid_premium = {};
        result
            .filter((d) => d.year === datum.year)
            .forEach((yearDatum) => {
            datum.input.grid_premium[yearDatum.label] = yearDatum.value;
        });
    });
}
async function fetchRestwasserRorShare(data) {
    const result = await csv('data/input/restwasser.csv', (d) => (Object.assign({}, autoType(d))));
    data.years.forEach((datum) => {
        datum.input.restwasser = {};
        result
            .filter((d) => d.year === datum.year)
            .forEach((yearDatum) => {
            datum.input.restwasser[yearDatum.label] = yearDatum.value;
        });
    });
}
async function fetchAdapGACO2HC1(data) {
    const result = await csv('data/input/cost/adapGACO2HC1.csv', (d) => (Object.assign({}, autoType(d))));
    groups(result, (d) => d.year).forEach((d) => {
        const year = yearMap[d[0]];
        // TODO: awaiting answer from Claus on more clarification of the meaning of the sheet
        if (!year.input.adap1) {
            year.input.adap1 = {};
        }
        d[1].forEach((d) => {
            year.input.adap1[varLookupMap[d.variable].key] = d.value;
        });
    });
    data.trueCost.adapGACO2HC1 = result;
}
async function fetchAdapGACO2HC2(data) {
    const result = await csv('data/input/cost/adapGACO2HC2.csv', (d) => (Object.assign({}, autoType(d))));
    groups(result, (d) => d.year).forEach((d) => {
        const year = yearMap[d[0]];
        if (!year.input.adap2) {
            year.input.adap2 = {};
        }
        d[1].forEach((d) => {
            year.input.adap2[varLookupMap[d.unit].key] = d.value;
            if (varLookupMap[d.unit].key === 'h2QuotaPctOfHHV') {
                year.input.adap2.shareBlueH2GreenH2 = d.variable3;
            }
            if (varLookupMap[d.unit].key === 'h2QuotaPctOfVolume') {
                year.input.adap2.generationPowerPctOfBasePrice = d.variable2;
            }
        });
    });
    data.trueCost.adapGACO2HC2 = result;
}
async function fetchAdapGACO2HC3(data) {
    const result = await csv('data/input/cost/adapGACO2HC3.csv', (d) => (Object.assign({}, autoType(d))));
    groups(result, (d) => d.year).forEach((d) => {
        const year = yearMap[d[0]];
        if (!year.input.adap3) {
            year.input.adap3 = {};
        }
        d[1].forEach((d) => {
            year.input.adap3[varLookupMap[d.id].key] = d.value;
        });
    });
    data.trueCost.adapGACO2HC3 = result;
}
// TODO: awaiting Claus' answer, but seems duplicate with the LCOE variables
// async function fetchAvailabilityPrice(data) {
//   const result = await csv('data/input/cost/availabilityPrice.csv', (d) => ({
//     ...autoType(d),
//   }))
//   console.log(
//     'availabilityPrice',
//     result.map((d) => {
//       const item = {
//         T: d.T,
//         year: d.year,
//         [variables.find((v) => v.xlsLabel === d.variable).key]: d.value,
//       }
//       if (d.lcoe && d.lcoe !== null) {
//         item.lcoe = lcoeMap[d.lcoe].key
//       }
//       return item
//     })
//   )
//   data.trueCost.availabilityPrice = result
// }
async function fetchBasePrice(data) {
    const result = await csv('data/input/cost/base_price.csv', (d) => (Object.assign({}, autoType(d))));
    groups(result, (d) => d.year).forEach((d) => {
        const year = yearMap[d[0]];
        // there's some duplication here: the 25/100/250 prices are copied to each year!
        year.input.basePrice = {};
        d[1].forEach((d) => {
            year.input.basePrice[d.hours] = {
                value: d.value,
                base_price_25: d.baseprice_25,
                base_price_100: d.baseprice_100,
                base_price_250: d.baseprice_250,
            };
        });
    });
    data.trueCost.basePrice = result;
}
async function fetchBasePriceTech(data) {
    const result = await csv('data/input/cost/base_price_tech.csv', (d) => (Object.assign({}, autoType(d))));
    groups(result, (d) => d.year).forEach((d) => {
        const year = yearMap[d[0]];
        if (!year.input.basePriceTech) {
            year.input.basePriceTech = {};
        }
        d[1].forEach((d) => {
            year.input.basePriceTech[d.tech] = d.value;
        });
    });
    data.trueCost.basePriceTech = result;
}
// async function fetchFuelCostLHV(data) {
//   const result = await csv('data/input/cost/fuelCostLHV.csv', (d) => ({
//     ...autoType(d),
//   }))
//   data.trueCost.fuelCostLHV = result
// }
async function fetchHouseholdPrice(data) {
    const result = await csv('data/input/cost/householdPrice.csv', (d) => (Object.assign({}, autoType(d))));
    groups(result, (d) => d.year).forEach((d) => {
        const year = yearMap[d[0]];
        if (!year.householdPrice) {
            year.householdPrice = {};
        }
        if (!year.householdPrice.lcoe) {
            year.householdPrice.lcoe = {
                low: {},
                medium: {},
                high: {},
            };
        }
        d[1].forEach((d) => {
            if (varLookupMap[d.variable].key.endsWith('Low')) {
                year.householdPrice.lcoe.low[varLookupMap[d.variable].key.replace('Low', '')] = d.value;
            }
            else if (varLookupMap[d.variable].key.endsWith('Medium')) {
                year.householdPrice.lcoe.medium[varLookupMap[d.variable].key.replace('Medium', '')] = d.value;
            }
            else if (varLookupMap[d.variable].key.endsWith('High')) {
                year.householdPrice.lcoe.high[varLookupMap[d.variable].key.replace('High', '')] = d.value;
            }
            else {
                year.householdPrice[varLookupMap[d.variable].key] = d.value;
            }
        });
    });
    data.trueCost.householdPrice = result;
    console.log('done');
}
// async function fetchLCOEOther(data) {
//   const result = await csv('data/input/cost/lcoe_other.csv', (d) => ({
//     ...autoType(d),
//   }))
//   result.forEach((d) => {
//     const year = yearMap[d.year]
//     const key = variables.find((v) => v.xlsLabel === d.variable).key
//     // TODO: reference errors in Excel! Awaiting answer from Claus!!
//     const value = [
//       'subsidy',
//       'tax',
//       'totalPowerPriceHousehold',
//       'workingPrice',
//     ].includes(key)
//       ? null
//       : d.value
//     year.input[key] = value
//   })
//   console.log(yearMap)
//   data.trueCost.lcoeOther = result
// }
const techMap = arr2Map(technologies, 'key');
const varLookupMap = arr2Map(variables, 'xlsLabel');
const yearMap = arr2Map2(range(2020, 2051).map((d) => {
    const technologies = JSON.parse(JSON.stringify(techMap));
    range(1, 21).forEach((i) => {
        const t = technologies[`T${i}`];
        // TODO: add self/this reference to functions
        // TODO: remove functions from constants
        t.income = function (input, lcoe, powerPrice, demand, year) {
            // if (i === 18) {
            if ([1, 5, 10, 15, 16].includes(i)) {
                return getProfilePrice(input, powerPrice, this.profile);
            }
            else if ([2, 3, 4, 6, 7, 8, 9, 17, 19, 20].includes(i)) {
                return getFLHPrice(this, input, lcoe, powerPrice);
            }
            else if ([12, 13, 14].includes(i)) {
                return getSharedGenerationPrice(this, year, demand, powerPrice);
            }
            else if (i === 11) {
                return getProfileAndMin1(this, input, lcoe, powerPrice);
            }
            else if (i === 18) {
                return getProfileAndMin2(this, input, lcoe, powerPrice);
            }
        };
        // }
        t.investorFixed = function (lcoe) {
            return getInvestorFixed(this, lcoe);
        };
        t.investorVariable = function (lcoe, input) {
            return getInvestorVariable(this, input, lcoe);
        };
        t.investorTotal = function (input, lcoe) {
            return this.investorFixed(lcoe) + this.investorVariable(lcoe, input);
        };
        t.system = function (input, lcoe) {
            let systemCosts = this.lcoe[lcoe].systemCosts;
            return this.investorTotal(input, lcoe) + systemCosts;
        };
        t.external = function (input, lcoe) {
            return getExternalCost(this, input, lcoe);
        };
        t.social = function (input, lcoe) {
            return (this.investorTotal(input, lcoe) +
                this.system(input, lcoe) +
                this.external(input, lcoe));
        };
        t.generation = function (lcoe, years, currentYear, input) {
            if (this.key === 'T5') {
                return [getGenerationTotal(this, lcoe, years, currentYear)];
            }
            else if (['T12', 'T13', 'T14', 'T15', 'T17'].includes(this.key)) {
                return getGenerationRenewable(this, lcoe, years, currentYear, input);
            }
            else {
                return getGeneration(this, lcoe, years, currentYear);
            }
        };
        t.netBenefit = function (year, lcoe, calculated, years, input, powerPrice) {
            return getNetBenefit(this, year, lcoe, calculated, years, input, powerPrice);
        };
    });
    return {
        technologies,
        input: {
            year: d,
        },
    };
}), (d) => d.input.year);
export const lcoeMap = arr2Map([
    { key: 'low', label: 'LCOE_niedrig' },
    { key: 'medium', label: 'LCOE_mittel' },
    { key: 'high', label: 'LCOE_hoch' },
], 'label');
async function fetchLCOE(data) {
    const result = await csv('data/input/cost/lcoe.csv', (d) => (Object.assign({}, autoType(d))));
    groups(result, (d) => d.year, (d) => d.T, (d) => d.variable).forEach((y) => {
        const year = yearMap[y[0]];
        y[1].forEach((t) => {
            const technology = year.technologies[t[0]];
            technology.lcoe = {
                low: {},
                medium: {},
                high: {},
            };
            t[1].forEach((v) => {
                v[1].forEach((l) => {
                    const variable = varLookupMap[v[0]].key;
                    technology.lcoe[lcoeMap[l.setting].key][variable] = l.value;
                });
            });
        });
    });
    data.trueCost.lcoe = result;
}
async function fetchShareOfBasePrice(data) {
    const result = await csv('data/input/cost/share_of_base_price.csv', (d) => (Object.assign({}, autoType(d))));
    range(2020, 2051).forEach((i) => {
        const year = yearMap[i];
        groups(result, (d) => d.T).forEach((d) => (year.technologies[d[0]].shareOfBasePrice = d[1].reduce((acc, cur) => {
            acc[cur.share_of_base_price] = cur.value;
            return acc;
        }, {})));
    });
    data.trueCost.shareOfBasePrice = result;
}
async function fetchShareOfGeneration(data) {
    const result = await csv('data/input/cost/share_of_generation.csv', (d) => (Object.assign({}, autoType(d))));
    range(2020, 2051).forEach((i) => {
        const year = yearMap[i];
        result.forEach((d) => {
            const shareOfGeneration = (year.technologies[d.T].shareOfGeneration = {});
            shareOfGeneration.PA = d.PA;
            shareOfGeneration.PC = d.PC;
            shareOfGeneration.PV = d.PV;
        });
    });
    data.trueCost.shareOfGeneration = result;
}
async function fetchFuelCostLHV(data) {
    const result = await csv('data/input/cost/fuelCostLHV.csv', (d) => (Object.assign({}, autoType(d))));
    groups(result, (d) => d.year).forEach((d) => {
        const year = yearMap[d[0]];
        if (!year.input.fuelCostLHV) {
            year.input.fuelCostLHV = {};
        }
        d[1].forEach((d) => {
            if (!year.input.fuelCostLHV[d.type]) {
                year.input.fuelCostLHV[d.type] = {};
            }
            year.input.fuelCostLHV[d.type][lcoeMap[d.lcoe].key] = d.value;
        });
    });
    data.trueCost.fuelCostLHV = result;
}
// async function fetchCO2Data(data) {
//   const result = await csv(`data/input/CO2_Inhalt_KW.csv`, (d) => ({
//     ...autoType(d),
//   }))
//   result.forEach((d) => {
//     if (!data.years[<any>d.year - 2020].input.co2[asId(d.energy_type)]) {
//       data.years[<any>d.year - 2020].input.co2[asId(d.energy_type)] = {}
//     }
//     data.years[<any>d.year - 2020].input.co2[asId(d.energy_type)][d.variable] =
//       d.value
//   })
// }
async function fetchSystemCostLander1(data) {
    const result = await csv('data/input/cost/system_cost_lander_1.csv', (d) => (Object.assign({}, autoType(d))));
    Object.values(yearMap).forEach((year) => {
        year.input.systemCostLander1 = result;
    });
    data.trueCost.systemCostLander1 = result;
}
async function fetchSystemCostLander2(data) {
    const result = await csv('data/input/cost/system_cost_lander_2.csv', (d) => (Object.assign({}, autoType(d))));
    Object.values(yearMap).forEach((year) => {
        year.input.systemCostLander2 = result;
    });
    data.trueCost.systemCostLander2 = result;
}
const getHoursPerMonth = (month, year) => {
    if (month === 2) {
        return year % 4 === 0 ? 28 * 24 : 28 * 24;
    }
    else if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
        return 31 * 24;
    }
    return 30 * 24;
};
function generateEmptyDataStructure(scenario) {
    return {
        scenario,
        getMonthlyTimeSeries: function (accessor) {
            return this.years.reduce((acc, year) => {
                year.months.forEach((month) => {
                    acc.push(accessor ? accessor(month) : month);
                });
                return acc;
            }, []);
        },
        getAnnualTimeSeries: function (accessor) {
            return this.years.reduce((acc, year) => {
                acc.push(accessor ? accessor(year) : year);
                return acc;
            }, []);
        },
        costLookup: {
            lcoe: [],
            net_benefit: [],
            power_price_household: [],
        },
        costCompareLookup: [],
        trueCost: {
            technologies: {},
            years: range(2020, 2051).map((d) => ({
                year: d,
            })),
        },
        years: range(2020, 2051).map((year) => {
            const yearObj = {
                year,
                months: [],
                input: {
                    cost: {},
                    pv_small_scale: {},
                    CH: {
                        demand: { min: {}, max: {}, scenario: {}, userSettingRatios: {} },
                        capacity: { min: {}, max: {}, scenario: {}, userSettingRatios: {} },
                        speicherReserve: 0,
                        losses: {},
                    },
                    DE: {
                        demand: { min: {}, max: {}, scenario: {} },
                        capacity: { min: {}, max: {}, scenario: {} },
                    },
                    FR: {
                        demand: { min: {}, max: {}, scenario: {} },
                        capacity: { min: {}, max: {}, scenario: {} },
                    },
                    IT: {
                        demand: { min: {}, max: {}, scenario: {} },
                        capacity: { min: {}, max: {}, scenario: {} },
                    },
                    AT: {
                        demand: { min: {}, max: {}, scenario: {} },
                        capacity: { min: {}, max: {}, scenario: {} },
                    },
                    co2: {},
                    inputDemandSliders: {},
                },
                calculated: {
                    get hours() {
                        return yearObj.months.reduce((a, b) => a + b.hours, 0);
                    },
                },
            };
            yearObj.months = range(1, 13).map((month) => {
                const thisMonth = {
                    month,
                    parentYear: yearObj,
                    hours: getHoursPerMonth(month, year),
                    input: {
                        CH: {
                            availability: {
                                technology: {},
                                category: {},
                                ev: {},
                            },
                            exportCapacity: {},
                        },
                        DE: {
                            availability: {
                                technology: {},
                                category: {},
                                ev: {},
                            },
                            exportCapacity: {},
                        },
                        FR: {
                            availability: {
                                technology: {},
                                category: {},
                                ev: {},
                            },
                            exportCapacity: {},
                        },
                        IT: {
                            availability: {
                                technology: {},
                                category: {},
                                ev: {},
                            },
                            exportCapacity: {},
                        },
                        AT: {
                            availability: {
                                technology: {},
                                category: {},
                                ev: {},
                            },
                            exportCapacity: {},
                        },
                    },
                    calculated: {},
                };
                // need a self reference
                thisMonth['thisMonth'] = thisMonth;
                return thisMonth;
            });
            return yearObj;
        }),
    };
}
export async function loadData(scenarioCH, scenarioINT) {
    const data = generateEmptyDataStructure(scenarioCH);
    return await Promise.all([
        fetchCostLookupData(data),
        // fetchCostCompareLookup(data),
        fetchSpeicherReserve(data),
        fetchLosses(data, scenarioCH),
        fetchGenerationStack(data),
        fetchDemandData(scenarioCH, scenarioINT, data),
        fetchInputDemandSlidersData(data),
        fetchImportData(scenarioCH, data),
        fetchCapacityData(scenarioCH, scenarioINT, data),
        fetchAvailabilityData(data),
        fetchSurplusData(data),
        fetchCostTimeSeries(data),
        fetchPVSmallScale(data),
        fetchChfToEur(data),
        fetchGridPremium(data),
        fetchReceivingSubsidies(data),
        fetchRestwasserRorShare(data),
        fetchTemperatureData(data),
        // -------- true cost --------
        fetchShareOfBasePrice(data),
        fetchShareOfGeneration(data),
        fetchAdapGACO2HC1(data),
        fetchAdapGACO2HC2(data),
        fetchAdapGACO2HC3(data),
        // fetchAvailabilityPrice(data),
        fetchBasePrice(data),
        fetchBasePriceTech(data),
        fetchFuelCostLHV(data),
        fetchHouseholdPrice(data),
        fetchSystemCostLander1(data),
        fetchSystemCostLander2(data),
        // fetchLCOEOther(data),
        fetchLCOE(data),
    ]).then(() => {
        data.years.forEach((year, i) => {
            year.trueCost = yearMap[year.year];
        });
        return data;
    });
    console.timeEnd('loading');
}
// export async function loadData(scenarioCH, scenarioINT) {
//   console.time('loading')
//   const data = generateEmptyDataStructure(scenarioCH)
//   console.time('speicherReserve')
//   await fetchSpeicherReserve(data)
//   console.timeEnd('speicherReserve')
//   console.time('losses')
//   await fetchLosses(data, scenarioCH)
//   console.timeEnd('losses')
//   console.time('generationStack')
//   await fetchGenerationStack(data)
//   console.timeEnd('generationStack')
//   console.time('demand')
//   await fetchDemandData(scenarioCH, scenarioINT, data)
//   console.timeEnd('demand')
//   console.time('inputDemandSliders')
//   await fetchInputDemandSlidersData(data)
//   console.timeEnd('inputDemandSliders')
//   console.time('import')
//   await fetchImportData(scenarioCH, data)
//   console.timeEnd('import')
//   console.time('capacity')
//   await fetchCapacityData(scenarioCH, scenarioINT, data)
//   console.timeEnd('capacity')
//   console.time('availability')
//   await fetchAvailabilityData(data)
//   console.timeEnd('availability')
//   console.time('surplus')
//   await fetchSurplusData(data)
//   console.timeEnd('surplus')
//   // console.time('co2')
//   // await fetchCO2Data(data)
//   // console.timeEnd('co2')
//   console.timeEnd('loading')
//   return data
// }
