<script>
  import { isReset, userSettings } from './../stores.ts'
  import { _ } from 'svelte-i18n'
  import { format } from 'd3-format'
  import Reset from './../ui/Reset.svelte'
  import InfoIcon from '../ui/InfoIcon.svelte'

  let importCapacityGroupSlider

  isReset.subscribe(() => (importCapacityGroupSlider = 0))

  const f = format('.1f')

  $: isEdited =
    $userSettings.neighboringCountries.importCapacity.DE.value !== 0 ||
    $userSettings.neighboringCountries.importCapacity.DE.edited === true ||
    $userSettings.neighboringCountries.importCapacity.FR.value !== 0 ||
    $userSettings.neighboringCountries.importCapacity.FR.edited === true ||
    $userSettings.neighboringCountries.importCapacity.IT.value !== 0 ||
    $userSettings.neighboringCountries.importCapacity.IT.edited === true ||
    $userSettings.neighboringCountries.importCapacity.AT.value !== 0 ||
    $userSettings.neighboringCountries.importCapacity.AT.edited === true
</script>

<div class="outer">
  <div class="wrapper">
    <span class="label">
      <!-- <img src="icons/import.png" alt="icon" class="icon" /> -->
      <InfoIcon
        title={$_(
          'page.sections.userControls.switzerland.capacity.technologies.import.label'
        )}
        description={$_(
          'page.sections.userControls.switzerland.capacity.technologies.import.description'
        )}
      />
      {$_(
        'page.sections.userControls.switzerland.capacity.technologies.import.label'
      )}
      {#if isEdited}
        <span style="display: inline-block; margin-left: var(--size-2)">
          <Reset
            on:click={() => {
              $userSettings.neighboringCountries.importCapacity.DE.value = 0
              $userSettings.neighboringCountries.importCapacity.DE.edited = false
              $userSettings.neighboringCountries.importCapacity.FR.value = 0
              $userSettings.neighboringCountries.importCapacity.FR.edited = false
              $userSettings.neighboringCountries.importCapacity.IT.value = 0
              $userSettings.neighboringCountries.importCapacity.IT.edited = false
              $userSettings.neighboringCountries.importCapacity.AT.value = 0
              $userSettings.neighboringCountries.importCapacity.AT.edited = false
            }}
          />
        </span>
      {/if}
    </span>
  </div>
</div>

<style>
  .outer {
    width: 100%;
    padding: 4px;
  }
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .label {
    margin-left: 5px;
    display: flex;
  }

  .in-2050 {
    font-size: 1rem;
    display: inline-flex;
    flex-direction: column;
    margin-right: 5px;
    align-items: flex-end;
  }
</style>
