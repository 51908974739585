<script>
  import { calculatedData, capacityChanged, techGroups } from '../../stores'
  import { _ } from 'svelte-i18n'
  import { format } from 'd3-format'
  import MatrixSmall from '../../charts/matrix/MatrixSmall.svelte'

  const f = format('.2f')
  const f2 = format(',.1f')

  $: yearsWithDeficit = $calculatedData
    ?.getAnnualTimeSeries()
    .filter(
      (year) =>
        year.months.filter(
          (month) =>
            year.year >= 2024 && month.calculated.generation.deficit > 0
        ).length > 0
    )

  const renewableKeys = [
    'pv',
    'pv_alpin',
    // 'ps',
    'wind_on',
    'ror',
    'hs',
    'biomasse',
    'ccs_biomasse',
    'gtp',
    'oth_ee_total',
  ]

  const allKeys = [
    ...renewableKeys,
    'ga_total',
    // 'bc_total',
    'nu',
    'oth_fossil_total',
    'ccs_fossil',
  ]

  $: dataFor2050 = $calculatedData?.getAnnualTimeSeries().reverse()[0]

  $: renewablePct = dataFor2050
    ? renewableKeys.reduce(
        (acc, key) => acc + (dataFor2050?.calculated.generation[key] || 0),
        0
      ) /
      Object.keys(dataFor2050?.calculated.generation).reduce(
        (acc, key) => acc + (dataFor2050?.calculated.generation[key] || 0),
        0
      )
    : 0

  $: importPct = dataFor2050
    ? dataFor2050?.months.reduce(
        (acc, month) => acc + month.calculated.generation.import.total,
        0
      ) /
      allKeys.reduce(
        (acc, key) => acc + (dataFor2050?.calculated.generation[key] || 0),
        0
      )
    : 0

  let capacityTechGroupColor = '#000000'

  capacityChanged.subscribe((key) => {
    capacityTechGroupColor = $techGroups.find((d) => d.key === key)?.color
  })

  $: capacity2050 =
    dataFor2050 && $capacityChanged
      ? $techGroups
          .find((d) => d.key === $capacityChanged)
          .technologies.reduce(
            (acc, t) => acc + dataFor2050.calculated.capacity.CH[t],
            0
          ) / 1000
      : 0
</script>

<p class="message-wrapper">
  {#if yearsWithDeficit.length === 0}
    <div class="message">
      {@html $_('page.sections.charts.trafficLight.summary2050', {
        values: {
          pct: Math.round(renewablePct * 100),
          import: Math.round(importPct * 100),
        },
      })}
    </div>
  {:else}
    {console.log(yearsWithDeficit)}
    <div class="message">
      {@html $_('page.sections.charts.trafficLight.deficit', {
        values: {
          needed: f(
            yearsWithDeficit[0].months.reduce(
              (acc, cur) => acc + cur.calculated.generation.deficit,
              0
            )
          ),
          year: yearsWithDeficit[0].year,
        },
      })}
    </div>
  {/if}

  {#if $capacityChanged}
    <div
      class="message capacity-changed"
      style:--capacity-techgroup-color={capacityTechGroupColor}
    >
      {@html $_('page.sections.charts.trafficLight.valueChanged', {
        values: {
          capacity: $_(
            `page.sections.userControls.switzerland.capacity.groups.${$capacityChanged}.label`
          ),
          value: f2(capacity2050),
        },
      })}
    </div>
  {/if}

  {#if $calculatedData}
    <MatrixSmall />
  {:else}
    Loading...
  {/if}
</p>

<style>
  .message-wrapper {
    width: 100%;
    height: 603px;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    margin-top: 1px;
    background-color: var(--color-grey-100);
    border-radius: 5px;
  }
  .message {
    width: 80%;
  }

  .message:first-child p {
    font-weight: bold;
  }

  .message p {
    margin-bottom: 20px;
  }

  .message :global(h3) {
    font-family: 'Almarena Regular', 'Open Sans', sans-serif;
    font-weight: var(--weight-bold);
    font-size: var(--scale-5);
  }

  .message :global(span) {
    padding: 0 8px;
    border-radius: 20px;
  }

  .message :global(span) {
    background: var(--capacity-techgroup-color);
    color: white;
  }

  .message :global(em) {
    font-weight: var(--weight-bold);
  }
</style>
