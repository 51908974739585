<script>
  import { _ } from 'svelte-i18n'
  import {
    costDetailsHover,
    techGroups,
    selectedYear,
    scenarioCH,
    selectedLCOE,
    selectedPowerPrice,
    calculatedData,
    activeTab,
  } from '../../stores'
  import { individualTechMapping, costTechMapping } from './../../constants'
  import { format } from 'd3-format'
  import { hsl, color } from 'd3-color'
  import { sum } from 'd3-array'

  const f = format('0,.2f')
</script>

<div class="container">
  <div class="inner">
    {#if $costDetailsHover}
      {#if $costDetailsHover.type === 'comparison'}
        <h3
          style="font-size: var(--scale-4); margin-bottom: var(--size-2); margin-top: var(--size-4);"
        >
          {$_(
            'page.sections.charts.cost.scenario_comparison.total_cost_per_technology'
          )}
        </h3>

        {#each Object.entries($costDetailsHover.data.cost).reverse() as d}
          {@const techColor = $techGroups.find((tg) =>
            tg.technologies.includes(individualTechMapping[d[0]])
          )?.color}
          {@const { h, s, l } = hsl(techColor)}
          {@const textColor = isNaN(l) ? 'black' : hsl(h, s, 0.5)}
          <div
            class="comparison-cost"
            style="border-right: 4px solid {techColor
              ? textColor
              : 'transparent'};"
            class:active={$costDetailsHover.single === d[0]}
          >
            <span
              class="technology"
              class:weaker={d[1] === 0}
              style="color: {techColor ? textColor : 'black'}"
            >
              {$_(`page.sections.technologies.${d[0]}`)}
            </span>:
            <span class="cost" class:weaker={d[1] === 0}>{f(d[1])}</span>
            <span class="unit" class:weaker={d[1] === 0}
              >{$_(`page.sections.charts.cost.scenario_comparison.unit`)}</span
            >
          </div>
        {/each}
        <div class="comparison-cost" style="margin-top: var(--size-2);">
          <span class="technology" style="font-weight: bold;">Total</span>:
          <span class="cost"
            >{f(sum(Object.values($costDetailsHover.data.cost)))}</span
          >
          <span class="unit"
            >{$_(`page.sections.charts.cost.scenario_comparison.unit`)}</span
          >
        </div>
        <div
          class="comparison-cost"
          style="border-right: 4px solid transparent; margin-top: var(--size-3);"
          class:active={$costDetailsHover.single === 'subsidies'}
        >
          <span
            class="technology"
            class:weaker={$costDetailsHover.subsidies === 0}
            style="color: var(--color-green-700);"
          >
            {$_(`page.sections.charts.cost.scenario_comparison.subsidies`)}
          </span>:
          <span class="cost" class:weaker={$costDetailsHover.subsidies === 0}
            >{f($costDetailsHover.subsidies)}</span
          >
          <span class="unit" class:weaker={$costDetailsHover.subsidies === 0}
            >{$_(`page.sections.charts.cost.scenario_comparison.unit`)}</span
          >
        </div>

        <div
          class="comparison-cost"
          style="border-right: 4px solid transparent; margin-top: var(--size-3);"
          class:active={$costDetailsHover.single === 'demand'}
        >
          <span
            class="technology"
            class:weaker={$costDetailsHover.demand === 0}
          >
            {$_(`page.sections.charts.cost.scenario_comparison.demand`)}
          </span>:
          <span class="cost" class:weaker={$costDetailsHover.demand === 0}
            >{f($costDetailsHover.demand)}</span
          >
          <span class="unit" class:weaker={$costDetailsHover.demand === 0}
            >TWh</span
          >
        </div>
      {:else if $costDetailsHover.type === 'income'}
        <h3 class="tech-title">
          <span
            class="tech-group-title"
            style="background-color: var(--color-tech-{costTechMapping[
              $costDetailsHover.data.nr
            ]}); color: {['T12', 'T13', 'T14', 'T15', 'T17'].includes(
              $costDetailsHover.data.nr
            )
              ? `var(--color-tech-${
                  costTechMapping[$costDetailsHover.data.nr]
                }-darker)`
              : 'white'};"
            >{$_(
              `page.sections.technologies.${$costDetailsHover.data.nr}`
            )}</span
          >
        </h3>
        <div class="income-details">
          <div class="fixed-costs cost-category">
            {@html $_('page.sections.charts.cost.assumptions.fixed', {
              values: {
                value: f(
                  $calculatedData.years[$selectedYear - 2020].trueCost
                    .technologies[$costDetailsHover.data.nr].calculated
                    .netBenefit.lcoeMWh.investorFixed
                ),
              },
            })}
            <ul>
              <li>
                <span class="input-var"
                  ><em
                    >{$_(
                      'page.sections.charts.cost.assumptions.investment'
                    )}</em
                  >:</span
                >
                <span class="input-value">
                  {Math.round(
                    $calculatedData.years[$selectedYear - 2020].trueCost
                      .technologies[$costDetailsHover.data.nr].lcoe[
                      $selectedLCOE
                    ].investmentCosts
                  )} EUR/kW
                </span>
              </li>
              <li>
                <span class="input-var"
                  ><em
                    >{$_(
                      'page.sections.charts.cost.assumptions.full_load_hours'
                    )}</em
                  >:</span
                >
                <span class="input-value">
                  {Math.round(
                    $calculatedData.years[$selectedYear - 2020].trueCost
                      .technologies[$costDetailsHover.data.nr].lcoe[
                      $selectedLCOE
                    ].availability
                  )} hours
                </span>
              </li>
              {#if Math.round($calculatedData.years[$selectedYear - 2020].trueCost.technologies[$costDetailsHover.data.nr].lcoe[$selectedLCOE].decommissioningCosts) > 0}
                <li>
                  <span class="input-var">
                    <em
                      >{$_(
                        'page.sections.charts.cost.assumptions.disposal'
                      )}</em
                    >:
                  </span>
                  <span class="input-value">
                    {Math.round(
                      $calculatedData.years[$selectedYear - 2020].trueCost
                        .technologies[$costDetailsHover.data.nr].lcoe[
                        $selectedLCOE
                      ].decommissioningCosts +
                        $calculatedData.years[$selectedYear - 2020].trueCost
                          .technologies[$costDetailsHover.data.nr].lcoe[
                          $selectedLCOE
                        ].disposalCosts
                    )} EUR/MWh el
                  </span>
                </li>
              {:else if ['T10', 'T11'].includes($costDetailsHover.data.nr)}
                <li>
                  <span class="input-var">
                    <em
                      >{$_(
                        'page.sections.charts.cost.assumptions.water_tax'
                      )}</em
                    >:
                  </span>
                  <span class="input-value">
                    {$calculatedData.speicherReserve.waterTax} CHF/MWh
                  </span>
                </li>
              {:else}
                <li>&nbsp;</li>
                <!-- just to make sure that the box is not jumping all the time -->
              {/if}
            </ul>
          </div>

          <div
            class="variable-costs cost-category"
            class:variable-costs-fixed-height={$costDetailsHover !== undefined}
          >
            {@html $_('page.sections.charts.cost.assumptions.variable', {
              values: {
                value: f(
                  $calculatedData.years[$selectedYear - 2020].trueCost
                    .technologies[$costDetailsHover.data.nr].calculated
                    .netBenefit.lcoeMWh.investorVariable
                ),
              },
            })}
            <ul>
              <li>
                <span class="input-var"
                  ><em
                    >{$_('page.sections.charts.cost.assumptions.co2_price')}</em
                  >:</span
                >
                <span class="input-value">
                  {Math.round(
                    $calculatedData.years[$selectedYear - 2020].trueCost
                      .technologies[$costDetailsHover.data.nr].lcoe[
                      $selectedLCOE
                    ].CO2Price
                  )} EUR/t-CO2</span
                >
              </li>
            </ul>
            <p class="further-costs">
              {#if $costDetailsHover.data.nr === 'T6'}
                {$_('page.sections.charts.cost.assumptions.renewable_gas')}
              {/if}
            </p>
            <p class="further-costs">
              {$_('page.sections.charts.cost.assumptions.further_var')}
            </p>
          </div>

          <div class="social-costs cost-category">
            {#if $costDetailsHover.data.nr === 'T13'}
              {@const technologies =
                $calculatedData.years[$selectedYear - 2020].trueCost
                  .technologies}
              {@html $_('page.sections.charts.cost.assumptions.social', {
                values: {
                  value: f(
                    technologies[$costDetailsHover.data.nr].calculated
                      .netBenefit.lcoeMWh.social +
                      (technologies.T13.calculated.netBenefit.valueMWh -
                        technologies.T12.calculated.netBenefit.valueMWh)
                  ),
                },
              })}
            {:else}
              {@html $_('page.sections.charts.cost.assumptions.social', {
                values: {
                  value: f(
                    $calculatedData.years[$selectedYear - 2020].trueCost
                      .technologies[$costDetailsHover.data.nr].calculated
                      .netBenefit.lcoeMWh.social
                  ),
                },
              })}
            {/if}
            <ul>
              <li>
                <span class="input-var"
                  ><em
                    >{$_(
                      'page.sections.charts.cost.assumptions.external_co2_damage'
                    )}</em
                  >:</span
                >
                <span class="input-value">
                  {Math.round(
                    $calculatedData.years[$selectedYear - 2020].trueCost
                      .technologies[$costDetailsHover.data.nr].lcoe[
                      $selectedLCOE
                    ].environmentalDamage
                  )} EUR/t-CO2</span
                >
              </li>
              <li>
                <span class="input-var">
                  <em
                    >{$_(
                      'page.sections.charts.cost.assumptions.environmental_damage'
                    )}</em
                  >:
                </span>
                <span class="input-value">
                  {Math.round(
                    $calculatedData.years[$selectedYear - 2020].trueCost
                      .technologies[$costDetailsHover.data.nr].lcoe[
                      $selectedLCOE
                    ].otherEnvironmentalDamage
                  )} EUR/MWh el
                </span>
              </li>
            </ul>
          </div>
          <p class="further-costs">
            {$_('page.sections.charts.cost.assumptions.further_social')}
          </p>
          {@html $_('page.sections.charts.cost.assumptions.revenue', {
            values: {
              scenario: $_(
                `page.sections.userControls.switzerland.scenario.scenarios.${$scenarioCH}.label`
              ),
              value: Math.round(
                $calculatedData.years[$selectedYear - 2020].trueCost
                  .technologies[$costDetailsHover.data.nr].calculated.netBenefit
                  .valueMWh
              ),
              powerPrice: $selectedPowerPrice,
            },
          })}
          <!-- <span class="cost-type">Revenues</span> for the
          <em>
            {$_(
              `page.sections.userControls.switzerland.scenario.scenarios.${$scenarioCH}.label`
            )}
          </em>
          scenario are on annual average
          <span class="em-revenue"
            >{Math.round(
              $calculatedData.years[$selectedYear - 2020].trueCost.technologies[
                $costDetailsHover.data.nr
              ].calculated.netBenefit.valueMWh
            )}</span
          >
          CHF/MWh (based on the slider setting of {$selectedPowerPrice}
          CHF/MWh). -->
        </div>
      {:else if $costDetailsHover.type === 'subsidies'}
        <h4>{$costDetailsHover.year}</h4>
        {#each $costDetailsHover.data.filter((d) => d[0] !== 'year') as entries}
          {@const techColor = $techGroups.find((tg) =>
            tg.technologies.includes(individualTechMapping[entries[0]])
          )?.color}
          {@const { h, s, l } = hsl(techColor)}
          {@const textColor = isNaN(l) ? 'black' : hsl(h, s, 0.5)}
          <div class="comparison-cost">
            <span
              class="technology"
              class:weaker={entries[1] === 0}
              style="color: {techColor ? textColor : 'black'}"
              >{$_(`page.sections.technologies.${entries[0]}`)}</span
            >:
            <span class="cost" class:weaker={entries[1] === 0}
              >{entries[1] === 0 ? '--' : f(entries[1])}</span
            >
            <span class="unit">Mio. CHF/a</span>
          </div>
        {/each}
      {:else if $costDetailsHover.type === 'household_cost'}
        <h4>{$costDetailsHover.year}</h4>
        {#each $costDetailsHover.data.filter((d) => d[0] !== 'year') as entries}
          <div class="comparison-cost">
            <span
              class="technology"
              style="color: var(--color-cost-{entries[0]})"
              class:weaker={entries[1] === 0}
              >{$_(`page.sections.charts.cost.subsidies.${entries[0]}`)}</span
            >:
            <span class="cost" class:weaker={entries[1] === 0}
              >{entries[1] === 0 ? '--' : f(entries[1])}</span
            >
            <span class="unit">CHF/MWh</span>
          </div>
        {/each}
      {:else}
        Unknown $costDetailsHover.type: {$costDetailsHover?.type}
      {/if}
    {:else if $activeTab === 'income'}
      <p class="fixed-costs cost-category">
        {@html $_('page.sections.charts.cost.income_summary.fix')}
      </p>
      <p class="variable-costs cost-category">
        {@html $_('page.sections.charts.cost.income_summary.var')}
      </p>
      <p class="social-costs cost-category">
        {@html $_('page.sections.charts.cost.income_summary.social')}
      </p>
      <p>
        {@html $_('page.sections.charts.cost.income_summary.values')}
      </p>
    {:else if $activeTab === 'scenario_cost'}
      {@html $_('page.sections.charts.cost.scenario_comparison_summary.text')}
    {:else if $activeTab === 'subsidies'}
      {@html $_('page.sections.charts.cost.subsidies_summary.text')}
    {:else}
      ERROR: Tab is not defined: {$activeTab}!
    {/if}
  </div>
</div>

<style>
  .hide {
    visibility: hidden;
  }

  .cost-category {
    padding: var(--size-2);
    margin-block: var(--size-1);
    border-radius: var(--radius-sm);
  }

  .fixed-costs {
    background-color: var(--color-cost-inv-fixed-weakest);
  }

  .variable-costs {
    /* background-color: var(--color-blue-300); */
    background-color: rgba(99, 179, 237, 0.4);
  }

  .variable-costs-fixed-height {
    height: 190px;
  }

  .social-costs {
    /* background-color: var(--color-green-300); */
    background-color: rgba(154, 230, 180, 0.4);
  }

  .cost-type {
    font-weight: bold;
  }
  .income-details {
    font-size: var(--scale-3);
    line-height: var(--line-xs);
  }
  .income-details p {
    line-height: var(--line-sm);
  }

  .income-details li {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: var(--scale-2);
  }
  .income-details .input-var {
    width: calc(70% - 20px);
    margin-left: 20px;
    font-weight: normal;
  }
  .income-details .input-value {
    text-align: end;
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: end;
  }

  .income-details em {
    font-style: normal;
    font-weight: bold;
  }

  .income-details ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .active {
    font-weight: bold;
  }

  .weaker {
    opacity: 0.5;
  }

  .comparison-cost {
    text-align: right;
    margin-bottom: 1px;
    padding-right: var(--size-1);
    font-size: var(--scale-3);
  }

  .comparison-cost .cost {
    display: inline-block;

    width: var(--size-14);
  }

  .comparison-cost .unit {
    font-size: var(--scale-2);
  }

  .container {
    width: 100%;
    height: 630px;
    background-color: var(--color-grey-100);
    border-radius: 5px;
  }

  .inner {
    padding: var(--size-4);
  }

  .further-costs {
    font-size: var(--scale-2);
  }

  h3 {
    font-family: 'Almarena Regular', 'Open Sans', sans-serif;
    font-weight: var(--weight-bold);
    font-size: var(--scale-5);
    margin: 0;
  }

  ul {
    padding-top: var(--size-12);
  }
  /* 
  .em-inv-fix {
    background-color: var(--color-cost-inv-fixed-weaker);
    color: black;
    font-weight: bold;
    font-size: var(--scale-2);
    display: inline-block;
    padding: var(--size-1) var(--size-2);
    border-radius: var(--radius-xs);
  }

  .em-inv-var {
    background-color: var(--color-cost-inv-var-weaker);
    color: black;
    font-weight: bold;
    font-size: var(--scale-2);
    display: inline-block;
    padding: var(--size-1) var(--size-2);
    border-radius: var(--radius-xs);
  }

  .em-social {
    background-color: var(--color-cost-social-weaker);
    color: black;
    font-weight: bold;
    font-size: var(--scale-2);
    display: inline-block;
    padding: var(--size-1) var(--size-2);
    border-radius: var(--radius-xs);
  } */

  /* .em-revenue {
    background-color: var(--color-gray-300);
    color: black;
    font-weight: bold;
    font-size: var(--scale-2);
    display: inline-block;
    padding: var(--size-1) var(--size-2);
    border-radius: var(--radius-xs);
  } */

  .tech-group-title {
    padding: var(--size-1) var(--size-2);
    border-radius: var(--radius-xs);
    text-align: center;
    font-size: var(--scale-4);
    display: inline-block;
  }

  .tech-title {
    text-align: center;
  }

  .subtitle {
    font-size: var(--scale-3);
  }
</style>
