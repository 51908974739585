﻿<script>
  import Collapsable from '../../components/Collapsable.svelte'

  export let title
</script>

<Collapsable {title}>
  <Collapsable title="Modellansatz">
    <h2>Modellansatz</h2>

    <h3>Auf einen Blick</h3>

    <p>Die Eckpunkte zu Annahmen und Berechnungen sind:</p>

    <li>Unterstellung monatlicher Strombilanzen</li>

    <li>
      Nutzung historischer Daten zur Berechnung von monatlicher Stromerzeugung
    </li>

    <li>
      Kombination historischer Daten mit Szenarienannahmen zur Berechnung der
      monatlichen Stromnachfrage
    </li>

    <li>
      Nutzung von ENTSO-E und ENTSOG Szenarien als Voreinstellungen für die
      Dekarbonisierung in Nachbarländern
    </li>

    <p>Im Folgenden sind diese Eckpunkte weiter ausgeführt.</p>

    <h3>Energie- statt Leistungsbilanz</h3>

    <p>
      Monatliche Energiebilanzen anstelle von Leistungsbilanzen sind Basis für
      das Modell.
    </p>

    <h3>Monatsbilanzen</h3>

    <p>
      Anders als in vielen anderen europäischen Ländern ist in der Schweiz nicht
      eine mögliche Kapazitätsknappheit in einzelnen Stunden ausschlaggebend für
      die Versorgungssicherheit. Dies liegt daran, dass eine hohe Anzahl an
      hydraulischen Speicher- und Pumpspeicheranlagen und neue
      Speichermöglichkeiten dafür sorgen, dass stündliche oder tägliche
      Schwankungen in der Stromproduktion ausgeglichen werden können.
    </p>

    <p>
      Die Problematik liegt eher darin, dass bei einer längeren Flaute der
      sonstigen Stromerzeugung grosse Speicherbecken leerlaufen können. In
      diesem Fall ist eine Energieknappheit Auslöser für eine mögliche
      Unterbrechung der Stromversorgung.
    </p>

    <h3>Stromerzeugung</h3>

    <p>
      Wir bilden einmal auf Jahresbasis und einmal auf Monatsbasis ab, wieviel
      Strom durch welche Technologien erzeugt werden kann. Methodisch bedeutet
      dies, dass für alle Szenarien hinterlegt wird, wie hoch die Kapazität zur
      Stromerzeugung für alle Technologien ist. Mithilfe der maximalen
      monatlichen Stromerzeugung der einzelnen Technologien wird abgeleitet, wie
      viel Strom maximal in jedem Monat des Jahres produziert werden kann.
    </p>

    <p>
      Die hinterlegten Monatsprofile für die Technologien sind bei den
      Eingangsdaten der Kapazitäten unter Nutzungsgrad zu finden. Typische
      Profile sind aus historischen Daten zur mittleren Verfügbarkeit
      abgeleitet.
    </p>

    <h3>Stromnachfrage</h3>

    <p>
      Auch für die Nachfrage ist hinterlegt, welcher Anteil der Nachfrage im
      Jahr typischerweise in welchem Monat anfällt. Die jährlichen Gesamtmengen
      entstammen den jeweiligen Szenarien.
    </p>

    <h3>Importe</h3>

    <p>Siehe Kategorie "Importe" im Menü der Beschreibungen.</p>
  </Collapsable>

  <Collapsable title="Stressfaktoren - wie robust ist das Szenario?">
    <p>
      Untersuchen Sie wie robust das Szenario auf ungünstige Bedingungen
      reagiert. Es können fünf Stressfaktoren mittels «Slider» eingestellt
      werden
    </p>
    <h3>Erzeugung Wind PV Hydro</h3>

    <p>
      Dieser Slider variiert die Produktionsmenge der erneuerbaren Energien.
      «wenig» entspricht der für die Schweiz ungünstigsten Konstellation an
      Erneuerbaren-Produktion in Mitteleuropa, wie sie für einen gegebenen Monat
      im Zeitraum 1985-2016 real für die Kombination Wind und PV beobachtet
      wurde. Dieser Zustand kann grundsätzlich, in einem sehr ungünstigen Fall,
      auch mehrere Monate anhalten. «sehr wenig» entspricht einer extremen
      Konstellation: für jedes Land (Schweiz und Nachbarländer) und für jede
      Technologie (Wind, PV und Wasserkraft) wird gleichzeitig die jeweils
      schlechteste monatliche Verfügbarkeit unterlegt. Ein solcher Zustand ist
      vergleichbar mit einer «Dunkelflaute», welche meist nur einige Tage oder
      wenige Wochen anhält. Für eine längere Periode (z.B. ein Jahr) ist dies
      eine zu pessimistische Sicht, mögliche monatliche Defizite sollten hier
      nicht addiert werden. «viel» und «sehr viel» sind gleich definiert,
      spiegelbildlich für die jeweils günstigsten Werte der
      Erneuerbaren-Produktion)
    </p>

    <h3>Temperatur</h3>

    <p>
      Dieser Slider variiert den temperaturabhängigen Teil der Nachfrage. Die
      Einstellungen «kalt», «sehr kalt» gehen für die Schweiz und die
      Nachbarländer von einer um 2.5 Grad bzw. 5 Grad unter Norm liegenden
      Durchschnittstemperatur aus. Dadurch steigen der konventionelle Verbrauch
      und der Verbrauch der Wärmepumpen. Die Einstellungen «warm», «sehr warm»
      gehen spiegelbildlich von 2.5 bzw. 5 Grad über Norm liegenden
      Durchschnittstemperaturen aus, was zu tieferer Stromnachfrage führt.
    </p>

    <h3>Gasverfügbarkeit EU</h3>

    <p>
      Dieser Slider variiert die Gasverfügbarkeit in Europa. Ausgehend von
      historischen Werten wird die Verfügbarkeit für die Schweiz und die
      Nachbarländer auf 80/60/40/20% gesenkt.
    </p>

    <h3>Verfügbarkeit KKWs FR</h3>

    <p>
      Dieser Slider variiert die Verfügbarkeit französischer Kernkraftwerke.
      Ausgehend von historischen Werten wird die Verfügbarkeit französischer
      Kernkraftwerke auf 80/60/40/20% des durchschnittlichen historischen
      Niveaus gesenkt. Zum Vergleich: Im Sommer 2022 sank die Verfügbarkeit
      zwischenzeitlich auf rund 50% ab.
    </p>

    <h3>EU begrenzt CH-Importe</h3>

    <p>
      Dieser Slider begrenzt die Möglichkeit für Stromimporte auf Werte unter
      dem technisch möglichen Potential. Ab 2025 gilt in der EU die 70%-Klausel,
      nach der an EU-Binnengrenzen mindestens 70% der technisch verfügbaren
      Kapazität genutzt werden können muss. Je nach Auslegung dieser Klausel ist
      es möglich, dass die verfügbaren Grenzkapazität zur Schweiz seitens EU
      begrenzt werden. Mit dem Slider kann eingestellt werden, in viel Stunden
      aller Monate diese zusätzliche Begrenzung wirkt: 20/40/60/80% der Stunden.
    </p>
  </Collapsable>

  <Collapsable title="Quellen">
    <p>Zu Technologien</p>
    <ul>
      <li>IEA: World Energy Outlook 2020, 2021, 2022</li>

      <li>IEA: IEA-The-Future-of-Hydrogen-Assumptions-Annex.pdf</li>

      <li>IEA: NetZeroby2050-ARoadmapfortheGlobalEnergySector.pdf</li>

      <li>
        IRENA: Costs Projectiosn for Utility Scale Battery Storage 2021.pdf
      </li>

      <li>IRENA: IRENA_Power_Generation_Costs_2020.pdf</li>

      <li>IRENA: IRENA_World_Energy_Transitions_Outlook_2021.pdf</li>

      <li>IRENA: IRENA_Global_Renewables_Outlook_2020.pdf</li>

      <li>IRENA: IRENA_Green_hydrogen_cost_2020.pdf</li>

      <li>Bloomberg: BNEF_NEO2021.pdf</li>

      <li>
        Energy Transitions Commission: Making Cleam Electrification Possilbe;
        ETC-Global-Power-Report-.pdf
      </li>

      <li>Lazards: lazards-levelized-cost-of-energy-version-150-vf.pdf</li>

      <li>Lazards: lazards-levelized-cost-of-storage-version-70-vf.pdf</li>

      <li>
        Lazards: lazards-levelized-cost-of-hydrogen-analysis-version-20-vf.pdf
      </li>

      <li>
        Prof. P. Capros, E. Dimopoulou, S. Evangelopoulou, T. Fotiou, M.
        Kannavou, P. Siskos, G. Zazias: Technology pathways in
        decarbonisation.pdf
      </li>

      <li>
        EWI Köln:
        EWI_WP_20-04_Estimating_long-term_global_supply_costs_for_low-carbon_Schoenfisch_Braendle_Schulte.pdf
      </li>

      <li>
        EntsoE:
        TYNDP_2020_Scenario_Building-Guidelines_03_Annex_2_Cost_Assumptions_final_report.pdf
      </li>

      <li>
        Prognos20: Achieving Net Zero Carbon Emissions in Switzerland in 2050_
        Low Carbon Scenarios and their System Costs.pdf
      </li>

      <li>2021-01-20_Wasserkraftpotential_Boes_final.pdf</li>

      <li>Wirtschaftlichkeit Wasserkraft 2011-2020_WEL4-2021.pdf</li>

      <li>
        2023-anderegg-strebel-rohrer_alpine-photovoltaik-versuchsanlage-davos-totalp.pdf
      </li>
    </ul>

    <p>Zu Externalitäten</p>
    <ul>
      <li>
        Ecofys. 2014. «Subsidies and Costs of EU Energy - Final Report.»
        angefordert von der EU Commission
      </li>

      <li>
        Frischknecht, Rolf. 2017. « Life Cycle Assessment and Human Health
        Impacts of Electricity Production.» Uster: Treeze
      </li>

      <li>
        Frischknecht, Rolf, und René Itten. 2013. «Externe Kosten von
        Energiesyste Men Gemäss ECOPLAN.» Uster: Treeze
      </li>

      <li>
        Houses of Parliament. 2011. « Carbon Footprint of Electricity
        Generation.» Postnote Update 383. London: UK Parliamentary Office of
        Science and Technology
      </li>

      <li>
        IPCC. 2014. « Climate Change 2014 - Mitigation of Climate Change -
        Summary for Policymakers and Technical Summary.» Part of the Working
        Group III Contribution to the Fifth Assessment Report of the
        Intergovernmental Panel on Climate Change
      </li>

      <li>
        OECD und NEA. 2003. «Nuclear Electricity Generation: What Are the
        External Costs?.» NEA Nr. 4372.Paris: OECD
      </li>

      <li>
        OECD und NEA. 2012. «Nuclear Energy and Renewables System Effects in
        Low-Carbon Electricity Systems.» NEA Nr. 7056. Paris: OECD
      </li>

      <li>
        OECD und NEA. 2019. «The Costs of Decarbonization: System Costs with
        High Shares of Nuclear and Renewables.» NEA Nr. 7299. Paris: OECD
      </li>

      <li>
        Rabl, Ari, and Veronika A. Rabl. 2013. “External Costs of Nuclear:
        Greater or Less than the Alternatives?” Energy Policy 57 (June): 575–84.
        https://doi.org/10.1016/j.enpol.2013.02.028
      </li>

      <li>
        Sovacool, Benjamin K. 2008. «Valuing the Greenhouse Gas Emissions from
        Nuclear Power: A Critical Survey.» Energy Policy 36 (8): 2950–63.
        https://doi.org/10.1016/j.enpol.2008.04.017
      </li>

      <li>
        Strbac, G., and Marko Aunedi. 2016. «Whole-System Cost of Variable
        Renewables in Future GB Electricity System.»
        https://doi.org/10.13140/RG.2.2.24965.55523
      </li>

      <li>Swissnuclear. 2016. «Kostenstudie 2016</li>

      <li>Swissnuclear. 2021. «Kostenstudie 2021.»</li>

      <li>
        Trinomics. 2020. «Final Report - External Costs.» Brussels: im Auftrag
        der EU Commission
      </li>

      <li>
        Ueckerdt, Falko, Lion Hirth, Gunnar Luderer, and Ottmar Edenhofer. 2013.
        «System LCOE: What Are the Costs of Variable Renewables?» Energy
        (Oxford) 63: 61–75. https://doi.org/10.1016/j.energy.2013.10.072
      </li>

      <li>
        UN Economic Commission for Europe. 2021. «Life Cycle Assessment of
        Electricity Generation Options.» Genf: UN
      </li>
    </ul>
  </Collapsable>
</Collapsable>
