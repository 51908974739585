<script>
  import { _ } from 'svelte-i18n'
  import { fade } from 'svelte/transition'
  import ImportCapacity from '../charts/ImportCapacity.svelte'
  import ImportGroup from '../charts/ImportGroup.svelte'
  import TechnologyDetail from '../charts/TechnologyDetail.svelte'
  import TechnologyGroup from '../charts/TechnologyGroup.svelte'
  import ScenarioSelectors from '../components/ScenarioSelectors.svelte'
  import { scenarioINT, userSettings, techGroups } from '../stores'
  import UserSetting from '../UserSetting.svelte'
  import ControlRowCard from '../components/ControlRowCard.svelte'
  import { hiddenCapacities } from '../constants'

  import Row from '../components/Row.svelte'

  export let expand

  $: techGroupByKey = $techGroups.reduce((acc, d) => {
    acc[d.key] = d
    return acc
  }, {})

  $: keys = $techGroups.filter((d, i) => i >= 4).map((d) => d.key)
</script>

<Row expand={[...keys, 'import'].includes(expand)}>
  <svelte:fragment slot="top">
    {#each keys as key}
      <ControlRowCard
        bind:expand
        show={keys.includes(key)}
        color={techGroupByKey[key].color}
        {key}
      >
        <TechnologyGroup techGroup={techGroupByKey[key]} />
      </ControlRowCard>
    {/each}
    <ControlRowCard
      bind:expand
      show={true}
      color="var(--weak-color)"
      key="import"
      updateSelectedTechnology={false}
    >
      <ImportGroup />
    </ControlRowCard>
  </svelte:fragment>

  <!-- Capacity 2 detail + Import detail -->
  <svelte:fragment slot="details">
    {#if [...keys, 'import'].includes(expand)}
      <div class="details" in:fade out:fade={{ delay: 0 }}>
        <div class="detail">
          {#if keys.includes(expand)}
            {@const techGroup = techGroupByKey[expand]}
            {#each techGroup.subGroups as subGroup, j}
              <div
                class="active"
                style="grid-column: {1 + keys.indexOf(techGroup.key) + j} / {1 +
                  keys.indexOf(techGroup.key) +
                  j +
                  1}"
              >
                <div class="detail-inner">
                  <!-- assumes that currently stores.techGroups.subGroups.technologies has only 1 technology -->
                  {#if !hiddenCapacities.includes(subGroup.technologies[0].key)}
                    <TechnologyDetail {subGroup} {techGroup} />
                  {/if}
                </div>
              </div>
            {/each}
          {:else}
            <div style="grid-column: 4 / 5;" class="active">
              <div class="detail-inner">
                <ImportCapacity />
                {#each Object.keys($userSettings.neighboringCountries.importCapacity) as userSetting, i}
                  <div
                    style="display: flex;  align-items: center; position: relative;"
                  >
                    <span
                      style="width: 100px; display: inline-block; margin-right: 10px; margin-top: {i ===
                      0
                        ? 12
                        : 0}px;"
                      >{$_(
                        `page.sections.userControls.neighboringCountries.capacity.countries.${userSetting}`
                      )}</span
                    >
                    <UserSetting
                      {userSetting}
                      {i}
                      type="range"
                      sliderWidth="120px"
                      group="neighboringCountries"
                      subGroup="importCapacity"
                      techGroup={{ key: 'dummy' }}
                    />
                  </div>
                {/each}
              </div>
            </div>
            <div class="active" style="grid-column: 5 / 6;">
              <div class="detail-inner">
                <ScenarioSelectors
                  store={scenarioINT}
                  geo="INT"
                  on:scenario-toggle={() => {
                    // console.log(demandGroupSlider.value)
                    // demandGroupSlider.value = 0
                    // importCapacityGroupSlider.value = 0
                  }}
                />
              </div>
            </div>
          {/if}
        </div>
      </div>
    {/if}
  </svelte:fragment>
</Row>

<style>
  .details {
    height: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    width: 100%;
  }

  .detail {
    /* background-color: #fde68a; */
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  .detail-inner {
    padding: 10px;
  }

  .active {
    background-color: var(--weak-color);
  }
</style>
