<script>
  import { range } from 'd3-array'
  import { format } from 'd3-format'
  import { scaleLinear, scaleQuantize } from 'd3-scale'
  import { pointer } from 'd3-selection'
  import { area, curveCatmullRom } from 'd3-shape'
  import { cubicInOut } from 'svelte/easing'
  import { tweened } from 'svelte/motion'
  import OutlineText from './../components/OutlineText.svelte'
  import { months3 } from './../constants.ts'

  export let key
  export let color
  export let maxPct = 1
  export let data

  const monthLetters = [
    'J',
    'F',
    'M',
    'A',
    'M',
    'J',
    'J',
    'A',
    'S',
    'O',
    'N',
    'D',
  ]

  const f = format(',.1f')

  let mousePosition
  let hoverValue = undefined

  // availability is the same for every year, so we just take the 1st year to show the monthly profile

  const tweenedData = tweened(undefined, { easing: cubicInOut })

  $: $tweenedData = data

  const width = 250
  const height = 150

  const margin = {
    top: 20,
    right: 15,
    bottom: 20,
    left: 40,
  }

  const w = width - margin.left - margin.right
  const h = height - margin.top - margin.bottom

  const x = scaleLinear().domain([0, 11]).range([0, w])
  const xq = scaleQuantize().domain([0, w]).range(range(12))

  $: y = scaleLinear().domain([0, maxPct]).range([h, 0])

  $: ticks = y.nice().ticks(4)

  const a = area()
    .x((d, i) => x(i))
    .y1((d) => y(d))
    .y0(() => y(0))
    .curve(curveCatmullRom)
</script>

<svg {width} {height} style="margin-top: 20px;">
  <defs>
    <pattern
      id="diagonalHatch-availability-{key}"
      patternUnits="userSpaceOnUse"
      width="4"
      height="4"
    >
      <path
        d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
        style="stroke: {color}; stroke-width:1"
      />
    </pattern>
  </defs>
  <g transform="translate({margin.left}, {margin.top})">
    <rect fill="url(#diagonalHatch-availability-{key})" width={w} height={h} />
    <path
      d={a($tweenedData)}
      fill={color}
      on:mouseover={(e) => {
        mousePosition = pointer(e)
        hoverValue = {
          month: xq(mousePosition[0]),
          value: data[xq(mousePosition[0])],
        }
      }}
      on:mousemove={(e) => {
        mousePosition = pointer(e)
        hoverValue = {
          month: xq(mousePosition[0]),
          value: data[xq(mousePosition[0])],
        }
      }}
      on:mouseout={() => (hoverValue = undefined)}
      on:focus={() => {}}
      on:blur={() => {}}
    />

    {#if !hoverValue}
      {#each monthLetters as month, i}
        <text class="x-tick" dy={14} x={x(i)} y={h}>{month}</text>
      {/each}
    {:else}
      <text x={x(hoverValue.month)} y={h} class="x-tick" dy={14}
        >{months3[hoverValue.month]}</text
      >
    {/if}

    {#each ticks as tick}
      {#if tick > 0}
        <text y={y(tick)} class="y-tick" dx={-4}>{tick * 100}%</text>
      {/if}
    {/each}

    {#if hoverValue}
      <OutlineText
        backColor="rgb(247, 245, 244)"
        x={Math.min(w - 20, Math.max(20, x(hoverValue.month)))}
        y={y(hoverValue.value)}
        dy={-8}
        style="text-anchor: middle; font-size: var(--scale-1);"
        >{f(hoverValue.value * 100)}%</OutlineText
      >
    {/if}
  </g>
</svg>

<style>
  svg {
    max-width: 370px;
  }

  text {
    font-size: var(--scale-1);
  }
</style>
