﻿<script>
  import Collapsable from '../../components/Collapsable.svelte'

  export let title
</script>

<Collapsable {title} track={true}>
  <p>
    Switzerland is among the countries in Europe most connected to other
    countries through power lines. Imports and exports can be looked at at the
    level of years; however, it is more instightful to look at them at the level
    of seasons or of months.
  </p>
  <p>
    During the summer months, Switzerland is currently a net exporter of
    electricity: the country exports more than it imports. In the future, more
    electricity could be exported in summer if Switzerland expanded its
    photovoltaic production. On a seasonal basis, however, imports to
    Switzerland dominate in winter. This means that the country cannot produce
    enough electricity itself at this time to meet the high demand. If Swiss
    production is insufficient, electricity can be imported under two
    conditions. First, the scenario chosen or created must allow imports; output
    capacities are available for import. Second, the foreign countries must have
    surplus electricity available for export. This depends on the chosen or
    created scenarios for decarbonisation in neighbouring countries as well as
    the selected number of cloudy and windless days.
  </p>
  <p>
    In order to estimate the options for export to Switzerland, the same energy
    balance is used for all neighbouring states as for Switzerland, based on
    European development scenarios (ENTSO-E and ENTSOG TYNDP scenarios from
    2022). The potential surpluses are divided among all neighbouring countries
    proportional to the level of import demand. The maximum amount of
    electricity that can be imported is the amount neighbouring countries have
    available for potential export that can be transported via the power lines.
    In other words, the lower of the two values for export potential and line
    capacity is the maximum import quantity. European development scenarios
    (ENTSO-E and ENTSOG TYNDP scenarios from 2022). The potential surpluses are
    divided among all neighbouring countries proportional to the level of import
    demand. The maximum amount of electricity that can be imported is the amount
    neighbouring countries have available for potential export that can be
    transported via the power lines. In other words, the lower of the two values
    for export potential and line capacity is the maximum import quantity.
  </p>
  <p>
    Domestic production and import potential help determine for each month
    whether Switzerland has an electricity deficit or surplus.
  </p>
</Collapsable>
