<script>
  import { userSettings, calculatedData } from './../stores.ts'
  import AvailabilityPanel from '../components/panels/AvailabilityPanel.svelte'
  import CapacityPanel from '../components/panels/CapacityPanel.svelte'
  import InfoPanel from '../components/panels/InfoPanel.svelte'
  import { _ } from 'svelte-i18n'
  import { Tabs, TabList, TabPanel, Tab } from './../components/tabs/tabs.js'
  import Reset from './../ui/Reset.svelte'
  import InfoIcon from '../ui/InfoIcon.svelte'
  import { hiddenCapacities } from '../constants.js'

  export let subGroup
  export let techGroup
  // $: console.log(subGroup.technologies[0], techGroup)

  let groupSlider

  // when a scenario is selected, reset the group slider
  userSettings.subscribe((d) => {
    if (
      techGroup.technologies.every(
        (d) => $userSettings.switzerland.installedCapacity[d].edited === false
      )
    ) {
      groupSlider = 0
    }
  })

  const allGroupTechnologyKeys = techGroup.subGroups
    .map((g) => g.technologies.map((d) => d.key))
    .flat()

  const allGroupAvailabilityKeys = techGroup.subGroups.map(
    (d) => d.availabilityKey
  )

  $: capacityEdited = allGroupTechnologyKeys.reduce(
    (acc, key) =>
      acc ||
      $userSettings.switzerland.installedCapacity[subGroup.technologies[0].key]
        .value !== 0 ||
      $userSettings.switzerland.installedCapacity[subGroup.technologies[0].key]
        .edited,
    false
  )

  $: availabilityEdited = allGroupAvailabilityKeys.reduce(
    (acc, key) =>
      acc || $userSettings.switzerland.availabilityOfTech[key] !== 'avg',
    false
  )
</script>

<h4>
  <InfoIcon
    title={$_(
      `page.sections.userControls.switzerland.capacity.technologies.${subGroup.technologies[0].key}.label`
    )}
    description={$_(
      `page.sections.userControls.switzerland.capacity.technologies.${subGroup.technologies[0].key}.description`
    )}
  />
  {$_(
    `page.sections.userControls.switzerland.capacity.technologies.${subGroup.technologies[0].key}.label`
  )}
  {#if capacityEdited}
    <span style="margin-left: var(--size-2)">
      <Reset
        on:click={() => {
          const tmp = $userSettings

          tmp.switzerland.installedCapacity[
            subGroup.technologies[0].key
          ].value = 0

          tmp.switzerland.installedCapacity[
            subGroup.technologies[0].key
          ].edited = false

          tmp.switzerland.customCapacity[subGroup.technologies[0].key] =
            $calculatedData
              .getAnnualTimeSeries()
              .map(
                (year) =>
                  year.input.CH.capacity.scenario[subGroup.technologies[0].key]
              )

          $userSettings = tmp
        }}
      />
    </span>
  {/if}
</h4>

<Tabs>
  <TabList>
    <Tab>
      {$_(
        'page.sections.userControls.switzerland.capacity.controls.tabs.capacity'
      )}
      <!-- {#if capacityEdited}<span class="edited">*</span>{/if} -->
    </Tab>
    <Tab>
      {$_(
        'page.sections.userControls.switzerland.capacity.controls.tabs.utilization'
      )}
      <!-- {#if availabilityEdited}<span class="edited">*</span>{/if} -->
    </Tab>
  </TabList>

  <TabPanel>
    <div class="panels">
      {#each subGroup.technologies as technology}
        <CapacityPanel {techGroup} {technology} />
      {/each}
    </div>
  </TabPanel>

  <TabPanel>
    <div class="panels">
      {#each subGroup.technologies as technology, i}
        <AvailabilityPanel {technology} {techGroup} {subGroup} {i} />
      {/each}
    </div>
  </TabPanel>
</Tabs>

<style>
  .panels {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  /* ----- */
  h4 {
    margin: 0;
    display: flex;
  }

  .edited {
    color: var(--accent-color);
  }
</style>
