<script>
  export let active
</script>

<button class:active on:click>
  <slot />
</button>

<style>
  button {
    /* background-color: var(--color-gray-old); */
    background-color: var(--color-grey-200);
    color: black;
    border: none;
    padding: 5px 20px;
    cursor: pointer;
    outline: none;
    margin-right: 1px;
  }

  button:active,
  button:hover {
    background-color: var(--color-grey-300);
  }

  button.active {
    background-color: var(--accent-color);
    color: white;
  }

  button:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  button:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
</style>
