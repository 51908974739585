<script>
  import MatrixChart from './MatrixChart.svelte'
  import { _ } from 'svelte-i18n'
  import Popover from '../../components/Popover.svelte'
  import MatrixLegend from './MatrixLegend.svelte'
  import { LayerCake, Svg } from 'layercake'
  import { months3 } from '../../constants.js'
  import { format } from 'd3-format'
  import { popoverContent, showPopover } from '../../stores'

  let locked

  let svg

  let selectedYear
  let selectedMonth
  let selectedDate
  let popover

  $: if (popover !== undefined) {
    $showPopover = true

    const month = $_(`page.sections.other.months.${months3[popover.month]}`)
    const year = popover.year
    const label =
      popover.surplus > 0
        ? 'surplus'
        : popover.deficit > 0
        ? 'deficit'
        : 'import'
    const value =
      popover.surplus > 0
        ? f(popover.surplus)
        : popover.deficit > 0
        ? f(popover.deficit)
        : f(popover.import)

    const displayLabel = $_(
      `page.sections.charts.surplusDeficit.detailLabels.${label}`
    )

    $popoverContent = `
      <h3 style="text-align: center; margin-top: 0;">
        ${month}
        ${year}
      </h3>
      <div
        style="
          width: 100%;
          padding: var(--size-2);
          color: ${label === 'deficit' ? 'white' : 'black'};
          font-size: var(--scale-2);
          text-align: center;
          text-transform: uppercase;
          background-color: var(--color-energy-${label});"
      >
        ${displayLabel}: <span style="
          font-weight: bold;
          text-transform: none;
          font-size: var(--scale-3);">${value} TWh</span>
      </div>
    `
  } else {
    $showPopover = false
  }

  const f = format('.2f')
</script>

<div class="wrapper">
  <div class="bottom-row">
    <MatrixLegend />
  </div>
  <div class="container">
    <LayerCake
      bind:element={svg}
      padding={{ top: 0, right: 60, bottom: 20, left: 30 }}
    >
      <Svg>
        <MatrixChart
          bind:popover
          bind:locked
          bind:selectedYear
          bind:selectedMonth
          bind:selectedDate
        />
      </Svg>
    </LayerCake>
  </div>
</div>

<!-- <div class="auxiliary">
    {#if selectedYear}
      <MatrixYearChart {data} {selectedYear} {cellSize} />
    {:else if selectedMonth !== undefined}
      <MatrixMonthChart {data} {selectedMonth} {cellSize} />
    {:else if selectedDate !== undefined}
      <MatrixDateChart {data} {hoverTech} {selectedDate} {cellSize} />
    {:else}
      <MatrixInstructions />
    {/if}
  </div> -->
<style>
  .wrapper {
    display: grid;
    grid-template-rows: 40px 30px 450px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .container {
    /* width: 800px; */
    /* height: 300px; */
    width: 100%;
    height: 432px;
  }

  .top-row,
  .bottom-row {
    display: flex;
    justify-content: space-between;
  }

  p {
    max-width: 900px;
  }

  h1 {
    display: flex;
    justify-content: flex-start;
    margin: 0;
  }
</style>
