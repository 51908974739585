<script>
  import RangeSlider from './../ui/RangeSlider.svelte'
  import { userSettings } from './../stores'
  import { _ } from 'svelte-i18n'
  import Reset from '../ui/Reset.svelte'
  import InfoIcon from '../ui/InfoIcon.svelte'

  export let stressor
</script>

<div class="stressor">
  <div class="stressor-label">
    <InfoIcon
      title={$_(
        `page.sections.userControls.switzerland.stressors.${stressor.key}.label`
      )}
      description={$_(
        `page.sections.userControls.switzerland.stressors.${stressor.key}.description`
      )}
    />
    {$_(
      `page.sections.userControls.switzerland.stressors.${stressor.key}.label`
    )}
    {#if $userSettings.general.stressors[stressor.key].value !== $userSettings.general.stressors[stressor.key].defaultValue}
      <span style="position: absolute; top: 8px; right: var(--size-4)">
        <Reset
          on:click={() => {
            $userSettings.general.stressors[stressor.key].value =
              $userSettings.general.stressors[stressor.key].defaultValue
          }}
        />
      </span>
    {/if}
  </div>
  <RangeSlider
    min={stressor.min}
    max={stressor.max}
    step={stressor.step}
    markers={stressor.markers.map((d, i) => ({
      value: d,
      label: `${
        $_(
          `page.sections.userControls.switzerland.stressors.${stressor.key}.markers`
        )[i]
      }`,
    }))}
    bind:value={$userSettings.general.stressors[stressor.key].value}
  />
</div>

<style>
  .reset:active {
    background: transparent;
  }

  input {
    background: none;
  }

  .stressor {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 2px;
    width: 100%;
    background-color: rgba(255, 0, 0, 0.1);
    align-items: center;
    border-radius: 5px;
    border-top: 4px solid #f00;
    padding: 16px 10px 8px;
  }

  .stressor-label {
    width: 100%;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-top: -6px;
    font-size: var(--scale-3);
    color: #f00;
  }

  input[type='range'] {
    margin-top: 15px;
  }
</style>
