<script>
  import { fade } from 'svelte/transition'
  import DemandGroup from '../charts/DemandGroup.svelte'
  import TechnologyDetail from '../charts/TechnologyDetail.svelte'
  import TechnologyGroup from '../charts/TechnologyGroup.svelte'
  import TotalDemand from '../charts/TotalDemand.svelte'
  import DemandControl2 from '../components/DemandControl2.svelte'
  import { techGroups } from '../stores'
  import ControlRowCard from '../components/ControlRowCard.svelte'

  import Row from '../components/Row.svelte'

  export let expand

  $: techGroupByKey = $techGroups.reduce((acc, d) => {
    acc[d.key] = d
    return acc
  }, {})

  $: keys = $techGroups.filter((d, i) => i < 4).map((d) => d.key)
</script>

<Row expand={[...keys, 'demand'].includes(expand)}>
  <svelte:fragment slot="top">
    {#each keys as key}
      <ControlRowCard
        bind:expand
        show={keys.includes(key)}
        color={techGroupByKey[key].color}
        {key}
      >
        <TechnologyGroup techGroup={techGroupByKey[key]} />
      </ControlRowCard>
    {/each}
    <ControlRowCard
      bind:expand
      show={true}
      color="var(--weak-color)"
      key="demand"
      updateSelectedTechnology={false}
    >
      <DemandGroup />
    </ControlRowCard>
  </svelte:fragment>

  <svelte:fragment slot="details">
    {#if [...keys, 'demand'].includes(expand)}
      <div class="details" in:fade out:fade={{ delay: 0 }}>
        <div class="detail">
          {#if keys.includes(expand)}
            {@const techGroup = techGroupByKey[expand]}
            {#each techGroup.subGroups as subGroup, j}
              <div
                class="active"
                style="grid-column: {1 + keys.indexOf(techGroup.key) + j} / {1 +
                  keys.indexOf(techGroup.key) +
                  j +
                  1};"
              >
                <!-- style="background-color: var(--color-tech-{techGroup.key}-partial-transparent);" -->
                <div class="detail-inner">
                  <TechnologyDetail {subGroup} {techGroup} />
                </div>
              </div>
            {/each}
          {:else}
            {#each ['klass', 'ev', 'h2', 'hp'] as demandType, i}
              <div class="active" style="grid-column: {i + 1} / {i + 2};">
                <div class="detail-inner">
                  <DemandControl2 selectedTech={demandType} />
                </div>
              </div>
            {/each}
            <div class="active" style="grid-column: 5 / 6;">
              <div class="detail-inner">
                <TotalDemand />
              </div>
            </div>
          {/if}
        </div>
      </div>
    {/if}
  </svelte:fragment>
</Row>

<style>
  .details {
    height: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    width: 100%;
  }

  .detail {
    /* background-color: #fde68a; */
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  .detail-inner {
    padding: 10px;
  }

  .active {
    background-color: var(--weak-color);
  }
</style>
