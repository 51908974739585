<div class="button-group">
  <slot />
</div>

<style>
  .button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
</style>
