<script>
  import { range } from 'd3-array'
  import { format } from 'd3-format'
  import { scaleLinear, scaleQuantize } from 'd3-scale'
  import { pointer } from 'd3-selection'
  import { area, curveCatmullRom, line } from 'd3-shape'
  import { _ } from 'svelte-i18n'
  import {
    calculatedData,
    selectedTech,
    userSettings,
    capacityChanged,
  } from '../stores'
  import { draw } from './../actions/draw'
  import OutlineText from './../components/OutlineText.svelte'

  export let technology
  export let techGroup

  $: capacity = $calculatedData.getAnnualTimeSeries((year) => ({
    min: year.input.CH.capacity.min[technology],
    max: year.input.CH.capacity.max[technology],
    scenario: year.input.CH.capacity.scenario[technology],
    capacity: year.calculated.capacity.CH[technology],
  }))

  $: maxAnnualCapacity = Math.max(
    ...$calculatedData.years.map(
      (year) => year.input.CH.capacity.max[technology]
    )
  )

  $: drawYear = undefined

  let mousePosition
  let hoverValue = undefined

  let showInstruction = true

  const f = format(',.1f')
  const tf = format(',.1r')

  const width = 250
  const height = 150

  const margin = {
    top: 20,
    right: 15,
    bottom: 20,
    left: 30,
  }

  const w = width - margin.left - margin.right
  const h = height - margin.top - margin.bottom

  const x2 = scaleLinear().domain([0, 30]).range([0, w]).clamp(true)
  const xq = scaleQuantize().domain([0, w]).range(range(31))

  $: y = scaleLinear().domain([0, maxAnnualCapacity]).range([h, 0]).clamp(true)

  const a4 = area()
    .x((d, i) => x2(i))
    .y1((d) => y(d.capacity))
    .y0((d) => y(0))
    .curve(curveCatmullRom)

  const l = line()
    .x((d) => d.x)
    .y((d) => d.y)
</script>

<input
  type="range"
  min={-1}
  max={1}
  step="0.25"
  style="width: 100%; padding: 0; margin: 0;"
  bind:value={$userSettings.switzerland.installedCapacity[technology].value}
  on:input={() => {
    $userSettings.switzerland.installedCapacity[technology].edited =
      $userSettings.switzerland.installedCapacity[technology].value !== 0
  }}
  on:mousedown={() => {
    $selectedTech = techGroup.key
    $capacityChanged = techGroup.key
  }}
  on:mouseup={() => {
    $selectedTech = undefined
  }}
/>

<svg {width} {height} id="svg-{technology}">
  <defs>
    <marker
      id="arrow"
      viewBox="0 0 10 10"
      refX="5"
      refY="5"
      markerWidth="6"
      markerHeight="6"
      orient="auto-start-reverse"
    >
      <path d="M 0 0 L 10 5 L 0 10 z" />
    </marker>
  </defs>
  <g
    style="cursor: crosshair;"
    transform="translate({margin.left}, {margin.top})"
    use:draw={{
      svg: document.getElementById(`svg-${technology}`),
      margin: margin,
    }}
    on:draw={(e) => {
      const yearIndex = Math.round(x2.invert(e.detail.x))

      if (yearIndex > 1) {
        const tmp = $userSettings
        tmp.switzerland.customCapacity[technology][yearIndex] = Math.max(
          Math.min(
            y.invert(e.detail.y),
            $calculatedData.years[yearIndex].input.CH.capacity.max[technology]
          ),
          $calculatedData.years[yearIndex].input.CH.capacity.min[technology]
        )

        tmp.switzerland.installedCapacity[technology].edited = true

        $userSettings = tmp

        drawYear = {
          yearIndex: Math.round(x2.invert(e.detail.x)),
          capacity: Math.round(y.invert(e.detail.y)),
        }
      }
    }}
    on:drawstart={() => {
      showInstruction = false

      const tmp = $userSettings

      tmp.switzerland.customCapacity[technology] =
        tmp.switzerland.customCapacity[technology].map((d, i) => {
          const currentValue = d
          const sliderValue =
            tmp.switzerland.installedCapacity[technology].value
          const minValue =
            $calculatedData.years[i].input.CH.capacity.min[technology]
          const maxValue =
            $calculatedData.years[i].input.CH.capacity.max[technology]

          const newValue =
            currentValue +
            sliderValue *
              (sliderValue < 0
                ? currentValue - minValue
                : maxValue - currentValue)

          return newValue
        })

      tmp.switzerland.installedCapacity[technology].value = 0
      $userSettings = tmp
    }}
    on:drawend={() => {
      drawYear = undefined
    }}
  >
    {#if showInstruction}
      <text
        x={w / 2}
        y={y.range()[1]}
        dy={-4}
        style="text-anchor: middle; fill: #3d3d3d; font-size: var(--scale-1);"
        >{$_(
          'page.sections.userControls.switzerland.capacity.chartInstruction'
        )}</text
      >
      >
    {/if}
    <rect width={w} height={h} fill="transparent" />
    <line x2={w} y1={y(0)} y2={y(0)} stroke="black" />
    <line y1={y(0)} y2={y.range()[1]} stroke="black" marker-end="url(#arrow)" />
    <text
      y={y.range()[1]}
      style="text-anchor: middle; font-size: var(--scale-1);"
      dy={-10}>GW</text
    >
    <path
      d={a4(capacity)}
      fill={techGroup.color}
      on:mouseover={(e) => {
        mousePosition = pointer(e)
        hoverValue = {
          year: xq(mousePosition[0]) + 2020,
          value: capacity[xq(mousePosition[0])].capacity,
        }
      }}
      on:mousemove={(e) => {
        mousePosition = pointer(e)
        hoverValue = {
          year: xq(mousePosition[0]),
          value: capacity[xq(mousePosition[0])].capacity,
        }
      }}
      on:mouseout={() => {
        hoverValue = undefined
      }}
      on:focus={() => {}}
      on:blur={() => {}}
    />
    <path
      d={line()
        .x((d, i) => x2(i))
        .y((d) => y(d.max))(capacity)}
      id="max-{technology}"
      stroke="black"
      stroke-width={0.5}
      fill="none"
      stroke-dasharray="2 2"
      style="pointer-events: none;"
    />
    <path
      d={line()
        .x((d, i) => x2(i))
        .y((d) => y(d.min))(capacity)}
      id="min-{technology}"
      stroke="black"
      stroke-width={0.5}
      fill="none"
      stroke-dasharray="2 2"
      style="pointer-events: none;"
    />
    <path
      d={line()
        .x((d, i) => x2(i))
        .y((d) => y(d.scenario))(capacity)}
      id="scenario-{technology}"
      stroke={techGroup.color}
      stroke-width={2}
      fill="none"
    />

    <path
      d={line()
        .x((d, i) => x2(i))
        .y((d) => y(d.capacity))
        .curve(curveCatmullRom)(capacity)}
      class="editable-scenario-path"
      stroke-width={3}
      fill="none"
    />

    {#each capacity as c, i}
      <g transform="translate({x2(i)}, {y(c.capacity)})">
        <circle r={2.5} class="year-dot" />
      </g>
    {/each}

    <OutlineText x={x2(30)} class="max" dy={-2} backColor="rgb(247, 245, 244)"
      >max</OutlineText
    >

    <OutlineText
      x={x2(30)}
      y={y(capacity[30].min)}
      class="min"
      dy={-2}
      backColor="rgb(247, 245, 244)">min</OutlineText
    >

    {#if hoverValue && !drawYear}
      <OutlineText
        backColor="rgb(247, 245, 244)"
        strokeWidth={5}
        x={Math.min(w - 24, Math.max(28, x2(hoverValue.year)))}
        y={y(hoverValue.value)}
        dy={-8}
        style="text-anchor: middle; font-size: var(--scale-1);"
        >{f(hoverValue.value / 1000)} GW</OutlineText
      >
    {/if}

    {#if !drawYear}
      <g>
        {#if !hoverValue}
          {#each range(2020, 2051) as year, i}
            {#if i % 5 === 0}
              <text transform="translate({x2(i)}, {h})" dy={16} class="x-tick"
                >{year}</text
              >
            {/if}
          {/each}
        {:else}
          <text
            transform="translate({x2(xq(mousePosition[0]))}, {h})"
            dy={14}
            class="x-tick">{xq(mousePosition[0]) + 2020}</text
          >
        {/if}
      </g>
    {/if}
    {#if drawYear}
      <text
        transform="translate({x2(drawYear.yearIndex)}, {h})"
        dy={14}
        class="x-tick">{drawYear.yearIndex + 2020}</text
      >
      <!-- TODO: kind of duplicate with the mouseover for the circle, but the hovering of the circle does not work beyond the max -->
      <text
        transform="translate({x2(drawYear.yearIndex)}, {y(
          Math.min(
            Math.round(capacity[drawYear.yearIndex].max),
            drawYear.capacity
          )
        )})"
        dy={-14}
        fill="rgb(247, 245, 244)"
        stroke="rgb(247, 245, 244)"
        stroke-width={2}
        style="text-anchor: middle; font-size: var(--scale-1);"
        >{f(
          Math.min(
            Math.round(capacity[drawYear.yearIndex].max),
            drawYear.capacity
          ) / 1000
        )}
        <tspan style="font-size: var(--scale-1);">GW</tspan></text
      >
      <text
        transform="translate({x2(drawYear.yearIndex)}, {y(
          Math.min(
            Math.round(capacity[drawYear.yearIndex].max),
            drawYear.capacity
          )
        )})"
        dy={-14}
        style="text-anchor: middle; font-size: var(--scale-1);"
        >{f(
          Math.min(
            Math.round(capacity[drawYear.yearIndex].max),
            drawYear.capacity
          ) / 1000
        )}
        <tspan style="font-size: var(--scale-1);">GW</tspan></text
      >
    {/if}

    {#each y.nice().ticks(4) as tick}
      {#if tick > 0}
        <text dx={-4} class="y-tick" y={y(tick)}>{tf(tick / 1000)}</text>
      {/if}
    {/each}
  </g>
</svg>

<!-- {#if $userSettings.switzerland.installedCapacity[technology].edited}
  <div style="display: flex; justify-content: flex-end;">
    <button
      class="reset"
      on:click={() => {
        const tmp = $userSettings

        tmp.switzerland.installedCapacity[technology].value = 0
        tmp.switzerland.customCapacity[technology] = $calculatedData.years.map(
          (d) => d.input.CH.capacity.scenario[technology]
        )
        tmp.switzerland.installedCapacity[technology].edited = false

        $userSettings = tmp
      }}
      >{$_(
        'page.sections.userControls.switzerland.capacity.controls.resetButton'
      )}</button
    >
  </div>
{/if} -->
<style>
  text {
    font-size: var(--scale-1);
  }

  .reset {
    border: none;
    outline: none;
    /* background: none; */
    background: var(--accent-color);
    color: white;
    cursor: pointer;
    height: 20px;
    border-radius: 20px;
    font-size: var(--scale-1);
    padding: 0 10px;
  }

  .editable-scenario-path {
    stroke: var(--accent-color);
  }

  .year-dot {
    fill: var(--accent-color);
  }

  svg {
    max-width: 370px;
  }
</style>
