<script>
  import {
    calculatedData,
    isReset,
    selectedYear,
    userSettings,
  } from './../stores.ts'
  import { sum } from 'd3-array'
  import { _ } from 'svelte-i18n'
  import { format } from 'd3-format'
  import Reset from './../ui/Reset.svelte'
  import InfoIcon from '../ui/InfoIcon.svelte'

  export let techGroup

  const f = format('.1f')

  let groupSlider

  isReset.subscribe(() => (groupSlider = 0))
</script>

<div class="outer">
  <div class="wrapper">
    <span class="label">
      <!-- <img src="icons/{techGroup.key}.png" alt="icon" class="icon" /> -->
      <InfoIcon
        title={$_(
          `page.sections.userControls.switzerland.capacity.groups.${techGroup.key}.label`
        )}
        description={$_(
          `page.sections.userControls.switzerland.capacity.groups.${techGroup.key}.description`
        )}
      />
      {$_(
        `page.sections.userControls.switzerland.capacity.groups.${techGroup.key}.label`
      )}
      <span style="margin-left: var(--size-2)">
        {#if techGroup.subGroups
          .map((d) => d.technologies.map((d) => d.key))
          .flat()
          .reduce((acc, key) => acc || $userSettings.switzerland.installedCapacity[key].edited, false)}
          <Reset
            on:click={() => {
              techGroup.subGroups
                .map((d) => d.technologies.map((d) => d.key))
                .flat()
                .forEach((key) => {
                  const tmp = $userSettings
                  tmp.switzerland.installedCapacity[key].value = 0
                  tmp.switzerland.installedCapacity[key].edited = false
                  tmp.switzerland.customCapacity[key] = $calculatedData
                    .getAnnualTimeSeries()
                    .map((year) => year.input.CH.capacity.scenario[key])
                  $userSettings = tmp
                })
            }}
          />
        {/if}
      </span>
    </span>

    {#if $calculatedData && $selectedYear}
      <div class="in-2050">
        <span style="text-align: right;">in {$selectedYear}:</span>
        <span style="font-weight: bold; font-size: 1.4rem;">
          {f(
            sum(
              techGroup.technologies,
              (t) =>
                $calculatedData?.getAnnualTimeSeries()[$selectedYear - 2020]
                  .calculated.capacity.CH[t]
            ) / 1000
          )} GW
        </span>
      </div>
    {/if}
  </div>

  <!-- <input
    bind:value={groupSlider}
    list="tickmarks-{techGroup.key}"
    style="width: 100%; background: none;"
    id="slider-{techGroup.key}"
    type="range"
    min={-1}
    max={1}
    step="0.5"
    on:input|stopPropagation={(e) => {
      const tmp = $userSettings

      $capacityChanged = techGroup.key

      techGroup.technologies.forEach((t) => {
        tmp.switzerland.installedCapacity[t].value = e.target.value
        tmp.switzerland.installedCapacity[t].edited = true
      })

      $userSettings = tmp
    }}
    on:click|stopPropagation={() => {}}
    on:mousedown|stopPropagation={() => ($selectedTech = techGroup.key)}
    on:mouseup|stopPropagation={() => ($selectedTech = undefined)}
  /> -->
</div>

<style>
  .outer {
    width: 100%;
    padding: 4px;
  }
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .label {
    margin-left: 5px;
    display: flex;
  }

  .in-2050 {
    font-size: 1rem;
    display: inline-flex;
    flex-direction: column;
    margin-right: 5px;
  }
</style>
