<script>
  import { _, locale } from 'svelte-i18n'
  import { trackLanguageSwitch } from './../track.ts'
</script>

<div class="language-switch">
  <button
    class:active={$locale === 'de'}
    on:click={() => {
      locale.set('de')
      trackLanguageSwitch('DE')
    }}>DE</button
  ><button
    class:active={$locale === 'en'}
    on:click={() => {
      locale.set('en')
      trackLanguageSwitch('EN')
    }}>EN</button
  >
</div>

<style>
  .language-switch {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .language-switch button {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    padding: var(--size-1);
  }

  .language-switch button:hover {
    transform: scale(1.1);
  }

  .language-switch button.active {
    cursor: default;
    font-weight: bold;
  }

  .language-switch button.active:hover {
    transform: scale(1);
  }
</style>
