<script>
  import { getContext } from 'svelte'
  import { format } from 'd3-format'
  import { costDetailsHover } from '../../stores'

  const f = format(',.2r')

  const {
    data,
    xGet,
    yGet,
    yRange,
    xScale,
    yScale,
    xRange,
    xDomain,
    x,
    height,
    padding,
  } = getContext('LayerCake')
</script>

{#each $data as d, i}
  {#if i % 2 === 0}
    <rect
      x={-$padding.left}
      y={$yGet(d)}
      height={Math.max(0, $yScale.bandwidth())}
      width={$xRange[1] + $padding.left + $padding.right}
      fill="var(--color-grey-50)"
    />
  {/if}
{/each}

{#each $xScale.nice().ticks() as tick}
  <text
    x={$xScale(tick)}
    dy={-12}
    class="tick"
    fill={tick < 0 ? 'red' : 'steelblue'}>{tick}</text
  >
  <line
    x1={$xScale(tick)}
    x2={$xScale(tick)}
    y2={$height}
    stroke="#ccc"
    stroke-dasharray="1 1"
  />
{/each}

<line
  x1={$xScale(0)}
  y1={$yRange[0]}
  x2={$xScale(0)}
  y2={$yRange[1]}
  stroke="var(--color-grey-300)"
/>

{#each $data as d, i}
  <rect
    x={-$padding.left}
    y={$yGet(d) - 2}
    height={Math.max(0, $yScale.bandwidth()) + 4}
    width={$xRange[1] + $padding.left + $padding.right + 1}
    fill="transparent"
    on:mouseover={() =>
      ($costDetailsHover = {
        type: 'income',
        data: d,
      })}
    on:mouseout={() => ($costDetailsHover = undefined)}
  />
  <g
    transform="translate({$x(d) < 0 ? $xGet(d) : $xScale(0)}, {$yGet(d)})"
    class:hover={$costDetailsHover?.data?.nr === d.nr}
  >
    <rect
      class="income"
      height={$yScale.bandwidth()}
      width={Math.abs($xScale(0) - $xScale($x(d)))}
      rx={3}
      ry={3}
      on:mouseover={() =>
        ($costDetailsHover = {
          type: 'income',
          data: d,
          showOverlay: true,
        })}
      on:mouseout={() => ($costDetailsHover = undefined)}
    />

    {#if $x(d) < 0}
      <text
        class="label"
        style="fill: red;"
        dx={-5}
        text-anchor="end"
        dy={$yScale.bandwidth() / 2}
      >
        {f($x(d))}
      </text>
    {:else}
      <text
        x={Math.abs($xScale(0) - $xScale($x(d)))}
        style="fill: steelblue;"
        class="label"
        dx={5}
        text-anchor="start"
        dy={$yScale.bandwidth() / 2}
      >
        +{f($x(d))}
      </text>
    {/if}
  </g>
{/each}

<style>
  .label {
    dominant-baseline: central;
    font-size: var(--scale-2);
    pointer-events: none;
  }

  .tick {
    text-anchor: middle;
    font-size: var(--scale-1);
  }

  .income {
    fill: var(--color-grey-400);
  }

  .hover .income {
    fill: var(--color-grey-500);
  }
</style>
