<script>
  import { getContext } from 'svelte'
  import { TABS } from './Tabs.svelte'
  import { activeTab } from './../../stores'
  import { track } from '../../track'

  export let id
  export let resetYear = false

  const tab = {}
  const { registerTab, selectTab, selectedTab } = getContext(TABS)

  registerTab(tab)
</script>

<button
  class:selected={$selectedTab === tab}
  on:click={() => {
    selectTab(tab)

    if (id) {
      $activeTab = id
    }

    track(id)
  }}
>
  <slot />
</button>

<style>
  button {
    background: var(--color-grey-100);
    border: none;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 3px solid var(--color-grey-100);
    margin: 0;
    cursor: pointer;
    font-size: 1em;
    margin-bottom: 0px;
    margin-left: 1px;
    margin-right: 1px;
    padding: var(--size-1) var(--size-3);
  }

  button:active {
    background: var(--color-grey-100);
    border-bottom: 3px solid var(--color-grey-100);
  }

  button:hover {
    background: var(--color-grey-200);
    border-bottom: 3px solid var(--color-grey-200);
  }

  .selected {
    border-bottom: 3px solid rgb(255 93 100);
    background: var(--color-grey-200);
    color: black;
    /* box-shadow: 0px -4px 10px 0px rgba(255, 93, 100, 0.2); */
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    font-weight: 500;
  }

  button.selected:hover {
    background: var(--color-grey-200);
    border-bottom: 3px solid rgb(255 93 100);
  }
</style>
