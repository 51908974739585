<script>
  export let backColor = 'rgba(255, 255, 255, 0.7)'
  export let strokeWidth = 2
</script>

<text
  {...$$restProps}
  fill={backColor}
  stroke={backColor}
  stroke-width={strokeWidth}
>
  <slot />
</text>
<text {...$$restProps}>
  <slot />
</text>

<style>
  text {
    font-size: var(--scale-0);
  }
</style>
