﻿<script>
  import Collapsable from '../../components/Collapsable.svelte'

  export let title
</script>

<Collapsable {title}>
  <p>
    Die Schweiz gehört zu den am stärksten durch Stromleitungen mit dem Ausland
    verbundenen Ländern Europas. Importe und Exporte können auf jährlichem
    Niveau angesehen werden; wertvoller ist allerdings eine feinere Ansicht auf
    dem Niveau von Jahreszeiten oder Monaten.
  </p>
  <p>
    In den Sommermonaten exportiert die Schweiz zurzeit mehr Strom, als sie
    importiert. Dieser Trend könnte mit einem Ausbau von Photovoltaik weiter
    zunehmen. Auf dem Niveau von Jahreszeiten dominieren im Winter allerdings
    Importe in die Schweiz. Das bedeutet, dass das Land zu dieser Zeit nicht
    genügend Strom selbst herstellen kann, um die hohe Nachfrage zu decken. Wenn
    die Schweizer Produktion nicht ausreicht, kann Strom unter zwei Bedingungen
    importiert werden. Erstens muss das gewählte oder erstellte Szenario Importe
    zulassen; es sind Leistungskapazitäten für den Import verfügbar. Zweitens
    muss das Ausland überschüssigen Strom für den Export zur Verfügung haben.
    Dies hängt von den gewählten oder erstellten Szenarien zur Dekarbonisizerung
    in Anrainerstaaten sowie von den ausgewählten Dunkelflautetagen ab.
  </p>
  <p>
    Um die mögliche Exportmenge in die Schweiz zu schätzen, werden nach dem
    gleichen Modellierungsansatz für alle Anrainer monatliche Energiebilanzen
    erstellt, auf Basis europäischer Entwicklungsszenarien (ENTSO-E und ENTSOG
    TYNDP Szenarien aus 2022). Die potenziellen Überschüsse in der
    Stromproduktion werden bestimmt. Das Exportpotential wird auf alle
    Nachbarländer mit Importbedarf anteilig ihres Bedarfs aufgeteilt. Das
    maximale Exportpotenzial aus den Anrainern in die Schweiz und die
    szenarioabhängige Importkapazität ergeben dann die maximale Importmenge.
  </p>
  <p>
    Mithilfe der Eigenproduktion und dem Importpotential wird für jeden Monat
    bestimmt, ob die Schweiz ein Stromdefizit oder einen Stromüberschuss hat.
  </p>
</Collapsable>
