import { get } from 'svelte/store';
import { sum, range } from 'd3-array';
export const months3 = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
];
export const keys = [
    'import',
    'pv',
    'pv_alpin',
    'wind_on',
    'ror',
    'hs',
    'biomasse',
    'ccs_biomasse',
    'gtp',
    'oth_ee_total',
    'nu',
    'ga_total',
    'ccs_fossil',
    'oth_fossil_total',
].reverse();
export const thermalBackupTech = ['ccs_fossil', 'ga_total', 'gtp'];
export const hiddenCapacities = ['hc_total'];
// export const IGNORE_TECH = ['hc_total', 'bc_total', 'ps', 'wind_off']
export const IGNORE_TECH = [];
export const winterMonths = [1, 2, 3, 10, 11, 12];
export const summerMonths = [4, 5, 6, 7, 8, 9];
export const allMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const temperatureKeys = [
    'very_cold',
    'cold',
    'normal',
    'warm',
    'very_warm',
];
export const costTechMapping = {
    T1: 'nu',
    T2: 'fossil',
    T3: 'co2Free',
    T4: 'fossil',
    T5: 'nu',
    T6: 'co2Free',
    T7: 'fossil',
    T8: 'biomass',
    T9: 'biomass',
    T10: 'hydro',
    T11: 'hydro',
    T12: 'pv',
    T13: 'pv',
    T14: 'pv',
    T15: 'wind',
    T16: 'hydro',
    T17: 'otherRenewable',
    T18: 'hydro', //'Existing RoR',
};
export const individualTechMapping = {
    T1: 'bc_total',
    T2: 'hc_total',
    T3: 'ga_total',
    T4: 'oth_fossil_total',
    T5: 'nu',
    T6: 'gtp',
    T7: 'ccs_fossil',
    T8: 'ccs_biomasse',
    T9: 'biomasse',
    T10: 'ror',
    T11: 'hs',
    T12: 'ps',
    T13: 'pv',
    T14: 'pv_alpin',
    T15: 'wind_on',
    T16: 'wind_off',
    T17: 'oth_ee_total', //'Geothermal',
};
export const individualTechMappingReverse = {
    bc_total: 'T1',
    hc_total: 'T2',
    ga_total: 'T3',
    oth_fossil_total: 'T4',
    nu: 'T5',
    gtp: 'T6',
    ccs_fossil: 'T7',
    ccs_biomasse: 'T8',
    biomasse: 'T9',
    ror: 'T10',
    hs: 'T11',
    ps: 'T12',
    pv: 'T13',
    pv_alpin: 'T14',
    wind_on: 'T15',
    wind_off: 'T16',
    oth_ee_total: 'T17', //'Geothermal',
};
export function getProfilePrice(input, powerPrice, tech) {
    return powerPrice * input.basePriceTech[tech];
}
function getRoundedAvailability(self, lcoe) {
    return Math.ceil(self.lcoe[lcoe].availability || 0 / 100);
}
function getBasePrice(self, input, lcoe) {
    return input.basePrice[getRoundedAvailability(self, lcoe)];
}
export function getBasePriceCorrection(basePrice, powerPrice) {
    return powerPrice < 100
        ? (1 - (100 - powerPrice) / (100 - 25)) * basePrice.base_price_100 +
            ((100 - powerPrice) / (100 - 25)) * basePrice.base_price_25
        : (1 - (100 - powerPrice) / (100 - 250)) * basePrice.base_price_100 +
            ((100 - powerPrice) / (100 - 250)) * basePrice.base_price_250;
}
export function getFLHPrice(self, input, lcoe, powerPrice) {
    const basePrice = getBasePrice(self, input, lcoe);
    const basePriceCorrection = getBasePriceCorrection(basePrice, powerPrice);
    return basePrice.value * basePriceCorrection * powerPrice;
}
export function getProfileAndMin1(self, input, lcoe, powerPrice) {
    const basePrice = getBasePrice(self, input, lcoe);
    const basePriceCorrection = getBasePriceCorrection(basePrice, powerPrice);
    return (Math.max((basePrice.value * basePriceCorrection - 1) * 0.4 + 1, 1.15) *
        powerPrice);
}
export function getProfileAndMin2(self, input, lcoe, powerPrice) {
    const basePrice = getBasePrice(self, input, lcoe);
    const basePriceCorrection = getBasePriceCorrection(basePrice, powerPrice);
    return (Math.max((basePrice.value * basePriceCorrection - 1) * 0.3 + 1, 1.15) *
        powerPrice);
    // const basePrice = input.basePrice[2500]
    // const basePriceCorrection = getBasePriceCorrection(basePrice, powerPrice)
    // const share =
    //   (self.shareOfGeneration.PC * generation[self.name.old] +
    //     self.shareOfGeneration.PV * generation[self.name.old] +
    //     self.shareOfGeneration.PA * generation[self.name.old]) /
    //   demand
    // return (
    //   powerPrice * Math.max(1.15, basePrice.value * basePriceCorrection * share)
    // )
}
export function getSharedGenerationPrice(self, year, demand, powerPrice) {
    const generationPC = sum(year.trueCost.technologies.T12.calculatedGeneration) / 1000;
    const generationPV = sum(year.trueCost.technologies.T13.calculatedGeneration) / 1000;
    const generationPA = sum(year.trueCost.technologies.T14.calculatedGeneration) / 1000;
    self.generationPCPVPA = {
        PC: generationPC,
        PV: generationPV,
        PA: generationPA,
    };
    const efficiency = generationPC * self.shareOfGeneration.PC +
        generationPV * self.shareOfGeneration.PV +
        generationPA * self.shareOfGeneration.PA;
    const share = efficiency / demand;
    const var1 = Math.floor(share * 20) / 20;
    const var2 = Math.floor((var1 + 0.05) * 100) / 100;
    const var3 = self.shareOfBasePrice[var1];
    const var4 = self.shareOfBasePrice[var2];
    // TODO: IF YEAR === 2020
    return (powerPrice *
        ((1 - (share - var1) / 0.05) * var3 + ((share - var1) / 0.05) * var4));
}
function getGridCostAddition(year, years, lcoe, householdPrice) {
    if (year.year <= 2021) {
        return 0;
    }
    const generationPC = sum(year.trueCost.technologies.T12.calculatedGeneration) / 1000;
    const generationPV = sum(year.trueCost.technologies.T13.calculatedGeneration) / 1000;
    const generationPA = sum(year.trueCost.technologies.T14.calculatedGeneration) / 1000;
    const totalGeneration = generationPC + generationPV + generationPA;
    const demand = sum(Object.values(year.calculated.demand.CH.absolute.userValues));
    const systemCostMin1 = years[year.year - 2020 - 1].trueCost.technologies.T13.lcoe[lcoe].systemCosts;
    const generationPC2020 = sum(years[0].trueCost.technologies.T12.calculatedGeneration) / 1000;
    const generationPV2020 = sum(years[0].trueCost.technologies.T13.calculatedGeneration) / 1000;
    const generationPA2020 = sum(years[0].trueCost.technologies.T14.calculatedGeneration) / 1000;
    const totalGeneration2020 = generationPA2020 + generationPV2020 + generationPC2020;
    const demand2020 = sum(Object.values(years[0].calculated.demand.CH.absolute.userValues));
    const systemCostMin2 = years[year.year - 2020 - 2].trueCost.technologies.T13.lcoe[lcoe].systemCosts;
    const multiplierGrid = householdPrice.multiplierGrid;
    return (((totalGeneration / demand) * systemCostMin1 -
        (totalGeneration2020 / demand2020) * systemCostMin2) *
        multiplierGrid);
}
export function getHouseholdPrice(householdPrice, powerPrice, technologies, year, years, lcoe) {
    const workingPrice = powerPrice * householdPrice.workingPriceMulti +
        householdPrice.workingPriceAdd;
    const gridAndFixedPrice = householdPrice.gridFixCosts +
        getGridCostAddition(year, years, lcoe, householdPrice);
    const sumOfTotalInvestorNetBenefit = sum(Object.values(technologies).filter((d) => !['T16', 'T18', 'T19', 'T20'].includes(d.key)), (d) => Math.min(0, d.calculated.netBenefit.netBenefit.investorTotal));
    const demand = sum(Object.values(year.calculated.demand.CH.absolute.userValues));
    const subsidy = -sumOfTotalInvestorNetBenefit / demand;
    const tax = (workingPrice + gridAndFixedPrice) * householdPrice.tax;
    return {
        workingPrice,
        gridAndFixedPrice,
        subsidy,
        tax,
        total: workingPrice + gridAndFixedPrice + subsidy + tax,
    };
}
export function getInvestorFixed(self, lcoe) {
    const lcoeData = self.lcoe[lcoe];
    const investmentCosts = lcoeData.investmentCosts;
    const alpha = lcoeData.alpha;
    const availability = lcoeData.availability;
    const fixedOM = lcoeData.fixedOM;
    const decommissionCosts = lcoeData.decommissioningCosts;
    const disposalCosts = lcoeData.disposalCosts;
    const capitalCosts = ((investmentCosts * alpha) / availability) * 1000;
    const fixedOMCosts = (fixedOM / availability) * 1000;
    return capitalCosts + fixedOMCosts + decommissionCosts + disposalCosts;
}
export function getInvestorVariable(self, input, lcoe) {
    const lcoeData = self.lcoe[lcoe];
    const directCostTHG = lcoeData.CO2EmissionsEl * input.fuelCost.CO2[lcoe];
    const transportCostCO2 = lcoeData.transportCO2 * lcoeData.CO2CaptureEl;
    const storageCostCO2 = lcoeData.storageCO2 * lcoeData.CO2CaptureEl;
    const fuelCostAddition = self.key === 'T4' ? 3 : 0;
    const efficiencyReduction = self.key === 'T4' && input.year === 2020 ? 0.9 : 1;
    const fuelCost = (input.fuelCost[self.fuelKind][lcoe] + fuelCostAddition) /
        (lcoeData.efficiencyLHV * efficiencyReduction);
    // const fuelCost =
    //   self.fuelKind === undefined
    //     ? 0
    //     : input.fuelCost[self.fuelKind][lcoe] / lcoeData.efficiencyLHV
    return (lcoeData.variableOM +
        fuelCost +
        directCostTHG +
        transportCostCO2 +
        storageCostCO2 +
        lcoeData.transportH2 +
        lcoeData.storageH2);
}
export function getExternalCost(self, input, lcoe) {
    const lcoeData = self.lcoe[lcoe];
    const indirectCostGreenhouseGas = input.fuelCost.CO2[lcoe] * lcoeData.indirectGHGEmissions;
    const directHarmGreenhouseGas = (lcoeData.environmentalDamage - input.fuelCost.CO2[lcoe]) *
        lcoeData.CO2EmissionsEl;
    const indirectHarmGreenhouseGas = (lcoeData.environmentalDamage - input.fuelCost.CO2[lcoe]) *
        lcoeData.indirectGHGEmissions;
    return (lcoeData.systemCosts +
        indirectCostGreenhouseGas +
        (self.key === 'T6' ? 0 : directHarmGreenhouseGas) +
        indirectHarmGreenhouseGas +
        lcoeData.otherEnvironmentalDamage +
        lcoeData.resourceUsage);
}
export function getNetBenefit(self, year, lcoe, calculated, years, input, powerPrice) {
    let valueMWh = calculated.income;
    const sumOfGeneration = sum(year.trueCost.technologies[self.key].calculatedGeneration);
    if (self.key === 'T13') {
        const gridCostAddition = getGridCostAddition(year, years, lcoe, year.trueCost.householdPrice);
        const workingPrice = powerPrice * year.trueCost.householdPrice.workingPriceMulti +
            year.trueCost.householdPrice.workingPriceAdd;
        const gridFixedCost = year.trueCost.householdPrice.gridFixCosts + gridCostAddition;
        const incomeOfSelfConsumption = Math.max(powerPrice, (year.trueCost.householdPrice.lcoe[lcoe].savingMarketPrice *
            workingPrice +
            year.trueCost.householdPrice.lcoe[lcoe].savingGrid * gridFixedCost +
            year.trueCost.householdPrice.lcoe[lcoe].savingSubsidy *
                (year.trueCost.householdPrice.lcoe[lcoe].formulaSubsidyA +
                    year.trueCost.householdPrice.lcoe[lcoe].formulaSubsidyB *
                        powerPrice)) *
            (1 +
                year.trueCost.householdPrice.lcoe[lcoe].consideringTax *
                    year.trueCost.householdPrice.tax));
        const sumOfSelfGeneration = sum(year.trueCost.technologies[self.key].calculatedGeneration.map((d, i) => d *
            years[i].trueCost.householdPrice.lcoe[lcoe]
                .shareSelfConsumptionNewPlants));
        const shareOfSelfConsumptionAllPlants = sumOfSelfGeneration / sumOfGeneration;
        valueMWh =
            valueMWh * (1 - shareOfSelfConsumptionAllPlants) +
                incomeOfSelfConsumption * shareOfSelfConsumptionAllPlants;
    }
    const valueMioEUR = (valueMWh * sumOfGeneration) / 1000;
    const costs2 = year.trueCost.technologies[self.key].calculatedGeneration.reduce((acc, cur, i, arr) => {
        const lcoeData = self.lcoe[lcoe];
        const otherYear = years[i].trueCost;
        const otherYearLcoeData = otherYear.technologies[self.key].lcoe[lcoe];
        const otherYearEfficiency = otherYearLcoeData.efficiencyLHV;
        const otherYearFuelPrice = otherYear.input.fuelCost[self.fuelKind][lcoe];
        const otherYearFuelCost = otherYearFuelPrice / otherYearEfficiency;
        const otherYearCO2Emissions = otherYearLcoeData.CO2EmissionsEl;
        const otherYearCO2Price = otherYear.input.fuelCost.CO2[lcoe];
        const otherYearDirectCostsGHG = otherYearCO2Price * otherYearCO2Emissions;
        const otherYearEnvironmentalDamage = otherYearLcoeData.environmentalDamage;
        const otherYearDirectDamageGHG = (otherYearEnvironmentalDamage - otherYearCO2Price) *
            otherYearCO2Emissions;
        const otherYearIndirectGHGEmissions = otherYearLcoeData.indirectGHGEmissions;
        const otherYearIndirectDamageTHG = (otherYearEnvironmentalDamage - otherYearCO2Price) *
            otherYearIndirectGHGEmissions;
        const fuelPrice = year.trueCost.input.fuelCost[self.fuelKind][lcoe];
        const CO2Price = year.trueCost.input.fuelCost.CO2[lcoe];
        const environmentalDamage = lcoeData.environmentalDamage;
        const calculatedFuelPrice = fuelPrice / otherYearEfficiency - otherYearFuelCost;
        const calculatedCO2Price = CO2Price * otherYearCO2Emissions - otherYearDirectCostsGHG;
        const calculatedDirectDamage = (environmentalDamage - CO2Price) * otherYearCO2Emissions -
            otherYearDirectDamageGHG;
        const calculatedIndirectDamage = (environmentalDamage - CO2Price) * otherYearIndirectGHGEmissions -
            otherYearIndirectDamageTHG;
        const initialCalculated = i === arr.length - 1
            ? calculated
            : years[i].trueCost.technologies[self.key].calculated;
        acc.fixedInvestor += initialCalculated.investorFixed * cur;
        acc.variableInvestor +=
            (initialCalculated.investorVariable +
                calculatedFuelPrice +
                calculatedCO2Price) *
                cur;
        acc.system +=
            (initialCalculated.system + calculatedFuelPrice + calculatedCO2Price) *
                cur;
        acc.total +=
            (initialCalculated.investorTotal +
                initialCalculated.external +
                calculatedFuelPrice +
                calculatedCO2Price +
                calculatedDirectDamage +
                calculatedIndirectDamage) *
                cur;
        return acc;
        //   =M2209 +
        // (O2155 / M2166 - M2183) * E2206 +
        // (O2156 * M2161 - M2184) * E2206 +
        // ((O2177 - O2156) * M2161 - M2194) * F2206 +
        // ((O2177 - O2156) * M2176 - M2195) * F2206
    }, {
        fixedInvestor: 0,
        variableInvestor: 0,
        system: 0,
        total: 0,
    });
    // in MWh
    const investorFixedMWh = sumOfGeneration === 0
        ? calculated.investorFixed
        : costs2.fixedInvestor / sumOfGeneration;
    const investorVariableMWh = sumOfGeneration === 0
        ? calculated.investorVariable
        : costs2.variableInvestor / sumOfGeneration;
    // const investorTotalMWh = investorFixedMWh + investorVariableMWh // not used for final result calculation
    const systemTotalMWh = sumOfGeneration === 0 ? calculated.system : costs2.system / sumOfGeneration;
    // const systemMWh = systemTotalMWh - investorTotalMWh // not used for final result calculation
    let totalMWh = sumOfGeneration === 0
        ? calculated.investorTotal + calculated.external
        : costs2.total / sumOfGeneration;
    if (self.key === 'T6') {
        const directHarmGreenhouseGas = (self.lcoe[lcoe].environmentalDamage - input.fuelCost.CO2[lcoe]) *
            self.lcoe[lcoe].CO2EmissionsEl;
        totalMWh += directHarmGreenhouseGas;
    }
    const investorTotalMWh = investorFixedMWh + investorVariableMWh;
    const systemMWh = systemTotalMWh - investorTotalMWh;
    const externalMWh = totalMWh - systemTotalMWh;
    const socialMWh = systemMWh + externalMWh;
    // in Mio EUR
    const investorFixedMioEUR = (investorFixedMWh * sumOfGeneration) / 1000;
    const investorVariableMioEUR = (investorVariableMWh * sumOfGeneration) / 1000;
    const investorTotalMioEUR = investorFixedMioEUR + investorVariableMioEUR;
    const systemTotalMioEUR = (systemTotalMWh * sumOfGeneration) / 1000;
    const systemMioEUR = systemTotalMioEUR - investorTotalMioEUR;
    const totalMioEUR = (totalMWh * sumOfGeneration) / 1000;
    const externalMioEUR = totalMioEUR - systemTotalMioEUR;
    return {
        lcoeMWh: {
            investorFixed: investorFixedMWh,
            investorVariable: investorVariableMWh,
            investorTotal: investorTotalMWh,
            systemTotal: systemTotalMWh,
            system: systemMWh,
            total: totalMWh,
            external: externalMWh,
            social: socialMWh,
        },
        lcoeMioEUR: {
            investorFixed: investorFixedMioEUR,
            investorVariable: investorVariableMioEUR,
            investorTotal: investorTotalMioEUR,
            systemTotal: systemTotalMioEUR,
            system: systemMioEUR,
            total: totalMioEUR,
            external: externalMioEUR,
        },
        netBenefit: {
            investorFixed: valueMioEUR - investorFixedMioEUR,
            investorVariable: valueMioEUR - investorVariableMioEUR,
            investorTotal: valueMioEUR - investorTotalMioEUR,
            system: valueMioEUR - systemMioEUR,
            systemTotal: valueMioEUR - systemTotalMioEUR,
            external: valueMioEUR - externalMioEUR,
            total: valueMioEUR - totalMioEUR,
        },
        valueMWh,
        valueMioEUR,
    };
}
// E2206 = 0, F2206 = 0: fix investor
// E2238 = 1, F2238 = 0: var investor
// E2270 = 1, F2270 = 0: total system
// E2302 = 1, F2302 = 1: fix investor
// =K2207 // fix investor
// =K2239 + (U2155 / K2166 - K2183) + (U2156 * K2161 - K2184)  // var investor
// =K2271 + (U2155 / K2166 - K2183) + (U2156 * K2161 - K2184)  // total system
// =K2303 + (U2155 / K2166 - K2183) + (U2156 * K2161 - K2184) + ((U2177 - U2156) * K2161 - K2194) + ((U2177 - U2156) * K2176 - K2195) // total
// =K2207 + (U2155 / K2166 - K2183) * E2206 + (U2156 * K2161 - K2184) * E2206 + ((U2177 - U2156) * K2161 - K2194) * F2206 + ((U2177 - U2156) * K2176 - K2195) * F2206 // fix investor
// =K2239 + (U2155 / K2166 - K2183) * E2238 + (U2156 * K2161 - K2184) * E2238 + ((U2177 - U2156) * K2161 - K2194) * F2238 + ((U2177 - U2156) * K2176 - K2195) * F2238 // var investor
// =K2271 + (U2155 / K2166 - K2183) * E2270 + (U2156 * K2161 - K2184) * E2270 + ((U2177 - U2156) * K2161 - K2194) * F2270 + ((U2177 - U2156) * K2176 - K2195) * F2270 // total system
// =K2303 + (U2155 / K2166 - K2183) * E2302 + (U2156 * K2161 - K2184) * E2302 + ((U2177 - U2156) * K2161 - K2194) * F2302 + ((U2177 - U2156) * K2176 - K2195) * F2302 // total
export function getGenerationTotal(self, lcoe, years, currentYear) {
    const availability = years[currentYear - 2020].trueCost.technologies[self.key].lcoe[lcoe]
        .availability;
    const capacity = years[currentYear - 2020].calculated.capacity.CH[self.name.old];
    return (capacity * availability) / 1000 || 0;
}
export function getGenerationRenewable(self, lcoe, years, currentYear, input) {
    const generationTotal = getGenerationTotal(self, lcoe, years, currentYear);
    years[currentYear - 2020].trueCost.technologies[self.key].intermediate = {};
    years[currentYear - 2020].trueCost.technologies[self.key].intermediate.generationTotal = generationTotal;
    const previousYearGenerationTotal = currentYear === 2020
        ? 0
        : years[Math.max(0, currentYear - 2020 - 1)].trueCost.technologies[self.key].intermediate.generationTotal;
    const generationNewWithoutPhaseOut = generationTotal - previousYearGenerationTotal;
    const phaseOutPerYear = range(2020, 2051).map((i) => {
        const thisYearGeneration = years[i - 2020].calculated.generation[self.name.old];
        const previousYearGeneration = i === 2020
            ? thisYearGeneration
            : years[i - 2020 - 1].calculated.generation[self.name.old];
        const generationNewWithoutPhaseOut = thisYearGeneration - previousYearGeneration;
        return input.year ===
            i +
                years[i - 2020].trueCost.technologies[self.key].lcoe[lcoe]
                    .depreciationPeriod
            ? generationNewWithoutPhaseOut
            : 0;
    });
    if (!years[currentYear - 2020].trueCost.technologies[self.key].intermediate
        .phaseOutPerYear) {
        years[currentYear - 2020].trueCost.technologies[self.key].intermediate.phaseOutPerYear = phaseOutPerYear;
    }
    const phaseOutTotal = sum(phaseOutPerYear);
    const phaseInTotal = generationNewWithoutPhaseOut + phaseOutTotal;
    years[currentYear - 2020].trueCost.technologies[self.key].intermediate.phaseInTotal = phaseInTotal; // TODO: check if this is correct
    const phaseOutCumulativePerYear = range(2020, 2051).map((j) => {
        return range(2020, input.year + 1).reduce((acc, cur, i) => acc +
            years[currentYear - 2020].trueCost.technologies[self.key].intermediate
                .phaseOutPerYear[j - 2020], 0);
    });
    const generation = [];
    range(2020, input.year + 1).forEach((i) => {
        generation.push(
        ///IS THIS THE FIX??? Problem is that generation can get negative if capacity is 0, and then the income charts look weird.
        Math.max(0, years[i - 2020].trueCost.technologies[self.key].intermediate
            .phaseInTotal - phaseOutCumulativePerYear[i - 2020])
        // years[i - 2020].trueCost.technologies[self.key].intermediate
        //   .phaseInTotal - phaseOutCumulativePerYear[i - 2020]
        );
    });
    return generation;
}
export function getGeneration(self, lcoe, years, currentYear) {
    const generation = [];
    const additionalNecessaryGeneration = [];
    years[currentYear - 2020].trueCost.technologies[self.key].intermediate = {};
    years[currentYear - 2020].trueCost.technologies[self.key].intermediate.additionalNecessaryGeneration = additionalNecessaryGeneration;
    // horizontal
    const generationTotal = getGenerationTotal(self, lcoe, years, currentYear);
    const previousYearGenerationTotal = getGenerationTotal(self, lcoe, years, Math.max(currentYear - 1, 2020));
    const phaseIn = currentYear === 2020
        ? Math.max(generationTotal, 0)
        : Math.max(generationTotal - previousYearGenerationTotal, 0);
    const phaseOut = currentYear === 2020
        ? Math.max(-generationTotal, 0)
        : Math.max(previousYearGenerationTotal - generationTotal, 0);
    // vertical
    for (let v = 2020; v <= currentYear; v++) {
        const previousGeneration = currentYear === 2020
            ? []
            : years[currentYear - 2020 - 1].trueCost.technologies[self.key]
                .calculatedGeneration;
        const previousAdditionalGeneration = currentYear === 2020
            ? []
            : years[currentYear - 2020 - 1].trueCost.technologies[self.key]
                .intermediate.additionalNecessaryGeneration;
        if (currentYear === v) {
            // gray
            if (currentYear === 2020) {
                // first gray
                generation.push(Math.max(phaseIn - phaseOut, 0));
                additionalNecessaryGeneration.push(Math.max(phaseIn - phaseOut, 0));
            }
            else {
                // other grays
                generation.push(Math.max(phaseIn -
                    (phaseOut -
                        (generation[v - 2020 - 1] - previousGeneration[v - 2020 - 1])), 0));
                additionalNecessaryGeneration.push(Math.max(phaseIn -
                    (phaseOut -
                        (generation[v - 2020 - 1] -
                            previousAdditionalGeneration[v - 2020 - 1])), 0));
            }
        }
        else if (v === 2020) {
            // yellow
            generation.push(Math.max(previousGeneration[0] - phaseOut, 0));
            additionalNecessaryGeneration.push(phaseOut - (previousGeneration[0] - generation[0]));
        }
        else {
            // white
            generation.push(Math.max(previousGeneration[v - 2020] -
                additionalNecessaryGeneration[v - 2020 - 1], 0));
            additionalNecessaryGeneration.push(additionalNecessaryGeneration[v - 2020 - 1] -
                (previousGeneration[v - 2020] - generation[v - 2020]));
        }
    }
    return generation;
}
export const technologies = [
    {
        key: 'T1',
        name: { long: 'New Nuclear', short: 'New NU', old: 'bc_total' },
        profile: 'NU',
        fuelKind: 'NU',
        calculated: {},
    },
    {
        key: 'T2',
        name: { long: 'Hard Coal', short: 'New HC', old: 'hc_total' },
        fuelKind: 'HC',
        calculated: {},
    },
    {
        key: 'T3',
        name: { long: 'GT Gas', short: 'New Env Res', old: 'ga_total' },
        fuelKind: 'Env',
        calculated: {},
    },
    {
        key: 'T4',
        name: { long: 'CCGT Gas', short: 'Exist Fossil', old: 'oth_fossil_total' },
        fuelKind: 'GA',
        calculated: {},
    },
    {
        key: 'T5',
        name: { long: 'Nuclear', short: 'Exist NU', old: 'nu' },
        profile: 'NU',
        fuelKind: 'NU',
        calculated: {},
    },
    {
        key: 'T6',
        name: { long: 'CCGT H2', short: 'New Env Market', old: 'gtp' },
        fuelKind: 'Env',
        calculated: {},
    },
    {
        key: 'T7',
        name: { long: 'CCS CCGT Gas', short: 'CCS Fossil', old: 'ccs_fossil' },
        fuelKind: 'GA',
        calculated: {},
    },
    {
        key: 'T8',
        name: { long: 'CCS Biomass', short: 'CCS Bio', old: 'ccs_biomasse' },
        fuelKind: 'BM',
        calculated: {},
    },
    {
        key: 'T9',
        name: { long: 'Biomass CHP', short: 'Bio', old: 'biomasse' },
        fuelKind: 'BM',
        calculated: {},
    },
    {
        key: 'T10',
        name: { long: 'Run-of-River', short: 'RoR', old: 'ror' },
        profile: 'RoR',
        fuelKind: 'no',
        calculated: {},
    },
    {
        key: 'T11',
        name: { long: 'Hydro-storage', short: 'HS', old: 'hs' },
        fuelKind: 'no',
        calculated: {},
    },
    {
        key: 'T12',
        name: { long: 'PV Ground', short: 'PC Ground', old: 'ps' },
        fuelKind: 'no',
        calculated: {},
    },
    {
        key: 'T13',
        name: { long: 'PV Rooftop', short: 'PV Roof', old: 'pv' },
        fuelKind: 'no',
        calculated: {},
    },
    {
        key: 'T14',
        name: { long: 'PV Alpin', short: 'PV Alpin', old: 'pv_alpin' },
        fuelKind: 'no',
        calculated: {},
    },
    {
        key: 'T15',
        name: { long: 'Wind onshore', short: 'Wind on', old: 'wind_on' },
        profile: 'WN',
        fuelKind: 'no',
        calculated: {},
    },
    {
        key: 'T16',
        name: { long: 'Run-of-River', short: 'New RoR', old: 'wind_off' },
        profile: 'RoR',
        fuelKind: 'no',
        calculated: {},
    },
    {
        key: 'T17',
        name: { long: 'Geothermie', short: 'Geo', old: 'oth_ee_total' },
        fuelKind: 'no',
        calculated: {},
    },
    {
        key: 'T18',
        name: { long: 'Hydro-Storage', short: 'New HS' },
        fuelKind: 'no',
        calculated: {},
    },
    {
        key: 'T19',
        name: { long: 'CCGT Gas', short: 'New Gas Market' },
        fuelKind: 'GA',
        calculated: {},
    },
    {
        key: 'T20',
        name: { long: 'CCGT H2', short: 'New H2 Market' },
        fuelKind: 'H2',
        calculated: {},
    },
];
export const variables = [
    { key: 'depreciationPeriod', xlsLabel: 'Depreciation period', unit: 'year' },
    {
        key: 'waccDevelopentOverTime',
        xlsLabel: 'WACC development over time',
        unit: '%',
    },
    { key: 'alpha', xlsLabel: 'Alfa', unit: '%' },
    { key: 'fuelPrice', xlsLabel: 'Fuel price', unit: 'EUR/MWth' },
    { key: 'CO2Price', xlsLabel: 'CO2 price', unit: 'EUR/t-CO2' },
    { key: 'transportCO2', xlsLabel: 'Transport CO2', unit: 'EUR/t-CO2' },
    { key: 'storageCO2', xlsLabel: 'Storage CO2', unit: 'EUR/t-CO2' },
    { key: 'CO2Emissions', xlsLabel: 'CO2 emissions', unit: 't-CO2/MWh th' },
    { key: 'CO2Capture', xlsLabel: 'CO2 capture', unit: 't-CO2/MWh th' },
    { key: 'CO2EmissionsEl', xlsLabel: 'CO2Emissionsel', unit: 't-CO2/MWh el' },
    { key: 'CO2CaptureEl', xlsLabel: 'CO2Captureel', unit: 't-CO2/MWh el' },
    { key: 'transportH2', xlsLabel: 'Transport H2', unit: 'EUR/MWh H2(LHV)' },
    { key: 'storageH2', xlsLabel: 'Storage H2', unit: 'EUR/MWh H2(LHV)' },
    { key: 'availability', xlsLabel: 'Availability', unit: 'hours/year' },
    { key: 'availability', xlsLabel: 'Availabilty ', unit: 'hours/year' },
    { key: 'efficiencyLHV', xlsLabel: 'Efficiency (LHV)', unit: '%' },
    { key: 'investmentCosts', xlsLabel: 'Investment costs', unit: 'EUR/kW' },
    { key: 'fixedOM', xlsLabel: 'Fix O&M', unit: 'EUR/kW' },
    { key: 'variableOM', xlsLabel: 'Variable O&M', unit: 'EUR/MWh el' },
    {
        key: 'decommissioningCosts',
        xlsLabel: 'Stilllegungskosten',
        unit: 'EUR/MWh el',
    },
    { key: 'disposalCosts', xlsLabel: 'Entsorgungskosten', unit: 'EUR/MWh el' },
    { key: 'sharePowerGeneration', xlsLabel: 'AnteilStromerzeugung', unit: null },
    { key: 'ownConsumption', xlsLabel: 'Eigenverbrauch', unit: null },
    {
        key: 'shareOfSelfConsumption',
        xlsLabel: 'AnteilEigenverbrauch',
        unit: null,
    },
    { key: 'systemCosts', xlsLabel: 'Systemkosten', unit: 'EUR/MWh el' },
    {
        key: 'indirectGHGEmissions',
        xlsLabel: 'Indirekte THG Emissionen (Lifecycle)',
        unit: 't-CO2/MWh el',
    },
    {
        key: 'environmentalDamage',
        xlsLabel: 'Umweltschaden THG',
        unit: 'EUR/t-CO2',
    },
    {
        key: 'otherEnvironmentalDamage',
        xlsLabel: 'weiterer Umweltschaden',
        unit: 'EUR/MWh el',
    },
    { key: 'resourceUsage', xlsLabel: 'Resourcenverbrauch', unit: 'EUR/MWh el' },
    {
        key: 'relativeValueToBasePrice',
        xlsLabel: 'Relative Wertigkeit zum Basepreis',
        unit: 'EUR/MWh',
    },
    { key: 'absoluteValue', xlsLabel: 'Absolute Wertigkeit', unit: 'EUR/MWh' },
    { key: 'totalDemand', xlsLabel: 'Total Demand', unit: 'EUR/MWh' },
    {
        key: 'workingPriceMulti',
        xlsLabel: 'Working Price multi',
        unit: 'EUR/MWh',
    },
    { key: 'workingPriceAdd', xlsLabel: 'Working Price add', unit: 'EUR/MWh' },
    { key: 'gridFixCosts', xlsLabel: 'Grid & Fix Costs', unit: 'EUR/MWh' },
    { key: 'multiplierGrid', xlsLabel: 'Multiplier Grid', unit: undefined },
    { key: 'subsidy', xlsLabel: 'Subsidy', unit: 'EUR/MWh' },
    { key: 'tax', xlsLabel: 'Tax', unit: 'EUR/MWh' },
    { key: 'totalPowerPriceHousehold', xlsLabel: 'Total', unit: 'EUR/MWh' },
    {
        key: 'relativeValueToBasePrice',
        xlsLabel: 'Relative Wertigkeit zum Basepreis [EUR/MWh]',
        unit: 'EUR/MWh',
    },
    { key: 'minGas', xlsLabel: 'Min Gas', unit: 'EUR/MWh' },
    { key: 'minCO2', xlsLabel: 'Min CO2', unit: 'EUR/t-CO2' },
    { key: 'minHC', xlsLabel: 'Min HC', unit: 'EUR/MWh' },
    { key: 'CCGT_GA_CO2', xlsLabel: 'CCGT_GA_CO2', unit: 'EUR/t-CO2' },
    {
        key: 'CCGT_GA_CO2_contents',
        xlsLabel: 'CCGT_GA_CO2 Inhalt',
        unit: 't-CO2/MWh',
    },
    {
        key: 'CCGT_GA_efficiency',
        xlsLabel: 'CCGT_GA_Efficiency (LHV)',
        unit: '%',
    },
    { key: 'CCGT_GA_varOM', xlsLabel: 'CCGT_GA_Variable O&M', unit: 'EUR/MWh' },
    { key: 'CCGT_GA_CCS_base', xlsLabel: 'CCGT_GA_CCS (Base)', unit: 'EUR/MWh' },
    {
        key: 'PP_HC_CO2_contents',
        xlsLabel: 'PP_HC_CO2 Inhalt',
        unit: 't-CO2/MWh',
    },
    { key: 'PP_HC_efficiency', xlsLabel: 'PP_HC_Efficiency (LHV)', unit: '%' },
    { key: 'PP_HC_varOM', xlsLabel: 'PP_HC_Variable O&M', unit: 'EUR/MWh' },
    { key: 'PP_HC_CDS_base', xlsLabel: 'PP_HC_CDS (Base)', unit: 'EUR/MWh' },
    { key: 'h2BlueLCOH', xlsLabel: 'H2 Blue LCOH', unit: undefined },
    { key: 'h2BlueFuel', xlsLabel: 'H2 Blue Fuel', unit: undefined },
    { key: 'h2BlueEfficiency', xlsLabel: 'H2 Blue Eff', unit: undefined },
    { key: 'h2GreenLCOH', xlsLabel: 'H2 Green LCOH', unit: undefined },
    { key: 'h2GreenFuel', xlsLabel: 'H2 Green Fuel', unit: undefined },
    { key: 'h2GreenEfficiency', xlsLabel: 'H2 Green Eff', unit: undefined },
    { key: 'floorPriceGas', xlsLabel: 'Floor Price Gas', unit: undefined },
    { key: 'floorPriceCO2', xlsLabel: 'Floor Price CO2', unit: undefined },
    { key: 'minCCS', xlsLabel: 'Min CCS CCS', unit: undefined },
    { key: 'maxCCS', xlsLabel: 'Max CCS CCS', unit: undefined },
    { key: 'CCS2020', xlsLabel: 'CSS 2020 (60 EUR/MWh)', unit: undefined },
    { key: 'CCS2050', xlsLabel: 'CSS 2050 (60 EUR/MWh)', unit: undefined },
    { key: 'ccsFitting', xlsLabel: 'CCS Anstieg pro EUR/MWh', unit: undefined },
    {
        key: 'floorPriceHardCoal',
        xlsLabel: 'Floor Price Hard Coal',
        unit: undefined,
    },
    { key: 'minCDS', xlsLabel: 'Min CDS CDS', unit: undefined },
    { key: 'maxCDS', xlsLabel: 'Max CDS CDS', unit: undefined },
    { key: 'CDS2020', xlsLabel: 'CDS 2020 (60 EUR/MWh)', unit: undefined },
    { key: 'CDS2050', xlsLabel: 'CDS 2050 (60 EUR/MWh)', unit: undefined },
    { key: 'cdsFitting', xlsLabel: 'CDS Anstieg pro EUR/MWh', unit: undefined },
    {
        key: 'h2QuotaPctOfVolume',
        xlsLabel: 'H2 Quota (% of olume)',
        unit: undefined,
    },
    { key: 'h2QuotaPctOfHHV', xlsLabel: 'H2 Quota (% of HHV)', unit: undefined },
    {
        key: 'shareSelfConsumptionNewPlantsLow',
        xlsLabel: 'SC_niedrig',
        unit: undefined,
    },
    {
        key: 'shareSelfConsumptionNewPlantsMedium',
        xlsLabel: 'SC_mittel',
        unit: undefined,
    },
    {
        key: 'shareSelfConsumptionNewPlantsHigh',
        xlsLabel: 'SC_hoch',
        unit: undefined,
    },
    { key: 'formulaSubsidyALow', xlsLabel: 'Fa_niedrig', unit: undefined },
    { key: 'formulaSubsidyAMedium', xlsLabel: 'Fa_mittel', unit: undefined },
    { key: 'formulaSubsidyAHigh', xlsLabel: 'Fa_hoch', unit: undefined },
    { key: 'formulaSubsidyBLow', xlsLabel: 'Fb_niedrig', unit: undefined },
    { key: 'formulaSubsidyBMedium', xlsLabel: 'Fb_mittel', unit: undefined },
    { key: 'formulaSubsidyBHigh', xlsLabel: 'Fb_hoch', unit: undefined },
    {
        key: 'savingMarketPriceLow',
        xlsLabel: 'ConMarket_niedrig',
        unit: undefined,
    },
    {
        key: 'savingMarketPriceMedium',
        xlsLabel: 'ConMarket_mittel',
        unit: undefined,
    },
    { key: 'savingMarketPriceHigh', xlsLabel: 'ConMarket_hoch', unit: undefined },
    { key: 'savingGridLow', xlsLabel: 'ConGrid_niedrig', unit: undefined },
    { key: 'savingGridMedium', xlsLabel: 'ConGrid_mittel', unit: undefined },
    { key: 'savingGridHigh', xlsLabel: 'ConGrid_hoch', unit: undefined },
    { key: 'savingSubsidyLow', xlsLabel: 'ConSubsidy_niedrig', unit: undefined },
    {
        key: 'savingSubsidyMedium',
        xlsLabel: 'ConSubsidy_mittel',
        unit: undefined,
    },
    { key: 'savingSubsidyHigh', xlsLabel: 'ConSubsidy_hoch', unit: undefined },
    { key: 'consideringTaxLow', xlsLabel: 'ConTax_niedrig', unit: undefined },
    { key: 'consideringTaxMedium', xlsLabel: 'ConTax_mittel', unit: undefined },
    { key: 'consideringTaxHigh', xlsLabel: 'ConTax_hoch', unit: undefined },
    {
        key: 'necessarySubsidyCalculationIncludingSelfConsumptionLow',
        xlsLabel: 'InklSC_niedrig',
        unit: undefined,
    },
    {
        key: 'necessarySubsidyCalculationIncludingSelfConsumptionMedium',
        xlsLabel: 'InklSC_mittel',
        unit: undefined,
    },
    {
        key: 'necessarySubsidyCalculationIncludingSelfConsumptionHigh',
        xlsLabel: 'InklSC_hoch',
        unit: undefined,
    },
];
