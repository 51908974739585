﻿<script>
  import Collapsable from '../../components/Collapsable.svelte'

  export let title
</script>

<Collapsable {title} track={true}>
  <Collapsable title="Model approach">
    <h2>Model approach</h2>

    <h3>At a glance</h3>

    <p>The key parameters for assumptions and calculations are:</p>

    <li>Assumption of monthly electricity balances</li>

    <li>Use of historical data to calculate monthly electricity production</li>

    <li>
      Combination of historical data with scenario assumptions for the
      calculation of monthly electricity demand
    </li>

    <li>
      Use of ENTSO-E and ENTSOG scenarios as defaults for decarbonisation in
      neighbouring countries
    </li>

    <p>These key parameters are further elaborated below.</p>

    <h3>Energy balance instead of power balance</h3>

    <p>
      Monthly energy balances are the basis for the model instead of power
      balances.
    </p>

    <h3>Monthly balances</h3>

    <p>
      Unlike many other European countries, in Switzerland it is not a potential
      capacity shortage in individual hours that is critical for the security of
      supply. This is because a high number of hydraulic storage and pumped
      storage plants and new storage options make it possible to compensate for
      hourly or daily fluctuations in electricity production.
    </p>

    <p>
      The problem, rather, is that in the event of a prolonged lull in other
      electricity production, large storage reservoirs can run dry. In this
      case, the energy scarcity can result in a possible interruption of the
      power supply.
    </p>

    <h3>Power generation</h3>

    <p>
      We chart how much electricity can be generated by which technologies on an
      annual and monthly basis. In terms of methodology, this means that for
      each of the scenarios we record the electricity generation capacity for
      each technology. Based on the maximum amount of electricity that can be
      produced monthly by each technology, we derive the maximum amount of
      electricity that can be produced in each month of the year.
    </p>

    <p>
      The stored monthly profiles for each technology can be found in the input
      data for capacities under their utilisation. Typical profiles are derived
      from historical data based on average availability.
    </p>

    <p>
      A potential cloudy and windless condition can be set to either 0.7 or 14
      days per year. In the model, these days are automatically placed in
      February and apply equally to Switzerland and neighbouring countries.
    </p>

    <h3>Electricity demand</h3>

    <p>
      For demand, the figures showing the percentage of the demand typically
      occurring in each month are also stored. The annual quantities are taken
      from the respective scenarios.
    </p>

    <h3>Imports</h3>

    <p>See "Imports" in the menu for a description.</p>
  </Collapsable>
  <Collapsable title="Additional evaluations">
    <h3>How many days can Switzerland be self-sufficient?</h3>

    <p>
      For each month, it is calculated how high Swiss production is compared to
      the demand. Based on this, it can be determined how many days a month
      Switzerland can supply itself with electricity.
    </p>

    <h3>Can stored electricity cover deficits throughout the year?</h3>

    <p>
      Can power supply gaps within a year be prevented? By deducting the
      deficits throughout the year from the total potential production through
      storage (in TWh). If the amount of energy stored is greater than the
      deficit, no supply shortage occurs. Conversely, if demand is not met by
      generation, imports and reserves, then an electricity deficit occurs.
    </p>
  </Collapsable>
</Collapsable>
