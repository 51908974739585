<script>
  import EditIcon from '../ui/icons/EditIcon.svelte'
  import CloseIcon from '../ui/icons/CloseIcon.svelte'
  import { selectedTechnology } from '../stores'

  export let expand
  export let key
  export let show
  export let color
  export let updateSelectedTechnology = true

  let hover = undefined
</script>

<div class="item">
  {#if show}
    <div
      on:mouseover={() => (hover = key)}
      on:mouseout={() => (hover = undefined)}
      on:click={() => {
        expand = expand === key ? null : key

        if (updateSelectedTechnology) {
          $selectedTechnology = $selectedTechnology === key ? undefined : key
        } else {
          $selectedTechnology = undefined
        }
      }}
      class="item-inner"
      style="cursor: pointer; border-top: 4px solid var(--color-tech-{key}); {expand ===
        key || hover === key
        ? `background: var(--color-tech-${key}-partial-transparent)`
        : ''}"
    >
      <slot />
      <div class="expand-button">
        {#if expand === key}
          <CloseIcon />
        {:else}
          <EditIcon />
        {/if}
      </div>
    </div>
  {/if}
</div>

<style>
  .item {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9em;
  }

  .item-inner {
    background-color: var(--color-grey-100);
    width: 100%;
    height: 100%;
    margin: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
  }

  .expand-button {
    border: none;
    border-radius: 5px;
    margin: 0;
    padding: 4px 4px 2px 4px;
    cursor: pointer;
    background: none;
    outline: none;
  }
</style>
