<script>
  import { showPopover, popoverContent, popoverTitle } from '../stores'

  export let title
  export let description = 'missing description'
  export let top = 0
  export let left = 0
</script>

<img
  src="icons/infopoint.svg"
  width={16}
  style="top: {top}px; left: {left}px;"
  on:mouseover={() => {
    $showPopover = true
    $popoverContent = description
    $popoverTitle = title
  }}
  on:mouseout={() => {
    $showPopover = false
    $popoverContent = undefined
    $popoverTitle = undefined
  }}
/>

<style>
  img {
    position: relative;
    margin-right: var(--size-1);
  }
</style>
