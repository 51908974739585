import { dataset_dev } from 'svelte/internal';
import { writable } from 'svelte/store';
const sliderRangeValue = (id, scenario) => (yearOfAnalysis, data) => data.filter((d) => d.country === 'CH' &&
    d.scenario === scenario &&
    d.year === yearOfAnalysis)[id];
const importSliderRangeValue = (country, scenario) => (yearOfAnalysis, data) => data.filter((d) => d.country === country &&
    d.scenario === scenario &&
    d.year === yearOfAnalysis)[country];
const sliderValue = () => { };
const importSliderValue = () => { };
const scenarios = [
    {
        name: 'Basisszenario',
        description: 'Description for scenario ... TODO',
        id: 'Basisszenario',
        selectable: true,
    },
    {
        name: 'Energieperspektiven 2050+',
        description: 'Description for scenario ... TODO',
        id: 'SzI',
        selectable: true,
    },
    {
        name: 'Nordmann',
        description: 'Description for scenario ... TODO',
        id: 'SzII',
        selectable: true,
    },
    {
        name: 'Grossen',
        description: 'Description for scenario ... TODO',
        id: 'SzIII',
        selectable: true,
    },
    {
        name: 'EntsoE',
        description: 'Description for scenario ... TODO',
        id: 'SzIV',
        selectable: true,
    },
    {
        name: 'LEMM21',
        description: 'Description for scenario ... TODO',
        id: 'SzV',
        selectable: true,
    },
    {
        name: 'LEMM21',
        description: 'Description for scenario ... TODO',
        id: 'SzVI',
        selectable: true,
    },
    {
        name: 'LEMM21',
        description: 'Description for scenario ... TODO',
        id: 'SzVII',
        selectable: true,
    },
    {
        name: 'LEMM20',
        description: 'Description for scenario ... TODO',
        id: 'SzVIII',
        selectable: true,
    },
    {
        name: 'LEMM20',
        description: 'Description for scenario ... TODO',
        id: 'SzIX',
        selectable: true,
    },
    {
        name: 'LEMM20',
        description: 'Description for scenario ... TODO',
        id: 'SzX',
        selectable: true,
    },
    {
        name: 'Min',
        description: 'Description for scenario ... TODO',
        id: 'Max',
        selectable: false,
    },
    {
        name: 'Max',
        description: 'Description for scenario ... TODO',
        id: 'Min',
        selectable: false,
    },
];
const countries = [
    {
        id: 'CH',
        name: 'Switzerland',
        neighbors: ['AT', 'IT', 'FR', 'DE'],
    },
    {
        id: 'DE',
        name: 'Germany',
        neighbors: ['CH', 'AT', 'FR'],
    },
    {
        id: 'FR',
        name: 'France',
        neighbors: ['CH', 'IT', 'DE'],
    },
    {
        id: 'IT',
        name: 'Italy',
        neighbors: ['CH', 'AT', 'FR'],
    },
    {
        id: 'AT',
        name: 'Austria',
        neighbors: ['CH', 'AT', 'FR'],
    },
];
const energySources = [
    {
        primaryEnergyCarrier: 'Braunkohle',
        type: 'Fossil',
        id: 'BC total',
    },
    {
        primaryEnergyCarrier: 'Steinkohle',
        type: 'Fossil',
        id: 'HC total',
    },
    {
        primaryEnergyCarrier: 'Erdgas',
        type: 'Fossil',
        id: 'GA total',
    },
    {
        primaryEnergyCarrier: 'andere Fossile',
        type: 'Fossil',
        id: 'Oth. Fossil total',
    },
    {
        primaryEnergyCarrier: 'Kernenergie',
        type: 'CO2 freie Techn.',
        id: 'NU',
    },
    {
        primaryEnergyCarrier: 'Gas-to-Power (H2)',
        type: 'CO2 freie Techn.',
        id: 'GtP',
    },
    {
        primaryEnergyCarrier: 'CCS Fossil',
        type: 'CO2 freie Techn.',
        id: 'CCS Fossil',
    },
    {
        primaryEnergyCarrier: 'CCS Biomasse',
        type: 'Erneuerbare',
        id: 'T-BX',
    },
    {
        primaryEnergyCarrier: 'Biomasse',
        type: 'Erneuerbare',
        id: 'T-BM',
    },
    {
        primaryEnergyCarrier: 'Laufwasserkraft',
        type: 'Erneuerbare',
        id: 'RoR',
    },
    {
        primaryEnergyCarrier: 'Speicherkraft',
        type: 'Erneuerbare',
        id: 'HS',
    },
    {
        primaryEnergyCarrier: 'Pumpspeicherkraft',
        type: 'Erneuerbare',
        id: 'PS',
    },
    {
        primaryEnergyCarrier: 'PV',
        type: 'Erneuerbare',
        id: 'PH',
    },
    {
        primaryEnergyCarrier: 'PV alpin',
        type: 'Erneuerbare',
        id: 'PV alpin',
    },
    {
        primaryEnergyCarrier: 'Wind on',
        type: 'Erneuerbare',
        id: 'Wind on',
    },
    {
        primaryEnergyCarrier: 'Wind off',
        type: 'Erneuerbare',
        id: 'Wind off',
    },
    {
        primaryEnergyCarrier: 'andere Erneuerbare',
        type: 'Erneuerbare',
        id: 'E-AE',
    },
];
const energyCategories = [
    {
        name: 'Conventional',
        id: 'Klass',
    },
    {
        name: 'Heat Pump',
        id: 'HP',
    },
    {
        name: 'Elektromobility',
        id: 'EV',
    },
    {
        name: 'Demand for H2 Generation',
        id: 'H2',
    },
];
// TODO: can this be structured with the energy sources??
const technologies = [
    'Hydro',
    'PV',
    'Biomass',
    'Wind',
    'Other renewables',
    'Nuclear',
    'Gas',
    'Other Fossil',
    'GtP (H2)',
    'CCS Fossil',
];
const neighborTechnologies = [
    'Fossil',
    'Kernenergie',
    'Carbon Neutral',
    'Wind and PV',
];
const generalUserSettings = [
    {
        category: 'general',
        subCategory: 'general',
        id: 'dunkelflaute',
        label: 'Days of "Dunkelflaute" oer month (all countries)',
        description: 'Description for slider ... TODO',
        values: {
            min: 0,
            max: 30,
            default: 15,
            unit: 'days',
        },
    },
    {
        category: 'general',
        subCategory: 'general',
        id: 'scenario',
        label: 'Scenario',
        description: 'Description for slider ... TODO',
        values: {
            range: () => model.scenarios.filter((d) => d.selectable),
            default: 'SzV',
        },
    },
    {
        category: 'general',
        subCategory: 'general',
        id: 'yearOfAnalysis',
        label: 'Year of analysis',
        description: 'Description for slider ... TODO',
        values: {
            min: () => 2020,
            max: () => 2050,
            default: () => 2040,
            unit: 'years',
        },
    },
];
const capacityUserSettings = energySources.map((d) => ({
    category: 'Switzerland',
    subCategory: 'Installed Capacity',
    id: d.id,
    label: d.primaryEnergyCarrier,
    description: 'Description for slider ... TODO',
    values: {
        min: () => sliderRangeValue(d.id, 'Min'),
        max: () => sliderRangeValue(d.id, 'Max'),
        // value: () => sliderValue(d.id, scenario),
        unit: 'MW',
    },
}));
const demandUserSettings = energyCategories.map((d) => ({
    category: 'Switzerland',
    subCategory: 'Power Demand',
    id: d.id,
    label: d.name,
    description: 'Description for slider ... TODO',
    values: {
        min: () => sliderRangeValue(d.id, 'Min'),
        max: () => sliderRangeValue(d.id, 'Max'),
        // value: () => sliderValue(d.id, scenario),
        unit: 'TWh',
    },
}));
// TODO: how is this used in the calculation?
const availabilityUserSettings = technologies.map((d) => ({
    category: 'Switzerland',
    subCategory: 'Availability of Technology',
    id: d,
    label: d,
    description: 'Description for slider ... TODO',
    values: {
        options: ['low', 'medium', 'hight'],
        // value: () => sliderValue(d, scenario),
    },
}));
const importCapacityUserSettings = countries
    .filter((d) => d.id !== 'CH')
    .map((d) => ({
    category: 'Neighboring Countries',
    subCategory: 'Import Capacity NTC',
    fromCountry: d.id,
    label: `Import Capacity [MW] from ${d.name} into Switzerland`,
    description: 'Description for slider ... TODO',
    values: {
        min: () => importSliderRangeValue(d.id, 'Min'),
        max: () => importSliderRangeValue(d.id, 'Max'),
        // value: () => importSliderValue(d.id, scenario),
        unit: 'MW',
    },
}));
const neighborAvailabilityUserSettings = neighborTechnologies.map((d) => ({
    category: 'Neighboring Countries',
    subCategory: 'Availability of Technology',
    id: d,
    label: d,
    description: 'Description for slider ... TODO',
    values: {
        options: ['low', 'medium', 'hight'],
        // value: () => sliderValue(d, scenario),
    },
}));
export const model = {
    scenarios,
    countries,
    energySources,
    energyCategories,
    technologies,
    neighborTechnologies,
    userSettings: {
        switzerland: {
            general: generalUserSettings,
            capacity: capacityUserSettings,
            demand: demandUserSettings,
            availability: availabilityUserSettings,
            scenario: {
                category: 'Switzerland',
                subCategory: 'Power Demand',
                label: 'Scenario',
                id: 'scenario',
                description: 'Description for slider ... TODO',
                values: {
                    options: ['Conservative', 'BAU', 'Net-Zero'],
                },
            },
        },
        neighboringCountries: {
            scenario: {
                category: 'Neighboring Countries',
                subCategory: 'general',
                label: 'Scenario',
                id: 'scenario',
                description: 'Description for slider ... TODO',
                values: {
                    options: ['Conservative', 'BAU', 'Net-Zero'],
                },
            },
            import: importCapacityUserSettings,
            availability: neighborAvailabilityUserSettings,
        },
    },
};
