<script>
  import { tweened } from 'svelte/motion'
  import { cubicInOut } from 'svelte/easing'
  import { selectedTechnology, techGroups } from './../../stores.ts'

  export let d

  const scale = 5

  $: techGroup = $techGroups.find((d) => d.key === $selectedTechnology)
</script>

{#if d.surplus > 0}
  <circle fill="#00ECD1" r={Math.sqrt(d.surplus) * scale} />
{:else if d.deficit > 0}
  <circle
    stroke="purple"
    stroke-width={1}
    fill="none"
    r={Math.sqrt(d.deficit) * scale + 2}
  />
  <circle fill="purple" r={Math.sqrt(d.deficit) * scale} />
{:else if d.import > 0}
  <circle
    fill="url(#diagonalHatch-import)"
    r={Math.sqrt(Math.abs(d.import)) * scale}
  />
{/if}
