<script>
  import { pointer } from 'd3-selection'
  import OutlineText from './../components/OutlineText.svelte'
  import { calculatedData, selectedDemandTech } from './../stores'
  import { sum, range, max } from 'd3-array'
  import { stack, area, curveCatmullRom, line } from 'd3-shape'
  import { scaleLinear, scaleQuantize } from 'd3-scale'
  import { _ } from 'svelte-i18n'
  import { format } from 'd3-format'

  const keys = ['losses', 'klass', 'ev', 'h2', 'hp']

  const f = format(',.2f')

  $: data = stack().keys(keys)(
    $calculatedData.getAnnualTimeSeries((year) =>
      Object.assign(year.calculated.demand.CH.absolute.userValues, {
        losses: year.calculated.demandLosses,
      })
    )
  )

  $: dataMin = $calculatedData.getAnnualTimeSeries((year) =>
    sum(Object.values(year.calculated.demand.CH.absolute.min))
  )

  $: dataMax = $calculatedData.getAnnualTimeSeries((year) =>
    sum(Object.values(year.calculated.demand.CH.absolute.max))
  )

  const width = 250
  const height = 150

  const margin = {
    top: 20,
    right: 15,
    bottom: 20,
    left: 40,
  }

  const colors = {
    klass: 'hsl(0, 0%, 50%)',
    ev: 'hsl(0, 0%, 60%)',
    h2: 'hsl(0, 0%, 70%)',
    hp: 'hsl(0, 0%, 80%)',
    losses: 'url(#diagonalHatch-losses)',
  }

  const w = width - margin.left - margin.right
  const h = height - margin.top - margin.bottom

  const x = scaleLinear().domain([0, 30]).range([0, w])
  const xq = scaleQuantize().domain([0, w]).range(range(31))

  let hoverValue = undefined
  let mousePosition

  $: y = scaleLinear()
    .domain([0, max(dataMax)])
    .range([h, 0])

  $: ticks = y.nice().ticks(5)

  const a = area()
    .x((d, i) => x(i))
    .y0((d) => y(d[0]))
    .y1((d) => y(d[1]))
    .curve(curveCatmullRom)

  $: getColor = (key) => {
    if (!$selectedDemandTech || $selectedDemandTech === key) {
      return colors[key]
    }

    return '#EFEFEF'
  }
</script>

<!-- TODO: -->
<h4>
  {$_('page.sections.userControls.switzerland.demand.labels.totalDemand')}
</h4>
<svg {width} {height}>
  <defs>
    <pattern
      id="diagonalHatch-losses"
      patternUnits="userSpaceOnUse"
      width="4"
      height="4"
    >
      <path
        d="M-1,1 l2,-2
          M0,4 l4,-4
          M3,5 l2,-2"
        style="stroke: #8d8d8d; stroke-width: 1;"
      />
    </pattern>
    <marker
      id="arrow"
      viewBox="0 0 10 10"
      refX="5"
      refY="5"
      markerWidth="6"
      markerHeight="6"
      orient="auto-start-reverse"
    >
      <path d="M 0 0 L 10 5 L 0 10 z" />
    </marker>
  </defs>
  <g transform="translate({margin.left} {margin.top})">
    {#each data as d, i}
      <path
        d={a(d)}
        fill={!hoverValue || hoverValue[0] === d.key
          ? getColor(d.key)
          : '#EfEfEf'}
        on:mouseover={(e) => {
          mousePosition = pointer(e)
          hoverValue = [
            data[i].key,
            data[i][xq(pointer(e)[0])].data[data[i].key],
          ]
        }}
        on:mousemove={(e) => {
          mousePosition = pointer(e)
          hoverValue = [
            data[i].key,
            data[i][xq(pointer(e)[0])].data[data[i].key],
          ]
        }}
        on:mouseout={() => (hoverValue = undefined)}
        on:focus={() => {}}
        on:blur={() => {}}
      />
    {/each}
    {#if hoverValue}
      <text x={w / 2} y={y.range()[1]} style="text-anchor: middle;">
        <tspan style="font-weight: bold;">
          {$_(
            `page.sections.userControls.switzerland.demand.demandTypes.${hoverValue[0]}.label`
          )}
        </tspan>: {f(hoverValue[1])} TWh</text
      >
    {/if}

    <line x2={w} y1={y(0)} y2={y(0)} stroke="black" />
    <line y1={y(0)} y2={y.range()[1]} stroke="black" marker-end="url(#arrow)" />

    <path
      d={line()
        .x((d, i) => x(i))
        .y((d) => y(d))(dataMax)}
      stroke="black"
      stroke-width={0.5}
      fill="none"
      stroke-dasharray="2 2"
      style="pointer-events: none"
    />

    <path
      d={line()
        .x((d, i) => x(i))
        .y((d) => y(d))(dataMin)}
      stroke="black"
      stroke-width={0.5}
      fill="none"
      stroke-dasharray="2 2"
      style="pointer-events: none"
    />

    <OutlineText
      backColor="rgba(231, 208, 211, 0.7)"
      x={x(30)}
      y={y(dataMin[30])}
      class="min"
      dy={-2}>min</OutlineText
    >

    <OutlineText x={x(30)} class="max" dy={-2}>max</OutlineText>

    {#if !hoverValue}
      {#each range(2020, 2051) as year, i}
        {#if i % 5 === 0}
          <text transform="translate({x(i)}, {h})" dy={14} class="x-tick"
            >{year}</text
          >
        {/if}
      {/each}
    {:else}
      <text
        transform="translate({x(xq(mousePosition[0]))}, {h})"
        dy={14}
        class="x-tick">{xq(mousePosition[0]) + 2020}</text
      >
    {/if}

    {#each ticks as tick}
      {#if tick > 0}
        <text dx={-4} class="y-tick" y={y(tick)}>{tick}</text>
      {/if}
    {/each}

    <text
      style="dominant-baseline: central; text-anchor: middle; font-size: var(--scale-1);"
      dy={-14}
      dx={-4}
      y={y.range()[1]}>TWh</text
    >
  </g>
</svg>

<style>
  text {
    font-size: var(--scale-1);
  }
  h4 {
    margin: 0;
  }

  svg {
    margin-top: 70px;
    max-width: 370px;
  }
</style>
