<script>
  import NecessarySubsidiesChart from './NecessarySubsidiesChart.svelte'
  import { LayerCake, Svg } from 'layercake'
  import { range, groups, extent } from 'd3-array'
  import {
    calculatedData,
    selectedLCOE,
    selectedPowerPrice,
    selectedCostView,
  } from '../../stores'
  import { stack } from 'd3-shape'
  import { trueCosts } from '../../costCalculation.js'

  const technologies = range(1, 17).map((d) => `T${d}`)

  $: trueCostData = $trueCosts.getAnnualTimeSeries((year) => {
    const result = {
      year: year.year,
    }

    Object.values(year.trueCost.technologies)
      .filter((d) => !['T3', 'T16', 'T20', 'T19', 'T18'].includes(d.key))
      .forEach((d) => {
        result[d.key] =
          Math.min(0, d.calculated.netBenefit.netBenefit.investorTotal) * -1
      })

    return result
  })

  $: data = stack().keys(technologies)(trueCostData)

  $: xDomain = extent(trueCostData, (d) => d.year)
  $: yDomain = extent(data.map((d) => d.flat()).flat())
</script>

<div class="container">
  <div class="chart-container">
    {#if data}
      <LayerCake
        {data}
        padding={{ top: 30, right: 20, bottom: 20, left: 30 }}
        {yDomain}
        {xDomain}
      >
        <Svg>
          <NecessarySubsidiesChart />
        </Svg>
      </LayerCake>
    {/if}
  </div>
</div>

<style>
  .container {
    width: 100%;
    height: 100%;
  }

  .chart-container {
    width: 100%;
    height: calc(100% - 20px);
  }
</style>
