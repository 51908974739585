﻿<script>
  import Collapsable from './../../components/Collapsable.svelte'

  export let title
</script>

<Collapsable {title}>
  <Collapsable title="Konventionelle Nachfrage">
    <p>
      Die konventionelle Nachfrage stellt den Endenergieverbrauch der
      unterschiedlichen Sektoren wie Haushalt, Dienstleistungen, Industrie,
      Landwirtschaft, Verkehr und Umwandlung dar. Nichtberücksichtigt wird der
      Stromverbrauch für Wärmepumpen, für Elektromobilität und der Strom, der
      zur Erzeugung von Wasserstoff (H2) notwendig ist.
    </p>
    <p>
      Die zukünftige Entwicklung der konventionellen Nachfrage wird durch zwei
      gegenläufige Trends beeinflusst:
    </p>
    <ol>
      <li>
        Effizienzsteigerungen führen zu einem zu erwartenden (stark) sinkenden
        Trend in der Nachfrage.
      </li>
      <li>
        Neue Anwendungsgebiete von Elektrizität in allen Sektoren
        (Sektorenkopplung) wie beispielsweise durch zunehmende E-Mobilität
        führen zu einer zu erwartenden steigenden Nachfrage.
      </li>
    </ol>
    <p>
      Des Weiteren sind das Bevölkerungswachstum und die wirtschaftliche
      Entwicklung wichtige Faktoren in der Entwicklung der Stromnachfrage.
    </p>
  </Collapsable>
  <Collapsable title="Nachfrage Wärmepumpen">
    <p>
      Diese Nachfrage berücksichtigt den Strombedarf aller Wärmepumpen. Sie
      beinhaltet die Nachfrage von Wärmepumpen in Gebäuden (Haushalt und
      Dienstleistung), in der Industrie, in der Landwirtschaft und die von
      Grosswärmepumpen.
    </p>
    <p>
      Da in unserer Darstellung die Nachfrage von Industrie, Landwirtschaft und
      Grosswärmepumpen mitenthalten ist, ergeben sich gegenüber der offiziellen
      Statistik leicht höhere Werte (2019: 2.7 TWh vs. 2.3 TWh).
    </p>
  </Collapsable>
  <Collapsable title="Nachfrage Elektromobilität">
    <p>
      Die Dekarbonisierung des Verkehrssektors wird zu einem starken Anstieg des
      Stromverbrauches führen. In diesem Nachfrageblock wird die Nachfrage aus
      dem Strassenverkehr dargestellt. Der Schienenverkehr befindet sich im Teil
      «Konventionelle Nachfrage».
    </p>
    <p>
      Je nach Entwicklung der Kilometerleistung möglicher Alternativen wie mit
      Wasserstoff angetriebenen Autos oder Autos, die mit synthetischen
      Treibstoffen fahren, ist die potentielle jährliche Nachfrage anders. Bei
      einem <a
        href="https://rogernordmann.ch/buch-sonne-fur-den-klimaschutz-ein-solarplan-fur-die-schweiz/"
        class="link link--external"
        target="_blank"
        title="Roger Nordmann, “Kapitel 3.3 Elektrifizierung Der Mobilität (Ohne Luftverkehr),” in Sonne Für Den Klimaschutz: Ein Solarplan Für Die Schweiz, 2nd ed. (Basel: Zytglogge Verlag AG, 2019)"
        >vollständigen Umstieg auf Elektromobilität wird die Nachfrage auf bis
        zu 17 TWh in 2050 geschätzt.</a
      >.
    </p>
  </Collapsable>
  <Collapsable title="Nachfrage Wasserstoffproduktion">
    <p>
      In dieser Kategorie wird der Stromverbrauch zur Erzeugung von Wasserstoff
      via Elektrolyse zusammengefasst. In einer dekarbonisierten Welt spielt
      Wasserstoff als Energiequelle eine entscheidende Rolle. Die
      Anwendungsmöglichkeiten sind vielfältig: Er kann als Brennstoff in (vor
      allem schweren) Kraftfahrzeugen, in industriellen Prozessen wie jenen, die
      hohe Hitze benötigen, als Basis für andere Brennstoffe wie Ammonium und
      mehr genutzt werden.
    </p>
    <h3>Wasserstoff & dekarbonisierte Stromversorgung</h3>
    <p>
      Eine weitere wichtige Funktion von Wasserstoff ist, dass er als Quelle und
      Speicher von Strom dienen kann. In dem dekarbonisierten Kreislauf wird
      hier durch Elektrolyse von Strom aus erneuerbaren Energiequellen
      sogenannter grüner Wasserstoff hergestellt. Dieser kann zu einem späteren
      Zeitpunkt wieder zur Stromerzeugung genutzt werden, indem er ähnlich wie
      heute Erdgas zur Stromgewinnung verbrannt wird. In der Schweiz könnte
      diese Art von abrufbar produziertem Strom gerade im Winter wertvoll sein,
      wenn durch weniger Sonneneinstrahlung weniger Solarstrom verfügbar ist.
      Derzeit wird in der Schweiz kein Wasserstoff via Elektrolyse erzeugt. Das
      Potential ist, je nach nationalem Stromerzeugungspotential, möglichen
      Stromimporten und je nach Speichermöglichkeit von Wasserstoff gross.
    </p>
  </Collapsable>
</Collapsable>
