<script>
  import { _ } from 'svelte-i18n'
  import { selectedTimePeriod } from './../../stores'
</script>

<div class="btn-group">
  <button
    class:selectedTimePeriod={$selectedTimePeriod === 'winter'}
    on:click={() => ($selectedTimePeriod = 'winter')}
    >{$_('page.sections.charts.energyMix.periodSelector.winter')}</button
  >
  <button
    class:selectedTimePeriod={$selectedTimePeriod === 'wholeYear'}
    on:click={() => ($selectedTimePeriod = 'wholeYear')}
    >{$_('page.sections.charts.energyMix.periodSelector.wholeYear')}</button
  >
  <button
    class:selectedTimePeriod={$selectedTimePeriod === 'summer'}
    on:click={() => ($selectedTimePeriod = 'summer')}
    >{$_('page.sections.charts.energyMix.periodSelector.summer')}</button
  >
</div>

<style>
  .btn-group {
    margin-left: 30px;
  }

  .btn-group button {
    cursor: pointer; /* Pointer/hand icon */
    float: left; /* Float the buttons side by side */
    /* background: #ffffff; */
    /* border: 2px solid #ff5d64; */
    /* color: #ff5d64; */
    border: none;
    background: #efefef;
    outline: none;
    cursor: pointer;
    font-size: 1.4rem;
    padding: 4px 20px;
  }

  /* Clear floats (clearfix hack) */
  .btn-group:after {
    content: '';
    clear: both;
    display: table;
  }

  .btn-group button:last-child {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    margin-left: 1px;
  }

  .btn-group button:first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    margin-right: 1px;
  }

  .btn-group .selectedTimePeriod {
    background: var(--accent-color);
    color: white;
  }
</style>
