<script>
  import { activeMainView } from '../../stores.js'
  import NetBenefit from '../../charts/cost/NetBenefit.svelte'
  import { _ } from 'svelte-i18n'
  import { onMount, onDestroy } from 'svelte'
  import { trueCosts } from '../../costCalculation.js'
  import CostControls from '../../charts/cost/CostControls.svelte'

  $: console.log($trueCosts)

  onMount(() => {
    activeMainView.set('cost')
  })

  onDestroy(() => {
    activeMainView.set(undefined)
  })
</script>

<div class="wrapper">
  <CostControls />

  <div class="charts-wrapper">
    <div class="charts">
      <div class="chart">
        <NetBenefit />
      </div>
    </div>
  </div>
</div>

<style>
  .wrapper {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .charts-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .charts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .chart {
    width: 100%;
    height: 440px;
  }
</style>
