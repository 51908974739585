import { writable, readable, derived } from 'svelte/store';
import { csv } from 'd3-fetch';
import { sum, group } from 'd3-array';
import { loadData } from './fetchers';
import { calculateAvailability, calculateAvailabilityNoStressor, calculateCapacity, calculateAnnualDemand, calculatedDemandLosses, calculateImportCapacity, calculateMonthlyCapacity, calculateProduction, calculateMonthlyDemand, calculateTotalDemand, calculateTotalProduction, calculateSurplus, calculateTotalNeighborSurplus, calculateTotalSurplus, calculateEnergyDeficit, calculateImportPotential, calculateMaxImportability, calculateCHTotalSurplus, calculateAnnualProduction, calculateSelfSufficientDays, calculateMinAnnualSelfSufficientDays, calculateGeneration, calculateAnnualGeneration, calculateAnnualCost, calculateSpeicherReserve2, calculateImportEnergy, } from './calculators';
import { register, init, getLocaleFromNavigator, addMessages, } from 'svelte-i18n';
import en from './i18n/en.json';
import de from './i18n/de.json';
import { autoType } from 'd3-dsv';
import { winterMonths, summerMonths, allMonths } from './constants';
addMessages('en', en);
addMessages('de', de);
init({
    fallbackLocale: 'de',
    // initialLocale: getLocaleFromNavigator(),
    initialLocale: 'de',
});
export const activeTab = writable('energymix');
export const mousePosition = writable({ x: 0, y: 0 });
export const showPopover = writable(false);
export const popoverContent = writable();
export const popoverTitle = writable();
// scenario comparison
export const compare1 = writable(undefined);
export const compare2 = writable(undefined);
export const activeMainView = writable();
export const isReset = writable(0);
export const selectedTimePeriod = writable('winter');
export const activeMonths = derived(selectedTimePeriod, ($selectedTimePeriod) => {
    if ($selectedTimePeriod === 'winter') {
        return winterMonths;
    }
    else if ($selectedTimePeriod === 'summer') {
        return summerMonths;
    }
    else {
        return allMonths;
    }
});
export const cost = readable(undefined, (set) => {
    csv('data/costs.csv', autoType).then((result) => set(group(result, (d) => d.scenario)));
});
export const costDetailsHover = writable(undefined);
export const capacityChanged = writable(undefined);
export const userSettingsInitial = {
    general: {
        general: {
            dunkelflaute: 0,
        },
        stressors: {
            pv_wind_hydro_generation: { defaultValue: 2, value: 2, edited: false },
            temperature: { defaultValue: 2, value: 2, edited: false },
            gas_availability: { defaultValue: 1, value: 1, edited: false },
            nu_fr: { defaultValue: 1, value: 1, edited: false },
            demand_reduction: { defaultValue: 0, value: 0, edited: false },
            eu_grid_limitation: { defaultValue: 0, value: 0, edited: false },
        },
    },
    neighboringCountries: {
        scenario: '',
        importCapacity: {
            DE: { value: 0, edited: false },
            FR: { value: 0, edited: false },
            IT: { value: 0, edited: false },
            AT: { value: 0, edited: false },
        },
        availabilityOfTech: {
            fossil: 'avg',
            kernenergie: 'avg',
            carbon_neutral: 'avg',
            wind_and_pv: 'avg',
        },
    },
    switzerland: {
        scenario: '',
        speicherReserve: 1.8,
        monthlyAdditionalInflow: 0.2,
        installedCapacity: {
            ga_total: { value: 0, edited: false },
            oth_fossil_total: { value: 0, edited: false },
            nu: { value: 0, edited: false },
            bc_total: { value: 0, edited: false },
            gtp: { value: 0, edited: false },
            ccs_fossil: { value: 0, edited: false },
            ccs_biomasse: { value: 0, edited: false },
            biomasse: { value: 0, edited: false },
            ror: { value: 0, edited: false },
            hs: { value: 0, edited: false },
            hc_total: { value: 0, edited: false },
            wind_off: { value: 0, edited: false },
            ps: { value: 0, edited: false },
            pv: { value: 0, edited: false },
            pv_alpin: { value: 0, edited: false },
            wind_on: { value: 0, edited: false },
            oth_ee_total: { value: 0, edited: false },
        },
        customCapacity: {
            ga_total: [],
            oth_fossil_total: [],
            nu: [],
            bc_total: [],
            gtp: [],
            ccs_fossil: [],
            ccs_biomasse: [],
            biomasse: [],
            ror: [],
            hs: [],
            ps: [],
            pv: [],
            pv_alpin: [],
            wind_on: [],
            oth_ee_total: [],
        },
        powerDemand: {
            klass: {
                value: 0,
                edited: false,
                views: [
                    { type: 'absolute', unit: 'TWh' },
                    {
                        type: 'relative',
                        unit: 'MWh/per capita',
                    },
                ],
            },
            hp: {
                value: 0,
                edited: false,
                views: [
                    { type: 'absolute', unit: 'TWh' },
                    { type: 'relative', unit: '% of heat demand' },
                ],
            },
            ev: {
                value: 0,
                edited: false,
                views: [
                    { type: 'absolute', unit: 'TWh' },
                    {
                        type: 'relative',
                        unit: '% of mobility demand',
                    },
                ],
            },
            h2: {
                value: 0,
                edited: false,
                views: [{ type: 'absolute', unit: 'TWh' }],
            },
        },
        availabilityOfTech: {
            hydro: 'avg',
            pv: 'avg',
            biomasse: 'avg',
            wind: 'avg',
            other_ren: 'avg',
            nuclear: 'avg',
            gas: 'avg',
            other_fossil: 'avg',
            gtp: 'avg',
            ccs_fossil: 'avg',
            fixed: 'avg', // TODO: no slider available, fixed value
        },
        costPrice: 70,
    },
};
export const userSettings = writable(userSettingsInitial);
export const globalIsEdited = derived(userSettings, ($userSettings) => {
    const dunkelflauteEdited = $userSettings.general.general.dunkelflaute !== 0;
    const swissCapacityEdited = Object.values($userSettings.switzerland.installedCapacity).some((d) => d.edited === true);
    const swissDemandEdited = Object.values($userSettings.switzerland.powerDemand).some((d) => d.edited === true);
    const swissAvailabilityEdited = Object.values($userSettings.switzerland.availabilityOfTech).some((d) => d !== 'avg');
    const neighbourAvailabilityEdited = Object.values($userSettings.neighboringCountries.availabilityOfTech).some((d) => d !== 'avg');
    const neighbourImportEdited = Object.values($userSettings.neighboringCountries.importCapacity).some((d) => d.edited === true);
    return [
        dunkelflauteEdited,
        swissCapacityEdited,
        swissDemandEdited,
        swissAvailabilityEdited,
        neighbourAvailabilityEdited,
        neighbourImportEdited,
    ].some((d) => d === true);
});
// these stores are defined separately in order to trigger derived stores
export const scenarioCH = writable(undefined);
export const scenarioINT = writable(undefined);
// user settings are set after scenario stores are updated so that the user settings object contains all the user settings, including selected scenario
scenarioCH.subscribe((d) => userSettings.update((a) => {
    a.switzerland.scenario = d;
    return a;
}));
scenarioINT.subscribe((d) => userSettings.update((a) => {
    a.neighboringCountries.scenario = d;
    return a;
}));
export const scenarios = readable(undefined, (set) => {
    csv(`data/input/scenarios.csv`, (d) => (Object.assign(Object.assign({}, d), { active: d.active === 'True', default: d.default === 'True' }))).then((result) => {
        const ch = result.filter((d) => d.geo === 'CH').map((d) => d.key);
        const int = result.filter((d) => d.geo === 'INT').map((d) => d.key);
        const defaultSelectedScenario = result.find((d) => d.default).key;
        scenarioCH.set(defaultSelectedScenario);
        scenarioINT.set(int[0]);
        set({
            CH: ch,
            INT: int,
        });
        // const ch = result.filter((d) => d.geo === 'CH').map((d) => d.key)
        // const int = result.filter((d) => d.geo === 'INT').map((d) => d.key)
        // scenarioCH.set(ch[0])
        // scenarioINT.set(int[0])
        // set({
        //   CH: ch,
        //   INT: int,
        // })
    });
});
export const selectedTech = writable(undefined);
export const selectedDemandTech = writable(undefined);
export const selectedMonth = writable(undefined);
// export const selectedYear = writable(undefined)
export const selectedTechnology = writable(undefined);
export const selectedYear = writable(2050);
export const lockedYear = writable(2050);
export const selectedYearIsLocked = writable(false);
export const selectedLanguage = writable('en');
export const selectedLCOE = writable('medium');
export const selectedPowerPrice = writable(75);
export const selectedCostView = writable('Investor');
export const techGroups = readable([
    {
        key: 'pv',
        label: 'Solar',
        technologies: ['pv', 'pv_alpin', 'ps'],
        color: 'rgb(255, 216, 93)',
        subGroups: [
            {
                availabilityKey: 'pv',
                label: 'Solar',
                technologies: [{ key: 'ps', label: 'PV Ground', type: 'renewable' }],
            },
            {
                availabilityKey: 'pv',
                label: 'Solar',
                technologies: [{ key: 'pv', label: 'PV Roof', type: 'renewable' }],
            },
            {
                availabilityKey: 'pv',
                label: 'Solar',
                technologies: [
                    { key: 'pv_alpin', label: 'PV Alpin', type: 'renewable' },
                ],
            },
        ],
    },
    {
        key: 'wind',
        label: 'Wind',
        technologies: ['wind_on'],
        color: '#a8d8fb',
        subGroups: [
            {
                availabilityKey: 'wind',
                label: 'Wind',
                technologies: [
                    { key: 'wind_on', label: 'Wind on-shore', type: 'renewable' },
                ],
            },
        ],
    },
    {
        key: 'hydro',
        label: 'Hydro',
        technologies: ['ror', 'hs'],
        color: '#729DD3',
        subGroups: [
            {
                availabilityKey: 'hydro',
                label: 'Hydro',
                technologies: [
                    { key: 'ror', label: 'Run-of-River', type: 'renewable' },
                ],
            },
            {
                availabilityKey: 'hydro',
                label: 'Hydro',
                technologies: [
                    { key: 'hs', label: 'Storage power', type: 'renewable' },
                ],
            },
        ],
    },
    {
        key: 'biomass',
        label: 'Biomass',
        technologies: ['biomasse', 'ccs_biomasse'],
        color: '#59A660',
        subGroups: [
            {
                availabilityKey: 'biomasse',
                label: 'Biomass',
                technologies: [
                    { key: 'biomasse', label: 'Biomass', type: 'renewable' },
                ],
            },
            {
                availabilityKey: 'biomasse',
                label: 'Biomass',
                technologies: [
                    { key: 'ccs_biomasse', label: 'CCS Biomass', type: 'renewable' },
                ],
            },
        ],
    },
    {
        key: 'co2Free',
        label: 'CO2 free',
        technologies: ['gtp', 'ga_total'],
        color: 'rgb(135, 195, 194)',
        subGroups: [
            {
                availabilityKey: 'gtp',
                label: 'Renewable gas',
                technologies: [{ key: 'gtp', label: 'Renewable gas', type: 'co2Free' }],
            },
            {
                availabilityKey: 'gas',
                label: 'Gas',
                technologies: [{ key: 'ga_total', label: 'Gas', type: 'fossil' }],
            },
        ],
    },
    {
        key: 'otherRenewable',
        label: 'Other renewables',
        technologies: ['oth_ee_total'],
        color: '#F7CEA0',
        subGroups: [
            {
                availabilityKey: 'other_ren',
                label: 'Other renewables',
                technologies: [
                    { key: 'oth_ee_total', label: 'Geothermal', type: 'renewable' },
                ],
            },
        ],
    },
    {
        key: 'nu',
        label: 'Kernenergie',
        technologies: ['nu', 'bc_total'],
        color: 'rgb(207, 176, 149)',
        subGroups: [
            {
                availabilityKey: 'nuclear',
                label: 'Kernenergie',
                technologies: [{ key: 'nu', label: 'Kernenergie', type: 'co2Free' }],
            },
            {
                availabilityKey: 'nuclear',
                label: 'Kernenergie',
                technologies: [
                    { key: 'bc_total', label: 'New nuclear', type: 'co2Free' },
                ],
            },
        ],
    },
    {
        key: 'fossil',
        label: 'Fossil',
        technologies: ['oth_fossil_total', 'ccs_fossil', 'hc_total'],
        color: '#7B6F71',
        subGroups: [
            {
                availabilityKey: 'other_fossil',
                label: 'Other fossil',
                technologies: [
                    { key: 'oth_fossil_total', label: 'Other fossil', type: 'fossil' },
                ],
            },
            {
                availabilityKey: 'ccs_fossil',
                label: 'CCS Fossil',
                technologies: [
                    { key: 'ccs_fossil', label: 'CCS Fossil', type: 'co2Free' },
                ],
            },
            {
                availabilityKey: 'hc_total',
                label: 'Hard Coal',
                technologies: [{ key: 'hc_total', label: 'Hard coal', type: 'fossil' }],
            },
        ],
    },
]);
export const stressors = readable([
    {
        key: 'pv_wind_hydro_generation',
        values: [0, 1, 2, 3, 4],
        min: 0,
        max: 4,
        step: 1,
        markers: [0, 1, 2, 3, 4],
    },
    {
        key: 'temperature',
        values: [0, 1, 2, 3, 4],
        min: 0,
        max: 4,
        step: 1,
        markers: [0, 1, 2, 3, 4],
    },
    {
        key: 'gas_availability',
        values: [0.6, 0.7, 0.8, 0.9, 1],
        min: 0.6,
        max: 1,
        step: 0.1,
        markers: [0.6, 0.7, 0.8, 0.9, 1],
    },
    {
        key: 'nu_fr',
        values: [0.2, 0.4, 0.6, 0.8, 1],
        min: 0.2,
        max: 1,
        step: 0.2,
        markers: [0.2, 0.4, 0.6, 0.8, 1],
    },
    // {
    //   key: 'demand_reduction',
    //   values: [-0.2, -0.15, -0.1, -0.05, 0],
    //   min: -0.2,
    //   max: 0,
    //   step: 0.05,
    //   markers: [-0.2, -0.15, -0.1, -0.05, 0],
    // },
    {
        key: 'eu_grid_limitation',
        values: [0, 0.2, 0.4, 0.6, 0.8],
        min: 0,
        max: 0.8,
        step: 0.2,
        markers: [0, 0.2, 0.4, 0.6, 0.8],
    },
]);
// export const inputData = derived(
//   [scenarioCH, scenarioINT],
//   ([$scenarioCH, $scenarioINT], set) => {
//     if (!$scenarioCH || !$scenarioINT) {
//       return
//     }
//     loadData($scenarioCH, $scenarioINT).then((data) => set(data))
//     // see https://github.com/sveltejs/svelte/issues/2118
//     return () => {
//       set = () => {}
//     }
//   }
// )
export const inputData = writable(undefined);
inputData.subscribe((input) => {
    if (!input) {
        return;
    }
    input.then((inp) => {
        userSettings.update((current) => {
            const tmp = current;
            // copy capacity values to custom values so that they can be adjusted without affecting the original scenario values
            Object.keys(tmp.switzerland.installedCapacity).forEach((key) => {
                tmp.switzerland.customCapacity[key] = inp.years.map((d) => d.input.CH.capacity.scenario[key]);
            });
            return tmp;
        });
    });
});
export const calculatedData = derived([inputData, userSettings], ([$inputData, $userSettings], set) => {
    if (!$inputData) {
        return undefined;
    }
    $inputData.then((inp) => {
        console.time('calculate');
        const calculatedData = inp;
        const year2023 = inp.years.find((d) => d.year === 2023);
        calculatedData.years = inp.years.map((year, i) => {
            const newYear = year;
            newYear.calculated.capacity = calculateCapacity($userSettings.switzerland.installedCapacity, $userSettings.switzerland.customCapacity, year);
            newYear.calculated.demand = calculateAnnualDemand($userSettings.switzerland.powerDemand, year);
            newYear.calculated.demandLosses = calculatedDemandLosses(year);
            year.months = year.months.map((month) => {
                const newMonth = month;
                newMonth.calculated.importCapacity = calculateImportCapacity($userSettings.neighboringCountries.importCapacity, $userSettings.general.stressors.eu_grid_limitation, month);
                newMonth.calculated.importEnergy = calculateImportEnergy(month);
                newMonth.calculated.availability = calculateAvailability($userSettings.switzerland.availabilityOfTech, $userSettings.neighboringCountries.availabilityOfTech, $userSettings.general.stressors, month);
                newMonth.calculated.availabilityNoStressor =
                    calculateAvailabilityNoStressor($userSettings.switzerland.availabilityOfTech, $userSettings.neighboringCountries.availabilityOfTech, $userSettings.general.general.dunkelflaute, month);
                newMonth.calculated.production = calculateProduction(month);
                newMonth.calculated.monthlyCapacity = calculateMonthlyCapacity(month);
                // newMonth.calculated.co2 = calculateCO2(month)
                newMonth.calculated.demand = calculateMonthlyDemand(month, $userSettings.general.stressors, inp.temperature);
                // newMonth.calculated.demandNoStressor =
                //   calculateMonthlyDemandNoStressor(month)
                newMonth.calculated.totalProduction = calculateTotalProduction(month);
                newMonth.calculated.totalDemand = calculateTotalDemand(month);
                newMonth.calculated.surplus = calculateSurplus(month);
                newMonth.calculated.totalNeighborSurplus =
                    calculateTotalNeighborSurplus(month);
                newMonth.calculated.totalSurplus = calculateTotalSurplus(month);
                newMonth.calculated.energyDeficit = calculateEnergyDeficit(month);
                newMonth.calculated.importPotential = calculateImportPotential(month);
                newMonth.calculated.maxImportability =
                    calculateMaxImportability(month);
                newMonth.calculated.CH_totalSurplus = calculateCHTotalSurplus(month);
                newMonth.calculated.CH_totalSurplusWithoutImport =
                    calculateCHTotalSurplus(month);
                newMonth.calculated.selfSufficientDays =
                    calculateSelfSufficientDays(month);
                // takes into account the New Gas approach
                newMonth.calculated.generation = calculateGeneration(month, inp.generationStack, inp.speicherReserve, inp.years[i - 1]);
                return newMonth;
            });
            // speicher reserve is a running calculation and must be done separately, as it depends on the previous month. It is calculated for the month level
            // calculateSpeicherReserve(newYear)
            newYear.calculated.minSelfSufficientDays =
                calculateMinAnnualSelfSufficientDays(year);
            newYear.calculated.production = calculateAnnualProduction(year);
            newYear.calculated.generation = calculateAnnualGeneration(year);
            newYear.calculated.cost = calculateAnnualCost(year, inp.years[i - 1], year2023, calculatedData.years, $userSettings.switzerland.costPrice);
            return newYear;
        });
        console.timeEnd('calculate');
        set(calculatedData);
        return calculatedData;
    });
});
export const calculatedDataNonReactive = function (inputData, userSettings) {
    if (!inputData) {
        return undefined;
    }
    console.time('calculate non-reactive');
    const calculatedData = inputData;
    const year2023 = inputData.years.find((d) => d.year === 2023);
    calculatedData.years = inputData.years.map((year, i) => {
        const newYear = year;
        newYear.calculated.capacity = calculateCapacity(userSettings.switzerland.installedCapacity, userSettings.switzerland.customCapacity, year);
        newYear.calculated.demand = calculateAnnualDemand(userSettings.switzerland.powerDemand, year);
        newYear.calculated.demandLosses = calculatedDemandLosses(year);
        year.months = year.months.map((month) => {
            const newMonth = month;
            newMonth.calculated.importCapacity = calculateImportCapacity(userSettings.neighboringCountries.importCapacity, userSettings.general.stressors.eu_grid_limitation, month);
            newMonth.calculated.importEnergy = calculateImportEnergy(month);
            newMonth.calculated.availability = calculateAvailability(userSettings.switzerland.availabilityOfTech, userSettings.neighboringCountries.availabilityOfTech, userSettings.general.stressors, month);
            newMonth.calculated.availabilityNoStressor =
                calculateAvailabilityNoStressor(userSettings.switzerland.availabilityOfTech, userSettings.neighboringCountries.availabilityOfTech, userSettings.general.general.dunkelflaute, month);
            newMonth.calculated.production = calculateProduction(month);
            newMonth.calculated.monthlyCapacity = calculateMonthlyCapacity(month);
            // newMonth.calculated.co2 = calculateCO2(month)
            newMonth.calculated.demand = calculateMonthlyDemand(month, userSettings.general.stressors, inputData.temperature);
            // newMonth.calculated.demandNoStressor =
            //   calculateMonthlyDemandNoStressor(month)
            newMonth.calculated.totalProduction = calculateTotalProduction(month);
            newMonth.calculated.totalDemand = calculateTotalDemand(month);
            newMonth.calculated.surplus = calculateSurplus(month);
            newMonth.calculated.totalNeighborSurplus =
                calculateTotalNeighborSurplus(month);
            newMonth.calculated.totalSurplus = calculateTotalSurplus(month);
            newMonth.calculated.energyDeficit = calculateEnergyDeficit(month);
            newMonth.calculated.importPotential = calculateImportPotential(month);
            newMonth.calculated.maxImportability = calculateMaxImportability(month);
            newMonth.calculated.CH_totalSurplus = calculateCHTotalSurplus(month);
            newMonth.calculated.CH_totalSurplusWithoutImport =
                calculateCHTotalSurplus(month);
            newMonth.calculated.selfSufficientDays =
                calculateSelfSufficientDays(month);
            // takes into account the New Gas approach
            newMonth.calculated.generation = calculateGeneration(month, inputData.generationStack, inputData.speicherReserve, inputData.years[i - 1]);
            return newMonth;
        });
        // speicher reserve is a running calculation and must be done separately, as it depends on the previous month. It is calculated for the month level
        // calculateSpeicherReserve(newYear)
        newYear.calculated.minSelfSufficientDays =
            calculateMinAnnualSelfSufficientDays(year);
        newYear.calculated.production = calculateAnnualProduction(year);
        newYear.calculated.generation = calculateAnnualGeneration(year);
        newYear.calculated.cost = calculateAnnualCost(year, inputData.years[i - 1], year2023, calculatedData.years, userSettings.switzerland.costPrice);
        return newYear;
    });
    console.timeEnd('calculate non-reactive');
    return calculatedData;
};
export const maxMaxCapacity = derived([calculatedData, techGroups], ([$calculatedData, $techGroups]) => Math.max(...$techGroups.map((tg) => sum($calculatedData.getMonthlyTimeSeries((month) => sum(Object.entries(month.calculated.monthlyCapacity.CH).filter((d) => tg.technologies.includes(d[0])), (d) => d[1].max))))));
export const cloned1 = writable(undefined);
export const cloned2 = writable(undefined);
