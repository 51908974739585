<script>
  import NetBenefitChart from './NetBenefitChart.svelte'
  import NetBenefitLabels from './NetBenefitLabels.svelte'
  import CostIncomeChart from './CostIncomeChart.svelte'
  import { LayerCake, Svg } from 'layercake'
  import { max, extent } from 'd3-array'
  import { scaleBand, scaleOrdinal } from 'd3-scale'
  import { selectedCostView } from '../../stores'
  import Legend from './Legend.svelte'
  import { _ } from 'svelte-i18n'
  import SortIcon from './SortIcon.svelte'
  import { selectedYear } from '../../stores'
  import { trueCosts } from '../../costCalculation.js'

  let sortBy = 'netBenefit'
  let sortDirection = 'desc'

  $: trueCostData = Object.values(
    $trueCosts.years.find((d) => d.year === $selectedYear).trueCost.technologies
  )
    .filter((d) => !['T3', 'T16', 'T20', 'T19', 'T18'].includes(d.key))
    .map((d) => {
      const social =
        $selectedCostView === 'Investor'
          ? 0
          : d.calculated.netBenefit.lcoeMWh.system +
            d.calculated.netBenefit.lcoeMWh.external

      const cost =
        d.calculated.netBenefit.lcoeMWh.investorFixed +
        d.calculated.netBenefit.lcoeMWh.investorVariable +
        social

      return {
        technology: d.key,
        netBenefit:
          (d.key === 'T13'
            ? d.calculated.netBenefit.valueMWh
            : d.calculated.income) - cost,
        income:
          d.key === 'T13'
            ? d.calculated.netBenefit.valueMWh
            : d.calculated.income,
        social,
        invFix: d.calculated.netBenefit.lcoeMWh.investorFixed,
        invVar: d.calculated.netBenefit.lcoeMWh.investorVariable,
        cost,
        nr: d.key,
      }
    })
    .map((d, _, arr) => {
      if (d.technology === 'T13' && $selectedCostView === 'Social') {
        d.social =
          d.social + (d.income - arr.find((d) => d.technology === 'T12').income)

        d.netBenefit =
          d.income -
          d.cost -
          (d.income - arr.find((d) => d.technology === 'T12').income)
      }

      return d
    })
    .sort((a, b) => {
      if (sortBy === 'netBenefit') {
        return sortDirection === 'desc'
          ? b.netBenefit - a.netBenefit
          : a.netBenefit - b.netBenefit
      } else if (sortBy === 'technology') {
        return sortDirection === 'desc'
          ? b.technology.localeCompare(a.technology)
          : a.technology.localeCompare(b.technology)
      } else if (sortBy === 'costIncome') {
        if ($selectedCostView === 'Investor') {
          return sortDirection === 'desc'
            ? b.invFix + b.invVar - (a.invFix + a.invVar)
            : a.invFix + a.invVar - (b.invFix + b.invVar)
        } else {
          return sortDirection === 'desc'
            ? b.invFix + b.invVar + b.social - (a.invFix + a.invVar + a.social)
            : a.invFix + a.invVar + a.social - (b.invFix + b.invVar + b.social)
        }
      }
    })
</script>

<div class="container">
  <div class="wrapper">
    <div class="chart-container-wrapper">
      <h4
        class="chart-title"
        class:active-sort={sortBy === 'costIncome'}
        on:click={() => {
          sortDirection =
            sortBy === 'costIncome'
              ? sortDirection === 'desc'
                ? 'asc'
                : 'desc'
              : 'desc'
          sortBy = 'costIncome'
        }}
      >
        {$_('page.sections.new.costVsIncome')} <span class="unit">CHF/MWh</span>
        {#if sortBy === 'costIncome'}
          <SortIcon {sortDirection} />
        {/if}
      </h4>
      <div class="chart-container">
        <LayerCake
          data={trueCostData}
          padding={{ top: 20, right: 80, bottom: 10, left: 20 }}
          yScale={scaleBand().paddingInner([0.1]).round(true)}
          xDomain={[0, max(trueCostData, (d) => Math.max(d.cost, d.income))]}
          zScale={scaleOrdinal()}
          y="technology"
        >
          <Svg>
            <defs>
              <pattern
                id="diagonal-hatch-income"
                patternUnits="userSpaceOnUse"
                width="4"
                height="4"
              >
                <path
                  d="M-1,1 l2,-2
                    M0,4 l4,-4
                    M3,5 l2,-2"
                  style="stroke: steelblue"
                />
              </pattern>
              <pattern
                id="diagonal-hatch-income-loss"
                patternUnits="userSpaceOnUse"
                width="4"
                height="4"
              >
                <path
                  d="M-1,1 l2,-2
                    M0,4 l4,-4
                    M3,5 l2,-2"
                  style="stroke: red"
                />
              </pattern>
            </defs>
            <CostIncomeChart />
          </Svg>
        </LayerCake>
      </div>
    </div>

    <div class="chart-container-wrapper">
      <h4
        class="chart-title"
        class:active-sort={sortBy === 'technology'}
        on:click={() => {
          sortDirection =
            sortBy === 'technology'
              ? sortDirection === 'desc'
                ? 'asc'
                : 'desc'
              : 'desc'
          sortBy = 'technology'
        }}
      >
        {$_('page.sections.charts.cost.income.production_technology')}
        {#if sortBy === 'technology'}
          <SortIcon {sortDirection} />
        {/if}
      </h4>
      <div class="chart-container">
        <LayerCake
          data={trueCostData}
          padding={{ top: 20, right: 20, bottom: 10, left: 20 }}
          yScale={scaleBand().paddingInner([0.05]).round(true)}
          y="technology"
        >
          <Svg>
            <NetBenefitLabels />
          </Svg>
        </LayerCake>
      </div>
    </div>

    <div class="chart-container-wrapper">
      <h4
        class="chart-title"
        class:active-sort={sortBy === 'netBenefit'}
        on:click={() => {
          sortDirection =
            sortBy === 'netBenefit'
              ? sortDirection === 'desc'
                ? 'asc'
                : 'desc'
              : 'desc'
          sortBy = 'netBenefit'
        }}
      >
        {$_('page.sections.new.netBenefit')} <span class="unit">CHF/MWh</span>
        {#if sortBy === 'netBenefit'}
          <SortIcon {sortDirection} />
        {/if}
      </h4>

      <div class="chart-container">
        <LayerCake
          data={trueCostData}
          padding={{ top: 20, right: 50, bottom: 10, left: 40 }}
          yScale={scaleBand().paddingInner([0.1]).round(true)}
          xDomain={extent(trueCostData, (d) => d.netBenefit)}
          y="technology"
          x="netBenefit"
        >
          <Svg>
            <NetBenefitChart />
          </Svg>
        </LayerCake>
      </div>
    </div>
  </div>
</div>

<style>
  .container {
    width: 100%;
    height: 100%;
  }

  h4 {
    cursor: pointer;
    user-select: none;
  }

  h4:hover {
    text-decoration: underline;
  }

  .active-sort {
    text-decoration: underline;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 180px 1fr;
    height: 100%;
    width: 100%;
  }

  .chart-container {
    width: 100%;
    height: calc(100% - 20px);
    /* border: 1px solid var(--color-blue-500); */
  }
</style>
