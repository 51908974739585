<script>
  import { format } from 'd3-format'
  import { _ } from 'svelte-i18n'
  import Help from '../../components/Help.svelte'
  import { hsl } from 'd3-color'

  export let value
  export let label
  export let color = 'black'
  export let nestedKeys = undefined
  export let nestedI18nKey = undefined
  export let formatPattern = ',.2f'
  export let unit = 'TWh'
  export let hideNested = undefined
  export let cssVar

  const f = format(formatPattern)

  $: textColor =
    color === 'black' ? 'black' : hsl(color).l < 0.5 || isNaN(hsl(color).l)
</script>

<div class="item">
  <span
    class="label"
    class:nested-parent={nestedKeys !== undefined}
    style="color: {nestedKeys ? color : hsl(color).darker()};">{label}</span
  >
  {#if value === undefined}
    <span class="value" />
  {:else if nestedKeys}
    <div class="nestevalues">
      {#each nestedKeys as key}
        <div
          class="item"
          class:hide={hideNested !== undefined && hideNested.includes(key)}
        >
          <span
            class="label nested"
            style="color: {color === 'black' ? 'none' : color}"
            >{$_(`${nestedI18nKey}.${key}`)}</span
          >
          <span
            class="value"
            style="border-right: 4px solid {color === 'black'
              ? 'none'
              : color}; color: black;"
          >
            {#if value[key] !== undefined}
              {value[key] > 0 ? f(value[key]) : '--'}
              {unit}
            {/if}
          </span>
        </div>
      {/each}
    </div>
  {:else}
    <span
      class="value"
      style="border-right: 4px solid {color === 'black'
        ? 'none'
        : color}; color: {textColor};"
      >{value > 0 ? f(value) : '--'} {unit}</span
    >
  {/if}
</div>

<style>
  div {
    text-align: right;
  }

  .hide {
    visibility: hidden;
  }

  .item {
    margin-bottom: 1px;
  }

  .nested-parent {
    margin-right: 94px;
  }

  .nested {
    font-style: italic;
    font-weight: normal;
  }

  .value {
    font-weight: normal;
    display: inline-block;
    width: 100px;
    padding: 0 var(--size-1);
    /* border-radius: 3px; */
  }

  /* @media (min-width: 1024px) {
    span {
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
  } */
</style>
