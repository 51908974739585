<script>
  import {
    selectedLCOE,
    selectedPowerPrice,
    selectedCostView,
  } from './../../stores.ts'
  import {
    scenarios,
    scenarioCH,
    scenarioINT,
    calculatedData,
    calculatedDataNonReactive,
    userSettings,
    userSettingsInitial,
    compare1,
    compare2,
    cloned1,
    cloned2,
    globalIsEdited,
  } from './../../stores'
  import { LayerCake, Svg } from 'layercake'
  import { loadData } from '../../fetchers'
  import { _ } from 'svelte-i18n'
  import GenerationCost from './GenerationCost.svelte'
  import { max } from 'd3-array'
  import Loader from '../../ui/Loader.svelte'
  import { calculateTrueCost } from '../../costCalculation.js'
  import clone from 'clone'

  const padding = { top: 60, right: 40, bottom: 10, left: 40 }

  let maxValueCompare1 = { generation: 0, cost: 0, demand: 0 }
  let maxValueCurrent = { generation: 0, cost: 0, demand: 0 }
  let maxValueCompare2 = { generation: 0, cost: 0, demand: 0 }

  $: maxGeneration = max([
    maxValueCompare1.generation,
    maxValueCurrent.generation,
    maxValueCompare2.generation,
  ])

  $: maxDemand = max([
    maxValueCompare1.demand,
    maxValueCurrent.demand,
    maxValueCompare2.demand,
  ])

  $: maxCost = max([
    maxValueCompare1.cost,
    maxValueCurrent.cost,
    maxValueCompare2.cost,
  ])

  // function prepData1(input, userSettings) {
  //   const userSettingsClone = clone(userSettings)

  //   $cloned1 = clone(input)

  //   Object.keys(userSettingsClone.switzerland.installedCapacity).forEach(
  //     (key) => {
  //       userSettingsClone.switzerland.customCapacity[key] = $cloned1.years.map(
  //         (d) => d.input.CH.capacity.scenario[key]
  //       )
  //     }
  //   )

  //   return calculatedDataNonReactive($cloned1, userSettingsClone)
  // }

  function prepData1(input, userSettings) {
    //     if (window.Worker) {
    // const myWorker = new Worker(".js");
    // }

    const userSettingsClone = clone(userSettings)

    // if ($cloned1 === undefined) {
    //   $cloned1 = clone(input)
    // }
    $cloned1 = input

    Object.keys(userSettingsClone.switzerland.installedCapacity).forEach(
      (key) => {
        userSettingsClone.switzerland.customCapacity[key] = $cloned1.years.map(
          (d) => d.input.CH.capacity.scenario[key]
        )
      }
    )

    return calculatedDataNonReactive($cloned1, userSettingsClone)
  }

  function prepData2(input, userSettings) {
    const userSettingsClone = clone(userSettings)

    // if ($cloned2 === undefined) {
    // $cloned2 = clone(input)
    $cloned2 = input
    // }

    Object.keys(userSettingsClone.switzerland.installedCapacity).forEach(
      (key) => {
        userSettingsClone.switzerland.customCapacity[key] = $cloned2.years.map(
          (d) => d.input.CH.capacity.scenario[key]
        )
      }
    )

    return calculatedDataNonReactive($cloned2, userSettingsClone)
  }
</script>

<div class="container">
  <div class="scenario-selectors">
    <div>
      <!-- <label for="scenario-compare1"
        >{$_(
          'page.sections.charts.cost.scenario_comparison.select_scenario'
        )}:</label
      > -->
      <select bind:value={$compare1} id="scenario-compare1">
        {#if !$compare1}
          <option value={null} selected
            >{$_(
              'page.sections.charts.cost.scenario_comparison.select_scenario'
            )}</option
          >
        {/if}
        {#each $scenarios.CH as scenario}
          <option value={scenario}
            >{$_(
              `page.sections.userControls.switzerland.scenario.scenarios.${scenario}.label`
            )}</option
          >
        {/each}
      </select>
    </div>

    <div class="current-scenario">
      <div>
        {#if $globalIsEdited}
          {$_(
            'page.sections.userControls.switzerland.scenario.scenarios.custom.label'
          )}
        {:else}
          {$_(
            `page.sections.userControls.switzerland.scenario.scenarios.${$scenarioCH}.label`
          )}
        {/if}
      </div>
    </div>

    <div>
      <!-- <label for="scenario-compare1"
        >{$_(
          'page.sections.charts.cost.scenario_comparison.select_scenario'
        )}:</label
      > -->
      <select id="scenario-compare2" bind:value={$compare2}>
        {#if !$compare2}
          <option value={null} selected
            >{$_(
              'page.sections.charts.cost.scenario_comparison.select_scenario'
            )}</option
          >
        {/if}
        {#each $scenarios.CH as scenario}
          <option value={scenario}
            >{$_(
              `page.sections.userControls.switzerland.scenario.scenarios.${scenario}.label`
            )}</option
          >
        {/each}
      </select>
    </div>
  </div>

  <div class="topics">
    <div class="scenario-result">
      {#if !$compare1}
        <span class="no-scenario">
          {$_(
            'page.sections.charts.cost.scenario_comparison.no_scenario_selected'
          )}
        </span>
      {:else}
        {#await loadData($compare1, $scenarioINT)}
          <Loader />
        {:then data}
          {#key [$calculatedData, $selectedPowerPrice, $selectedLCOE, $selectedCostView]}
            <LayerCake
              {padding}
              data={calculateTrueCost(
                prepData1(data, userSettingsInitial),
                $selectedLCOE,
                $selectedPowerPrice
              )}
            >
              <Svg>
                <defs>
                  <pattern
                    id="diagonalHatch-import"
                    patternUnits="userSpaceOnUse"
                    width="4"
                    height="4"
                  >
                    <path
                      d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
                      style="stroke:#000; stroke-width:1"
                    />
                  </pattern>
                </defs>
                <GenerationCost
                  scenario={$compare1}
                  bind:maxValues={maxValueCompare1}
                  {maxGeneration}
                  {maxDemand}
                  {maxCost}
                />
              </Svg>
            </LayerCake>
          {/key}
        {:catch error}
          <div>{error}</div>
        {/await}
      {/if}
    </div>

    <div class="scenario-current">
      {#key [$calculatedData, $selectedPowerPrice, $selectedLCOE, $selectedCostView]}
        <LayerCake
          {padding}
          data={calculateTrueCost(
            $calculatedData,
            $selectedLCOE,
            $selectedPowerPrice
          )}
        >
          <Svg>
            <defs>
              <pattern
                id="diagonalHatch-import"
                patternUnits="userSpaceOnUse"
                width="4"
                height="4"
              >
                <path
                  d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
                  style="stroke:#000; stroke-width:1"
                />
              </pattern>
            </defs>
            <GenerationCost
              scenario={$scenarioCH}
              bind:maxValues={maxValueCurrent}
              {maxGeneration}
              {maxDemand}
              {maxCost}
            />
          </Svg>
        </LayerCake>
      {/key}
    </div>

    <div class="scenario-result">
      {#if !$compare2}
        <span class="no-scenario"
          >{$_(
            'page.sections.charts.cost.scenario_comparison.no_scenario_selected'
          )}</span
        >
      {:else}
        {#await loadData($compare2, $scenarioINT)}
          <Loader />
        {:then data}
          {#key [$calculatedData, $selectedPowerPrice, $selectedLCOE, $selectedCostView]}
            <LayerCake
              data={calculateTrueCost(
                prepData2(data, userSettingsInitial),
                $selectedLCOE,
                $selectedPowerPrice
              )}
              {padding}
            >
              <Svg>
                <defs>
                  <pattern
                    id="diagonalHatch-import"
                    patternUnits="userSpaceOnUse"
                    width="4"
                    height="4"
                  >
                    <path
                      d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
                      style="stroke:#000; stroke-width:1"
                    />
                  </pattern>
                </defs>
                <GenerationCost
                  scenario={$compare2}
                  bind:maxValues={maxValueCompare2}
                  {maxGeneration}
                  {maxDemand}
                  {maxCost}
                />
              </Svg>
            </LayerCake>
          {/key}
        {:catch error}
          <div>{error}</div>
        {/await}
      {/if}
    </div>
  </div>

  <div class="legend">
    {$_('page.sections.charts.cost.scenario_comparison.legend_label')}
  </div>
</div>

<style>
  select {
    outline: none;
  }

  .scenario-result {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 56px 420px 20px;
  }

  .scenario-selectors {
    background: var(--color-grey-100);
    display: grid;
    align-items: center;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;

    width: 100%;
    height: 100%;
  }

  .current-scenario {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 2px solid white;
    border-right: 2px solid white;
    font-weight: bold;
  }

  .topics {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    width: 100%;
    height: 100%;
  }

  .no-scenario {
    font-style: italic;
    font-size: var(--scale-2);
  }

  .legend {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--scale-2);
  }
</style>
