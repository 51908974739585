﻿<script>
  import Collapsable from './../../components/Collapsable.svelte'

  export let title
</script>

<Collapsable {title}>
  <Collapsable title="Photovoltaik">
    <h3>Auf einen Blick</h3>
    <ul>
      <li>
        Erneuerbare Energiequelle mit dem derzeit grössten Ausbaupotential in
        der Schweiz.
      </li>

      <li>Ein Grossteil der Produktion fällt in den Sommermonaten an.</li>

      <li>
        Bei sehr hoher installierter Leistung sind Langzeitspeicher oder eine
        Abregelung (Nichtnutzung von überschüssig produziertem Strom) nötig.
      </li>
    </ul>
    <h3>Photovoltaik als Technologie</h3>

    <p>
      Photovoltaikanlagen können auf Gebäuden, bestehender Infrastruktur oder
      als Freiflächenanlagen installiert werden. Innerhalb der letzten 25 Jahre
      sind die Produktionskosten für Solarmodule sehr stark gefallen, was die
      Technologie ökonomisch attraktiver macht. Photovoltaikanlagen haben eine
      Lebensdauer von 20-30 Jahren. 1 MW Photovoltaik benötigt knapp 10000 m2
      Fläche. Über das Jahr gesehen können damit etwa 250 Haushalte versorgt
      werden.
    </p>

    <p>
      Solarenergie hat hohe Investitions- oder Fixkosten, aber nur geringe
      variable Kosten. Das bedeutet, dass zum Bau der Anlagen die grössten
      Investitionen nötig werden, während über die Jahre nur geringe weitere
      Kosten zu erwarten sind.
    </p>

    <p>
      Photovoltaik ist eine nicht abrufbare Stromquelle mit einem ausgeprägten
      Tages- und Jahresprofil. Die meiste Sonneneinstrahlung und somit auch
      Stromproduktion findet zu gewissen Jahres- und Tageszeiten, wie im Sommer
      und um die Mittagszeit, statt. Sie ist nicht abrufbar, da dieses
      Produktionsprofil nicht der Stromnachfrage angepasst werden kann. Gerade
      bei hohen Anteilen von Solarstrom im Strommix kann es daher für
      Stromversorgungssicherheit wichtig werden, überschüssigen Strom aus diesen
      Stosszeiten in Kurz- und Langzeitspeichern zu speichern.
    </p>
    <h3>Photovoltaik in der Schweiz</h3>
    <p>
      Im Jahresschnitt ist die Photovoltaik an einem durchschnittlichen Standort
      in der in der Schweiz rund 10% der Zeit verfügbar. Durch kurze Tage und
      flachen Lichteinfall ist die Stromproduktion im Winter stark beschränkt.
      Veränderungen des Stellwinkels können die Stromproduktion im Winter
      erhöhen.
    </p>

    <p>
      Das nutzbare Potential von Photovoltaik auf Dächern und Fassaden wird vom
      Bundesamt für Energie auf 67 TWh geschätzt (1). Freiflächenanlagen stossen
      aus Landschaftsschutzgründen auf Widerstand, hier wurde ein geringes
      Potential hinterlegt, obwohl sie wirtschaftlicher als Dachanlagen sind.
      Grundsätzlich liegt somit das theoretische Potential in der Schweiz noch
      deutlich höher.
    </p>

    <p>...</p>

    <ol>
      <li>
        <a
          href="https://www.bfe.admin.ch/bfe/de/home/news-und-medien/medienmitteilungen/mm-test.msg-id-74641.html"
          title="“Schweizer Hausdächer und -Fassaden könnten jährlich 67 TWh Solarstrom produzieren” April 15, 2019"
          target="_blank"
          class="link link--external"
        >
          Bundesamt für Energie, “Schweizer Hausdächer und -Fassaden könnten
          jährlich 67 TWh Solarstrom produzieren” April 15, 2019.</a
        >
      </li>
    </ol>
  </Collapsable>
  <Collapsable title="Photovoltaik Alpin">
    <h3>Auf einen Blick</h3>
    <ul>
      <li>
        Alpine Photovoltaik wird in den Bergen mit meist steilem Winkel
        installiert.
      </li>

      <li>
        Produziert pro Einheit allgemein mehr Strom als Photovoltaik in
        niedrigeren Lagen - allerdings ist in den Bergen auch weniger Fläche
        verfügbar.
      </li>
    </ul>
    <h3>Photovoltaik Alpin als Technologie</h3>
    <p>
      Photovoltaik im alpinen Gebiet, oder PV alpin, ist mit einem steilen
      Winkel installiert. So kann bei tiefstehender Sonne mehr Elektrizität und
      bei hochstehender Sonne weniger Elektrizität als bei Anlagen mit weniger
      steilen Winkeln produziert werden. Photovoltaikanlagen haben eine
      Lebensdauer von 20-30 Jahren.
    </p>

    <p>
      Es gibt eine höhere Stromproduktion als Photovoltaik in tieferen Lagen, da
      es insbesondere im Winter mehr Sonnenstunden und übers ganze Jahr kühlere
      Witterung gibt. Insgesamt weist PV alpin insgesamt ein deutlich
      ausgeglicheneres Jahresprofil auf, bei dem bis zur Hälfte der
      Produktionsmenge im Winter anfällt. Allerdings ist aus sozialpolitischen
      und naturschutzbedingten Gründen auch weniger wirtschaftlich nutzbare
      Fläche verfügbar.
    </p>

    <p>
      PV alpin hat hohe Investitions- oder Fixkosten, aber nur geringe variable
      Kosten. Die grössten Ausgaben fallen also zum Bau der Anlagen an, während
      über die Jahre des Betriebs geringe weitere Kosten zu erwarten sind.
    </p>
    <h3>Photovoltaik Alpin in der Schweiz</h3>
    <p>
      Wir nehmen an, dass bis zu 15 TWh/a Solarstrom in den Alpen produziert
      werden können. Das Potential von Freiflächenanlagen in den Alpen hängt
      sehr stark von der Abwägung von Schutz- und Nutzinteressen ab. Das technisch machbare
      Potential für PV alpin ist deutlich höher.
    </p>
  </Collapsable>
  <Collapsable title="Windenergie">
    <h3>Auf einen Blick</h3>
    <p />
    <ul>
      <li>
        Erneuerbare Stromquelle, die besonders durch ihre Produktion im Winter
        in der Schweiz strategisch wertvoll sein kann.
      </li>
      <li>
        Windkraft ist eine dargebotsabhängige Energieform – ihre Leistung gilt
        nicht als gesichert, da das Wehen des Windes nicht von aussen
        kontrolliert werden kann.
      </li>
    </ul>
    <h3>Windenergie als Technologie</h3>
    <p>
      Windkraftanlagen sind meist dezentral in Windparks mit 5-40 Anlagen
      angesiedelt. Innerhalb der letzten 25 Jahre gab es grosse Fortschritte in
      der Grösse und somit der Leistung einzelner Windkraftanlagen: von ungefähr
      0.5 MW ist diese auf bis zu 9 MW pro Anlage gestiegen. Windkraftanlagen
      haben eine Lebensdauer von 20-30 Jahren.
    </p>
    <p>
      Windenergie hat hohe Investitions- oder Fixkosten, aber nur geringe
      variable Kosten. Das bedeutet, dass anfangs zum Bau der Anlagen die
      grössten Ausgaben anfallen, während über die Jahre nur geringe weitere
      Kosten zu erwarten sind.
    </p>
    <p>
      Windkraft ist eine dargebotsabhängige Energieform, d.h. ihre Leistung gilt
      nicht als gesichert, da das Wehen des Windes gut prognostiziert, aber
      nicht von aussen kontrolliert werden kann. Gleichzeitig muss Strom zu dem
      Zeitpunkt, in dem er produziert wird, genutzt werden. Deshalb sind bei
      hohen Anteilen von Energiequellen wie Wind im Strommix Optionen wie
      Speicherkraft für Stromversorgungssicherheit wichtig.
    </p>
    <h3>Windenergie in der Schweiz</h3>
    <p>
      Im Jahresschnitt ist die Windenergie in der Schweiz rund 15%-20% der Zeit
      verfügbar. Etwa zwei Drittel des Stroms werden im Winterhalbjahr
      produziert. Das nutzbare Potential von Windenergie wird in einer Studie
      von Suisse Eole in Kooperation mit dem Schweizer Bundesamt für Energie auf
      9 TWh/a eingeschätzt (1). Das vom Bund festgelegte Windstromziel bezieht
      sich auf eine Studie von 2012, in der das Potential auf 4.3 TWh/a
      geschätzt wurde (1). Windenergie und Wasserspeicherkraft lassen sich sehr
      gut kombinieren für die Versorgung im Winter. In der Schweiz gibt es bis
      heute aufgrund des Zielkonflikts aus Nutz- und Schutzinteressen nur sehr
      wenige Windkraftanlagen. Ohne Vereinfachung der Bewilligungsverfahren wird
      dies kaum zu ändern sein.
    </p>

    <p>
      Windparks mit einer Produktion von 20 GWh pro Jahr oder mehr (entspricht
      0.02 TWh oder mehr) sind gemäss Energiegesetz von «nationalem Interesse»
      (2). Somit stehen bei der Planung und Bewilligung von Windparks bei einer
      Interessenabwägung Nutz-, Schutz- und andere nationale Interessen auf
      einer Stufe.
    </p>

    <p>…</p>

    <ol>
      <li>
        <a
          href="https://www.bfe.admin.ch/bfe/de/home/versorgung/erneuerbare-energien/windenergie.html"
          title=" Suisse Éole in Kooperation mit dem Bundesamt für Energie, “Windenergiestrategie: Winterstrom und Klimaschutz,” Dezember 6, 2020, Seite 28. "
          target="_blank"
          class="link link--external"
        >
          Suisse Éole in Kooperation mit dem Bundesamt für Energie,
          “Windenergiestrategie: Winterstrom und Klimaschutz,” Dezember 6, 2020,
          Seite 28.
        </a>
      </li>

      <li>
        <a
          href=" https://www.bfe.admin.ch/bfe/de/home/versorgung/erneuerbare-energien/windenergie.html "
          title="Bundesamt für Energie, “Windenergie.”"
          target="_blank"
          class="link link--external"
        >
          Bundesamt für Energie, “Windenergie.”
        </a>
      </li>
    </ol>
  </Collapsable>
  <Collapsable title="Wasserkraft - Laufwasser">
    <h3>Auf einen Blick</h3>
    <ul>
      <li>
        Wasserkraft liefert insgesamt etwa 60% des Schweizer Stroms;
        Laufwasserkraft macht knapp ein Drittel der maximalen Leistung von
        Wasserkraft aus.
      </li>

      <li>
        Laufwasserkraft nutzt das Gefälle von Flussläufen, um Strom zu
        produzieren.
      </li>
    </ul>
    <h3>Laufwasserkraft als Technologie</h3>
    <p>
      Laufwasserkraft ist eine erneuerbare Energiequelle, die die Strömung eines
      Flusses zur Stromerzeugung nutzt. Daher können Laufwasserkraftwerke im
      Gegensatz zu Speicherkraftwerken die Strommenge nicht nach Bedarf
      anpassen. Die produzierte Strommenge kann durch saisonale und
      witterungsbedingte Schwankungen im Verhalten des Flusses beeinflusst
      werden. Etwa alle 40 Jahre sind grosse Ersatzinvestitionen notwendig;
      variable Kosten nach Installation eines Kraftwerks sind aber in der Regel
      gering.
    </p>

    <h3>Laufwasserkraft in der Schweiz</h3>

    <p>
      Das erste Laufwasserkraftwerk wurde bereits 1886 in Betrieb genommen.
      Momentan liefert Wasserkraft knapp 60% des Schweizer Stroms;
      Laufwasserkraft macht etwa 40% dieser Produktion aus (1). Bei dem Betrieb
      und Ausbau von Wasserkraft in der Schweiz gibt es Widerstand von
      Fischereiverbänden und vom Landschafts- und Naturschutz.
    </p>

    <p>
      Das Potential für Laufwasserkraft ist in der Schweiz fast vollkommen
      ausgeschöpft. An den meisten grösseren Flüssen der Schweiz stehen in
      relativ engen Abständen Laufwasserkraftwerke. Der Schweizer
      Wasserwirtschaftsverbund schätzt, dass Wasserkraft bis 2050 bis zu 6.4 TWh
      (etwa 5 TWh davon Laufwasserkraft) abnehmen oder bis zu 8.7 TWh (etwa die
      Hälfte davon Laufwasserkraft) zunehmen wird (2).
    </p>

    <p>...</p>

    <ol>
      <li>
        Bundesamt für Energie, “Schweizerische Gesamtenergiestatistik 2020,”
        Seite 39.
      </li>

      <li>
        <a
          href=" https://www.swv.ch/wp-content/uploads/2018/12/WEL-4-2018_Energieeinbussen_hq.pdf"
          title=" Roger Pfammatter und Nadia Semadeni Wicki, “Energieeinbussen aus Restwasserbestimmungen – Stand und Ausblick,” Wasser Energie Luft 110. Jahrgang, no. 4 (2018): 233–45, Seite 243."
          target="_blank"
          class="link link—external"
        >
          Roger Pfammatter und Nadia Semadeni Wicki, “Energieeinbussen aus
          Restwasserbestimmungen – Stand und Ausblick,” Wasser Energie Luft 110.
          Jahrgang, no. 4 (2018): 233–45, Seite 243.
        </a>
      </li>
    </ol>
  </Collapsable>
  <Collapsable title="Wasserkraft – Speicherkraft">
    <h3>Auf einen Blick</h3>
    <ul>
      <li>
        Wasserkraft ist die älteste Stromquelle in der Schweiz und liefert
        momentan etwa 60% des Schweizer Stroms; Speicherkraft macht etwa zwei
        Drittel der maximalen Leistung von Wasserkraft aus.
      </li>

      <li>
        Speicherkraftwerke sind strategisch wertvoll, da sie gleichzeitig
        erneuerbar und in ihrer Stromproduktion steuerbar sind.
      </li>

      <li>
        Das Potential für den Ausbau von Speicherkraft ist fast ausgeschöpft.
      </li>
    </ul>
    <h3>Speicherkraft als Technologie</h3>
    <p>
      Speicherkraft ist eine erneuerbare Energiequelle, die Stauseen und das
      kontrollierte Ablassen von Wasser zur Stromproduktion nutzt. Bei
      Pumpspeicherkraft wird Wasser von einem niedriger gelegenen Reservoir in
      ein höheres gepumpt wird, um bei Bedarf zur Stromproduktion wieder
      abgelassen zu werden. Diese Art von Speicherkraft kann zum Beispiel
      überschüssigen Solarstrom nutzen, um das Wasser hochzupumpen, und somit
      als Speicher von Solarstrom dienen.
    </p>

    <p>
      Wie bei Laufwasserkraft sind die variablen Kosten während des Betriebs
      eines Kraftwerks bis auf Momente grosser Wartungen gering. Bei
      Pumspeicherkraft fallen höhere variable Kosten an, wegen der Energie, die
      fürs Pumpen benötigt wird.
    </p>
    <h3>Speicherkraft in der Schweiz</h3>
    <p>
      Wasserkraft ist die älteste in der Schweiz genutzte Technologie zur
      Stromerzeugung. Momentan liefert Wasserkraft knapp 60% des Schweizer
      Stroms; Speicherkraft macht etwa 60% dieser Stromproduktion aus (1). Beim
      Betrieb und Ausbau von Wasserkraft in der Schweiz gibt es Widerstand von
      Fischereiverbänden und vom Landschafts- und Naturschutz.
    </p>

    <p>
      Das Potential für Speicherkraft ist in der Schweiz fast vollkommen
      ausgeschöpft. Das meiste Gebirgswasser wird bereits in Speicherkraftwerken
      genutzt, das Ausbaupotential ist daher begrenzt. Der Schweizer
      Wasserwirtschaftsverband schätzt, dass Wasserkraft wegen
      Restwasserbestimmungen und fehlenden Investitionsanreizen bis 2050 bis zu
      etwa 7 TWh (etwa 1 TWh davon Speicherkraft) abnehmen oder bis zu 8.7 TWh
      (etwa die Hälfte davon Speicherkraft) zunehmen wird (2).
    </p>

    <p>...</p>

    <ol>
      <li>
        Bundesamt für Energie, “Schweizerische Gesamtenergiestatistik 2020,”
        Seite 39.
      </li>

      <li>
        <a
          href=" https://www.swv.ch/wp-content/uploads/2018/12/WEL-4-2018_Energieeinbussen_hq.pdf"
          title=" Roger Pfammatter und Nadia Semadeni Wicki, “Energieeinbussen aus Restwasserbestimmungen – Stand und Ausblick,” Wasser Energie Luft 110. Jahrgang, no. 4 (2018): 233–45, Seite 243."
          target="_blank"
          class="link link—external"
        >
          Roger Pfammatter und Nadia Semadeni Wicki, “Energieeinbussen aus
          Restwasserbestimmungen – Stand und Ausblick,” Wasser Energie Luft 110.
          Jahrgang, no. 4 (2018): 233–45, Seite 243.
        </a>
      </li>
    </ol>
  </Collapsable>
  <Collapsable title="Biomasse">
    <h3>Auf einen Blick</h3>
    <ul>
      <li>
        Stromproduktion basiert auf Biogas, Holz, Klärgasanlagen, und auf dem
        biogenen Anteil bei Kehrrichtverbrennung.
      </li>

      <li>
        Das Ausbaupotential ist vor allem aus Gründen der Wirtschaftlichkeit und
        der Verfügbarkeit des Brennstoffs begrenzt.
      </li>
    </ul>
    <h3>Biomasse als Technologie</h3>
    <p>
      Biomasse ist ein Oberbegriff für eine breite Palette an Kraftwerken, die
      Biomasse zur Stromerzeugung einsetzen: Biogas, Holz, Klärgasanlagen, und
      der biogene Anteil bei Kehrrichtverbrennung. Biomasse wird zur
      Stromproduktion vergoren, verbrannt oder vergast.
    </p>
    <h3>Biomasse in der Schweiz</h3>
    <p>
      Das Ausbaupotential von Biomasse ist in der Schweiz aus unterschiedlichen
      Gründen auf maximal 4 TWh begrenzt (1). Ökologisch ist der Einsatz von
      Biomasse zur Stromproduktion sinnvoll, wo sie ohnehin anfällt. Biomasse
      gezielt für die energetische Verwertung zu erzeugen ist jedoch oft kein
      ganzheitlicher Ansatz – ein Wettbewerb zwischen Nahrungsmittelanbau und
      Anbau für Strom- oder Brennstoffproduktion spielt beispielsweise eine
      Rolle.
    </p>

    <p>...</p>

    <ol>
      <li>
        Verband Schweizerischer Elektrizitätsunternehmen, “Strom aus Biomasse,”
        Basiswissen-Dokument (Aarau, January 3, 2020), Seite 3.
      </li>
    </ol>
  </Collapsable>

  <Collapsable title="Gas-to-Power">
    <h3>Auf einen Blick</h3>

    <ul>
      <li>Umfassen Kraftwerke zur Rückverstromung von Wasserstoff.</li>

      <li>
        Können flexibel eingesetzt werden und somit dazu beitragen, den
        Strombedarf im Winter zu decken.
      </li>
    </ul>

    <h3>Gas-to-Power als Technologie</h3>

    <p>
      Gas-to-Power bezeichnet in diesem Modell die Rückverstromung von durch
      erneuerbare Energien hergestelltem Wasserstoff. Dazu gibt es zwei
      Optionen: Erstens können Gaskraftwerke anstelle von fossilem Erdgas
      Wasserstoff oder ein Gemisch aus den beiden Gasen einsetzen, um Strom
      herzustellen. Zweitens können Brennstoffzellen durch eine chemische
      Reaktion aus Wasserstoff und Sauerstoff Strom produzieren.
    </p>

    <p>
      Gaskraftwerke haben ähnliche Eigenschaften wie mit Erdgas betriebene
      Kraftwerke: Ihre Leistung liegt bei ungefähr 400 MW und die Lebensdauer
      der Kraftwerke liegt bei etwa 45 Jahren. Bei der Rückverstromung bleiben
      etwa 40% der ursprünglichen Energiemenge übrig (dies ist der Wirkungsgrad)
      (1).
    </p>

    <p>
      Brennstoffzellen könnten vor allem Mobilität eingesetzt werden, da sie
      klein sind. Es gibt aber Pilotprojekte, die Brennstoffzellen auch in
      grösseren Wasserstoffkraftwerken einsetzen (2).
    </p>
    <h3>Gas-to-Power in der Schweiz</h3>

    <p>
      Zurzeit gibt es keine Gas-to-Power Anlagen für den Betrieb mit Wasserstoff
      in der Schweiz. Allerdings werden diese Anlagen von vielen als eine
      Möglichkeit dazu gesehen, in der Schweiz eine weitere abrufbare
      Stromquelle zu haben. Dies könnte vor allem im Winter wichtig sein, wenn
      weniger Strom durch Photovoltaik produziert wird. Gerade wenn der
      Wirkungsgrad von 40% noch gesteigert wird, könnte Power-to-Gas eine
      grössere Rolle spielen. Im Modell nehmen wir einen Ausbau auf maximal 3 GW
      Gas-to-Power Kapazität an, die etwa 16 TWh Strom pro Jahr produzieren
      können.
    </p>

    <p>…</p>

    <ol>
      <li>
        <a
          href="https://www.handelsblatt.com/technik/energie-umwelt/power-to-gas-gas-aus-wind-und-sonne/5870560.html"
          title="“David Meiländer, “Power to Gas: Gas aus Wind und Sonne”."
          target="_blank"
          class="link link--external"
        >
          David Meiländer, “Power to Gas: Gas aus Wind und Sonne”.
        </a>
      </li>

      <ol>
        <li>
          <a
            href="https://www.ndr.de/nachrichten/mecklenburg-vorpommern/Wasserstoffkraftwerk-Rostock-Neuer-Energieversorger,wasserstoffkraftwerk100.html"
            title="Paul Dimitri, “Wasserstoffkraftwerk Rostock: Neuer Energieversorger?”."
            target="_blank"
            class="link link--external"
            >Paul Dimitri, “Wasserstoffkraftwerk Rostock: Neuer
            Energieversorger?”.
          </a>
        </li>
      </ol>
    </ol>
  </Collapsable>

  <Collapsable title="Geothermie">
    <h3>Auf einen Blick</h3>
    <ul>
      <li>
        Erneuerbare Stromquelle, die in der Schweiz bisher noch nicht genutzt
        wird.
      </li>

      <li>Ist das ganze Jahr über verfügbar.</li>

      <li>
        Das realisierbare Potential wird beim momentanen technischen Stand auf
        etwa 4.4 TWh pro Jahr geschätzt und ist somit niedrig.
      </li>
    </ul>
    <h3>Geothermie als Technologie</h3>
    <p>
      Geothermie kann sowohl im Wärmebereich als auch für die Stromproduktion
      eingesetzt werden. Sie nutzt vor allem Wärmepumpen aber auch Tiefenwärme.
      Da Erdwärme das ganze Jahr verfügbar ist, können Anlagen – bis auf
      Stillstandszeiten während Revisionen – das ganze Jahr über genutzt werden.
      Die Lebensdauer von geothermischen Anlagen beträgt 30-35 Jahre.
    </p>

    <p>
      Geothermie hat hohe Investitions- oder Fixkosten, aber nur geringe
      variable Kosten. Das bedeutet, dass beim Bau der Anlagen die grössten
      Ausgaben anfallen, während im Betrieb nur geringe weitere Kosten zu
      erwarten sind.
    </p>
    <h3>Geothermie in der Schweiz</h3>
    <p>
      Das nutzbare Potential von Geothermie zur Stromproduktion ist in der
      Schweiz theoretisch hoch. Allerdings ist es bei der momentanen Bohrtechnik
      beschränkt und liegt bei rund 4.4 TWh pro Jahr (1). Wäre dieses Potential
      im Jahr 2020 voll ausgeschöpft gewesen, hätte es etwa 7% der gesamten
      Stromnachfrage gedeckt. In der Schweiz gibt es derzeit ein Pilotprojekt <a
        href="https://www.geo-energie-jura.ch/le-projet/"
        title="Geo-Energie Suisse AG in Haute-Sorne"
        target="_blank">Geo-Energie Suisse AG in Haute-Sorne</a
      > mit einer elektrischen Leistung von maximal 5 MW (Stand 2020). Bis auf das
      Pilotprojekt gibt es momentan keine Anlage für Stromerzeugung in der Schweiz
      (2). Geothermieanlagen ohne Wärmepumpen gibt es in der Schweiz momentan beispielsweise
      bei der Nutzung des tiefen Aquifers in Riehen und bei Thermalbädern.
    </p>

    <p>...</p>

    <ol>
      <li>
        <a
          href=" https://geothermie-schweiz.ch/waermestrom/petrothermal/potenzial/"
          title="Geothermie Schweiz, ““Potenzial – Geothermie Schweiz”."
          target="_blank"
          class="link link—external"
        >
          Geothermie Schweiz, ““Potenzial – Geothermie Schweiz”.
        </a>.
      </li>

      <li>
        <a
          href=" https://www.bfe.admin.ch/bfe/de/home/versorgung/erneuerbare-energien/geothermie.html "
          title=" Bundesamt für Energie, “Geothermie”. "
          target="_blank"
          class="link link—external"
        >
          Bundesamt für Energie, “Geothermie”.
        </a>.
      </li>
    </ol>
  </Collapsable>
  <Collapsable title="Kernenergie">
    <h3>Auf einen Blick</h3>
    <ul>
      <li>
        CO2-arme Technologie, die zurzeit etwa ein Drittel des in der Schweiz
        produzierten Stroms ausmacht.
      </li>

      <li>
        Strom wird das Jahr über verlässlich produziert; nur im Sommer müssen
        Kraftwerke für wenige Wochen zu Revisionszwecken abgeschaltet werden.
      </li>

      <li>
        Der Ausstieg aus der Kernenergie wurde vom Schweizer Volk beschlossen.
        Es werden keine neuen Anlagen gebaut; die bestehenden bleiben so lange
        im Einsatz, wie sie sicher sind.
      </li>
    </ul>
    <h3>Kernenergie als Technologie</h3>
    <p>
      Kernkraftwerke sind grosse, zentrale Kraftwerke, die fast ununterbrochen
      grosse Mengen an Strom produzieren. Nur für zwei bis vier Sommerwochen
      steht die Produktion für geplante Revisionen und Brennstoffwechsel still.
      Der grösste Kraftwerksblock in der Schweiz hat eine Leistung von 1200 MW,
      ungefähr das dreifache eines Blocks in einem Gaskraftwerk. Bei der
      Stromerzeugung fallen keine CO2-Emissionen an.
    </p>

    <p>
      Der Bau eines Kraftwerks hat sehr hohe anfängliche Investitionskosten.
      Nach vollendetem Bau sind die Betriebs- oder variablen Kosten
      vergleichsweise niedrig. Gleichzeitig können Wartungen und Nachrüstungen
      teuer sein, da hier sehr hohe Standards gesetzt werden.
    </p>
    <h3>Kernenergie in der Schweiz</h3>
    <p>
      Kernenergie macht zurzeit etwa ein Drittel des in der Schweiz produzierten
      Stroms aus (1). Dieser Strom stammt aus den Kernkraftwerken Leibstadt,
      Gösgen und Beznau I+II. Aufgrund des beschlossenen Ausstiegs aus der
      Kernenergie werden keine neuen Kraftwerke mehr gebaut. Die Laufzeit
      bestehender Kraftwerke ist in der Schweiz rechtlich nicht begrenzt.
      Stattdessen ist ein Nachweis erforderlich, dass ihr Betrieb sicher ist.
    </p>

    <p>...</p>

    <ol>
      <li>
        Bundesamt für Energie, “Schweizerische Gesamtenergiestatistik 2020,”
        Seite 39.
      </li>
    </ol>
  </Collapsable>
  <Collapsable title="Erdgas">
    <h3>Auf einen Blick</h3>
    <ul>
      <li>Ist eine abrufbare fossile Stromquelle.</li>

      <li>
        Wird momentan nicht zur Stromproduktion in der Schweiz eingesetzt.
      </li>

      <li>
        Die potentielle Rolle von Gaskraftwerken und/oder umgerüsteten
        Gaskraftwerken für den Betrieb mit Wasserstoff befindet sich in
        Diskussion.
      </li>
    </ul>
    <h3>Erdgaskraftwerke als Technologie</h3>
    <p>
      Gaskraftwerke produzieren zurzeit durch die Verbrennung von Erdgas (häufig
      vereinfacht als «Gas> bezeichnet) unter Ausstoss von CO2 Strom. Die
      Investitionskosten für den Bau von Gaskraftwerken sind je nach Technologie
      verhältnismässig niedrig, allerdings sind ihre variablen Kosten hoch, was
      den Betrieb teuer macht. Da Gaskraftwerke schnell hoch- und runtergefahren
      werden können, können sie sowohl im Dauerbetrieb als auch kurzfristig
      eingesetzt werden. Die Lebensdauer von Gaskraftwerken beträgt etwa 45
      Jahre; die durchschnittliche Leistung steht bei etwa 400 MW.
    </p>

    <p>
      Moderne Erdgaskraftwerke emittieren knapp die Hälfte an CO2 im Vergleich
      zu modernen Kohlekraftwerken. Ausserdem können mit Erdgas betriebene
      Kraftwerke meist nach Anpassungen auch mit erneuerbaren Gasen wie
      Wasserstoff betrieben werden. Zukünftig könnten Gaskraftwerke
      hauptsächlich als Backup-Kraftwerke dienen, die in Situationen der
      Stromknappheit zur Verfügung stehen.
    </p>
    <h3>Erdgaskraftwerke in der Schweiz</h3>
    <p>
      Momentan gibt es keine grossen Gaskraftwerke zur Stromproduktion in der
      Schweiz. Ob und wie Gaskraftwerke (fossil oder CO2-neutral betrieben)
      dereinst eine Rolle in der Stromproduktion der Schweiz spielen werden, ist
      momentan Gegenstand von Diskussionen. Im Modell nehmen wir an, dass
      maximal 4 GW Kapazität hinzugebaut werden, die ungefähr 33 TWh pro Jahr
      produzieren können.
    </p>
  </Collapsable>
  <Collapsable title="Bestand fossile Kraftwerke">
    <h3>Auf einen Blick</h3>

    <ul>
      <li>Umfassen in der Schweiz insbesondere viele kleine Anlagen.</li>

      <li>
        Strom wird häufig als Beiprodukt für gesteigerte Effizienz erzeugt (z.B.
        bei Wärmeerzeugung).
      </li>

      <li>
        Da Stromproduktion bei diesen Anlagen kein Hauptziel ist, ist das
        Ausbaupotential gering.
      </li>
    </ul>

    <h3>Bestehende fossile Kraftwerke als Technologien</h3>

    <p>
      Bestehende fossile Kraftwerke umfassen in diesem Fall insbesondere
      WKK-Anlagen (Wärme-Kraft-Kopplungsanlagen), Anlagen zur Verbrennung vom
      fossilen Anteil von Kehrricht, stromproduzierende Industrieanlagen,
      Deponiegasverbrennung und Fernheizkraftwerke (1). Bei vielen dieser häufig
      kleinen Anlagen ist Strom ein Beiprodukt, das produziert wird, um die
      Effizienz zu steigern. Aus diesem Grund produzieren Anlagen meist je nach
      dem Bedarf für deren Primärfunktion Strom - beispielsweise bei Wärmebedarf
      oder bei der Entsorgung von Kehrricht. Die Menge an Stromproduktion und
      der Zeitpunkt der Stromproduktion richten sich ebenfalls nach der
      Hauptfunktion der Anlagen.
    </p>

    <h3>Bestehende fossile Kraftwerke in der Schweiz</h3>

    <p>
      Zu diesem Zeitpunkt produzieren bestehende Kraftwerke etwa 2 TWh Strom im
      Jahr und machen somit einen geringen Anteil der Schweizer Stromproduktion
      aus. Das Ausbaupotential dieser Anlagen für Stromproduktion ist begrenzt,
      da die Hauptfunktion dieser Kraftwerke nicht Stromproduktion ist. Wenn es
      um den Ausbau von Potential zur Stromerzeugung geht, liegt der Fokus eher
      auf Anlagen, bei denen Strom kein Beiprodukt ist.
    </p>

    <p>…</p>

    <ol>
      <li>
        <a
          href=" https://pubdb.bfe.admin.ch/de/publication/download/10186"
          title="“Thermische Stromproduktion Inklusive Wärmekraftkopplung (WKK) in Der Schweiz” (Bern, September 2020)."
          target="_blank"
          class="link link--external"
        >
          Thermische Stromproduktion Inklusive Wärmekraftkopplung (WKK) in Der
          Schweiz” (Bern, September 2020).</a
        >
      </li>
    </ol>
  </Collapsable>

  <Collapsable title="CCS Fossil">
    <h3>Auf einen Blick</h3>
    <ul>
      <li>
        Fossile Brennstoffe werden zur Stromproduktion verbrannt; ein Grossteil
        des freigesetzten CO2s wird abgeschieden und gespeichert (Carbon Capture
        and Storage, kurz CCS).
      </li>

      <li>CCS ist noch nicht sehr ausgereift und energieintensiv.</li>

      <li>
        Der Ort der Lagerung des abgespaltenen CO2s ist zurzeit nicht klar.
      </li>
    </ul>
    <h3>CCS Fossil als Technologie</h3>
    <p>
      Bei Carbon Capture und Storage (CCS) Fossil wird ein fossiler Brennstoff
      verbrannt und freigesetztes CO2 abgeschieden. CCS Anlagen können zurzeit
      90-95% des CO2s zurückhalten; im Modell nehmen wir 90% an. Da dieser
      Prozess energieaufwändig ist, muss durch Abscheidung, Transport und
      Lagerung bis zu 40% mehr Brennstoff zur Stromproduktion eingesetzt werden
      als in einem Prozess ohne CCS (1). Dies hat zur Folge, dass CCS Fossil
      deutlich kostspieliger als fossile Stromproduktion ohne CCS ist.
    </p>

    <p>
      Als Speicherstätten für das «gefangene» CO2 kommen Lager unter der Erde,
      z.B. frühere Gas- und Öllagerstätten, saline Aquifere (tiefliegende,
      salzwasserführende Gesteinsschichten) oder der Meeresgrund in Frage. Ob
      und zu welchen Anteilen CO2 den Lagerstätten trotzdem entweicht und ob es
      weitere Sicherheitsrisiken gibt, wird derzeit erforscht.
    </p>
    <h3>CCS Fossil in der Schweiz</h3>
    <p>
      Derzeit wird CCS Fossil in der Schweiz nicht praktiziert. Im Modell nehmen
      wir an, dass 3 GW Kapazität hinzugebaut werden, die knapp 25 TWh pro Jahr
      produzieren können.
    </p>

    <p>...</p>

    <ol>
      <li>
        <a
          href="https://www.ipcc.ch/site/assets/uploads/2018/03/srccs_wholereport-1.pdf."
          title=" IPCC, “Special Report on Carbon Dioxide Capture and Storage” (New York, 2005), Seite 4. "
          target="_blank"
          class="link link--external"
          >IPCC, “Special Report on Carbon Dioxide Capture and Storage” (New
          York, 2005), Seite 4.</a
        >
      </li>
    </ol>
  </Collapsable>
</Collapsable>
