<script>
  import { calculatedData } from './../../stores.ts'
  import { _, locale } from 'svelte-i18n'

  const width = 600
  const height = 30

  const margin = {
    top: 15,
    right: 20,
    bottom: 20,
    left: 80,
  }

  $: itemMargin = $locale.startsWith('de') ? [-30, 130, 330] : [-30, 130, 280]

  const w = width - margin.left - margin.right
  const h = height - margin.top - margin.bottom

  $: yearsWithDeficit = $calculatedData
    .getAnnualTimeSeries()
    .filter(
      (year) =>
        year.months.filter((month) => month.calculated.generation.deficit > 0)
          .length > 0
    ).length
</script>

<div class="legend">
  <div class="legend-item">
    <svg width={24} height={24}>
      <circle cx={12} cy={12} r={10} fill="#00ECD1" />
    </svg>
    {$_('page.sections.charts.surplusDeficit.legend.surplus')}
  </div>
  <div class="legend-item">
    <svg width={24} height={24}>
      <circle cx={12} cy={12} r={10} fill="url(#diagonalHatch-import)" />
    </svg>
    {$_('page.sections.charts.surplusDeficit.legend.import')}
  </div>
  {#if yearsWithDeficit > 0}
    <div class="legend-item">
      <svg width={24} height={24}>
        <circle
          cx={12}
          cy={12}
          r={10}
          fill="white"
          stroke="purple"
          stroke-width={2}
        />
        <circle cx={12} cy={12} r={7} fill="purple" />
      </svg>
      {$_('page.sections.charts.surplusDeficit.legend.deficit')}
    </div>
  {/if}
</div>

<style>
  .legend {
    display: flex;
    flex-direction: row;
    height: var(--size-7);
  }

  .legend-item {
    margin-left: var(--size-2);
    display: flex;
    align-items: center;
    gap: var(--size-1);
  }
</style>
