<script>
  import Popover from './../../components/Popover.svelte'
  import { showPopover, popoverContent } from './../../stores.ts'
  import { LayerCake, Svg } from 'layercake'
  import EnergyMixChart from './EnergyMixChart.svelte'
  import { _ } from 'svelte-i18n'
  import SeasonSelector from './SeasonSelector.svelte'
  import { format } from 'd3-format'

  let selectedTimePeriod = 'winter'
  let showImportPotential = false

  let hoverTechnology

  $: if (hoverTechnology) {
    $showPopover = true

    const label =
      hoverTechnology.key === 'deficit'
        ? $_(`page.sections.charts.surplusDeficit.detailLabels.deficit`)
        : $_(
            `page.sections.userControls.switzerland.capacity.groups.${hoverTechnology.key}.label`
          )

    const value = format(',.1f')(hoverTechnology.value)

    $popoverContent = `
    <div style="font-size: var(--scale-3);">
      <span style="font-weight: bold;">
        ${label}:
      </span> ${value}TWh
    </div>
    `
  } else {
    $showPopover = false
  }
</script>

<div class="container">
  <div class="button-wrapper">
    <SeasonSelector bind:selectedTimePeriod />
    <button
      class="import-button"
      on:click={() => (showImportPotential = !showImportPotential)}
      >{!showImportPotential
        ? $_('page.sections.charts.energyMix.show')
        : $_('page.sections.charts.energyMix.hide')}
      {$_('page.sections.charts.energyMix.importPotential')}
    </button>
  </div>
  <div class="layercake-wrapper">
    <LayerCake>
      <Svg>
        <EnergyMixChart
          {showImportPotential}
          {selectedTimePeriod}
          bind:hoverTechnology
        />
      </Svg>
    </LayerCake>
  </div>
</div>

<!-- <div class="year-slider-wrapper">
    <input
      type="range"
      min="2020"
      max="2050"
      step="1"
      bind:value={$selectedYear}
    />
  </div> -->

<!-- <div class="button-wrapper">
    {#if $selectedYear !== undefined}
      <button
        on:click={() => ($selectedYear = undefined)}
        class="deselect-year-button"
        >{$_('page.sections.charts.surplusDeficit.clearSelection')}</button
      >
    {/if}
  </div> -->
<style>
  .container {
    width: 100%;
    height: 444px;
  }

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 40px;
  }

  .layercake-wrapper {
    width: 100%;
    height: 440px;
  }

  p {
    max-width: 720px;
  }

  .top-row {
    position: relative;
  }

  .import-button {
    border: none;
    outline: none;
    background: var(--accent-color);
    color: white;
    cursor: pointer;
    border-radius: 20px;
    font-size: 1.4rem;
    padding: 4px 20px;
    margin-right: 40px;
  }

  h1 {
    display: flex;
    justify-content: flex-start;
    margin: 0;
  }
</style>
