<script>
  import { getContext } from 'svelte'
  import { costTechMapping } from '../../constants'
  import { _ } from 'svelte-i18n'
  import { costDetailsHover } from '../../stores'

  const { xRange, yGet, data, yScale, padding } = getContext('LayerCake')
</script>

<g transform="translate(0, 0)">
  {#each $data as d, i}
    <rect
      x={-$padding.left}
      y={$yGet(d)}
      height={Math.max(0, $yScale.bandwidth())}
      width={$xRange[1] + $padding.left + $padding.right}
      fill="var(--color-tech-{costTechMapping[d.nr]})"
      rx={3}
      ry={3}
    />

    <g
      transform="translate({($xRange[0] + $xRange[1]) / 2}, {$yGet(d) +
        $yScale.bandwidth() / 2})"
      dy={-1}
    >
      <text
        fill={['T12', 'T13', 'T14', 'T15', 'T17'].includes(d.nr)
          ? 'black'
          : 'white'}
        class="label"
        class:hover={$costDetailsHover?.data?.nr === d.nr}
      >
        {$_(`page.sections.technologies.${d.nr}`)}
      </text>
    </g>
    <rect
      x={-$padding.left}
      y={$yGet(d) - 2}
      height={Math.max(0, $yScale.bandwidth()) + 4}
      width={$xRange[1] + $padding.left + $padding.right}
      fill="transparent"
      on:mouseover={() =>
        ($costDetailsHover = {
          type: 'income',
          data: d,
        })}
      on:mouseout={() => ($costDetailsHover = undefined)}
    />
  {/each}
</g>

<style>
  text {
    text-anchor: middle;
    dominant-baseline: central;
    font-size: var(--scale-3);
  }

  .hover {
    /* opacity: 0.5; */
    font-weight: bold;
  }
</style>
