<script>
  import {
    calculatedData,
    selectedYear,
    selectedTimePeriod,
  } from './../../stores.ts'
  import MatrixYearChart from './MatrixYearChart.svelte'
  import { sum } from 'd3-array'
  import { scaleLinear } from 'd3-scale'
  import { _ } from 'svelte-i18n'

  let hoverTech

  const cellSize = 13

  //   const { width, height } = getContext('LayerCake')

  //   $: xScale = scaleLinear().domain([2020, 2050]).range([0, $width])
  //   $: yScale = scaleLinear().domain([0, 12]).range([0, $height])
  $: xScale = scaleLinear().domain([2020, 2050]).range([0, 400])
  $: yScale = scaleLinear().domain([0, 12]).range([0, 400])

  $: matrixData = $calculatedData
    ?.getMonthlyTimeSeries((month) => {
      const totalProduction = sum(
        Object.values(month.calculated.generation.generation.byTechnology)
      )

      return {
        year: month.parentYear.year,
        month: month.month - 1,
        date: month.parentYear.year * 100 + month.month,
        value: month.calculated.generation.surplus, // TODO: remove: same as surplus prop
        totalProduction,
        production: {
          ...month.calculated.generation.generation.byTechnology,
          import: month.calculated.generation.import.total,
        },
        import: month.calculated.generation.import.total,
        surplus: month.calculated.generation.surplus,
        deficit: month.calculated.generation.deficit,
        demand: month.calculated.totalDemand.CH,
        selfSufficientDays: month.calculated.selfSufficientDays,
        speicherReserve: month.calculated.generation.generation.speicherReserve,
      }
    })
    .map((d, i) => ({
      ...d,
      //   stacked: [Math.floor(i / 12) * 2 * cellSize, (i % 12) * 2 * cellSize],
      stacked: [xScale(d.year), yScale(i % 12)],
    }))
</script>

<h4 class="chart-title">
  {$_('page.sections.charts.surplusDeficit.auxiliaryChartLabels.allMonths')}
  {$selectedYear}
</h4>
{#if $selectedYear !== undefined}
  <MatrixYearChart data={matrixData} {cellSize} />
  <!-- {:else if selectedMonth !== undefined}
  <MatrixMonthChart {matrixData} {$selectedMonth} {cellSize} />
{:else if selectedDate !== undefined}
  <MatrixDateChart {matrixData} {hoverTech} {$selectedDate} {cellSize} /> -->
  <!-- {:else}
  <MatrixInstructions /> -->
{/if}
