﻿<script>
  import Collapsable from './../../components/Collapsable.svelte'

  export let title
</script>

<Collapsable {title} track={true}>
  <Collapsable title="Photovoltaics">
    <h3>At a glance</h3>
    <ul>
      <li>
        Renewable energy source with the greatest expansion potential in
        Switzerland at present
      </li>

      <li>A large share of the production occurs in the summer months.</li>

      <li>
        With a high installed capacity, long-term storage or curtailment
        (non-use of excess electricity produced) is necessary.
      </li>
    </ul>
    <h3>Photovoltaics as a technology</h3>

    <p>
      Photovoltaic systems can be installed on buildings, existing
      infrastructure or as ground-mounted systems. Over the past 25 years, the
      production costs for solar modules have fallen significantly, which makes
      this technology more economically attractive. Photovoltaic systems have a
      service life of 20–30 years. 1 MW of photovoltaics requires just under
      10,000 m2 of space. Over the course of a year, this can supply roughly 250
      households.
    </p>

    <p>
      Solar energy has high investment or fixed costs, but low variable costs.
      In other words, the largest investments are needed to build the
      installations, while only minor further costs are expected over the years.
    </p>

    <p>
      Photovoltaics is not available on-demand as an electricity source and has
      a marked daily and annual profile. Most sunlight and thus electricity
      production takes place at certain times of the day and year, namely in
      summer and around midday. It is not always available, as the production
      profile cannot be adjusted to the electricity demand. Particularly with
      high shares of solar power in the electricity mix, it can be important for
      electricity supply security to store surplus power from these peak times
      in short- and long-term storage.
    </p>
    <h3>Photovoltaics in Switzerland</h3>
    <p>
      As a yearly average, photovoltaic production is available roughly 10% of
      the time at an average location in Switzerland. Electricity production is
      severely limited in winter due to the short days and low position of the
      sun. Changing the tilt angle can increase electricity production in
      winter.
    </p>

    <p>
      The usable potential of photovoltaics on roofs and façades is estimated by
      the Swiss Federal Office of Energy to be 67 TWh (1). For reasons of nature
      conservation, ground-mounted systems face resistance; a low potential was
      assumed here despite their being more economical than rooftop systems. The
      theoretical potential in Switzerland is therefore much higher.
    </p>

    <p>...</p>

    <ol>
      <li>
        <a
          href="https://www.bfe.admin.ch/bfe/de/home/news-und-medien/medienmitteilungen/mm-test.msg-id-74641.html"
          title="“Schweizer Hausdächer und -Fassaden könnten jährlich 67 TWh Solarstrom produzieren” April 15, 2019"
          target="_blank"
          class="link link--external"
        >
          Bundesamt für Energie, “Schweizer Hausdächer und -Fassaden könnten
          jährlich 67 TWh Solarstrom produzieren” April 15, 2019.</a
        >
      </li>
    </ol>
  </Collapsable>
  <Collapsable title="Alpine photovoltaics">
    <h3>At a glance</h3>
    <ul>
      <li>
        Alpine photovoltaic systems are installed in the mountains, generally at
        steep angles.
      </li>

      <li>
        They generally produce more electricity per unit than in lower-lying
        areas – but there is also less space available in the mountains.
      </li>
    </ul>
    <h3>Alpine photovoltaics as a technology</h3>
    <p>
      Photovoltaics in the Alpine area, or alpine PV, is installed at a steep
      angle. This allows more electricity to be produced when the sun is low in
      the sky, but produces less electricity when the sun is high than
      installations with less steep angles. Photovoltaic systems have a service
      life of 20–30 years.
    </p>

    <p>
      The electricity production is higher than for photovoltaics at lower
      altitudes because there are more hours of sunshine, particularly in
      winter, and cooler weather throughout the year. Overall, alpine PV has a
      much more balanced annual profile overall, with up to half of the
      production volume occurring in winter. However, for socio-political and
      nature conservation reasons, there is also less economically usable space
      available there.
    </p>

    <p>
      Alpine PV has high investment or fixed costs, but low variable costs. The
      greatest outlays occur when building the installations, while low
      additional costs can be expected over the years of operation.
    </p>
    <h3>Alpine photovoltaics in Switzerland</h3>
    <p>
      We assume that up to 15 TWh/a of solar power can be produced in the Alps.
      The potential for ground-mounted installations in the Alps largely depends
      on balancing protection and use interests. The technical potential for alpine PV is
      substantially higher.
    </p>
  </Collapsable>
  <Collapsable title="Wind energy">
    <h3>At a glance</h3>
    <p />
    <ul>
      <li>
        Renewable source of electricity that can be strategically valuable in
        Switzerland due to its production in winter.
      </li>
      <li>
        Wind power is a supply-dependent form of energy – its output is not
        considered secure because the blowing of the wind cannot be externally
        controlled.
      </li>
    </ul>

    <h3>Wind energy as a technology</h3>
    <p>
      Wind turbines are mostly installed in a decentralised fashion in wind
      farms with 5–40 turbines. Over the past 25 years, there have been major
      advances in the size and thus the output of individual wind turbines: the
      capacity has risen from roughly 0.5 MW to up to 9 MW per turbine. Wind
      turbines have a service life of 20–30 years.
    </p>
    <p>
      Wind energy has high investment or fixed costs, but low variable costs.
      The greatest outlays occur early on when building the installations, while
      low additional costs can be expected over the years of operation.
    </p>
    <p>
      Wind power is a supply-dependent form of energy, i.e. its output is not
      considered secure because the blowing of the wind can be well forecast but
      not externally controlled. At the same time, the electricity must be used
      at the time it is produced. For this reason, where high shares of energy
      sources such as wind are in the electricity mix, options like stored power
      are important for electricity supply security.
    </p>

    <p>
      Windparks mit einer Produktion von 20 GWh pro Jahr oder mehr (entspricht
      0.02 TWh oder mehr) sind gemäss Energiegesetz von «nationalem Interesse»
      (2). Somit stehen bei der Planung und Bewilligung von Windparks bei einer
      Interessenabwägung Nutz-, Schutz- und andere nationale Interessen auf
      einer Stufe.
    </p>

    <h3>Wind energy in Switzerland</h3>
    <p>
      As a yearly average, wind energy is available roughly 15–20% of the time
      in Switzerland. Roughly two-thirds of the electricity is produced in the
      winter half-year. According to a study by Suisse Eole in collaboration
      with the Swiss Federal Office of Energy, the usable potential of wind
      energy is estimated at 9 TWh/a (1). The wind power target set by the
      federal government is based on a study from 2012 in which the potential
      was estimated at 4.3 TWh/a (1). Wind energy and water storage power can be
      combined effectively for winter supply. In Switzerland, there are still
      very few wind turbines due to the conflicting interests of use and
      conservation. Without simplifying the approval procedures, this is
      unlikely to change.
    </p>
    <p>
      Wind farms with a production of 20 GWh per year or more (equivalent to
      0.02 TWh or more) are of ‘national interest’ according to the Energy Act
      (2). Utility, conservation and other national interests are therefore on
      the same level in terms of weighing the interests when it comes to
      planning and approving wind farms.
    </p>
    <ol>
      <li>
        <a
          href="https://www.bfe.admin.ch/bfe/de/home/versorgung/erneuerbare-energien/windenergie.html"
          title=" Suisse Éole in Kooperation mit dem Bundesamt für Energie, “Windenergiestrategie: Winterstrom und Klimaschutz,” Dezember 6, 2020, Seite 28. "
          target="_blank"
          class="link link--external"
        >
          Suisse Éole in Kooperation mit dem Bundesamt für Energie,
          “Windenergiestrategie: Winterstrom und Klimaschutz,” Dezember 6, 2020,
          Seite 28.
        </a>
      </li>

      <li>
        <a
          href=" https://www.bfe.admin.ch/bfe/de/home/versorgung/erneuerbare-energien/windenergie.html "
          title="Bundesamt für Energie, “Windenergie.”"
          target="_blank"
          class="link link--external"
        >
          Bundesamt für Energie, “Windenergie.”
        </a>
      </li>
    </ol>
  </Collapsable>
  <Collapsable title="Hydropower – run-of-river">
    <h3>At a glance</h3>
    <ul>
      <li>
        Run-of-river power uses the gradient of river courses to produce
        electricity.
      </li>

      <li>
        Overall, hydropower supplies roughly 60% of Swiss electricity, while
        run-of-river power accounts for just under a third of the maximum output
        of hydropower.
      </li>
    </ul>
    <h3>Run-of-river power as a technology</h3>
    <p>
      Run-of-river power is a renewable energy source that uses the flow of a
      river to generate electricity. Thus, in contrast to storage power plants,
      run-of-river power plants cannot adjust the amount of electricity
      according to demand. The quantity of electricity generated can be
      influenced by seasonal weather-related fluctuations in the river’s
      behaviour. Roughly every 40 years, large-scale replacement investments are
      necessary; variable costs following the installation of a power plant are,
      however, usually low.
    </p>

    <h3>Run-of-river power in Switzerland</h3>

    <p>
      The first run-of-river power plant went into operation as early as 1886.
      Currently, hydropower supplies just under 60% of Switzerland’s
      electricity; run-of-river power accounts for about 40% of that production
      (1). The operation and expansion of hydropower in Switzerland is opposed
      by fishery associations and nature conservation bodies.
    </p>

    <p>
      The potential for run-of-river power in Switzerland is almost entirely
      exhausted. Run-of-river power plants are located at relatively short
      intervals along most of Switzerland’s larger rivers. The Swiss water
      management association (SWV) estimates that hydropower will decrease to as
      little as 6.4 TWh (of which roughly 5 TWh is run-of-river power) by 2050
      or increase up to 8.7 TWh (of which roughly half is run-of-river power)
      (2).
    </p>

    <p>...</p>

    <ol>
      <li>
        Bundesamt für Energie, “Schweizerische Gesamtenergiestatistik 2020,”
        Seite 39.
      </li>

      <li>
        <a
          href=" https://www.swv.ch/wp-content/uploads/2018/12/WEL-4-2018_Energieeinbussen_hq.pdf"
          title=" Roger Pfammatter und Nadia Semadeni Wicki, “Energieeinbussen aus 

    Restwasserbestimmungen – Stand und Ausblick,” Wasser Energie Luft 110. 

    Jahrgang, no. 4 (2018): 233–45, Seite 243."
          target="_blank"
          class="link link—external"
        >
          Roger Pfammatter und Nadia Semadeni Wicki, “Energieeinbussen aus
          Restwasserbestimmungen – Stand und Ausblick,” Wasser Energie Luft 110.
          Jahrgang, no. 4 (2018): 233–45, Seite 243.
        </a>
      </li>
    </ol>
  </Collapsable>
  <Collapsable title="Hydropower – storage power">
    <h3>At a glance</h3>
    <ul>
      <li>
        Hydropower is the oldest source of electricity in Switzerland and
        currently supplies about 60% of Switzerland’s electricity; storage power
        accounts for roughly two-thirds of the maximum output of hydropower.
      </li>

      <li>
        Storage power plants are strategically valuable, as they are renewable
        and their electricity production can be controlled.
      </li>

      <li>The potential for expanding storage power is nearly exhausted.</li>
    </ul>
    <h3>Storage power as a technology</h3>
    <p>
      Storage power is a renewable energy source that uses reservoirs and the
      controlled release of water to generate electricity. In pumped storage
      power, water is pumped from a lower-lying reservoir to a higher reservoir
      to be released for power generation if needed. This type of storage power
      can, for example, use surplus solar power to pump up the water and thus
      serve as a form of storage for solar energy.
    </p>

    <p>
      As with run-of-river power, the variable costs during operation of a power
      plant are low, other than when major maintenance is required. Pumped
      storage power has higher variable costs due to the energy needed for
      pumping.
    </p>
    <h3>Storage power in Switzerland</h3>
    <p>
      Hydropower is the oldest power generation technology used in Switzerland.
      Currently, hydropower supplies just under 60% of Switzerland’s
      electricity; storage power accounts for about 60% of this (1). The
      operation and expansion of hydropower in Switzerland is opposed by fishery
      associations and nature conservation bodies.
    </p>
    <p>
      The potential for storage power in Switzerland is almost entirely
      exhausted. Most mountain water is already used in storage power plants, so
      the potential for expansion is limited. The Swiss water management
      association (SWV) estimates that hydropower will decrease to as little as
      7 TWh (of which roughly 1 TWh is storage power) by 2050 due to residual
      water requirements and lack of investment incentives or increase to up to
      8.7 TWh (of which roughly half is storage power) (2).
    </p>

    <p>...</p>

    <ol>
      <li>
        Bundesamt für Energie, “Schweizerische Gesamtenergiestatistik 2020,”
        page 39.
      </li>

      <li>
        <a
          href=" https://www.swv.ch/wp-content/uploads/2018/12/WEL-4-2018_Energieeinbussen_hq.pdf"
          title=" Roger Pfammatter und Nadia Semadeni Wicki, “Energieeinbussen aus 

    Restwasserbestimmungen – Stand und Ausblick,” Wasser Energie Luft 110. 

    Jahrgang, no. 4 (2018): 233–45, page 243."
          target="_blank"
          class="link link—external"
        >
          Roger Pfammatter und Nadia Semadeni Wicki, “Energieeinbussen aus
          Restwasserbestimmungen – Stand und Ausblick,” Wasser Energie Luft 110.
          Jahrgang, no. 4 (2018): 233–45, Seite 243.
        </a>
      </li>
    </ol>
  </Collapsable>
  <Collapsable title="Biomass">
    <h3>At a glance</h3>
    <ul>
      <li>
        Electricity production is based on biogas, wood, sewage gas plants and
        the biogenic share of waste incineration.
      </li>

      <li>
        The potential for expansion is limited primarily due to reasons of
        economic efficiency and fuel availability.
      </li>
    </ul>
    <h3>Biomass as a technology</h3>
    <p>
      Biomass is an umbrella term for a wide range of power plants that use
      biomass to generate electricity: biogas, wood, sewage gas plants and the
      biogenic share of waste incineration. Biomass is fermented, burned or
      gasified to produce electricity.
    </p>
    <h3>Biomass in Switzerland</h3>
    <p>
      The expansion potential for biomass in Switzerland is limited to a maximum
      of 4 TWh for various reasons (1). Environmentally, the use of biomass for
      electricity production makes sense where it occurs anyway. But producing
      biomass specifically for energy use is often not a holistic approach –
      competition between food production and cultivation for electricity or
      fuel production plays a role, for example.
    </p>

    <p>...</p>

    <ol>
      <li>
        Verband Schweizerischer Elektrizitätsunternehmen, “Strom aus Biomasse,”
        Basiswissen-Dokument (Aarau, January 3, 2020), page 3.
      </li>
    </ol>
  </Collapsable>

  <Collapsable title="Geothermal">
    <h3>At a glance</h3>
    <ul>
      <li>Renewable source of electricity not yet used in Switzerland.</li>

      <li>Available year-round.</li>

      <li>
        With the current technology, the feasible potential is estimated at
        roughly 4.4 TWh per year and is thus low.
      </li>
    </ul>
    <h3>Geothermal energy as a technology</h3>
    <p>
      Geothermal energy can be used both as a source of heat and for electricity
      production. It primarily uses heat pumps but also geothermal heat. As
      geothermal energy is available year-round, plants can be used throughout
      the year, aside from during downtimes for overhauls. The service life of
      geothermal plants is 30–35 years.
    </p>

    <p>
      Geothermal energy has high investment or fixed costs, but low variable
      costs. The greatest outlays occur when building the plants, while low
      additional costs can be expected for operation.
    </p>
    <h3>Geothermal energy in Switzerland</h3>
    <p>
      The usable potential of geothermal energy for electricity production is
      theoretically high in Switzerland. However, with current drilling
      technology the potential is limited and amounts to roughly 4.4 TWh per
      year (1). If this potential had been fully exploited in 2020, it would
      have covered about 7% of total electricity demand. In Switzerland there is
      currently a pilot project <a
        href="https://www.geo-energie-jura.ch/le-projet/"
        title="Geo-Energie Suisse AG in Haute-Sorne"
        target="_blank">Geo-Energie Suisse AG in Haute-Sorne</a
      > with a maximum electrical output of 5 MW (as of 2020). Aside from the pilot
      project, there is currently no plant for electricity generation in Switzerland
      (2). Existing geothermal systems without heat pumps in Switzerland include
      the use of the deep aquifer in Riehen and for thermal baths.
    </p>

    <p>...</p>

    <ol>
      <li>
        <a
          href=" https://geothermie-schweiz.ch/waermestrom/petrothermal/potenzial/"
          title="Geothermie Schweiz, “Potenzial – Geothermie Schweiz” "
          target="_blank"
          class="“link"
          link—external“
        >
          Geothermie Schweiz, ““Potenzial – Geothermie Schweiz”.
        </a>.
      </li>

      <li>
        <a
          href="https://www.bfe.admin.ch/bfe/de/home/versorgung/erneuerbare-energien/geothermie.html"
          title=" Bundesamt für Energie, “Geothermie” "
          target="_blank"
          class="link link—external"
        >
          Bundesamt für Energie, “Geothermie”.
        </a>.
      </li>
    </ol>
  </Collapsable>
  <Collapsable title="Nuclear energy">
    <h3>At a glance</h3>
    <ul>
      <li>
        Low-carbon technology that currently accounts for about one-third of the
        electricity produced in Switzerland.
      </li>

      <li>
        Electricity is reliably produced throughout the year; power plants only
        have to be shut down for a few weeks for maintenance purposes in summer.
      </li>

      <li>
        Der Ausstieg aus der Kernenergie wurde vom Schweizer Volk beschlossen.
        Es werden keine neuen Anlagen gebaut; die bestehenden bleiben so lange
        im Einsatz, wie sie sicher sind.
      </li>
    </ul>
    <h3>Nuclear energy as a technology</h3>
    <p>
      Nuclear power plants are large, centralised power plants that produce
      large amounts of electricity on an almost continuous basis. Production
      only stops for two to four weeks in summer for planned overhauls and fuel
      changes. The largest nuclear generating unit in Switzerland has a capacity
      of 1,200 MW, or roughly three times that of a unit in a gas power plant.
      No CO2 emissions are produced during electricity generation.
    </p>

    <p>
      The construction of a power plant has very high initial investment costs.
      Once construction is complete, the operating and variable costs are
      comparatively low. At the same time, maintenance and retrofitting can be
      expensive as very high standards are applied here.
    </p>

    <h3>Nuclear energy in Switzerland</h3>
    <p>
      Nuclear energy currently accounts for about one-third of the electricity
      produced in Switzerland (1). This electricity comes from the Leibstadt,
      Gösgen and Beznau I+II nuclear power plants. Due to the decision to phase
      out nuclear energy, no new power plants will be built. The operating life
      of existing power plants is not legally defined in Switzerland. Instead,
      proof that their operation is safe is required.
    </p>

    <p />

    <ol>
      <li>
        Bundesamt für Energie, “Schweizerische Gesamtenergiestatistik 2020,”
        page 39.
      </li>
    </ol>
  </Collapsable>
  <Collapsable title="Natural gas">
    <h3>At a glance</h3>
    <ul>
      <li>An on-demand fossil fuel power source.</li>

      <li>
        It is currently not used for electricity production in Switzerland.
      </li>

      <li>
        The potential role of gas power plants and/or converted gas power plants
        for operation with hydrogen is under discussion.
      </li>
    </ul>
    <h3>Natural gas power plants as a technology</h3>
    <p>
      Gas power plants currently produce electricity by burning natural gas
      while emitting CO2. The investment costs for the construction of gas power
      plants are relatively low depending on the technology, but their variable
      costs are high, which makes operation expensive. As gas power plants can
      be started up and shut down quickly, they can be used both in continuous
      operation and for a short time. The service life of gas power plants is
      roughly 45 years and the average capacity is approximately 400 MW.
    </p>

    <p>
      Modern natural gas power plants emit just under half the CO2 of modern
      coal-fired power plants. Moreover, power plants powered by natural gas can
      usually also be modified to operate with renewable gases such as hydrogen.
      In the future, gas power plants could be used primarily as backup power
      plants available in power shortfall situations.
    </p>
    <h3>Natural gas power plants in Switzerland</h3>
    <p>
      Currently, there are no large gas power plants for electricity generation
      in Switzerland. Whether and how gas power plants (fossil or carbon-neutral
      operation) may one day play a role in Switzerland’s electricity production
      is currently the subject of debate. In the model, we assume a potential
      maximum of 4 GW capacity to be added, producing up to 33 TWh per year.
    </p></Collapsable
  >

  <Collapsable title="Fossil fuel CCS">
    <h3>At a glance</h3>

    <ul>
      <li>
        Fossil fuels are burned to produce electricity; a large share of the CO2
        emitted is captured and stored (carbon capture and storage, or CCS).
      </li>

      <li>CCS is not yet very sophisticated and is energy-intensive.</li>

      <li>
        The storage location for the captured CO2 is not clear at present.
      </li>
    </ul>

    <h3>Fossil fuel CCS as a technology</h3>

    <p>
      In fossil fuel CCS, a fossil fuel is burned and the CO2 released is
      captured. CCS plants can currently capture 90–95% of CO2; in the model, we
      assume 90%. As this process is energy-intensive, the capture, transport
      and storage result in up to 40% more fuel being required for electricity
      production than in a process without CCS (1). This, in turn, means that
      fossil fuel CCS is much more costly than fossil fuel electricity
      production without CCS.
    </p>
    <p>
      Possible storage sites for the captured CO2 include underground
      reservoirs, e.g. former gas and oil storage sites, saline aquifers (deep,
      saltwater-bearing rock layers) or the seabed. Whether and to what extent
      CO2 nevertheless escapes its repositories and whether other safety risks
      may be in play are currently being researched.
    </p>
    <h3>Fossil fuel CCS in Switzerland</h3>

    <p>
      Fossil fuel CCS is currently not carried out in Switzerland. In the model,
      we assume a potential maximum of 4 GW capacity to be added, producing up
      to 33 TWh per year.
    </p>

    <p>...</p>

    <ol>
      <li>
        <a
          href="https://www.ipcc.ch/site/assets/uploads/2018/03/srccs_wholereport-1.pdf."
          title=" IPCC, “Special Report on Carbon Dioxide Capture and Storage” (New York, 2005), page 4. "
          target="_blank"
          class="link link--external"
          >IPCC, “Special Report on Carbon Dioxide Capture and Storage” (New
          York, 2005), Seite 4.</a
        >
      </li>
    </ol>
  </Collapsable>
</Collapsable>
