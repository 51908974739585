<script>
  import { getContext } from 'svelte'
  import { line } from 'd3-shape'
  import { format } from 'd3-format'
  import { scaleOrdinal } from 'd3-scale'
  import { costDetailsHover } from '../../stores'

  const f = format(',.2r')

  const {
    data,
    zScale,
    yGet,
    yRange,
    yScale,
    xScale,
    padding,
    xRange,
    height,
  } = getContext('LayerCake')

  $: l = line()
    .x((d) => $xScale(d.income))
    .y((d) => $yGet(d) + $yScale.bandwidth() / 2)

  const domain = ['invFix', 'invVar', 'social']

  const c1 = scaleOrdinal()
    .domain(domain)
    .range([
      'var(--color-cost-inv-fixed-weaker)',
      'var(--color-cost-inv-var-weaker)',
      'var(--color-cost-social-weaker)',
    ])

  const c3 = scaleOrdinal()
    .domain(domain)
    .range([
      'var(--color-cost-inv-fixed-darker)',
      'var(--color-cost-inv-var-darker)',
      'var(--color-cost-social-darker)',
    ])

  const c2 = scaleOrdinal()
    .domain(domain)
    .range([
      'var(--color-cost-inv-fixed)',
      'var(--color-cost-inv-var)',
      'var(--color-cost-social)',
    ])
</script>

{#each $data as d, i}
  {#if i % 2 === 0}
    <rect
      x={-$padding.left}
      y={$yGet(d)}
      height={Math.max(0, $yScale.bandwidth())}
      width={$xRange[1] + $padding.left + $padding.right}
      fill="var(--color-grey-50)"
    />
  {/if}
{/each}

{#each $xScale.nice().ticks() as tick}
  <line
    x1={$xScale(tick)}
    x2={$xScale(tick)}
    y2={$height}
    stroke="#ccc"
    stroke-dasharray="1 1"
  />
  <text x={$xScale(tick)} dy={-12} class="tick">{tick}</text>
{/each}

<line
  x1={$xScale(0)}
  y1={$yRange[0]}
  x2={$xScale(0)}
  y2={$yRange[1]}
  stroke="var(--color-grey-300)"
/>

{#each $data as d, i}
  <g transform="translate(0, {$yGet(d)})">
    <rect
      height={$yScale.bandwidth()}
      width={$xScale(d.invFix)}
      fill={$costDetailsHover?.data?.nr === d.nr ? c3('invFix') : c1('invFix')}
      rx={3}
      ry={3}
    />
    <rect
      x={$xScale(d.invFix)}
      height={$yScale.bandwidth()}
      width={$xScale(d.invVar)}
      fill={$costDetailsHover?.data?.nr === d.nr ? c3('invVar') : c1('invVar')}
      rx={3}
      ry={3}
    />
    <rect
      x={$xScale(d.invFix) + $xScale(d.invVar)}
      height={$yScale.bandwidth()}
      width={$xScale(d.social)}
      style="fill: {$costDetailsHover?.data?.nr === d.nr
        ? 'var(--color-green-800)'
        : c1('social')}"
      rx={3}
      ry={3}
    />
    <line
      x1={$xScale(d.income)}
      x2={$xScale(d.income)}
      y2={5}
      stroke="var(--color-grey-700)"
      stroke-width={3}
    />
    <line
      x1={$xScale(d.income)}
      x2={$xScale(d.income)}
      y1={$yScale.bandwidth() - 5}
      y2={$yScale.bandwidth()}
      stroke="var(--color-grey-700)"
      stroke-width={3}
    />

    {#if $costDetailsHover?.data.nr === d.nr && $costDetailsHover?.showOverlay}
      {@const isLoss = d.income - d.invFix - d.invVar - d.social < 0}
      <rect
        x={Math.min(
          $xScale(d.income),
          $xScale(d.invFix) + $xScale(d.invVar) + ($xScale(d.social) || 0)
        )}
        height={$yScale.bandwidth()}
        width={Math.abs(
          $xScale(d.income) -
            $xScale(d.invFix) -
            $xScale(d.invVar) -
            $xScale(d.social)
        )}
        fill={isLoss
          ? 'url(#diagonal-hatch-income-loss)'
          : 'url(#diagonal-hatch-income)'}
        rx={3}
        ry={3}
      />
    {/if}

    <text
      x={$xScale(d.income)}
      dy={$yScale.bandwidth() / 2}
      style="text-anchor: middle; dominant-baseline: central; font-size: var(--scale-1); font-weight: {$costDetailsHover
        ?.data?.nr === d.nr} : 'bold' : 'normal'};"
    >
      {Math.round(d.income)}
    </text>

    <rect
      x={-$padding.left}
      y={-2}
      height={Math.max(0, $yScale.bandwidth()) + 4}
      width={$xRange[1] + $padding.left + $padding.right}
      fill="transparent"
      on:mouseover={() =>
        ($costDetailsHover = {
          type: 'income',
          data: d,
        })}
      on:mouseout={() => ($costDetailsHover = undefined)}
    />
  </g>
{/each}

<style>
  .label {
    dominant-baseline: central;
    text-anchor: middle;
    font-size: var(--scale-1);
  }

  .tick {
    text-anchor: middle;
    font-size: var(--scale-1);
  }

  .income {
    stroke: var(--color-grey-700);
    stroke-width: 2;
    fill: none;
  }
</style>
