<script>
  import { _ } from 'svelte-i18n'
  import AvailabilityControl from '../../AvailabilityControl.svelte'
  import Availability from '../../charts/Availability.svelte'
  import Panel from './Panel.svelte'
  import { calculatedData } from '../../stores'

  export let technology
  export let techGroup
  export let subGroup
  export let i
</script>

<Panel>
  <!-- {#if i === 0}
    <AvailabilityControl
      {subGroup}
      trackingData={[
        $_('page.sections.userControls.switzerland.capacity.label'),
        $_(
          `page.sections.userControls.switzerland.capacity.groups.${techGroup.key}.label`
        ),
        $_(
          'page.sections.userControls.switzerland.capacity.controls.tabs.utilization'
        ),
      ]}
    />
  {/if} -->

  <Availability
    key={technology.key}
    color={techGroup.color}
    data={$calculatedData.years[0].months.map(
      (month) => month.calculated.availability.CH[technology.key]
    )}
  />
</Panel>
