<script>
  import { _ } from 'svelte-i18n'
  import {
    selectedLCOE,
    selectedPowerPrice,
    selectedCostView,
    activeTab,
  } from '../../stores.js'
  import ButtonGroup from '../../ui/ButtonGroup.svelte'
  import RangeSlider from '../../ui/RangeSlider.svelte'
  import Button from '../../ui/Button.svelte'

  $: lcoeOptions = [
    { key: 'low', label: $_('page.sections.new.low') },
    { key: 'medium', label: $_('page.sections.new.medium') },
    { key: 'high', label: $_('page.sections.new.high') },
  ]

  $: viewOptions = [
    { key: 'Investor', label: $_('page.sections.new.investor') },
    { key: 'Social', label: $_('page.sections.new.social') },
  ]
</script>

<div class="input-wrapper">
  <div class="input">
    <div class="label">{$_('page.sections.new.lcoe')}</div>
    <ButtonGroup>
      {#each lcoeOptions as item}
        <Button
          active={$selectedLCOE === item.key}
          on:click={() => ($selectedLCOE = item.key)}
        >
          {item.label}
        </Button>
      {/each}
    </ButtonGroup>
  </div>
  <div class="input">
    <div class="label">{$_('page.sections.new.powerPrice')}</div>
    <RangeSlider bind:value={$selectedPowerPrice} min={25} max={250} step={5} />
    <div class="price">{$selectedPowerPrice} CHF/MWh</div>
  </div>
  <div class="input">
    {#if $activeTab !== 'subsidies'}
      <div class="label">{$_('page.sections.new.view')}</div>
      <ButtonGroup>
        {#each viewOptions as item}
          <Button
            active={$selectedCostView === item.key}
            on:click={() => ($selectedCostView = item.key)}
          >
            {item.label}
          </Button>
        {/each}
      </ButtonGroup>
    {/if}
  </div>
</div>

<style>
  .label {
    display: flex;
    align-items: center;
    margin: 0 10px;
    white-space: nowrap;
  }

  .price {
    white-space: nowrap;
    font-size: 0.8em;
    font-weight: bold;
    margin-left: 8px;
  }

  .input-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    background-color: var(--color-grey-100);
    padding-block: var(--size-2);
  }

  .input {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
