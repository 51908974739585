<script>
  export let expand = undefined
</script>

<div class="row">
  <slot name="top" />
</div>
{#if expand !== undefined}
  <div class="row details" class:expanded={expand} class:collapsed={!expand}>
    <slot name="details" />
  </div>
{/if}

<style>
  .row {
    display: flex;
    flex-direction: row;
    margin-bottom: var(--size-1);
    justify-content: center;
  }

  .row.expanded {
    animation-duration: 0.5s;
    animation-name: expand-row;
    height: 250px;
  }

  .row.collapsed {
    animation-duration: 0.5s;
    animation-name: collapse-row;
    height: 0px;
    margin-bottom: 0;
  }

  @keyframes expand-row {
    0% {
      height: 0px;
    }
    100% {
      height: 250px;
    }
  }

  @keyframes collapse-row {
    0% {
      height: 250px;
    }
    100% {
      height: 0px;
    }
  }
</style>
