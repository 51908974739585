export function screenToSVG(svg, screenX, screenY) {
    const point = svg.createSVGPoint();
    point.x = screenX;
    point.y = screenY;
    return point.matrixTransform(svg.getScreenCTM().inverse());
}
export function SVGToScreen(svg, svgX, svgY) {
    const point = svg.createSVGPoint();
    point.x = svgX;
    point.y = svgY;
    return point.matrixTransform(svg.getScreenCTM());
}
export const arr2Map = (arr, key) => arr.reduce((acc, cur) => (Object.assign(Object.assign({}, acc), { [cur[key]]: cur })), {});
export const arr2Map2 = (arr, accessor) => arr.reduce((acc, cur) => (Object.assign(Object.assign({}, acc), { cur, [accessor(cur)]: cur })), {});
