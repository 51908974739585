<script>
  import { activeMainView } from '../../stores.js'
  import ScenarioCostComparison from '../../charts/cost/ScenarioCostComparison.svelte'
  import { _ } from 'svelte-i18n'
  import { onMount, onDestroy } from 'svelte'
  import CostControls from '../../charts/cost/CostControls.svelte'

  onMount(() => {
    activeMainView.set('cost')
  })

  onDestroy(() => {
    activeMainView.set(undefined)
  })
</script>

<div class="wrapper">
  <CostControls />

  <div class="charts-wrapper">
    <ScenarioCostComparison />
  </div>
</div>

<style>
  .wrapper {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .charts-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
</style>
