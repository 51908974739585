<script>
  import Demand from './Demand.svelte'
  import Imports from './Imports.svelte'
  import Methodology from './Methodology.svelte'
  import Scenarios from './Scenarios.svelte'
  import Technologies from './Technologies.svelte'
  import { _ } from 'svelte-i18n'
</script>

<Methodology title={$_('page.sections.about.sections.methodology')} />
<Technologies title={$_('page.sections.about.sections.technologies')} />
<Demand title={$_('page.sections.about.sections.demand')} />
<Imports title={$_('page.sections.about.sections.imports')} />
<Scenarios title={$_('page.sections.about.sections.scenarios')} />
