import { sum, range } from 'd3-array';
import { IGNORE_TECH, keys, temperatureKeys } from './constants';
const availabilitySliderMapping = {
    switzerland: {
        biomasse: 'biomasse',
        ccs_biomasse: 'biomasse',
        ccs_fossil: 'ccs_fossil',
        ga_total: 'gas',
        gtp: 'gtp',
        hc_total: 'fixed',
        hs: 'hydro',
        ror: 'hydro',
        nu: 'nuclear',
        bc_total: 'nuclear',
        oth_ee_total: 'other_ren',
        oth_fossil_total: 'other_fossil',
        ps: 'pv',
        pv: 'pv',
        pv_alpin: 'pv',
        wind_off: 'wind',
        wind_on: 'wind',
    },
    neighbors: {
        bc_total: 'fossil',
        biomasse: 'carbon_neutral',
        ccs_biomasse: 'carbon_neutral',
        ccs_fossil: 'carbon_neutral',
        ga_total: 'fossil',
        gtp: 'carbon_neutral',
        hc_total: 'fossil',
        hs: 'carbon_neutral',
        nu: 'kernenergie',
        oth_ee_total: 'carbon_neutral',
        oth_fossil_total: 'fossil',
        ps: 'carbon_neutral',
        pv: 'wind_and_pv',
        pv_alpin: 'wind_and_pv',
        ror: 'carbon_neutral',
        wind_off: 'wind_and_pv',
        wind_on: 'wind_and_pv',
    },
};
export const calculateImportCapacity = function (importCapacityInput, euGridLimitation, month) {
    return ['DE', 'FR', 'IT', 'AT'].reduce((acc, country) => {
        const importCapacity = month.input[country].exportCapacity;
        // fixing 2020 and 2021
        const sliderValue = month.parentYear.year <= 2021 ? 0 : importCapacityInput[country].value;
        // MW
        const NTCBaseCase = Math.min(Math.max(importCapacity.min, importCapacity.scenario +
            sliderValue *
                (sliderValue < 0
                    ? importCapacity.scenario - importCapacity.min
                    : importCapacity.max - importCapacity.scenario)), importCapacity.max);
        const stressor = month.parentYear.year >=
            month.input[country].exportCapacity.ntc_start_year
            ? euGridLimitation.value
            : 0;
        // MW
        const finalNTC = Math.min(NTCBaseCase * (1 - stressor) + importCapacity['70%'] * stressor, NTCBaseCase);
        acc[country] = finalNTC;
        return acc;
    }, {});
};
export const calculateImportEnergy = function (month) {
    return ['DE', 'FR', 'IT', 'AT'].reduce((acc, country) => {
        acc[country] =
            (month.calculated.importCapacity[country] * month.hours) / 1000 / 1000; // TWh
        return acc;
    }, {});
};
export const calculateCapacity = function (installedCapacity, customCapacity, year) {
    return ['CH', 'DE', 'FR', 'IT', 'AT'].reduce((acc, country) => {
        if (country !== 'CH') {
            acc[country] = year.input[country].capacity.scenario;
        }
        else {
            const min = year.input.CH.capacity.min;
            const max = year.input.CH.capacity.max;
            acc['CH'] = Object.keys(customCapacity).reduce((acc, key) => {
                const customCapacityValue = customCapacity[key][year.year - 2020];
                acc[key] =
                    customCapacityValue +
                        installedCapacity[key].value *
                            (installedCapacity[key].value < 0
                                ? customCapacityValue - min[key]
                                : max[key] - customCapacityValue);
                return acc;
            }, {});
        }
        return acc;
    }, {});
};
export const calculateAnnualDemand = function (demand, year) {
    return ['CH', 'DE', 'FR', 'IT', 'AT'].reduce((acc, country) => {
        if (country === 'CH') {
            const min = year.input[country].demand.min;
            const max = year.input[country].demand.max;
            const scenario = year.input[country].demand.scenario;
            const userValues = ['klass', 'ev', 'h2', 'hp'].reduce((acc, cur) => {
                acc[cur] =
                    scenario[cur] +
                        demand[cur].value *
                            (demand[cur].value < 0
                                ? scenario[cur] - min[cur]
                                : max[cur] - scenario[cur]);
                return acc;
            }, {});
            acc['CH'] = {
                absolute: Object.assign(Object.assign({}, ['min', 'max', 'scenario'].reduce((acc, cur) => {
                    acc[cur] = {
                        klass: year.input[country].demand[cur].klass,
                        ev: year.input[country].demand[cur].ev,
                        h2: year.input[country].demand[cur].h2,
                        hp: year.input[country].demand[cur].hp,
                    };
                    return acc;
                }, {})), { userValues }),
                relative: Object.assign(Object.assign({}, ['min', 'max', 'scenario'].reduce((acc, cur) => {
                    acc[cur] = {
                        klass: (year.input[country].demand[cur].klass * 1000000) /
                            year.input.inputDemandSliders['Bevölkerungsentwicklung'],
                        ev: (year.input[country].demand[cur].ev *
                            year.input.inputDemandSliders['Anteil BEV an PC']) /
                            year.input.inputDemandSliders['Nachfrage BEV (TWh)'],
                        h2: year.input[country].demand[cur].h2,
                        hp: (year.input[country].demand[cur].hp *
                            year.input.inputDemandSliders['Anteil Wärmepumpe im Basisszenario']) /
                            year.input.inputDemandSliders['Nachfrage WP (TWh)'],
                    };
                    return acc;
                }, {})), { userValues: {
                        klass: (userValues.klass * 1000000) /
                            year.input.inputDemandSliders['Bevölkerungsentwicklung'],
                        ev: (userValues.ev *
                            year.input.inputDemandSliders['Anteil BEV an PC']) /
                            year.input.inputDemandSliders['Nachfrage BEV (TWh)'],
                        h2: userValues.h2,
                        hp: (userValues.hp *
                            year.input.inputDemandSliders['Anteil Wärmepumpe im Basisszenario']) /
                            year.input.inputDemandSliders['Nachfrage WP (TWh)'],
                    } }),
            };
        }
        else {
            acc[country] = year.input[country].demand.scenario;
        }
        return acc;
    }, {});
};
export const calculatedDemandLosses = function (year) {
    return (year.input.CH.losses.netzverluste *
        sum(Object.values(year.calculated.demand.CH.absolute.userValues)) +
        year.input.CH.losses.speicherverluste);
};
export const calculateAvailability = function (swissTech, neighborTech, stressors, month) {
    // TODO: remove dunkelflaute??????
    return ['CH', 'DE', 'FR', 'IT', 'AT'].reduce((acc, country) => {
        const entries = country === 'CH'
            ? Object.entries(month.input[country].availability.technology).filter((d) => !IGNORE_TECH.includes(d[0]))
            : Object.entries(month.input[country].availability.technology);
        acc[country] = entries.reduce((acc, entry) => {
            const userSetting = country === 'CH'
                ? swissTech[availabilitySliderMapping.switzerland[entry[0]]]
                : neighborTech[availabilitySliderMapping.neighbors[entry[0]]];
            // apply stressors
            if (country === 'FR' && entry[0] === 'nu') {
                acc[entry[0]] = entry[1][userSetting] * stressors.nu_fr.value;
            }
            else if (entry[0] === 'ga_total') {
                acc[entry[0]] =
                    entry[1][userSetting] * stressors.gas_availability.value;
            }
            else if (['pv', 'pv_alpin', 'wind_on', 'wind_off', 'hs', 'ror'].includes(entry[0])) {
                if (stressors.pv_wind_hydro_generation.value === 0) {
                    // very low
                    acc[entry[0]] = entry[1].min;
                }
                else if (stressors.pv_wind_hydro_generation.value === 1) {
                    // low
                    acc[entry[0]] = 0.5 * entry[1].min + 0.5 * entry[1].avg;
                }
                else if (stressors.pv_wind_hydro_generation.value === 2) {
                    // medium
                    acc[entry[0]] = entry[1].avg;
                }
                else if (stressors.pv_wind_hydro_generation.value === 3) {
                    // high
                    acc[entry[0]] = 0.5 * entry[1].avg + 0.5 * entry[1].max;
                }
                else if (stressors.pv_wind_hydro_generation.value === 4) {
                    // very high
                    acc[entry[0]] = entry[1].max;
                }
            }
            else {
                acc[entry[0]] = entry[1][userSetting];
            }
            return acc;
        }, {});
        return acc;
    }, {
        CH: {},
        DE: {},
        FR: {},
        IT: {},
        AT: {},
    });
};
export const calculateAvailabilityNoStressor = function (swissTech, neighborTech, dunkelflaute, month) {
    // TODO: remove dunkelflaute??????
    // const dunkelflauteRelative = dunkelflaute / 30
    const dunkelflauteRelative = 1;
    return ['CH', 'DE', 'FR', 'IT', 'AT'].reduce((acc, country) => {
        const entries = country === 'CH'
            ? Object.entries(month.input[country].availability.technology).filter((d) => !IGNORE_TECH.includes(d[0]))
            : Object.entries(month.input[country].availability.technology);
        acc[country] = entries.reduce((acc, entry) => {
            const userSetting = country === 'CH'
                ? swissTech[availabilitySliderMapping.switzerland[entry[0]]]
                : neighborTech[availabilitySliderMapping.neighbors[entry[0]]];
            if (['pv', 'pv_alpin', 'wind_on', 'wind_off'].includes(entry[0])) {
                acc[entry[0]] =
                    entry[1].min * dunkelflauteRelative +
                        (1 - dunkelflauteRelative) *
                            entry[1].avg *
                            month.input[country].availability.ev[entry[0]][userSetting];
            }
            else {
                acc[entry[0]] = entry[1][userSetting];
            }
            return acc;
        }, {});
        return acc;
    }, {
        CH: {},
        DE: {},
        FR: {},
        IT: {},
        AT: {},
    });
};
export const calculateProduction = function (month) {
    return ['CH', 'DE', 'FR', 'IT', 'AT'].reduce((acc, country) => {
        const availability = month.calculated.availability[country];
        const entries = country === 'CH'
            ? Object.entries(availability).filter((d) => !IGNORE_TECH.includes(d[0]))
            : Object.entries(availability);
        // Object.entries(availability)
        // .filter((d) => !IGNORE_TECH.includes(d[0]))
        entries.forEach((entry) => {
            acc[country][entry[0]] =
                ((entry[1] *
                    month.parentYear.calculated.capacity[country][entry[0]]) /
                    1000 /
                    1000) *
                    month.hours;
        });
        return acc;
    }, {
        CH: {},
        DE: {},
        FR: {},
        IT: {},
        AT: {},
    });
};
export const calculateAnnualProduction = function (year) {
    return ['CH', 'DE', 'FR', 'IT', 'AT'].reduce((acc, country) => {
        const keys = Object.keys(year.input.CH.capacity.scenario);
        year.months.forEach((month) => {
            keys.forEach((key) => {
                if (!acc[country][key]) {
                    acc[country][key] = 0;
                }
                acc[country][key] += month.calculated.production[country][key];
            });
        });
        return acc;
    }, {
        CH: {},
        DE: {},
        FR: {},
        IT: {},
        AT: {},
    });
};
export const calculateMonthlyCapacity = function (month) {
    return ['CH'].reduce((acc, country) => {
        const availability = month.calculated.availability[country];
        Object.entries(availability).forEach((entry) => {
            acc[country][entry[0]] = {
                capacity: (month.parentYear.calculated.capacity.CH[entry[0]] / 1000 / 1000) *
                    month.hours,
                min: (month.parentYear.input.CH.capacity.min[entry[0]] / 1000 / 1000) *
                    month.hours,
                max: (month.parentYear.input.CH.capacity.max[entry[0]] / 1000 / 1000) *
                    month.hours,
            };
        });
        return acc;
    }, {
        CH: {},
        DE: {},
        FR: {},
        IT: {},
        AT: {},
    });
};
// export const calculateCO2 = function (month) {
//   return ['CH', 'DE', 'FR', 'IT', 'AT'].reduce(
//     (acc, country) => {
//       const production = month.calculated.production[country]
//       Object.entries(production).forEach((entry) => {
//         acc[country][entry[0]] =
//           month.parentYear.input.co2[entry[0]].co2_emission *
//           month.calculated.production[country][entry[0]]
//       })
//       return acc
//     },
//     {
//       CH: {},
//       DE: {},
//       FR: {},
//       IT: {},
//       AT: {},
//     }
//   )
// }
export const calculateMonthlyDemand = function (month, stressors, temperature) {
    return ['CH', 'DE', 'FR', 'IT', 'AT'].reduce((acc, country) => {
        //Monthly demand [TWh/month] = Annual demand x Availability from monthly demand profile (DB) x Number of hours per year (DB)
        if (country === 'CH') {
            const demandObj = month.parentYear.calculated.demand[country].absolute.userValues;
            Object.entries(demandObj).forEach((entry) => {
                const value = entry[0] === 'klass'
                    ? entry[1] + month.parentYear.calculated.demandLosses
                    : entry[1];
                acc.CH[entry[0]] =
                    value *
                        month.input.CH.availability.category[entry[0]].avg *
                        (month.hours / month.parentYear.calculated.hours);
            });
        }
        else {
            const demand = month.parentYear.calculated.demand[country];
            Object.entries(demand).forEach((entry) => {
                acc[country][entry[0]] =
                    entry[1] *
                        month.input[country].availability.category[entry[0]].avg *
                        (month.hours / month.parentYear.calculated.hours);
            });
        }
        // TODO: check if this is correct: klass temperature used for all keys????
        ;
        ['hp', 'ev'].forEach((key) => {
            acc[country][key] =
                acc[country][key] *
                    (1 +
                        temperature[country]['klass'][month.month - 1][temperatureKeys[stressors.temperature.value]]);
        });
        ['h2', 'klass'].forEach((key) => {
            acc[country][key] =
                acc[country][key] *
                    (1 +
                        temperature[country]['klass'][month.month - 1][temperatureKeys[stressors.temperature.value]] +
                        stressors.demand_reduction.value);
        });
        return acc;
    }, {
        CH: {},
        DE: {},
        FR: {},
        IT: {},
        AT: {},
    });
};
// export const calculateMonthlyDemandNoStressor = function (month) {
//   return ['CH', 'DE', 'FR', 'IT', 'AT'].reduce(
//     (acc, country) => {
//       //Monthly demand [TWh/month] = Annual demand x Availability from monthly demand profile (DB) x Number of hours per year (DB)
//       if (country === 'CH') {
//         const demandObj =
//           month.parentYear.calculated.demand[country].absolute.userValues
//         Object.entries(demandObj).forEach((entry) => {
//           const value =
//             entry[0] === 'klass'
//               ? entry[1] + month.parentYear.calculated.demandLosses
//               : entry[1]
//           acc.CH[entry[0]] =
//             value *
//             month.input.CH.availability.category[entry[0]].avg *
//             (month.hours / month.parentYear.calculated.hours)
//         })
//       } else {
//         const demand = month.parentYear.calculated.demand[country]
//         Object.entries(demand).forEach((entry) => {
//           acc[country][entry[0]] =
//             entry[1] *
//             month.input[country].availability.category[entry[0]].avg *
//             (month.hours / month.parentYear.calculated.hours)
//         })
//       }
//       return acc
//     },
//     {
//       CH: {},
//       DE: {},
//       FR: {},
//       IT: {},
//       AT: {},
//     }
//   )
// }
export const calculateTotalProduction = function (month) {
    return ['CH', 'DE', 'FR', 'IT', 'AT'].reduce((acc, country) => {
        acc[country] = Object.values(month.calculated.production[country]).reduce((a, b) => a + b, 0);
        return acc;
    }, {
        CH: {},
        DE: {},
        FR: {},
        IT: {},
        AT: {},
    });
};
export const calculateTotalDemand = function (month) {
    return ['CH', 'DE', 'FR', 'IT', 'AT'].reduce((acc, country) => {
        acc[country] = Object.values(month.calculated.demand[country]).reduce((a, b) => a + b, 0);
        return acc;
    }, {
        CH: {},
        DE: {},
        FR: {},
        IT: {},
        AT: {},
    });
};
export const calculateSurplus = function (month) {
    return ['CH', 'DE', 'FR', 'IT', 'AT'].reduce((acc, country) => {
        acc[country] =
            month.calculated.totalProduction[country] -
                month.calculated.totalDemand[country];
        return acc;
    }, {
        CH: {},
        DE: {},
        FR: {},
        IT: {},
        AT: {},
    });
};
export const calculateTotalNeighborSurplus = function (month) {
    return ['DE', 'FR', 'IT', 'AT'].reduce((acc, country) => acc + month.calculated.surplus[country], 0);
};
export const calculateTotalSurplus = function (month) {
    return ['CH', 'DE', 'FR', 'IT', 'AT'].reduce((acc, country) => acc + month.calculated.surplus[country], 0);
};
export const calculateEnergyDeficit = function (month) {
    const calc = (acc, country) => acc + Math.max(0, -month.calculated.surplus[country]);
    return {
        CH: ['DE', 'FR', 'IT', 'AT'].reduce(calc, 0),
        DE: ['FR', 'AT', 'CH'].reduce(calc, 0) + month.input.DE.surplus,
        FR: ['DE', 'IT', 'CH'].reduce(calc, 0) + month.input.FR.surplus,
        IT: ['FR', 'AT', 'CH'].reduce(calc, 0) + month.input.IT.surplus,
        AT: ['DE', 'IT', 'CH'].reduce(calc, 0) + month.input.AT.surplus,
    };
};
export const calculateImportPotential = function (month) {
    return ['DE', 'FR', 'IT', 'AT'].reduce((acc, country) => {
        acc[country] =
            month.calculated.energyDeficit[country] !== 0
                ? (Math.max(-month.calculated.surplus.CH, 0) /
                    month.calculated.energyDeficit[country]) *
                    Math.max(month.calculated.surplus[country], 0)
                : Math.max(0, month.calculated.surplus[country]);
        return acc;
    }, {
        DE: {},
        FR: {},
        IT: {},
        AT: {},
    });
};
export const calculateMaxImportability = function (month) {
    return ['DE', 'FR', 'IT', 'AT'].reduce((acc, country) => {
        acc[country] = Math.min(month.calculated.importPotential[country], month.calculated.importEnergy[country]);
        return acc;
    }, {
        DE: {},
        FR: {},
        IT: {},
        AT: {},
    });
};
export const calculateCHTotalSurplus = function (month) {
    const chProduction = Object.values(month.calculated.production.CH).reduce((a, b) => a + b, 0);
    const chImport = Object.values(month.calculated.maxImportability).reduce((a, b) => a + b, 0);
    const chDemand = Object.values(month.calculated.demand.CH).reduce((a, b) => a + b, 0);
    return chProduction + chImport - chDemand;
};
export const calculateCHTotalSurplusWithoutImport = function (month) {
    const chProduction = Object.values(month.calculated.production.CH).reduce((a, b) => a + b, 0);
    const chDemand = Object.values(month.calculated.demand.CH).reduce((a, b) => a + b, 0);
    return chProduction - chDemand;
};
export const calculateSelfSufficientDays = function (month) {
    return Math.min(Math.round((month.calculated.totalProduction.CH / month.calculated.totalDemand.CH) *
        30), 30);
};
export const calculateMinAnnualSelfSufficientDays = function (year) {
    return year.months.reduce((acc, cur) => acc + cur.calculated.selfSufficientDays, 0);
};
export const calculateGeneration = function (month, generationStack, speicherReserveInput, previousYear) {
    const totalDemandCH = month.calculated.totalDemand.CH;
    const alwaysOnTechKeys = generationStack
        .filter((d) => d.potentialSelection === false)
        .sort((a, b) => a.order - b.order)
        .map((d) => d.key);
    const alwaysOnGeneration = alwaysOnTechKeys.reduce((acc, key) => {
        acc[key] = month.calculated.production.CH[key];
        return acc;
    }, {});
    const alwaysOnGenerationTotal = sum(alwaysOnTechKeys.map((key) => month.calculated.production.CH[key]));
    let deficit = totalDemandCH - alwaysOnGenerationTotal;
    let importing = {};
    let optionalGeneration = {};
    // keys for technologies that can be turned on/off, only when needed (not green energy)
    const optionalTech = generationStack
        .filter((d) => d.potentialSelection === true)
        .sort((a, b) => a.order - b.order);
    if (deficit > 0) {
        optionalTech.forEach((tech) => {
            if (tech.isImport) {
                const country = tech.key.split('_')[1];
                const maxImport = month.calculated.maxImportability[country];
                importing[country] = Math.min(deficit, maxImport);
                deficit -= importing[country];
            }
            else {
                optionalGeneration[tech.key] = Math.min(deficit, month.calculated.production.CH[tech.key]);
                deficit -= optionalGeneration[tech.key];
            }
        });
    }
    const alwaysOnTotal = sum(Object.values(alwaysOnGeneration));
    const optionalTotal = sum(Object.values(optionalGeneration));
    const importTotal = sum(Object.values(importing));
    let surplus2 = alwaysOnTotal + optionalTotal + importTotal - totalDemandCH;
    // --------------------- start of speicher reserve calculation
    const speicherReserve = {
        available: 0,
        used: 0,
    };
    if (month.parentYear.year < 2021 ||
        (month.parentYear.year === 2021 && month.month === 1)) {
        speicherReserve.available = speicherReserveInput.absoluteValue;
        speicherReserve.used = 0;
    }
    else {
        let previousMonth;
        if (month.month === 1) {
            previousMonth = previousYear.months[11];
        }
        else {
            previousMonth = month.parentYear.months[month.month - 2];
        }
        const storagePreviousMonth = previousMonth.calculated.generation.generation.speicherReserve.available;
        const userSpeicherReserve = speicherReserveInput.absoluteValue;
        const monthlyAdditionalDrain = surplus2 < 0 ? surplus2 : 0;
        const monthlyExcess = surplus2 > 0 ? surplus2 : 0;
        const monthlyAdditionalInflow = Math.min(speicherReserveInput.shareOfHSGenerationThatCanBeUsed *
            alwaysOnGeneration.hs, monthlyExcess);
        let available = Math.max(0, Math.min(userSpeicherReserve, storagePreviousMonth + monthlyAdditionalDrain + monthlyAdditionalInflow));
        let used = storagePreviousMonth - available;
        speicherReserve.available = available;
        speicherReserve.used = used;
    }
    deficit -= speicherReserve.used;
    // --------------------- end of speicher reserve calculation
    const surplus = alwaysOnTotal +
        optionalTotal +
        importTotal +
        speicherReserve.used -
        totalDemandCH;
    const result = {
        demand: totalDemandCH,
        import: {
            byCountry: importing,
            total: importTotal,
        },
        generation: {
            byTechnology: Object.assign(keys.reduce((acc, cur) => {
                acc[cur] = 0;
                return acc;
            }, {}), alwaysOnGeneration, optionalGeneration),
            total: {
                alwaysOn: alwaysOnTotal,
                optional: optionalTotal,
                import: importTotal,
                total: alwaysOnTotal + optionalTotal + importTotal + speicherReserve.used,
            },
            speicherReserve,
        },
        surplus: surplus < 0.0000000000001 ? 0 : surplus,
        deficit: Math.max(0, deficit) < 0.0000000000001 ? 0 : Math.max(0, deficit),
    };
    return result;
};
export const calculateSpeicherReserve2 = function (month, input, previousYear) {
    if (!month.calculated.generation.generation.speicherReserve) {
        month.calculated.generation.generation.speicherReserve = {};
    }
    if (month.parentYear.year < 2021 ||
        (month.parentYear.year === 2021 && month.month === 1)) {
        month.calculated.generation.generation.speicherReserve.available =
            input.speicherReserve.absoluteValue;
        month.calculated.generation.generation.speicherReserve.used = 0;
        return;
    }
    let previousMonth;
    if (month.month === 1) {
        previousMonth = previousYear.months[11];
    }
    else {
        previousMonth = month.parentYear.months[month.month - 2];
    }
    const storagePreviousMonth = previousMonth.calculated.generation.generation.speicherReserve.available;
    const userSpeicherReserve = input.speicherReserve.absoluteValue;
    const surplusWithImport = month.calculated.surplus.CH + month.calculated.generation.import.total;
    const monthlyAdditionalDrain = surplusWithImport < 0 ? surplusWithImport : 0;
    const monthlyExcess = surplusWithImport > 0 ? surplusWithImport : 0;
    const monthlyAdditionalInflow = Math.min(input.speicherReserve.shareOfHSGenerationThatCanBeUsed *
        month.calculated.generation.generation.byTechnology.hs, monthlyExcess);
    let available = Math.max(0, Math.min(userSpeicherReserve, storagePreviousMonth + monthlyAdditionalDrain + monthlyAdditionalInflow));
    let used = storagePreviousMonth - available;
    month.calculated.generation.generation.speicherReserve.available = available;
    month.calculated.generation.generation.speicherReserve.used = used;
};
export const calculateAnnualGeneration = function (year) {
    const byTechnology = year.months.reduce((acc, month) => {
        Object.entries(month.calculated.generation.generation.byTechnology).forEach((entry) => {
            if (!acc[entry[0]]) {
                acc[entry[0]] = entry[1];
            }
            else {
                acc[entry[0]] += entry[1];
            }
        });
        return acc;
    }, {});
    return (year.calculated.generation = byTechnology);
};
export const calculateAnnualCost = function (year, previousYear, year2023, allYears, costPrice) {
    const capacity = year.calculated.capacity.CH;
    const generation = year.calculated.generation;
    const pvShareOfRooftop = year.input.pv_small_scale.share_of_new_rooftop;
    // also: pv is split into pv small and pv large
    const fullLoadHours = Object.keys(capacity).reduce((acc, key) => {
        const value = capacity[key] === 0 ? 0 : (generation[key] / capacity[key]) * 1000 * 1000;
        if (key === 'pv') {
            acc['pv_large'] = value;
            acc['pv_small'] = value;
        }
        else {
            acc[key] = value;
        }
        return acc;
    }, {});
    const keys = Object.keys(fullLoadHours);
    const newCapacity = Object.keys(capacity).reduce((acc, key) => {
        const value = previousYear === undefined
            ? 0
            : Math.max(0, capacity[key] - previousYear.calculated.capacity.CH[key]);
        if (key === 'pv') {
            acc['pv_large'] = value * (1 - pvShareOfRooftop);
            acc['pv_small'] = value * pvShareOfRooftop;
        }
        else if (['hs', 'ror'].includes(key)) {
            const hsRorConstant = year.year < 2036 ? 810 / 17 / 1000 : 1470 / 15 / 1000;
            const rorScenario1 = year.input.restwasser.share_of_ror * hsRorConstant;
            const hsScenario1 = hsRorConstant - rorScenario1;
            if (key === 'ror') {
                acc[key] = (rorScenario1 * 1000 * 1000) / fullLoadHours[key];
            }
            else if (key === 'hs') {
                acc[key] = (hsScenario1 * 1000 * 1000) / fullLoadHours[key];
            }
        }
        else {
            acc[key] = value;
        }
        return acc;
    }, {});
    // TODO: multiply PVsmall/PVlarge with ratio????
    const generationFromNewCapacity = Object.entries(newCapacity).reduce((acc, [key, value]) => {
        acc[key] = (value * fullLoadHours[key]) / (1000 * 1000);
        return acc;
    }, {});
    // TODO: should year.input.cost.co2_emissions_lemm be the previous year??
    // TODO: investment cost is * 0 for nuclear and existing fuel. Can't this be solved in the investment cost dataset?
    const costOfNewCapacityLCOE = Object.entries(fullLoadHours).reduce((acc, [key, value]) => {
        const costInput = year.input.cost;
        const restwasserFactor = key === 'hs'
            ? year.input.restwasser.necessary_investment_costs_hs
            : key === 'ror'
                ? year.input.restwasser.necessary_investment_costs_ror
                : 1;
        acc[key] =
            value === 0
                ? 0
                : ((restwasserFactor *
                    costInput.investment_costs[key] *
                    (['nu', 'oth_fossil_total'].includes(key) ? 0 : 1) *
                    costInput.alfa[key] +
                    costInput.fix_ou_m_costs[key]) *
                    1000) /
                    value +
                    costInput.variable_ou_m_costs[key] +
                    (costInput.co2_emissions_lemm[key] * costInput.co2_costs[key]) /
                        costInput.efficiency[key] +
                    costInput.fuel_costs[key] / costInput.efficiency[key] +
                    costInput.co2_ruckhaltung_lemm[key] *
                        costInput.co2_storage_transport[key] || 0;
        return acc;
    }, {});
    // TODO: should be an actual slider????
    // const SLIDER_VALUE = 70
    const SLIDER_VALUE = costPrice;
    const baseValue = SLIDER_VALUE / year.input.cost.chf_to_eur;
    const yearlyIncomeALL = keys.reduce((acc, key) => {
        const until2050 = allYears.reduce((acc2, y, i) => {
            let usedPowerPrice = y.input.cost.power_price[key] * baseValue;
            if (key === 'pv_small') {
                usedPowerPrice +=
                    (y.input.pv_small_scale.additional_income *
                        y.input.pv_small_scale.rate_of_self_consumption) /
                        y.input.cost.chf_to_eur;
            }
            acc2.push(usedPowerPrice);
            return acc2;
        }, []);
        const until2150 = range(2051, 2151).map((d) => until2050[until2050.length - 1]);
        acc[key] = until2050.concat(until2150);
        return acc;
    }, {});
    const weightALL = keys.reduce((acc, key) => {
        const subsidyPeriod = year2023.input.cost.subsidy_period[key];
        acc[key] = range(1, 142).reduce((acc2, y, i) => {
            if (i === 0) {
                acc2.push(1);
            }
            else {
                acc2.push(y <= subsidyPeriod
                    ? acc2[acc2.length - 1] / (1 + year2023.input.cost.wacc[key])
                    : 0);
            }
            return acc2;
        }, []);
        return acc;
    }, {});
    const npvFactor = keys.reduce((acc, key) => {
        acc[key] =
            (1 -
                (1 - year.input.cost.wacc[key]) **
                    year.input.cost.depreciation_time[key]) /
                (1 - (1 - year.input.cost.wacc[key]));
        return acc;
    }, {});
    const npvCosts = keys.reduce((acc, key) => {
        acc[key] = costOfNewCapacityLCOE[key] * npvFactor[key];
        return acc;
    }, {});
    const npvIncome = keys.reduce((acc, key) => {
        acc[key] = sum(yearlyIncomeALL[key]
            .slice(year.year - 2020)
            .map((d, i) => d * weightALL[key][i]));
        return acc;
    }, {});
    const netNPV = keys.reduce((acc, key) => {
        acc[key] = npvIncome[key] - npvCosts[key];
        return acc;
    }, {});
    const yearlyInvestmentSubsidies = keys.reduce((acc, key) => {
        acc[key] = Math.max(0, -netNPV[key]) * generationFromNewCapacity[key];
        return acc;
    }, {});
    const cumulatedYearlyInvestmentSubsidies = keys.reduce((acc, key) => {
        if (year.year === 2020) {
            acc[key] = 0;
        }
        else {
            acc[key] =
                previousYear.calculated.costs.cumulatedYearlyInvestmentSubsidies[key] +
                    yearlyInvestmentSubsidies[key];
        }
        return acc;
    }, {});
    if (!year.calculated.costs) {
        year.calculated.costs = {};
    }
    // store this intermediate value so that it can be accessed for calculating the cumulative value
    year.calculated.costs['cumulatedYearlyInvestmentSubsidies'] =
        cumulatedYearlyInvestmentSubsidies;
    const demandWithoutLosses = sum(Object.values(year.calculated.demand.CH.absolute.userValues));
    const prevYearPVSmall = year.year === 2020 ? 0 : previousYear.calculated.costs.consumptionPVSmall;
    const consumptionPVSmall = generationFromNewCapacity.pv_small + prevYearPVSmall;
    year.calculated.costs.consumptionPVSmall = consumptionPVSmall;
    const selfConsumptionPVSmall = year.input.pv_small_scale.rate_of_self_consumption * consumptionPVSmall;
    const basisDemand = demandWithoutLosses -
        selfConsumptionPVSmall -
        demandWithoutLosses *
            year.input.grid_premium['Abschlag Nachfrage Grossverbraucher'] -
        demandWithoutLosses * year.input.grid_premium['Abschlag Verzugskosten'];
    const incomeGridPremium = ((basisDemand * year.input.grid_premium.Netzzuschlag) / 100) * 1000;
    const einspeisevergutung = year.year === 2020
        ? 0
        : (year.input.grid_premium['Föderbedarf Einspeisevergütung'] /
            year.input.grid_premium.Netzzuschlag) *
            incomeGridPremium;
    const marktpramie = year.year === 2020
        ? 0
        : (year.input.grid_premium['Marktprämie'] /
            year.input.grid_premium.Netzzuschlag) *
            incomeGridPremium;
    const IBGrosswasser = year.year === 2020
        ? 0
        : (year.input.grid_premium['IB Grosswasser'] /
            year.input.grid_premium.Netzzuschlag) *
            incomeGridPremium;
    const IBKleinwasser = year.year === 2020
        ? 0
        : (year.input.grid_premium['IB Kleinwasser'] /
            year.input.grid_premium.Netzzuschlag) *
            incomeGridPremium;
    const effizienzausschreibungenUndSanierungen = year.year === 2020
        ? 0
        : (year.input.grid_premium['Effizienzausschreibungen & Sanierungen'] /
            year.input.grid_premium.Netzzuschlag) *
            incomeGridPremium;
    const ruckvergutungMantelerlass = year.year === 2020
        ? 0
        : (year.input.grid_premium['Rückvergütung Mantelerlass'] /
            year.input.grid_premium.Netzzuschlag) *
            incomeGridPremium;
    const availableSupportGridPremium = year.year < 2022
        ? 0
        : incomeGridPremium -
            (einspeisevergutung +
                marktpramie +
                IBGrosswasser +
                IBKleinwasser +
                effizienzausschreibungenUndSanierungen +
                ruckvergutungMantelerlass);
    const sumOfYearlyInvestmentSubsidiesIB = year.year < 2022
        ? 0
        : keys.reduce((acc, key) => (acc +=
            year.input.cost.chf_to_eur *
                year.input.cost.receiving_subsidies[key] *
                yearlyInvestmentSubsidies[key]), 0);
    year.calculated.costs.totalInvestment =
        year.year < 2022
            ? year.input.grid_premium['Bestand Netzzuschlag']
            : previousYear.calculated.costs.totalInvestment +
                availableSupportGridPremium -
                sumOfYearlyInvestmentSubsidiesIB;
    // TODO: Fossil fuel values are not exactly matching the Excel file (topping off)
};
