<script>
  import RangeSlider from '../ui/RangeSlider.svelte'
  import { globalIsEdited, selectedYear, calculatedData } from './../stores.ts'
  import {
    scenarios,
    scenarioCH,
    userSettings,
    userSettingsInitial,
  } from './../stores'
  import { _ } from 'svelte-i18n'
  import InfoIcon from '../ui/InfoIcon.svelte'
  import { track } from '../track.js'

  let scenarioHover = false
  let periodHover = false
  let yearHover = false

  let periodItems = ['wholeYear', 'winter', 'summer']
  let selectedPeriod = 0

  function resetScenario() {
    const tmp = $userSettings
    console.log(tmp)

    Object.keys(tmp.general.stressors).forEach((key) => {
      tmp.general.stressors[key].value = tmp.general.stressors[key].defaultValue
      tmp.general.stressors[key].edited = false
    })

    Object.keys(tmp.neighboringCountries.importCapacity).forEach((key) => {
      tmp.neighboringCountries.importCapacity[key].value = 0
      tmp.neighboringCountries.importCapacity[key].edited = false
    })

    Object.keys(tmp.switzerland.installedCapacity).forEach((key) => {
      tmp.switzerland.installedCapacity[key].value = 0
      tmp.switzerland.installedCapacity[key].edited = false
    })

    Object.keys(tmp.switzerland.customCapacity).forEach((key) => {
      tmp.switzerland.customCapacity[key] = $calculatedData
        .getAnnualTimeSeries()
        .map((year) => year.input.CH.capacity.scenario[key])
    })

    Object.keys(tmp.switzerland.powerDemand).forEach((key) => {
      tmp.switzerland.powerDemand[key].value = 0
      tmp.switzerland.powerDemand[key].edited = false
    })

    $userSettings = tmp
  }
</script>

<div class="wrapper">
  <div class="main-selection">
    {$_('page.sections.charts.main_selection.showing_results')}
    <!-- {$globalIsEdited ? $_('page.sections.charts.main_selection.modified') : ''} -->
    {#if !$globalIsEdited}
      <InfoIcon
        top={-20}
        left={10}
        title={$_(
          `page.sections.userControls.switzerland.scenario.scenarios.${$scenarioCH}.label`
        )}
        description={$_(
          `page.sections.userControls.switzerland.scenario.scenarios.${$scenarioCH}.description`
        )}
      />
    {/if}
    {#if $scenarioCH}
      <button
        class="selected-text"
        on:mouseenter={() => (scenarioHover = true)}
        on:mouseleave={() => (scenarioHover = false)}
      >
        {#if $globalIsEdited}
          {$_(
            `page.sections.userControls.switzerland.scenario.scenarios.custom.label`
          )}
        {:else}
          {$_(
            `page.sections.userControls.switzerland.scenario.scenarios.${$scenarioCH}.label`
          )}
        {/if}
        {#if scenarioHover}
          <div class="panel scenario-panel">
            <div class="current-item">
              {#if $globalIsEdited}
                {$_(
                  `page.sections.userControls.switzerland.scenario.scenarios.custom.label`
                )}
              {:else}
                {$_(
                  `page.sections.userControls.switzerland.scenario.scenarios.${$scenarioCH}.label`
                )}
              {/if}
            </div>
            {#each $scenarios.CH.filter( (d) => ($globalIsEdited ? true : d !== $scenarioCH) ) as scenario, i}
              <!-- {#each items as item} -->
              <button
                class="item"
                on:click={() => {
                  scenarioHover = false

                  $scenarioCH = scenario

                  track(scenario)

                  resetScenario()
                }}
              >
                {$_(
                  `page.sections.userControls.switzerland.scenario.scenarios.${scenario}.label`
                )}
              </button>
            {/each}
          </div>
        {/if}
        <!-- {#if $globalIsEdited}*{/if} -->
      </button>
    {/if}
    {$_('page.sections.charts.main_selection.in_year')}
    <button
      class="selected-text"
      on:mouseenter={() => (yearHover = true)}
      on:mouseleave={() => (yearHover = false)}
    >
      {$selectedYear}
      {#if yearHover}
        <div class="panel year-panel">
          <button class="item year-button">{$selectedYear}</button>
          <div class="slider-wrapper">
            <RangeSlider bind:value={$selectedYear} min={2023} max={2050} />
          </div>
        </div>
      {/if}
    </button>
    <!-- ,
    <button
      class="selected-text"
      on:mouseenter={() => (periodHover = true)}
      on:mouseleave={() => (periodHover = false)}
    >
      {periodItems[selectedPeriod]}
      {#if periodHover}
        <div class="panel period-panel">
          <button class="item">{periodItems[selectedPeriod]}</button>
          {#each periodItems.filter((d) => periodItems.indexOf(d) !== selectedPeriod) as item, i}
            <button
              class="item"
              on:click={() => {
                periodHover = false
                selectedPeriod = periodItems.indexOf(item)
              }}
              >{$_(
                `page.sections.charts.energyMix.periodSelector.${item}`
              )}</button
            >
          {/each}
        </div>
      {/if}
    </button> -->

    {#if $globalIsEdited}
      <button class="global-reset-button" on:click={resetScenario}>
        <svg
          width="800px"
          height="800px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="width: 14px; height: 14px; transform: translate(0, -10%); margin-right: var(--size-1);"
        >
          <path
            d="M14.5 20L15.3182 19.1429C18.9628 15.3247 16.2565 9 10.9781 9H6M6 9L10 13M6 9L10 5"
            stroke="white"
            stroke-width={2}
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {$_('page.sections.charts.main_selection.reset')}
      </button>
    {/if}
  </div>
</div>

<style>
  .global-reset-button {
    background: var(--accent-color);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 20px;
    border-radius: 20px;
    cursor: pointer;
    padding: 12px 12px;
    font-size: var(--scale-2);
    margin-top: 8px;
  }

  .wrapper {
    height: 40px;
    display: flex;
    margin-left: 366px;
    position: relative;
    width: 100%;
    /* background-color: var(--extra-weak-color); */
    border-radius: 5px;
  }

  .main-selection {
    display: flex;
    font-family: 'Almarena Regular', 'Open Sans', sans-serif;
    font-size: 32px;
    color: var(--text-color);
    position: relative;
    top: -20px;
  }

  button {
    outline: none;
    border: none;
    background: none;
    padding: 0;
    margin: 0 8px;
    white-space: nowrap;
    border-radius: 5px;
  }

  .selected-text::after {
    content: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='8' xmlns='http://www.w3.org/2000/svg' fill='%23ff5d64'%3E%3Cpath d='M15.587.165a.567.567 0 00-.8 0L8.25 6.755 1.712.165a.567.567 0 00-.799 0C.696.385.696.75.913.897l6.901 6.957A.553.553 0 008.177 8c.146 0 .29-.073.364-.146L15.587.97a.578.578 0 000-.805z' fill-rule='nonzero'/%3E%3C/svg%3E");
  }

  .selected-text {
    text-decoration: underline;
    color: var(--accent-color);
    position: relative;
    font-size: 32px;
  }

  .item {
    padding: 3px;
    text-align: left;
    padding: 8px;
    margin-bottom: 2px;
    font-size: 16px;
  }

  .current-item {
    color: var(--accent-color);
    text-decoration: underline;
    font-size: 32px;
    text-align: left;
    margin: 8px 8px 8px 16px;
    white-space: nowrap;
    border-radius: 5px;
  }

  .item:first-child {
    color: var(--accent-color);
    text-decoration: underline;
    font-size: 32px;
    text-align: left;
  }

  .item:not(:first-child) {
    background-color: #f4f4f4;
  }

  .item:not(:first-child):hover {
    background-color: #ddd;
    cursor: pointer;
  }

  .panel {
    position: absolute;
    top: -25px;
    left: 0;
    background-color: #fff;
    box-shadow: 5px 5px 10px rgb(0 0 0 / 10%);
    border: 1px solid var(--weak-color);
    border-radius: 5px;
    color: black;
    z-index: 100;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-left: -37px;
  }

  .scenario-panel {
    min-width: 250px;
  }

  .period-panel {
    min-width: 150px;
  }

  .year-panel {
    min-width: 300px;
  }

  .slider-wrapper {
    margin: 12px 0;
  }
</style>
