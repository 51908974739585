<script>
  export let alignment = 'center'
</script>

<div
  class="tab-list"
  class:space-between={alignment === 'space-between'}
  class:center={alignment === 'center'}
>
  <slot />
</div>

<style>
  .tab-list {
    border-bottom: 1px solid var(--color-gray-400);
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
  }
</style>
