<script>
  import { getContext } from 'svelte'
  import { area, curveStep } from 'd3-shape'
  import { range } from 'd3-array'
  import { costDetailsHover } from '../../stores'

  const { data, xScale, yScale, height } = getContext('LayerCake')

  const costTypes = ['workingPrice', 'gridAndFixedPrice', 'subsidy', 'tax']

  $: a = area()
    .x((d) => $xScale(d.data.year))
    .y0((d) => $yScale(d[0]))
    .y1((d) => $yScale(d[1]))
    .curve(curveStep)
</script>

{#each range(2020, 2051, 5) as year}
  <text
    dx={-5}
    class="tick tick-year"
    transform="translate({$xScale(year)}, {$height}) rotate(-90)">{year}</text
  >
{/each}

{#each $yScale.nice().ticks() as tick}
  <line
    x2={$xScale.range()[1]}
    y1={$yScale(tick)}
    y2={$yScale(tick)}
    stroke="#efefef"
  />
  <text y={$yScale(tick)} class="tick" dx={-5}>{tick / 10}</text>
{/each}

{#each $data as d, i}
  <path
    on:click={() => console.log(d, $data)}
    d={a(d.filter((d, i) => i >= 3))}
    fill="var(--color-cost-{costTypes[i]})"
  />
  <!-- fill="hsl({(i / $data.length) * 360}, 50%, 50%)" -->
{/each}

{#each range(2023, 2051) as year}
  {@const barWidth = $xScale(year) - $xScale(year - 1)}
  <rect
    x={$xScale(year - 1) + barWidth / 2}
    y={0}
    width={barWidth}
    height={$yScale.range()[0]}
    style="outline: none;"
    rx={3}
    ry={3}
    opacity={0.2}
    fill={$costDetailsHover?.type === 'household_cost' &&
    year === $costDetailsHover?.year
      ? 'var(--accent-color)'
      : 'transparent'}
    on:mouseover={() =>
      ($costDetailsHover = {
        type: 'household_cost',
        data: Object.entries($data.map((d) => d[year - 2020])[0].data),
        year,
      })}
    on:mouseout={() => ($costDetailsHover = undefined)}
  />
{/each}

<style>
  .tick {
    text-anchor: end;
    dominant-baseline: central;
  }

  .tick-year {
    text-anchor: end;
  }
</style>
