<script>
  export let value
  export let min = 0
  export let max = 10
  export let step = 1
  export let markers
</script>

<input
  type="range"
  {min}
  {max}
  {step}
  style="width: 100%;  margin: 0;"
  list="markers"
  bind:value
  on:mousedown
  on:mouseup
  on:input
/>
{#if markers !== undefined}
  <datalist id="markers">
    {#each markers as marker}
      <option
        class:active-marker={marker.value === value}
        value={marker.value}
        label={marker.label}
      />
    {/each}
  </datalist>
{/if}

<style>
  .active-marker {
    font-weight: bold;
  }

  input {
    margin: var(--size-2) 0;
  }

  input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    margin: 0;
    width: 100%;
    border: none;
    background: transparent;
  }

  input[type='range']::slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 5px;
    border: 0.2px solid rgba(127, 127, 127, 0.5);
  }
  input[type='range']::slider-thumb {
    height: 18px;
    width: 18px;
    border-radius: 18px;
    background: var(--accent-color);
    cursor: pointer;
    margin-top: -7px;
  }

  input[type='range']:active::slider-thumb {
    background: var(--accent-color);
  }

  /* ---------- webkit --------- */
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: none;
    border-radius: 5px;
    border: 0.2px solid rgba(127, 127, 127, 0.5);
    -webkit-appearance: auto;
  }

  input[type='range']::-webkit-slider-thumb {
    height: 18px;
    width: 18px;
    border-radius: 18px;
    background: var(--accent-color);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }

  input[type='range']:active::-webkit-slider-thumb {
    box-shadow: 0 0 0 5px rgba(255, 93, 100, 0.3);
  }

  /* ---------- Mozilla --------- */
  input[type='range']::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 5px;
    border: 0.2px solid rgba(127, 127, 127, 0.5);
  }
  input[type='range']::-moz-range-thumb {
    height: 13px;
    width: 13px;
    border-radius: 13px;
    background: var(--accent-color);
    cursor: pointer;
    margin-top: -5px;
    border: none;
  }

  input[type='range']:active::-moz-range-thumb {
    background: var(--accent-color);
  }

  /* ---------- Microsoft --------- */
  input[type='range']::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 5px;
    border: 0.2px solid rgba(127, 127, 127, 0.5);
  }
  input[type='range']::-ms-thumb {
    height: 13px;
    width: 13px;
    border-radius: 13px;
    background: var(--accent-color);
    cursor: pointer;
    margin-top: -5px;
  }

  input[type='range']:active::-ms-thumb {
    background: var(--accent-color);
  }

  datalist {
    display: flex;
    width: 100%;
    grid-template-columns: repeat(5, 1fr);
    color: var(--accent-color);
    justify-content: space-between;
  }

  datalist option {
    font-size: var(--scale-1);
  }
</style>
