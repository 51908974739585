<script>
  import { group, sum } from 'd3-array'
  import { _ } from 'svelte-i18n'
  import { months3 } from './../../constants'
  import {
    techGroups,
    calculatedData,
    selectedYear,
    selectedMonth,
    activeMonths,
    selectedTimePeriod,
    activeTab,
  } from './../../stores'
  import HoverValueItem from './HoverValueItem.svelte'
  import { summerMonths, winterMonths, allMonths } from '../../constants'

  export let month
  export let year

  $: monthLabel = month
    ? $_(`page.sections.other.months.${months3[month].toLocaleLowerCase()}`)
    : ''

  const byTechType2 = group(
    $techGroups.reduce((acc, cur) => {
      cur.subGroups.forEach((subGroup) =>
        subGroup.technologies.forEach((technology) =>
          acc.push({ ...technology, color: cur.color })
        )
      )
      return acc
    }, []),
    (d) => d.type
  )

  $: monthRange =
    $selectedTimePeriod === 'winter'
      ? winterMonths
      : $selectedTimePeriod === 'summer'
      ? summerMonths
      : allMonths

  $: data = $calculatedData
    .getMonthlyTimeSeries((month) => {
      const demand = month.calculated.totalDemand.CH
      const totalProduction = sum(
        Object.values(month.calculated.generation.generation.byTechnology)
      )
      const maxImportPotential = sum(
        Object.values(month.calculated.maxImportability)
      )

      const requiredImport = Math.max(
        0,
        Math.min(demand - totalProduction, maxImportPotential)
      )

      return {
        year: month.parentYear.year,
        month: month.month,
        date: month.parentYear.year * 100 + month.month,
        value: month.calculated.generation.surplus, // TODO: remove: same as surplus prop
        totalProduction,
        production: {
          ...month.calculated.generation.generation.byTechnology,
          import: month.calculated.generation.import.total,
        },
        import: month.calculated.generation.import.total,
        surplus: month.calculated.generation.surplus,
        deficit: month.calculated.generation.deficit,
        demand: month.calculated.totalDemand.CH,
        selfSufficientDays: month.calculated.selfSufficientDays,
        speicherReserve: month.calculated.generation.generation.speicherReserve,
      }
    })
    .filter((month) => {
      return $activeTab === 'energymix'
        ? monthRange.includes(month.month)
        : true
    })
    .filter((d) =>
      // $activeMonths.includes(d.month + 1) &&
      $selectedMonth !== undefined
        ? $selectedYear === d.year && $selectedMonth === d.month - 1
        : $selectedYear && d.year === $selectedYear
    )
    .reduce(
      (acc, cur) => {
        Object.keys(cur)
          .filter((d) => d !== 'year' && d !== 'month')
          .forEach((key) => {
            if (!acc[key]) {
              acc[key] = 0
            }

            if (key === 'production') {
              Object.keys(cur.production).forEach((key2) => {
                if (!acc.production[key2]) {
                  acc.production[key2] = 0
                }

                acc.production[key2] += cur.production[key2]
              })
            } else if (key === 'speicherReserve') {
              Object.keys(cur.speicherReserve).forEach((key2) => {
                if (!acc.speicherReserve[key2]) {
                  acc.speicherReserve[key2] = 0
                }
                acc.speicherReserve[key2] += Math.max(
                  0,
                  cur.speicherReserve[key2]
                )
              })
            } else {
              acc[key] += cur[key]
            }
          })

        return acc
      },
      {
        production: {},
        speicherReserve: { available: undefined, used: undefined },
      }
    )
</script>

<div class="container">
  <h2>
    {monthLabel}
    {year ? year : ''}
  </h2>
  {#if data}
    <section>
      <h3>
        <span
          >{$_(
            'page.sections.charts.surplusDeficit.detailLabels.overall'
          )}</span
        >
        <span class="year">
          {#if $activeTab === 'energymix' && ['winter', 'summer'].includes($selectedTimePeriod)}{$selectedTimePeriod}{/if}
          {$selectedMonth !== undefined
            ? $_(`page.sections.other.months.${months3[$selectedMonth]}`) + ' '
            : ''}{$selectedYear}
        </span>
      </h3>
      <HoverValueItem
        label={$_('page.sections.charts.surplusDeficit.detailLabels.surplus')}
        value={data ? data.surplus : undefined}
        color="rgb(0, 236, 209)"
      />
      <HoverValueItem
        label={$_('page.sections.charts.surplusDeficit.detailLabels.deficit')}
        value={data ? data.deficit : undefined}
        color="purple"
      />
      <HoverValueItem
        label={$_('page.sections.charts.surplusDeficit.detailLabels.import')}
        value={data ? data.import : undefined}
        color="#000001"
      />
      <HoverValueItem
        label={$_('page.sections.charts.surplusDeficit.detailLabels.demand')}
        value={data ? data.demand : undefined}
        color="#000001"
      />
      <HoverValueItem
        label={$_(
          'page.sections.charts.surplusDeficit.detailLabels.speicherreserve.label'
        )}
        value={data ? data.speicherReserve : undefined}
        color="var(--speicherreserve-color)"
        nestedKeys={['used', 'available']}
        nestedI18nKey="page.sections.charts.surplusDeficit.detailLabels.speicherreserve"
        hideNested={$selectedMonth !== undefined ? undefined : ['available']}
      />
      <div style="margin-top: 20px;">
        <HoverValueItem
          label={$_(
            'page.sections.charts.surplusDeficit.detailLabels.selfSufficientDays'
          )}
          value={data ? data.selfSufficientDays : undefined}
          formatPattern=",.0f"
          unit={$_('page.sections.charts.surplusDeficit.detailLabels.days')}
        />
      </div>
    </section>
  {/if}

  <section>
    <h3>
      <span
        >{$_(
          'page.sections.charts.surplusDeficit.detailLabels.renewable'
        )}</span
      >
      <span class="year">
        {#if $activeTab === 'energymix' && ['winter', 'summer'].includes($selectedTimePeriod)}{$selectedTimePeriod}{/if}
        {$selectedMonth !== undefined
          ? $_(`page.sections.other.months.${months3[$selectedMonth]}`) + ' '
          : ''}{$selectedYear}
      </span>
    </h3>
    {#each byTechType2.get('renewable') as tech}
      <HoverValueItem
        label={$_(
          `page.sections.userControls.switzerland.capacity.technologies.${tech.key}.label`
        )}
        value={data ? data.production[tech.key] : undefined}
        color={tech.color}
      />
    {/each}
  </section>

  <section>
    <h3>
      <span
        >{@html $_(
          'page.sections.charts.surplusDeficit.detailLabels.co2free'
        )}</span
      >
      <span class="year">
        {#if $activeTab === 'energymix' && ['winter', 'summer'].includes($selectedTimePeriod)}{$selectedTimePeriod}{/if}
        {$selectedMonth !== undefined
          ? $_(`page.sections.other.months.${months3[$selectedMonth]}`) + ' '
          : ''}{$selectedYear}
      </span>
    </h3>
    {#each byTechType2.get('co2Free') as tech}
      <HoverValueItem
        label={$_(
          `page.sections.userControls.switzerland.capacity.technologies.${tech.key}.label`
        )}
        value={data ? data.production[tech.key] : undefined}
        color={tech.color}
      />
    {/each}
  </section>

  <section>
    <h3>
      <span
        >{$_('page.sections.charts.surplusDeficit.detailLabels.fossil')}</span
      >
      <span class="year">
        {#if $activeTab === 'energymix' && ['winter', 'summer'].includes($selectedTimePeriod)}{$selectedTimePeriod}{/if}
        {$selectedMonth !== undefined
          ? $_(`page.sections.other.months.${months3[$selectedMonth]}`) + ' '
          : ''}{$selectedYear}
      </span>
    </h3>
    {#each byTechType2.get('fossil') as tech}
      <HoverValueItem
        label={$_(
          `page.sections.userControls.switzerland.capacity.technologies.${tech.key}.label`
        )}
        value={data ? data.production[tech.key] : undefined}
        color={tech.color}
      />
    {/each}
  </section>
</div>

<style>
  h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
  }
  .container {
    font-size: 1.4rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 2px;
    width: 100%;
  }

  h3 {
    font-family: 'Almarena Regular', 'Open Sans', sans-serif;
    margin: 0;
    text-align: left;
    font-weight: bold;
    font-size: var(--scale-4);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h3 .year {
    font-size: var(--scale-1);
    margin-right: 8px;
  }

  p {
    width: 100%;
    text-align: center;
    font-size: 1.4rem;
    font-style: italic;
  }

  @media (max-width: 1024px) {
    .container {
      grid-template-columns: 1fr;
    }
  }

  section {
    background-color: var(--color-grey-100);
    padding: 10px 20px;
    position: relative;
    border-radius: 5px;
  }
</style>
