<script>
  export let title
  export let description
</script>

<div class="panel">
  {#if title}
    <h1>{title}</h1>
  {/if}
  {#if description}
    <p>{description}</p>
  {/if}
  <slot />
</div>

<style>
  h1 {
    margin: 4px 0 0 6px;
    font-weight: 700;
    font-size: scale(3);
  }

  .panel {
    width: 100%;
  }
</style>
