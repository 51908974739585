<script>
  export let sortDirection
</script>

{#if sortDirection === 'desc'}
  <span class="sort-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="4"
      stroke="var(--accent-color)"
      width={14}
      height={14}
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  </span>
{:else}
  <span class="sort-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="4"
      stroke="var(--accent-color)"
      width={14}
      height={14}
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M4.5 15.75l7.5-7.5 7.5 7.5"
      />
    </svg>
  </span>
{/if}

<style>
  .sort-icon {
    margin-left: 4px;
  }
</style>
