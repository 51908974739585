<script>
  import { _ } from 'svelte-i18n'
  import { userSettings } from './stores'
  import Reset from './ui/Reset.svelte'

  export let userSetting
  export let group
  export let subGroup
  export let techGroup
  export let type
  export let sliderWidth = '100%'
  export let i
</script>

{#if type === 'range'}
  <div style="display: inline-block; width: 100px;">
    {#if i === 0}
      <datalist id="tickmarks">
        <option
          value="-1"
          label={$_(
            'page.sections.userControls.neighboringCountries.capacity.controls.min'
          )}
        />
        <option
          value="0"
          label={$_(
            'page.sections.userControls.neighboringCountries.capacity.controls.scenario'
          )}
        />
        <option
          value="1"
          label={$_(
            'page.sections.userControls.neighboringCountries.capacity.controls.max'
          )}
        />
      </datalist>
    {/if}
    <input
      list="tickmarks"
      type="range"
      min="-1"
      max="1"
      step="0.25"
      style="width: {sliderWidth}; padding: 0; margin: 0; background: none;"
      bind:value={$userSettings[group][subGroup][userSetting].value}
      on:mousedown={() => {
        $userSettings[group][subGroup][userSetting].edited = true
      }}
    />
  </div>
  {#if $userSettings[group][subGroup][userSetting].edited}
    <span style="position: absolute; right: 16px; bottom: 4px;">
      <Reset
        on:click={() => {
          $userSettings[group][subGroup][userSetting].edited = false
          $userSettings[group][subGroup][userSetting].value = 0
        }}
      />
    </span>
  {/if}
{:else if type === 'choice'}
  <div style="background-color: white; padding: 5px; margin: 10px 0;">
    <h5>
      {$_('page.sections.userControls.switzerland.capacity.utilization.label')}
      {$_(
        `page.sections.userControls.switzerland.capacity.technologies.${techGroup.key}.label`
      )}
    </h5>
    {#if techGroup.key === 'pv' || techGroup.key === 'wind'}
      <button
        on:click={() =>
          ($userSettings[group][subGroup][userSetting] = 'dunkelflaute')}
        >Dunkelflaute</button
      >
    {/if}
    <button
      on:click={() => ($userSettings[group][subGroup][userSetting] = 'min')}
      class={$userSettings[group][subGroup][userSetting] === 'min'
        ? 'selected'
        : ''}>Low</button
    >
    <button
      on:click={() => ($userSettings[group][subGroup][userSetting] = 'avg')}
      class={$userSettings[group][subGroup][userSetting] === 'avg'
        ? 'selected'
        : ''}>Average</button
    >
    <button
      on:click={() => ($userSettings[group][subGroup][userSetting] = 'max')}
      class={$userSettings[group][subGroup][userSetting] === 'max'
        ? 'selected'
        : ''}>High</button
    >
  </div>
{:else}
  {console.error('Invalid slider type:', type)}
{/if}

<style>
  datalist {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 1.2rem;
  }
  h5 {
    margin: 5px 0 0;
  }

  button {
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px 0;
    margin-right: 10px;
  }

  button:hover {
    color: var(--accent-color);
  }

  .selected {
    color: var(--accent-color);
    border-bottom: 2px solid var(--accent-color);
  }
  .reset-group {
    color: var(--accent-color);
    display: inline-block;
    position: relative;
    top: -6px;
    outline: none;
    background: none;
    border: none;
    margin: 0 0 0 5px;
    padding: 0;
    cursor: pointer;
    font-size: 2rem;
  }
</style>
