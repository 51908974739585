﻿<script>
  import Collapsable from './../../components/Collapsable.svelte'

  export let title
</script>

<Collapsable {title} track={true}>
  <Collapsable title="Conventional Demand">
    <p>
      Conventional demand represents the end energy consumption of various
      sectors such as households, services, industry, agriculture, transport and
      conversion. The electricity consumption for heat pumps, electromobility
      and the electricity used to generate hydrogen (H2) is not included.
    </p>
    <p>
      The future development of conventional demand will be shaped by two
      opposing trends:
    </p>

    <ol>
      <li>
        Efficiency gains leading to an expected (significant) downward trend in
        demand.
      </li>

      <li>
        New areas for the application of electricity in all sectors (sector
        coupling), such as an increase in e-mobility, leading to an expected
        increase in demand.
      </li>
    </ol>

    <p>
      Population growth and economic development are also important factors in
      the trajectory of electricity demand.
    </p>
  </Collapsable>

  <Collapsable title="Demand for heat pumps">
    <p>
      This demand takes into account the electricity demand of all heat pumps.
      It includes demand from heat pumps in buildings (household and services),
      industry, agriculture and heavy-duty heat pumps.
    </p>

    <p>
      As our representation includes demand from industry, agriculture and
      heavy-duty heat pumps, the values are slightly higher than the official
      statistics (2019: 2.7 TWh vs. 2.3 TWh).
    </p>
  </Collapsable>

  <Collapsable title="Demand for electromobility">
    <p>
      The decarbonisation of the transport sector will lead to a major increase
      in electricity consumption. Demand from road transport is shown in this
      demand block. Rail transport is in the ‘conventional demand’ section.
    </p>
    <p>
      Depending on the mileage trajectory of possible alternatives such as
      hydrogen-powered cars or cars running on synthetic fuels, the potential
      annual demand may vary. With a
      <a
        href="https://rogernordmann.ch/buch-sonne-fur-den-klimaschutz-ein-solarplan-fur-die-schweiz/"
        class="link link--external"
        target="_blank"
        title="Roger Nordmann, “Kapitel 3.3 Elektrifizierung Der Mobilität (Ohne Luftverkehr),” in Sonne Für Den Klimaschutz: Ein Solarplan Für Die Schweiz, 2nd ed. (Basel: Zytglogge Verlag AG, 2019)"
        >complete switch to electromobility, demand in the year 2050 is
        estimated at up to 17 TWh</a
      >.
    </p>
  </Collapsable>
  <Collapsable title="Demand for hydrogen production">
    <p>
      This category covers electricity consumption for the production of
      hydrogen via electrolysis. In a decarbonised world, hydrogen will play a
      key role as an energy source. The application possibilities are many and
      varied: it can be used as a fuel in (above all heavy) motor vehicles, in
      industrial processes such as those requiring high heat, as the basis for
      other fuels such as ammonia, and more.
    </p>
    <h3>Hydrogen & decarbonised power supply</h3>
    <p>
      Another important function of hydrogen is that it can be used to produce
      and store electricity. In the decarbonised cycle, electrolysis of
      electricity from renewable energy sources is used to produce ‘green
      hydrogen’. This can then be used again at a later date to generate power
      by burning it in a manner similar to the way natural gas is used to
      produce electricity today. In Switzerland, this type of on-demand
      electricity production could be particularly valuable in winter, when less
      solar power is available due to lower sunlight levels. No hydrogen is
      currently produced via electrolysis in Switzerland. The potential is
      great, depending on the national electricity generation potential,
      possible electricity imports and the potential for storing hydrogen.
    </p>
  </Collapsable>
</Collapsable>
