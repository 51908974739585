<script>
  import { link } from 'svelte-spa-router'
  import { _ } from 'svelte-i18n'
  import LanguageSwitch from '../components/LanguageSwitch.svelte'

  // export let p
</script>

<div class="header">
  <div class="item" style="background: none;">
    <div class="logo-wrapper">
      <span class="logo logo-axpo">
        <a
          href="/"
          use:link
          title="Axpo Power Switcher"
          style="position: relative; top: 20px;"
        >
          <img src="img/Axpo_logo_color_rgb.svg" alt="Axpo" />
        </a>
      </span>
      <span class="logo logo-ps">
        <img
          src="img/Axpo_Produktlogo_PowerSwitcher_RGB_ohneKurven.png"
          alt="Axpo | Power Switcher"
        />
      </span>
    </div>
  </div>
  <LanguageSwitch />
</div>

<style>
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .logo-wrapper {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;
  }

  .logo {
    height: 40px;
    width: 100%;
    position: relative;
    top: 8px;
  }

  .logo-ps {
    height: 40px;
  }

  .logo img {
    height: 100%;
  }

  .logo-axpo {
    height: 80px;
  }
</style>
