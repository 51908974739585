<script>
  import {
    calculatedData,
    userSettings,
    techGroups,
    selectedYear,
    selectedMonth,
  } from '../../stores'
  import { scaleLinear, scaleBand, scaleSqrt, scaleQuantize } from 'd3-scale'
  import { line, area, pie, arc } from 'd3-shape'
  import { max, range, sum } from 'd3-array'
  import { _, getLocaleFromHash } from 'svelte-i18n'
  import { pointer } from 'd3-selection'
  import { months3 } from '../../constants'
  import { onMount } from 'svelte'

  $: months = $calculatedData ? $calculatedData.getMonthlyTimeSeries() : []
  $: years = $calculatedData ? $calculatedData.getAnnualTimeSeries() : []

  const width = 1066
  const height = 436

  let activeTechnology = 'pv'

  const margin = {
    top: 20,
    right: 30,
    bottom: 30,
    left: 20,
  }

  const w = width - margin.left - margin.right
  const h = height - margin.top - margin.bottom

  $: x3 = scaleBand()
    .domain(range(0, years ? years.length : 0))
    .range([0, w])
    .padding(0.05)

  $: x = scaleLinear()
    .domain([0, years ? years.length : 0])
    .range([0, w])

  $: x2 = scaleLinear()
    .domain([0, months ? months.length : 0])
    .range([0, w])

  $: x4 = scaleQuantize()
    .domain([0, w])
    .range(range(0, months?.length + 1))

  const chartTopOffset = 120

  $: y = scaleLinear()
    .domain([
      0,
      months
        ? max(
            months,
            (d) => d.calculated.totalDemand.CH + d.calculated.generation.surplus
          )
        : 0,
    ])
    .range([h, chartTopOffset])
    .nice()

  $: r = scaleSqrt().domain(y.domain()).range([0, 45])

  const ld = line()
    .x((_, i) => x2(i))
    .y((d) => y(d.calculated.totalDemand.CH))

  const as = area()
    .x((_, i) => x2(i))
    .y0((d) => y(d.calculated.totalDemand.CH))
    .y1((d) =>
      y(
        d.calculated.generation.surplus > 0
          ? d.calculated.totalDemand.CH + d.calculated.generation.surplus
          : d.calculated.totalDemand.CH
      )
    )

  const ad = area()
    .x((_, i) => x2(i))
    .y0((d) =>
      y(
        d.calculated.generation.deficit > 0
          ? d.calculated.totalDemand.CH - d.calculated.generation.deficit
          : d.calculated.totalDemand.CH
      )
    )
    .y1((d) => y(d.calculated.totalDemand.CH))

  const ai = area()
    .x((_, i) => x2(i))
    .y0((d) =>
      y(
        d.calculated.generation.import.total > 0
          ? d.calculated.totalDemand.CH - d.calculated.generation.import.total
          : d.calculated.totalDemand.CH
      )
    )
    .y1((d) => y(d.calculated.totalDemand.CH))

  const an = area()
    .x((d, i) => x2(i))
    .y0((d) => y(0))
    .y1((d) => y(d.calculated.totalDemand.CH))

  const a2_ = (technology) =>
    area()
      .x((_, i) => x2(i))
      .y0((d) => y(0))
      .y1((d) =>
        y(
          sum(
            $techGroups
              .find((tg) => tg.key === technology)
              .technologies.map(
                (t) => d.calculated.generation.generation.byTechnology[t]
              )
            // .technologies.map((t) => d.calculated.production.CH[t])
          )
        )
      )

  let svg

  $: hoverMonth =
    months.length > 0
      ? { index: months.length - 1, data: months[months.length - 1] }
      : undefined

  $: pctGenerationOfDemand =
    hoverMonth === undefined
      ? 0
      : sum(
          $techGroups
            .find((tg) => tg.key === activeTechnology)
            .technologies.map(
              (t) =>
                hoverMonth?.data?.calculated.generation.generation.byTechnology[
                  t
                ]
            )
        ) / hoverMonth?.data?.calculated.totalDemand.CH

  function circlePath(cx, cy, r, deg) {
    let theta = (deg * Math.PI) / 180,
      dx = -r * Math.cos(theta),
      dy = r * Math.sin(theta)

    return `M ${cx + dx} ${cy + dy}
          a ${r},${r} 0 1,1 ${-2 * dx}, ${-2 * dy}
          a ${r}, ${r} 0 1,1 ${2 * dx},${2 * dy}`
  }

  onMount(() => {
    $selectedYear = 2050
    $selectedMonth = 11
  })

  let mouseX = 0
</script>

<div class="summary">
  <svg {width} {height} style="background-color: rgba(255, 255, 255, 0.5);">
    <defs>
      <marker
        id="arrow"
        viewBox="0 0 10 10"
        refX="9"
        refY="5"
        markerWidth="6"
        markerHeight="6"
        orient="auto-start-reverse"
      >
        <path d="M 0 0 L 10 5 L 0 10 z" />
      </marker>
      <pattern
        id="winter-hatch"
        patternUnits="userSpaceOnUse"
        width="4"
        height="4"
      >
        <path
          d="M-1,1 l2,-2
           M0,4 l4,-4
           M3,5 l2,-2"
          style="stroke: #adeaf8; stroke-width:1"
        />
      </pattern>
    </defs>
    <!-- svelte-ignore a11y-mouse-events-have-key-events -->
    <g
      bind:this={svg}
      transform="translate({margin.left}, {margin.top})"
      on:mousemove={(e) => {
        const index = Math.min(
          months?.length - 1,
          x4(Math.floor(pointer(e, svg)[0]))
        )
        hoverMonth = { index, data: months[index] }

        $selectedYear = months[index].parentYear.year
        $selectedMonth = months[index].month - 1

        mouseX = pointer(e, svg)[0]
      }}
    >
      <rect width={w} height={h} fill="transparent" />
      {#each years as year, i}
        <rect
          y={chartTopOffset - 20}
          x={x(i) - x.range()[1] / years.length / 4}
          width={x.range()[1] / years.length / 2}
          height={h - chartTopOffset + 20}
          fill="url(#winter-hatch)"
        />
        <text
          style="font-size: var(--scale-0); dominant-baseline: central; font-weight: {i %
            5 ===
          0
            ? 'bold'
            : 'normal'}; text-anchor: end; stroke: white; stroke-width: 3; paint-order: stroke fill;"
          transform="translate({x(i)}, {y.range()[0]}) rotate(-90)"
          dx={-4}>{i + 2020}</text
        >
      {/each}
      <path d={an(months)} fill="#eee" />
      <path d={ai(months)} fill="var(--color-energy-import)" />
      <path d={ad(months)} fill="var(--color-energy-deficit)" />
      <path d={as(months)} fill="var(--color-energy-surplus)" />
      <path
        d={a2_(activeTechnology)(months)}
        fill={`var(--color-tech-${activeTechnology})`}
      />
      <path d={ld(months)} stroke="black" stroke-width={2} fill="none" />
      {#each months as month, i}
        {#if month.calculated.generation.deficit > 0}
          <line
            x1={x2(i)}
            x2={x2(i)}
            y1={y.range()[1] -
              20 +
              (hoverMonth?.data?.month === month.month &&
              hoverMonth?.data?.parentYear.year === month.parentYear.year
                ? 0
                : 2)}
            y2={y.range()[1] -
              20 +
              (hoverMonth?.data?.month === month.month &&
              hoverMonth?.data?.parentYear.year === month.parentYear.year
                ? 12
                : 8)}
            stroke="var(--color-energy-deficit)"
            stroke-width={2}
          />
        {/if}
      {/each}

      {#if hoverMonth !== undefined}
        {@const radius = r(hoverMonth?.data?.calculated.totalDemand.CH)}
        {@const radiusMinusDeficit = r(
          hoverMonth?.data?.calculated.totalDemand.CH -
            Math.abs(Math.min(0, hoverMonth?.data?.calculated.CH_totalSurplus))
        )}
        {@const isWinter =
          hoverMonth.data.month <= 3 || hoverMonth.data.month >= 10}
        {@const circleX = Math.min(
          Math.max(mouseX, radius + 14),
          w - radius - 14
        )}
        <line
          x1={x2(hoverMonth.index)}
          x2={x2(hoverMonth.index)}
          y1={y(hoverMonth?.data?.calculated.totalDemand.CH)}
          y2={y(
            sum(
              $techGroups
                .find((tg) => tg.key === activeTechnology)
                .technologies.map(
                  (t) =>
                    hoverMonth?.data?.calculated.generation.generation
                      .byTechnology[t]
                )
            )
          )}
          marker-start="url(#arrow)"
          marker-mid="url(#arrow)"
          marker-end="url(#arrow)"
          stroke="black"
        />

        <g transform="translate({circleX}, 50)">
          {#if isWinter}
            <circle
              r={radius + 14}
              style="fill: url(#winter-hatch); stroke: white; stroke-width: 2;"
            />

            <path
              id="demand-circle-path"
              d={circlePath(0, 0, radius, -120)}
              style="fill: none;"
            />
            <text style="font-size: var(--scale-0);" dy={-4}>
              <textPath href="#demand-circle-path">
                {$_('page.sections.new.winter')}
              </textPath>
            </text>
          {/if}

          <circle
            r={radius}
            style="fill: var(--color-energy-deficit); stroke-width: 3;"
          />
          <circle r={radiusMinusDeficit} style="fill: #eee;" />

          <path
            d={arc()
              .startAngle(0)
              .endAngle(() => Math.PI * 2 * pctGenerationOfDemand)
              .innerRadius(0)
              .outerRadius(() => radius)()}
            fill={`var(--color-tech-${activeTechnology})`}
          />
          <circle
            r={radius}
            style="fill: none; stroke: black; stroke-width: 2"
          />
          <text
            y={-radius}
            dy={-18}
            style="text-anchor: middle; font-size: var(--scale-1);"
          >
            {$_('page.sections.userControls.switzerland.demand.label')}
          </text>
          <text
            y={-radius}
            dy={-6}
            style="text-anchor: middle; font-size: var(--scale-1);"
          >
            {Math.round(hoverMonth?.data?.calculated.totalDemand.CH * 10) /
              10}TWh
          </text>
          <text
            dy={-8}
            style="text-anchor: middle; dominant-baseline: central; font-size: var(--scale-1);"
          >
            {Math.round(pctGenerationOfDemand * 1000) / 10}%
          </text>
          <text
            dy={8}
            style="text-anchor: middle; dominant-baseline: central; font-size: var(--scale-1);"
          >
            {Math.round(
              sum(
                $techGroups
                  .find((tg) => tg.key === activeTechnology)
                  .technologies.map(
                    (t) =>
                      hoverMonth?.data?.calculated.generation.generation
                        .byTechnology[t]
                  )
              ) * 100
            ) / 100}TWh
          </text>
        </g>

        {#if circleX > w / 2}
          <g transform="translate({circleX - radius - 14}, {50})">
            <text
              style="text-anchor: end; dominant-baseline: central; font-size: var(--scale-1);"
              dx={-8}
              dy={-8}
            >
              {$_(
                `page.sections.other.months.${
                  months3[hoverMonth.data.month - 1]
                }`
              )}
            </text>
            <text
              style="text-anchor: end; dominant-baseline: central;"
              dx={-8}
              dy={8}
            >
              {hoverMonth.data.parentYear.year}
            </text>
          </g>
        {:else}
          <g transform="translate({circleX + radius + 14}, {50})">
            <text
              style="text-anchor: start; dominant-baseline: central; font-size: var(--scale-1)"
              dx={8}
              dy={-8}
            >
              {$_(
                `page.sections.other.months.${
                  months3[hoverMonth.data.month - 1]
                }`
              )}
            </text>
            <text
              style="text-anchor: start; dominant-baseline: central;"
              dx={8}
              dy={8}
            >
              {hoverMonth.data.parentYear.year}
            </text>
          </g>
        {/if}
      {/if}

      {#each y.ticks() as tick}
        <text dx={4} class="y-tick" x={w} y={y(tick)}>{tick}</text>
      {/each}
    </g>
  </svg>
</div>

<div class="item-container">
  <div class="techgroup-button-group">
    {#each $techGroups as techGroup}
      {@const bg =
        activeTechnology === techGroup.key
          ? `var(--color-tech-${techGroup.key}`
          : 'var(--color-gray-100)'}

      <button
        class="techgroup-button"
        style="background-color: {bg};"
        on:click={() => (activeTechnology = techGroup.key)}
      >
        {$_(
          `page.sections.userControls.switzerland.capacity.groups.${techGroup.key}.label`
        )}
      </button>
    {/each}
  </div>
</div>

<style>
  .techgroup-button-group {
    display: flex;
    justify-content: space-between;
  }

  .techgroup-button-group button {
    width: 100%;
    padding-block: var(--size-1);
    cursor: pointer;
    border: 1px solid white;
    border-radius: var(--radius-sm);
  }

  .techgroup-button-group button:hover {
    background: var(--color-gray-200);
  }

  .y-tick {
    font-size: var(--scale-1);
    text-anchor: start;
    dominant-baseline: central;
    paint-order: stroke fill;
    stroke: white;
    stroke-width: 3px;
  }
</style>
