<script>
  import { activeMainView } from '../../stores.js'
  import NecessarySubsidies from '../../charts/cost/NecessarySubsidies.svelte'
  import HouseholdCost from '../../charts/cost/HouseholdCost.svelte'
  import Legend from '../../charts/cost/Legend.svelte'
  import { _ } from 'svelte-i18n'
  import { onMount, onDestroy } from 'svelte'
  import { trueCosts } from '../../costCalculation.js'
  import CostControls from '../../charts/cost/CostControls.svelte'

  $: console.log($trueCosts)

  onMount(() => {
    activeMainView.set('cost')
  })

  onDestroy(() => {
    activeMainView.set(undefined)
  })
</script>

<div class="wrapper">
  <CostControls />

  <div class="charts-wrapper">
    <div class="subsidies-charts">
      <div class="subsidies-chart">
        <h4 class="chart-title">
          {$_('page.sections.new.necessarySubsidies')}
          <span class="unit">
            {$_('page.sections.charts.cost.subsidies.unit')}
          </span>
        </h4>
        <NecessarySubsidies />
      </div>
      <div class="subsidies-chart">
        <h4 class="chart-title">
          {$_('page.sections.new.costForHoushold')}
          <span class="unit">Rp/kWh</span>
        </h4>
        <HouseholdCost />
      </div>
    </div>
  </div>
</div>

<style>
  .chart-title {
    margin: var(--scale-0);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-size: var(--scale-3);
  }

  .chart-title .unit {
    font-weight: normal;
    font-size: var(--scale-2);
    margin-left: var(--scale-0);
  }

  .wrapper {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .charts-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .subsidies-charts {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .subsidies-chart {
    width: 100%;
    height: 400px;
  }
</style>
