﻿<script>
  import Collapsable from './../../components/Collapsable.svelte'

  export let title
</script>

<Collapsable {title} track={true}>
  <Collapsable title="Energy Perspectives 2050+">
    <p>
      The Swiss government’s Energy Perspectives 2050+ present different ways of
      uniting climate neutrality with electricity supply security in 2050. We
      use the basic scenario, in which the last nuclear power plant will be
      taken offline after 50 years of operation. The main components of this
      scenario are:
    </p>

    <ul>
      <li>Expansion of renewable energies</li>

      <li>Increased efficiency</li>

      <li>Electrification of transport and buildings</li>

      <li>Balancing of annual imports and exports</li>

      <li>Use of new technologies</li>
    </ul>

    <h4>Expansion of renewable energies</h4>

    <p>
      The expansion of all renewable energies such as photovoltaics and biomass
      is a primary focus of this scenario (1). The aim by 2050 is for almost all
      electricity in Switzerland to come from renewable sources. Hydropower,
      which is to be expanded by 10% compared to 2019, and photovoltaics
      (capacity of 37.5 GW, or about 35 TWh electricity production in 2050) are
      the two most important technologies here (2).
    </p>

    <h4>Increased efficiency</h4>

    <p>
      Increased efficiency for all appliances will help limit the rise in demand
      in spite of electrification (3). The efficiency of other processes is also
      to be further increased (4). The electricity demand per person will
      decrease accordingly, even though the population and total electricity
      demand will grow (5).
    </p>

    <h4>Electrification of transport and buildings</h4>

    <p>
      Heat pumps and district heating are to replace gas- and oil-based heating
      in buildings (6). Vehicles are to be powered by batteries, with the
      exception of lorries. Here, the use of fuels such as hydrogen remains an
      option. Batteries are currently not technically capable of effectively
      powering vehicles over a certain weight. Other renewable fuels may
      therefore be a better substitute for fossil fuels.
    </p>

    <h4>Balancing of annual imports and exports</h4>

    <p>
      This Energy Perspectives 2050+ scenario assumes that Switzerland will
      export electricity in the summer months and import it in winter. In
      winter, the total demand is not expected to be met by electricity storage
      in the scenario. Nevertheless, the scenario does assume that imports and
      exports will be balanced over the year as a whole (7).
    </p>

    <h4>Use of new technologies</h4>

    <p>
      Two other new technologies play a role in this scenario: carbon capture
      and storage and the production of hydrogen through renewable electricity.
      Carbon capture and storage (CCS) is to be used in electricity production
      through the fossil component of waste. Other fossil fuels will no longer
      be used for electricity production from roughly 2035 onwards (8). Aside
      from electricity, the scenario also envisions using CCS in cement and
      chemical plants.
    </p>

    <p>
      Hydrogen is to be produced with renewable electricity. The scenario
      assumes that this process will first be used in the 2040s, particularly as
      a fuel in heavy transport (10).
    </p>

    <h4>Key data at a glance</h4>

    <ul>
      <li>Electricity production</li>

      <ul>
        <li>Expansion of photovoltaics to 37.5 GW by 2050</li>

        <li>Expansion of wind to 2.2 GW by 2050</li>

        <li>Expansion of hydropower by about 10%</li>

        <li>Phase-out of nuclear energy after 50 years of operation</li>

        <li>Waste with CCS as the only fossil power source in 2050</li>
      </ul>

      <li>Demand</li>

      <ul>
        <li>Total electricity demand about 77 TWh in 2050</li>

        <li>E-mobility: from almost 0 TWh/a to about 13 TWh/a</li>

        <li>Heat pumps: just under 12 TWh/a in 2050</li>

        <li>Hydrogen production: just under 3 TWh in 2050</li>
      </ul>

      <li>Imports</li>

      <ul>
        <li>
          Import capacities are aligned with the TYNDP 2018 Sustainable
          Transition scenario.
        </li>
      </ul>
    </ul>
    <p>…</p>
    <ol>
      <li>
        Bundesamt für Energie (a), “<a
          href="https://www.bfe.admin.ch/bfe/de/home/politik/energieperspektiven-2050-plus.html"
          title="Energieperspektiven 2050+ - 

          Zusammenfassung Zusammenfassung der 

      wichtigsten Ergebnisse” (Bern: Bundesamt für 

          Energie, November 26, 2020), pages 25-27"
          class="link link--external"
          target="_blank"
          >Energieperspektiven 2050+ - Zusammenfassung Zusammenfassung der
          wichtigsten Ergebnisse” (Bern: Bundesamt für Energie, November 26,
          2020), Page 25-27.</a
        >
      </li>

      <li>Bundesamt für Energie (a), Page 21.</li>

      <li>
        Bundesamt für Energie (b), “<a
          href="https://www.bfe.admin.ch/bfe/de/home/politik/energieperspektiven-2050-plus.html"
          title="Energieperspektiven 2050+ Kurzbericht” 

          (Bern: Bundesamt für Energie, November 26, 2020), page 14"
          target="_blank"
          class="link link--external"
          >Energieperspektiven 2050+ Kurzbericht” (Bern: Bundesamt für Energie,
          November 26, 2020), Page 14</a
        >
      </li>

      <li>
        Bundesamt für Energie (a), “Energieperspektiven 2050+ - Zusammenfassung
        der wichtigsten Ergebnisse,” pages 3, 14.
      </li>

      <li>
        Bundesamt für Energie (b), “Energieperspektiven 2050+ Kurzbericht,” page
        18.
      </li>

      <li>
        Bundesamt für Energie (a), “Energieperspektiven 2050+ - Zusammenfassung
        der wichtigsten Ergebnisse,” page 7.
      </li>

      <li>Bundesamt für Energie (a), page 22.</li>

      <li>Bundesamt für Energie (a), page 21.</li>
      <li>
        Bundesamt für Energie (a), “Energieperspektiven 2050+ - Zusammenfassung
        der wichtigsten Ergebnisse,” page 7.
      </li>
    </ol>
  </Collapsable>

  <Collapsable title="Current Expansion Speed">
    <p>
      The Current Trends scenario showcases how the system of Swiss power supply
      could develop if, for one, current trends of capacity expansions and
      decreases were to continue on a mainly linear basis and if, for the other,
      restrictions for the expanded deployment of electricity sources remained
      high. The most important parts to this scenario are:
    </p>
    <ol>
      <li>Expansion of wind and solar energy according to historic trends</li>
      <li>No expansion in new electricity sources like hydrogen</li>
      <li>Small reduction in hydropower</li>
      <li>No new gas plants & phase-out of fossil-run plants</li>
    </ol>
    <h4>Expansion of wind and solar energy according to historic trends</h4>
    <p>
      Solar PV and wind are deployed according to historic trends up to 2020 and
      reach yearly capacitiy additions of 475 MW for solar PV and 12 MW for
      wind. Wind capacitiy therefore increases at a similar rate as biomass with
      10 MW per year. The process of permitting the building of additional
      plants has pivotal influence over whether and at what pace new plants may
      be deployed. This process can slow deployment down strongly.
    </p>
    <h4>No expansion in new electricity sources like hydrogen</h4>
    <p>
      New sources of electricity are not introduced. This means that
      technologies such as hydrogen for the production of power (gas-to-power)
      or carbon capture and storage do not play a role in the Swiss electricity
      future. Geothermal does equally not get deployed with the goal of power
      production.
    </p>
    <h4>Small reduction in hydropower</h4>
    <p>
      We assume that hydropower will experience higher reductions than
      expansions in power production due to regulations on residual water. In
      total this leads to the reduction of hydropower production by 2.65 TWh in
      2050. This reduction is distributed among the different types of
      hydropower plants.
    </p>
    <h4>No new gas plants & phase-out of fossil-run plants</h4>
    <p>
      We assume the there will be no capacity additions for power production
      based on natural gas. Simultaneously, phase-outs of fossil-run power
      plants continue; the share of fossil-based energy in the electricity mix
      declines according to their discontinued use at the end of their lifetime.
    </p>
    <h4>Key data at a glance</h4>
    <ol>
      <li>Electricity Production</li>
      <ol>
        <li>Expansion of PV to 17 GW by 2050</li>
        <li>Expansion of Wind to 0.4 GW by 2050</li>
        <li>No Gas-to-Power or new gas power plants</li>
        <li>
          Phase-out of nuclear energy after 50 years of operation (Gösgen and
          Leibstadt), and 55 years (Beznau)
        </li>
        <li>
          Decrease in the output of fossil fuel power plants from the level
          according to their lifetime
        </li>
      </ol>
      <li>Demand</li>
      <ol>
        <li>Total electricity demand of around 80 TWh in 2050</li>
        <li>E-mobility: from almost 0 TWh/a to 13 TWh in 2050</li>
        <li>Heat pumps: about 9 TWh in 2050</li>
        <li>Hydrogen production: 3 TWh in 2050</li>
      </ol>
      <li>Imports</li>
      <ol>
        <li>
          Imports to Switzerland are permitted, power line capacities are based
          on the line expansion plans of European grid operators (ENTSO-E) until
          2034, no new interconnectors afterwards.
        </li>
      </ol>
    </ol>
  </Collapsable>

  <Collapsable title="National Councillor Grossen">
    <h3>Grossen</h3>

    <p>
      In the Grossen Roadmap, National Councillor Jürgen Grossen presents a
      scenario for how Switzerland can minimise emissions, require no fossil
      fuels and become balance-sheet independent of electricity imports by 2050.
      He defined five milestones along the way (1):
    </p>

    <ul>
      <li>40% increase in electricity efficiency</li>

      <li>Electrification of the transport and building sectors</li>

      <li>Expansion of photovoltaics</li>

      <li>Seasonal storage through hydrogen</li>

      <li>
        Harmonisation of electricity consumption and production (not part of the
        model)
      </li>
    </ul>

    <h4>Fourty percent increase in electricity efficiency</h4>

    <p>
      With more efficient use of electricity, less electricity is needed for the
      same processes and less electricity is ‘wasted’. The latter happens, for
      instance, when appliances are left on stand-by or when they produce a lot
      of waste heat. Grossen wants to increase electricity efficiency by using
      smart systems in buildings and generally more efficient appliances (for
      example, appliances shut down automatically, less waste heat produced)
      (2).
    </p>

    <h4>Electrification of buildings and transport</h4>

    <p>
      In buildings, fossil-fuel-based heating will be replaced by heat pumps
      (3). In transport, individual passenger transport and local goods
      transport will be electrified. Heavy vehicles such as lorries will be
      powered by carbon-neutral fuels. This could be hydrogen produced using
      renewable energies (4).
    </p>

    <h4>Expansion of photovoltaics</h4>

    <p>
      Grossen assumes that by 2050 just under 44 GW of photovoltaics will
      produce 40 TWh of electricity annually. This means that two-thirds of all
      suitable roofs and façades will be covered with photovoltaic systems (5).
      Hydropower and photovoltaics will thus become the two most important
      suppliers of electricity in Switzerland.
    </p>

    <h4>Seasonal storage through hydrogen</h4>

    <p>
      Seasonal storage of electricity means that it is stored in the form of
      another fuel for re-conversion into electricity at a later time. In this
      case, surplus electricity from photovoltaics in summer is used to produce
      hydrogen through a process such as electrolysis. This can then later be
      converted back into electricity or used as a fuel for heavy goods
      vehicles. While batteries and pumped storage ensure the daily supply of
      electricity, the aim with hydrogen is to ensure a year-round supply (6).
    </p>

    <h4>Harmonisation of electricity consumption and production</h4>

    <p>
      The harmonisation of electricity demand and production will be achieved
      through investments in smart grids and decentralised storage (7). This
      could mean, for example, that surplus electricity from a photovoltaic
      installation on the roof is stored in the car’s battery in the garage. The
      Power Switcher does not, however, assume full integration as there are
      currently great uncertainties regarding regulation and implementation.
    </p>

    <h4>Key data at a glance</h4>

    <ul>
      <li>Electricity production</li>

      <ul>
        <li>Expansion of photovoltaics to almost 44 GW by 2050</li>

        <li>No change in hydropower capacities</li>

        <li>Phase-out of nuclear energy after 60 years of operation</li>

        <li>
          Linear decrease in the output of fossil fuel power plants from the
          level today to 0 in 2050
        </li>
      </ul>

      <li>Demand</li>

      <ul>
        <li>Total electricity demand of almost 90 TWh in 2050</li>

        <li>E-mobility: from almost 0 TWh/a to just under 23 TWh/a</li>

        <li>Heat pumps: 5 TWh/a in 2050</li>

        <li>Hydrogen production: just under 17 TWh in 2050</li>
      </ul>

      <li>Imports</li>

      <ul>
        <li>
          Import capacities are aligned with the TYNDP 2018 Sustainable
          Transition scenario
        </li>
      </ul>
    </ul>
    <p>…</p>
    <ol>
      <li>
        <a
          href="https://roadmap-grossen.ch/wp-content/uploads/2021/03/202102_Roadmap-Grossen-Fassung-2.pdf"
          title="Jürg Grossen, “Die Roadmap Grossen,” February 2021, page 3"
          target="_blank"
          class="link link--external"
          >Jürg Grossen, “Die Roadmap Grossen,” February 2021, Page 3.</a
        >
      </li>

      <li>Grossen, page 5.</li>

      <li>Grossen, page 5.</li>

      <li>Grossen, page 6.</li>

      <li>Grossen, page 6.</li>

      <li>Grossen, page 7.</li>

      <li>Grossen, page 7.</li>
    </ol>
  </Collapsable>

  <Collapsable title="National Councillor Nordmann">
    <p>
      In his book on the Swiss energy transition, National Councillor Roger
      Nordmann focuses on photovoltaics and electrification to minimise
      greenhouse gas emissions in the country by 2050 (1). The most important
      components of his scenario:
    </p>

    <ul>
      <li>Expansion of photovoltaics</li>

      <li>Power-to-Gas as seasonal storage</li>

      <li>Natural gas for balancing electricity supply and demand in winter</li>

      <li>Electrification of road transport and heating in buildings</li>
    </ul>

    <h4>Expansion of photovoltaics</h4>

    <p>
      By 2050, Nordmann envisions a capacity of 50 GW for photovoltaics.
      According to him, this will result in an annual production of almost 49
      TWh of solar power (2). Of this 49 TWh, 5 TWh cannot be used because it
      exceeds the capacity for storage (power-to-X, batteries, pumped storage)
      at the time of its production (3). Photovoltaics is thus becoming one of
      the main sources of electricity in Switzerland. According to Nordmann,
      solar power makes the phase-out of nuclear energy possible because no more
      electricity will need to be imported than is the case today. Imports will
      therefore be kept to a minimum (4).
    </p>

    <h4>Power-to-Gas as seasonal storage</h4>

    <p>
      Surplus solar power is to be used to advance power-to-X technologies.
      Fuels produced in this manner, such as hydrogen, can then be used to
      produce electricity again when demand outpaces production. A gap between
      increased demand and production is a particular risk in winter. The fuel
      produced in the summer is therefore used as seasonal storage for the
      winter (5). However, Nordmann believes that probably not enough fuel can
      be produced through power-to-X to completely bridge the gap (6).
    </p>

    <h4>Natural gas for balancing electricity supply and demand in winter</h4>

    <p>
      According to Nordmann, natural gas will be required in winter to close the
      gap between demand and the renewable electricity available. He states that
      1.7 TWh of green electricity from X-to-power will be available in winter.
      As the least carbon-intensive fossil option, the expectation is that
      natural gas will need to produce roughly TWh of electricity to fully meet
      the demand (7). Nordmann does not assume that more renewable energy can be
      imported in winter, as he presumes that all neighbouring countries will
      have the same problem of increased demand and lower production (8). He
      therefore assumes the worst-case scenario, in which natural-gas-based
      electricity will be needed in winter.
    </p>

    <h4>Electrification of road transport and heating in buildings</h4>

    <p>
      Electrification of road transport means that fossil fuels will no longer
      be used in this area. Lorries for heavy haulage could be powered
      differently, for instance with hydrogen (9).
    </p>

    <p>
      The author assumes a complete overhaul and efficient electrification of
      heating in buildings (10). Efficient electrification means limiting the
      increase in demand for electricity as much as possible.
    </p>

    <h4>Key data at a glance</h4>

    <p>
      The path to 2050 is represented in 10 stages. We assume three-year steps
      from 2020 onwards. Barring information to the contrary, removal/addition
      is linear along the pathway.
    </p>

    <ul>
      <li>Electricity production</li>

      <ul>
        <li>Expansion of photovoltaics to 50 GW by 2050</li>

        <li>No change in hydropower capacities</li>

        <li>Phase-out of nuclear energy after 55 years</li>

        <li>Gas-to-Power produces in 2050 around 1.7 TWh electricity</li>

        <li>Natural gas as back-up</li>
      </ul>

      <li>Demand</li>

      <ul>
        <li>Total electricity demand of about 85 TWh in 2050</li>

        <li>E-mobility: from almost 0 TWh/a to 17 TWh/a</li>

        <li>Heat pumps: 6 TWh/a in 2050</li>

        <li>Hydrogen production: 6 TWh in 2050</li>
      </ul>

      <li>Imports</li>

      <ul>
        <li>
          Import/export potential is subject to a linear decline in the monthly
          import/export balance to 0 by 2050 as the scenario assumes an
          equalised monthly balance
        </li>
      </ul>
    </ul>
    <p>…</p>
    <ol>
      <li>
        <a
          href="https://rogernordmann.ch/buch-sonne-fur-den-klimaschutz-ein-solarplan-fur-die-schweiz/"
          title="Roger Nordmann, “Kapitel 1. Das Wichtigste in Kürze,” in Sonne für den Klimaschutz: Ein Solarplan für die Schweiz, 2te Ausgabe (Basel: Zytglogge Verlag AG, 2019)."
          target="_blank"
          class="link link--external"
          >Roger Nordmann, “Kapitel 1. Das Wichtigste in Kürze,” in Sonne für
          den Klimaschutz: Ein Solarplan für die Schweiz, 2te Ausgabe (Basel:
          Zytglogge Verlag AG, 2019).</a
        >
      </li>

      <li>
        Nordmann, “Kapitel 12.5 Ergebnisse Der Szenarien Im Vergleich Zum Status
        Quo,” inSonne für den Klimaschutz: Ein Solarplan für die Schweiz, 2te
        Ausgabe (Basel: Zytglogge Verlag AG, 2019)."
      </li>

      <li>
        Nordmann, “Kapitel 8.1 Basisszenario: 50 GW Solarstrom, Elektrifizierung
        Des Strassenverkehrs Und Gebäudesanierung”.
      </li>

      <li>Nordmann, “Kapitel 12.3 Keine Höhere Gegenseitige Abhängigkeit."</li>

      <li>Nordmann, “Kapitel 12.2.5 Saisonale Speicherung.”</li>

      <li>
        Nordmann, “Kapitel 8.1 Basisszenario: 50 GW Solarstrom, Elektrifizierung
        Des Strassenverkehrs Und Gebäudesanierung.”
      </li>

      <li>
        Nordmann, “Kapitel 8.1 Basisszenario: 50 GW Solarstrom, Elektrifizierung
        Des Strassenverkehrs Und Gebäudesanierung.”
      </li>

      <li>Nordmann, “Kapitel 12.3 Keine Höhere Gegenseitige Abhängigkeit”.</li>

      <li>
        Nordmann, “Kapitel 3.3 Elektrifizierung Der Mobilität (Ohne
        Luftverkehr).”
      </li>

      <li>
        Nordmann, “Kapitel 12.5 Ergebnisse Der Szenarien Im Vergleich Zum Status
        Quo”; Nordmann, “Kapitel 8.1 Basisszenario: 50 GW Solarstrom,
        Elektrifizierung Des Strassenverkehrs Und Gebäudesanierung.”
      </li>
    </ol>
  </Collapsable>
  <Collapsable title="Former National Councillor Rechsteiner">
    <p>
      In his scenario, former National Councillor Rudolf Rechsteiner focuses on
      a Switzerland that remains integrated in the European electricity system
      and is largely decarbonised. The most important components of his scenario
      are:
    </p>

    <ul>
      <li>Expansion of photovoltaics</li>

      <li>Electrification of road transport and heating in buildings</li>

      <li>Storage for daily and weekly fluctuations</li>

      <li>Imports and gas power plants for supply security</li>
    </ul>

    <h4>Expansion of photovoltaics</h4>

    <p>
      In Rechsteiner's scenario, the capacity for photovoltaics increases to
      some 37 GW by 2035 or, in his estimation, 30 TWh of solar power
      production. By 2050, photovoltaics will have been expanded to 50 GW of
      capacity. The expansion of solar power and a phase-out of nuclear energy
      after 50 years of operation will take place in parallel.
    </p>

    <h4>Electrification of road transport and heating in buildings</h4>

    <p>
      In this model, electrification plays a crucial role in minimising the use
      of fossil fuels. Road transport will be converted to electrically powered
      vehicles as far as possible, while the heating of buildings by electric
      heat pumps will be advanced massively through renovation efforts.
    </p>

    <h4>Storage for daily and weekly fluctuations</h4>

    <p>
      Batteries, heat storage systems and smart applications will be used to
      balance out fluctuations in electricity production on a daily and weekly
      basis. Batteries and thermal storage can make the energy from surplus
      renewable electricity generation available again at a later time. Smart
      applications can reduce power consumption as needed, when electricity is
      temporarily scarce.
    </p>

    <h4>Imports and gas power plants for supply security</h4>

    <p>
      Rechsteiner assumes that a combination of electricity imports and gas
      power plants is optimal for securing the supply of electricity. In winter,
      imports will initially remain the preferred option. This will keep
      Switzerland firmly integrated in the European electricity system. For
      situations in which insufficient electricity is available for import,
      Rechsteiner envisions backup gas power plants in Switzerland. ‘Backup’
      means that they can be used if there is not enough renewable or imported
      electricity available.
    </p>

    <h4>Key data at a glance</h4>

    <ul>
      <li>Electricity production</li>

      <ul>
        <li>Expansion of PV to 50 GW by 2050</li>

        <li>Expansion of hydropower to 2 TWh additional production in 2050</li>

        <li>Phase-out of nuclear energy after 50 years of operation</li>

        <li>No use of fossil fuels in 2050</li>

        <li>No seasonal storage through hydrogen</li>

        <li>Carbon-neutral gas power plants for electricity supply security</li>
      </ul>

      <li>Demand</li>

      <ul>
        <li>Total electricity demand of 78 TWh in 2050</li>

        <li>E-mobility: from almost 0 TWh/a to 14.5 TWh/a /li></li>
        <li>Heat pumps: about 12 TWh/a in 2050</li>

        <li>Hydrogen production: almost 3 TWh in 2050</li>
      </ul>

      <li>Imports</li>

      <li>
        Imports to Switzerland are permitted, power line capacities are based on
        the line expansion plans of the European grid operators (ENTSO-E)
      </li>
    </ul>

    <ul>
      <li>
        Data based on <a
          href=" https://www.rechsteiner-basel.ch/"
          title=">Rudolf Rechsteiner, Die Energiewende Im Wartesaal (Zürich: Zocher & Peter, 2021)."
          target="_blank"
          class="link link--external"
        >
          Rudolf Rechsteiner, Die Energiewende Im Wartesaal (Zürich: Zocher &
          Peter, 2021).</a
        >
      </li>
    </ul>
  </Collapsable>

  <Collapsable title="Helion">
    <p>
      The Helion model focusses on the importance of photovoltaics for the
      transformation of the energy system by 2050 and for the design of a secure
      power supply based on renewable energy sources. The most important points
      are:
    </p>

    <ul>
      <li>
        Accelerated expansion of photovoltaics by 2050 without leaps in growth
        and without the use of alpine or other open spaces. The maximum
        expansion can be realised with half of the available roof area.
      </li>

      <li>Reliable funding instruments based on targets and expansion path</li>

      <li>
        Steady build-up of skilled workforce thanks to reliable expansion of
        photovoltaics
      </li>

      <li>
        Direct and indirect use of photovoltaics guarantees a sound security of
        supply
      </li>

      <li>
        Electrification of mobility and battery storage as part of security of
        supply
      </li>
    </ul>

    <h4>Accelerated expansion of photovoltaics without leaps in growth (1)</h4>

    <p>
      In the Helion scenario, the expansion of photovoltaics is growing steadily
      and strongly and will produce 52 TWh of electricity by 2050, corresponding
      to 52 percent of the available potential. The shares of the individual
      market segments are: Single-family homes (23%); multi-family homes (22%),
      industry/commerce (29%), agriculture (8%), public services (2%), parking
      lots/ noise barriers roads/ other infrastructure (16%). It is not
      absolutely necessary to make use of apline areas or open spaces; existing
      infrastructures are sufficient for the safe provision of winter supply.
      Helion views the rapid expansion of solar PV as important because greater
      PV capacity in itself, according to them, compensates for PV winter
      deficits and thus increases the general security of supply.
    </p>

    <h4>Reliable funding frameworks based on targets and expansion path</h4>

    <p>
      According to Helion, a PV expansion in the order of 52 TWh by 2050 is
      technically feasible and can be implemented for the most part using
      existing properties and infrastructure. However, with the existing network
      surcharge of 2.3 Rp per kWh, the expansion can only be financed up to an
      annual expansion of 700 MW, which is far below the necessary annual
      expansion of 2,200 MW. The option of putting the network surcharge fund
      into the red is a viable path, but this would need to be put into place by
      2023.
    </p>

    <h4>Building up a skilled workforce requires stability and perspectives</h4>

    <p>
      This scenario emphasizes an importance of the recruitment potential of new
      skilled workers to ensure a sustainable and solid expansion of
      photovoltaics and continuous growth. For this reason, they view it pivotal
      to avoid leaps in development expansion. A growth-oriented recruitment
      strategy of skilled workers is only possible if an industry conveys
      stability and perspective (example Helion: 250 new jobs created in 1.5
      years). Across Switzerland, Helion assumes that 21,000 additional skilled
      workers may enter the market through the eduction of lateral entrants or
      through the setting up of in-house vocational apprenticeships. If skilled
      workers are to be available in sufficient numbers one day, recruitment
      needs to begin immediately, and with it an accelerated expansion of
      photovoltaic systems.
    </p>

    <h4>
      Direct and indirect use of photovoltaics guarantees a sound security of
      supply
    </h4>

    <p>
      The PV surplus of the Helion scenario in summer are to be systematically
      converted into synthetic fuels that can be used, for example, for process
      heat in industry, for climate-neutral aviation or for the renewable
      operation of decentralised combined heat and power (CHP) plants during the
      winter months, which are operated only when needed and when there is a
      shortage. In the scenario, 52 TWh of electricity from photovoltaics
      enhances Switzerland’s security of supply firstly by providing sufficient
      quantities of electricity and secondly by converting and storing surplus
      summer electricity into synthetic fuels that can be put to various uses.
    </p>

    <h4>
      Electrification of mobility and battery storage as part of the security of
      supply
    </h4>

    <p>
      The proportion of electric vehicles in the total new vehicle market is
      increasing rapidly. These electric vehicles will effectively constitute
      decentrally located and controllable loads and storage facilities.
      Bi-directional vehicle storage creates flexibility for hours or a few
      days. VW will shortly market-launch this new technology. With this,
      e-mobility will achieve a daily storage capacity on the scale of today’s
      daily production of Swiss nuclear power plants, with the difference being
      that loads can be controlled and used more flexibly.
    </p>

    <p>…</p>

    <ol>
      <li>
        Source: Remund, J., Albrecht, S., Stickelberger, D.: Das Schweizer
        PV-Potenzial basierend auf jedem Gebäuden, 2019 (Basis: Meteotest study
        commissioned by Swiss Federal Office of Energy SFOE (April 2019)
      </li>
    </ol>
  </Collapsable>

  <Collapsable title="Axpo">
    <p>
      The Axpo-scenario presents one possible future for electricity. The most
      important trends are:
    </p>

    <ul>
      <li>Expansion of renewable energies</li>

      <li>Gas-to-power with carbon-neutral gas</li>

      <li>Increase in demand despite efficiency gains</li>

      <li>No expansion of fossil-fuel power plants in Switzerland</li>

      <li>Electricity imports when possible and necessary</li>
    </ul>

    <h4>Expansion of renewable energies</h4>
    <p>
      From a technical point of view, renewable energies can be expanded as long
      as permits and financing are secured. To meet demand, an expansion of
      photovoltaics to about 42 GW (roughly 38 TWh) by 2050 is assumed, with
      most plants located on rooftops, only few ground-mounted plants in the
      Alpine region. For wind power, an expansion to 3 TWh generation is assumed
      by 2050, unless permits and financing improve significantly, for
      geothermal less than 2 TWh, especially due to technological restrictions
      (earthquakes, heat-bearing strata), for biomass an additional 1 TWh.
      Electricity generation from hydropower remains at today's level, as it is
      assumed that expansion and losses from residual water remediation will
      cancel each other out.
    </p>

    <h4>Gas-to-power with CO2-neutral gas</h4>

    <p>
      Following the decommissioning of the Gösgen and Leibstadt nuclear power
      plants, gas-fired power plant capacities may be necessary to meet the
      demand for electricity, particularly in winter. To meet climate targets,
      CO2-neutral gas can be used for this purpose. Green and synthesis gases as
      well as green hydrogen come into question here. In the scenario, 1.8 GW of
      gas-to-power plant capacity is used for this purpose.
    </p>

    <h4>Increase in demand despite efficiency gains</h4>

    <p>
      The decarbonization of additional areas such as electromobility, heat
      pumps or for the production of green hydrogen leads to an increasing
      demand for renewable energies. In addition, the population development
      leads to an increase in the classical demand for electricity. Despite
      assumed efficiency increases of technical devices, this results in a
      stronger increase of electricity demand to about 86 TWh by 2050.
    </p>

    <h4>No expansion of fossil-fuel power plants in Switzerland</h4>

    The scenario assumes no new fossil power plants. Instead, electricity demand
    is met by imports and gas-to-power plants based on CO2-neutral gas,
    especially in winter, if this should be necessary.

    <h4>Key data at a glance</h4>

    <li>Electricty production</li>

    <ul>
      <li>Expansion of photovoltaics to about 42 GW by 2050</li>

      <li>Expansion of wind to 2 GW by 2050</li>

      <li>
        Expansion of Gas-to-Power-Anlagen synchronzied with nuclear phase out to
        about 2 GW in 2050
      </li>
      <li>Nuclear phase-out after 60 years of runtime</li>

      <li>
        Decrease in the output of fossil fuel power plants based on their
        lifetime
      </li>

      <li>No new fossil power plants</li>
    </ul>

    <li>Demand</li>

    <ul>
      <li>Total electricity demand of around 86 TWh in 2050</li>

      <li>E-mobility: from almost 0 TWh/a to 13 TWh in 2050</li>

      <li>Heat pumps: 9 TWh in 2050</li>

      <li>Hydrogen production: 6 TWh in 2050</li>
    </ul>

    <li>Imports</li>

    <ul>
      <li>
        Import capacities are aligned with the expansion plans of the Europeans
        TSOs (ENTSO-E)
      </li>
    </ul>
  </Collapsable>
</Collapsable>
