<script>
  import {
    userSettings,
    scenarios,
    globalIsEdited,
    scenarioCH,
    capacityChanged,
  } from './../stores'
  import { _ } from 'svelte-i18n'
  import { createEventDispatcher } from 'svelte'
  const dispatch = createEventDispatcher()

  export let store
  export let geo

  let value = undefined
  let selected = store

  store.subscribe((d) => {
    value = d
  })

  function resetUserSettings() {
    dispatch('scenario-toggle')

    $capacityChanged = undefined

    const tmp = $userSettings

    tmp.general.general.dunkelflaute = 0

    Object.keys(tmp.neighboringCountries.importCapacity).forEach((key) => {
      tmp.neighboringCountries.importCapacity[key].value = 0
      tmp.neighboringCountries.importCapacity[key].edited = false
    })

    Object.keys(tmp.switzerland.installedCapacity).forEach((key) => {
      tmp.switzerland.installedCapacity[key].value = 0
      tmp.switzerland.installedCapacity[key].edited = false
    })

    Object.keys(tmp.switzerland.powerDemand).forEach((key) => {
      tmp.switzerland.powerDemand[key].value = 0
      tmp.switzerland.powerDemand[key].edited = false
    })

    Object.keys(tmp.switzerland.availabilityOfTech).forEach((key) => {
      tmp.switzerland.availabilityOfTech[key] = 'avg'
    })

    $userSettings = tmp
  }
</script>

{#if geo === 'INT'}
  <h4 style="display: inline-block; margin: 0;">
    {$_('page.sections.userControls.neighboringCountries.scenario.label')}
  </h4>
  <ul class="int">
    {#each $scenarios[geo] as scenario, i}
      <li class:active={(!value && i === 0) || value === scenario}>
        <button
          on:click={() => {
            resetUserSettings()

            store.update((a) => scenario)

            $userSettings[
              geo === 'CH' ? 'switzerland' : 'neighboringCountries'
            ].scenario = scenario
          }}
          class="scenario"
          >{$_(
            `page.sections.userControls.${
              geo === 'CH' ? 'switzerland' : 'neighboringCountries'
            }.scenario.scenarios.${scenario}.label`
          )}{#if scenario === $scenarioCH && $globalIsEdited}<span
              class="edited"
              >[{$_(
                'page.sections.userControls.switzerland.scenario.edited'
              )}]</span
            >
          {/if}
        </button>
      </li>
    {/each}
  </ul>
{:else}
  <div class="scenarios">
    <h1 style="display: inline-block; margin: 0;">_Scenarios</h1>
    <ul>
      {#each $scenarios[geo] as scenario, i}
        <li class:active={(!value && i === 0) || value === scenario}>
          <button
            on:click={() => {
              resetUserSettings()

              store.update((a) => scenario)

              $userSettings[
                geo === 'CH' ? 'switzerland' : 'neighboringCountries'
              ].scenario = scenario
            }}
            class="scenario"
            >{$_(
              `page.sections.userControls.${
                geo === 'CH' ? 'switzerland' : 'neighboringCountries'
              }.scenario.scenarios.${scenario}.label`
            )}{#if scenario === $scenarioCH && $globalIsEdited}<span
                class="edited"
                >[{$_(
                  'page.sections.userControls.switzerland.scenario.edited'
                )}]</span
              >
            {/if}
          </button>
        </li>
      {/each}
    </ul>
  </div>
{/if}

<!-- <p>
  {$_(
    `page.sections.userControls.${
      geo === 'CH' ? 'switzerland' : 'neighboringCountries'
    }.scenario.scenarios.${value}.description`
  )}
</p> -->
<style>
  .edited {
    font-size: 1.2rem;
    font-style: italic;
    margin-left: 5px;
  }
  .scenario {
    /* border: 2px solid #e10019; */
    border: none;
    background: var(--color-grey-200);
    width: 100%;
    height: 40px;
    /* background: #ffffff; */
    /* border: 2px solid var(--accent-color); */
    /* color: var(--accent-color); */
    outline: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: var(--scale-3);
  }

  .scenario:active {
    background: var(--accent-color);
    color: white;
  }

  li.active button {
    background: var(--accent-color);
    color: white;
  }

  .scenarios ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .scenarios li {
    margin-bottom: 3px;
  }

  .scenarios li.active button {
    background-color: var(--accent-color);
    color: var(--accent-text-color);
    border-radius: 25px;
  }

  .scenarios button {
    border: none;
    outline: none;
    background: none;
    padding: none;
    cursor: pointer;
    /* background-color: var(--inactive-color); */

    color: var(--text-color);
    padding: 4px 30px;
    border-radius: 5px;
    width: 100%;
    font-size: 1.4rem;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: var(--size-2);
  }
</style>
