<script>
  import {
    userSettings,
    calculatedData,
    activeMonths,
    capacityChanged,
    isReset,
    selectedYear,
  } from './../stores.ts'
  import { _ } from 'svelte-i18n'
  import { sum } from 'd3-array'
  import { format } from 'd3-format'
  import Reset from './../ui/Reset.svelte'
  import InfoIcon from '../ui/InfoIcon.svelte'

  let demandGroupSlider

  isReset.subscribe(() => (demandGroupSlider = 0))

  const f = format('.1f')

  $: isEdited =
    $userSettings.switzerland.powerDemand.klass.value !== 0 ||
    $userSettings.switzerland.powerDemand.klass.edited === true ||
    $userSettings.switzerland.powerDemand.hp.value !== 0 ||
    $userSettings.switzerland.powerDemand.hp.edited === true ||
    $userSettings.switzerland.powerDemand.ev.value !== 0 ||
    $userSettings.switzerland.powerDemand.ev.edited === true ||
    $userSettings.switzerland.powerDemand.h2.value !== 0 ||
    $userSettings.switzerland.powerDemand.h2.edited === true
</script>

<div class="outer">
  <div class="wrapper">
    <span class="label">
      <!-- <img src="icons/demand.png" alt="icon" class="icon" /> -->
      <InfoIcon
        title={$_('page.sections.userControls.switzerland.demand.label')}
        description={$_(
          'page.sections.userControls.switzerland.demand.description'
        )}
      />
      {$_(`page.sections.userControls.switzerland.demand.label`)}
      {#if isEdited}
        <span style="display: inline-flex; margin-left: var(--size-2)">
          <Reset
            on:click={() => {
              $userSettings.switzerland.powerDemand.klass.value = 0
              $userSettings.switzerland.powerDemand.klass.edited = false

              $userSettings.switzerland.powerDemand.hp.value = 0
              $userSettings.switzerland.powerDemand.hp.edited = false

              $userSettings.switzerland.powerDemand.ev.value = 0
              $userSettings.switzerland.powerDemand.ev.edited = false

              $userSettings.switzerland.powerDemand.h2.value = 0
              $userSettings.switzerland.powerDemand.h2.edited = false
            }}
          />
        </span>
      {/if}
    </span>

    <!-- {#if $calculatedData && $selectedYear}
      <div class="in-2050">
        <span style="text-align: right;">in 2050:</span>
        <span style="font-weight: bold; font-size: 1.4rem;">
          {f(
            $calculatedData?.getAnnualTimeSeries((year) =>
              sum(
                year.months
                  .filter((month) => $activeMonths.includes(month.month))
                  .map((d) => d.calculated.generation.demand)
              )
            )[$selectedYear - 2020]
          )} TWh
        </span>
      </div>
    {/if} -->
  </div>

  <!-- <input
  list="tickmarks-demand"
  type="range"
  min={-1}
  max={1}
  step="0.5"
  bind:value={demandGroupSlider}
  style="width: 100%; background: none;"
  on:click|stopPropagation={() => {}}
  on:input={(e) => {
    const tmp = $userSettings

    $capacityChanged = undefined

    tmp.switzerland.powerDemand.klass.value = e.target.value
    tmp.switzerland.powerDemand.klass.edited = true

    tmp.switzerland.powerDemand.hp.value = e.target.value
    tmp.switzerland.powerDemand.hp.edited = true

    tmp.switzerland.powerDemand.ev.value = e.target.value
    tmp.switzerland.powerDemand.ev.edited = true

    tmp.switzerland.powerDemand.h2.value = e.target.value
    tmp.switzerland.powerDemand.h2.edited = true
    $userSettings = tmp
  }}
/> -->
</div>

<style>
  .outer {
    width: 100%;
    padding: 4px;
  }
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .label {
    margin-left: 5px;
    display: flex;
  }

  .in-2050 {
    font-size: 1rem;
    display: inline-flex;
    flex-direction: column;
    margin-right: 5px;
  }
</style>
