<script>
  import EnergyMix from '../charts/energy-mix/EnergyMix.svelte'
  import HoverValues from '../charts/matrix/HoverValues.svelte'
  import Matrix from '../charts/matrix/Matrix.svelte'
  import { loadData } from '../fetchers'
  import {
    calculatedData,
    inputData,
    scenarioCH,
    scenarioINT,
    scenarios,
    selectedYear,
    activeMainView,
    mousePosition,
    showPopover,
  } from '../stores'
  import Footer from '../ui/Footer.svelte'
  import Password from '../components/Password.svelte'
  import ScenarioCostPanel from '../components/panels/ScenarioCostPanel.svelte'
  import SubsidiesPanel from '../components/panels/SubsidiesPanel.svelte'
  import IncomePanel from '../components/panels/IncomePanel.svelte'
  import Row from '../components/Row.svelte'
  import ControlsRow1 from '../sections/ControlsRow1.svelte'
  import ControlsRow2 from '../sections/ControlsRow2.svelte'
  import Header from '../sections/Header.svelte'
  import Stressors from '../sections/Stressors.svelte'
  import { Tab, TabList, TabPanel, Tabs } from './../components/tabs/tabs'
  import MainSelection from '../sections/MainSelection.svelte'
  import UserChangeSummaryPanel from '../components/panels/UserChangeSummaryPanel.svelte'
  import 'pollen-css'
  import { _, locale } from 'svelte-i18n'
  import CostSummaryPanel from '../components/panels/CostSummaryPanel.svelte'
  import Popover from '../components/Popover.svelte'
  import Panel from '../components/panels/Panel.svelte'
  import ProfileChart from '../charts/profile/ProfileChart.svelte'
  import AboutDE from '../about/de/About.svelte'
  import AboutEN from '../about/en/About.svelte'
  import Tracking from '../components/Tracking.svelte'

  let demandGroupSlider
  let importCapacityGroupSlider

  $: {
    if ($calculatedData) {
      console.log($calculatedData)
    }
  }

  let p

  $: {
    if ($scenarioCH !== undefined && $scenarioINT !== undefined) {
      p = new Promise((resolve) => {
        const data = loadData($scenarioCH, $scenarioINT)

        $inputData = data

        resolve(data)
      })
    }
  }

  let activeTechGroup = undefined

  let expand = null
</script>

<!-- <Password> -->
<Tracking />
<main class="wrapper">
  <Header {p} />
  <!-- <Criticals /> -->

  <div class="row">
    <MainSelection />
  </div>

  <div class="primary-wrapper">
    <div class="primary">
      <div class="column" style="height: 100%;">
        {#if $calculatedData}
          {#if $activeMainView === 'cost'}
            <Tabs>
              <TabList>
                <Tab>
                  {@html $_('page.sections.charts.cost.income_summary.title')}
                </Tab>
              </TabList>

              <TabPanel>
                <CostSummaryPanel />
              </TabPanel>
            </Tabs>
          {:else}
            <Tabs>
              <TabList alignment="flex-start">
                <Tab>{$_('page.sections.new.summary')}</Tab>
                <Tab>{$_('page.sections.new.details')}</Tab>
                <!-- <Tab>{$_('page.sections.new.winter')}</Tab> -->
              </TabList>

              <TabPanel>
                {#if $calculatedData}
                  <UserChangeSummaryPanel />
                {:else}
                  Loading...
                {/if}
              </TabPanel>

              <TabPanel>
                {#if $selectedYear !== undefined}
                  <HoverValues />
                {:else}
                  <div class="help-text">
                    _hover help text: hover over the chart or click on a year
                  </div>
                {/if}
              </TabPanel>

              <!-- <TabPanel>
                <MultiLineWrapper data={[]} />
              </TabPanel> -->
            </Tabs>
          {/if}
        {:else}
          <div class="loading">Loading...</div>
        {/if}
      </div>

      <div class="column">
        <Tabs>
          <TabList alignment="space-between">
            <div>
              <span class="tab-group-label"
                >{$_('page.tabs.energy_security')}:</span
              >
              <Tab id="energymix">{$_('page.tabs.energy_mix')}</Tab>
              <Tab id="bubblechart">{$_('page.tabs.month_overview')}</Tab>
              <Tab id="profilechart">{$_('page.tabs.annual_cycle')}</Tab>
            </div>
            <!-- <Tab>{$_('page.sections.new.cost')}</Tab> -->
            <div>
              <span class="tab-group-label">{$_('page.tabs.cost')}:</span>
              <Tab id="scenario_cost">{$_('page.tabs.scenario_costs')}</Tab>
              <Tab id="income">{$_('page.tabs.income')}</Tab>
              <Tab id="subsidies">{$_('page.tabs.subsidies')}</Tab>
            </div>
          </TabList>

          <TabPanel>
            {#if $calculatedData}
              <Panel
                title={$_('page.panels.energy_mix.title')}
                description={$_('page.panels.energy_mix.description')}
              >
                <EnergyMix />
              </Panel>
            {:else}
              Loading...
            {/if}
          </TabPanel>

          <TabPanel>
            {#if $calculatedData && $scenarios}
              <Panel
                title={$_('page.panels.surplus_deficit.title')}
                description={$_('page.panels.surplus_deficit.description')}
              >
                <Matrix />
              </Panel>
            {:else}
              Loading...
            {/if}
          </TabPanel>

          <TabPanel>
            {#if $calculatedData && $scenarios}
              <Panel
                title={$_('page.panels.profile.title')}
                description={$_('page.panels.profile.description')}
              >
                <ProfileChart />
              </Panel>
            {:else}
              Loading...
            {/if}
          </TabPanel>

          <TabPanel>
            {#if $calculatedData}
              <Panel
                title={$_('page.panels.scenario_cost.title')}
                description={$_('page.panels.scenario_cost.description')}
              >
                <ScenarioCostPanel />
              </Panel>
            {:else}
              Loading...
            {/if}
          </TabPanel>

          <TabPanel>
            {#if $calculatedData}
              <Panel
                title={$_('page.panels.income.title')}
                description={$_('page.panels.income.description')}
              >
                <IncomePanel />
              </Panel>
            {:else}
              Loading...
            {/if}
          </TabPanel>

          <TabPanel>
            {#if $calculatedData}
              <Panel
                title={$_('page.panels.subsidies.title')}
                description={$_('page.panels.subsidies.description')}
              >
                <SubsidiesPanel />
              </Panel>
            {:else}
              Loading...
            {/if}
          </TabPanel>
        </Tabs>
      </div>
    </div>
  </div>

  <div class="controls">
    <div class="config">
      <!-- Stressors -->
      <Row>
        <Stressors slot="top" />
      </Row>

      <!-- Capacity 1 + Demand -->
      <ControlsRow1 bind:expand />

      <!-- Capacity 2 + Import -->
      <ControlsRow2 bind:expand />
    </div>
  </div>

  <div class="about">
    <div class="about-inner">
      <h1>{$_('page.sections.about.label')}</h1>
      {#if $locale === 'en'}
        <AboutEN />
      {:else}
        <AboutDE />
      {/if}
    </div>
  </div>

  <div class="video-wrapper">
    {#if $locale === 'en'}
      <!-- EN -->
      <iframe
        class="video"
        src="https://www.youtube.com/embed/LOh0OTKGKUM?si=6JlBVjiFmRwTU5J3&amp;controls=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
    {:else}
      <!-- DE-->
      <iframe
        class="video"
        src="https://www.youtube.com/embed/tlEhZVPAUlE?si=2pJ9GTdST2CWyQzE&amp;controls=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
    {/if}
  </div>
</main>

{#if $showPopover}
  <Popover />
{/if}

<Footer />
<!-- </Password> -->
<svelte:window
  on:mousemove={(e) => {
    $mousePosition = {
      // x: e.clientX - Math.max(0, window.innerWidth - 1440) / 2,
      // x: e.clientX + window.scrollX,
      // y: e.clientY + window.scrollY,
      x: e.pageX,
      y: e.pageY,
    }
  }}
/>

<svelte:head>
  <meta property="og:title" content="Axpo Power Switcher" />
  <meta property="og:site_name" content="Axpo Power Switcher" />
  <meta property="og:description" content="" />
  <meta property="og:url" content="https://powerswitcher.axpo.com" />
  <meta property="og:type" content="website" />
  <meta
    property="og:image"
    content="https://powerswitcher.axpo.com/img/Axpo_Produktlogo_PowerSwitcher_RGB_ohneKurven.png"
  />
</svelte:head>

<style>
  :root {
    --accent-color: #ff5d64;
    --accent-color-light: #ffdadb;
    --inactive-color: #d1d5db;
    --text-color: #3d3d3d;
    --accent-text-color: #fff;
    --weak-color: #f4f4f4;
    --extra-weak-color: #f9f9f9;
    --color-gray-old: #efefef;
    --color-gray-400: #9ca3af;
    --color-gray-100: #f9f9f9;

    font-family: 'Open Sans', sans-serif;
  }

  .video-wrapper {
    /* display: flex; */
    /* justify-content: center; */
    width: 100%;
  }

  .video {
    aspect-ratio: 16/9;
    max-width: var(--width-md);
    width: 100%;
    margin-left: 370px;
  }

  .about {
    padding-block: var(--size-10);
    width: 100%;
  }

  .about-inner {
    width: var(--width-lg);
    margin-left: 370px;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    top: 4px;
    position: relative;
  }

  main {
    /* height: 140vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* width: 100%; */
    width: 1440px;
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
  }

  .config {
    display: grid;
    grid-template-columns: 5fr;
    max-width: 1440px;
    width: 100%;
  }

  .row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
  }

  .controls {
    background-color: white;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
  }

  .primary {
    height: 680px;
    min-width: 1180px;
    display: grid;
    grid-template-columns: 2fr 6fr;
    width: 100%;
    gap: 10px;
  }

  .primary-wrapper {
    display: flex;
    justify-content: center;
  }

  .tab-group-label {
    font-size: var(--scale-2);
    font-style: italic;
  }
</style>
