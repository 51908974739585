<script>
  import {
    calculatedData,
    selectedTech,
    techGroups,
    selectedTimePeriod,
    selectedYear,
    selectedMonth,
    selectedYearIsLocked,
  } from './../../stores.ts'
  import { stack, area, line, curveCatmullRom } from 'd3-shape'
  import { scaleLinear, scaleQuantize } from 'd3-scale'
  import { range, sum, max } from 'd3-array'
  import { tweened } from 'svelte/motion'
  import { cubicOut } from 'svelte/easing'
  import { _ } from 'svelte-i18n'
  import { pointer } from 'd3-selection'
  import { summerMonths, winterMonths, allMonths } from '../../constants'
  import { getContext } from 'svelte'
  import { onMount } from 'svelte'

  const { width, height } = getContext('LayerCake')

  export let showImportPotential = false
  // export let selectedTimePeriod = 'winter'
  export let hoverTechnology

  let demandLine
  let demandLabelAngle = 0

  const margin = {
    top: 20,
    right: 38,
    bottom: 41,
    left: 52,
  }

  $: w = $width - margin.left - margin.right
  $: h = $height - margin.top - margin.bottom

  $: x = scaleLinear().domain([0, 30]).range([0, w])
  $: xq = scaleQuantize().domain([0, w]).range(range(31))

  $: monthRange =
    $selectedTimePeriod === 'winter'
      ? winterMonths
      : $selectedTimePeriod === 'summer'
      ? summerMonths
      : allMonths

  let hoverTech
  let mousePosition = [0, 0]

  $: keys = $techGroups
    .map((d) => d.key)
    .reverse()
    .concat(['speicherReserveUsed', 'import'])

  $: chartData = $calculatedData.getAnnualTimeSeries((year) => {
    // const annualGeneration = year.calculated.generation

    const filteredGeneration = year.months
      .filter((month) => monthRange.includes(month.month))
      .map((d) => d.calculated.generation.generation.byTechnology)
      .reduce((acc, cur, i) => {
        Object.entries(cur).forEach((entry) => {
          if (!acc[entry[0]]) {
            acc[entry[0]] = entry[1]
          } else {
            acc[entry[0]] += entry[1]
          }
        })
        return acc
      }, {})

    const techData = Object.assign(
      $techGroups.reduce((acc, cur) => {
        acc[cur.key] = Object.entries(filteredGeneration)
          .filter((d) => cur.technologies.includes(d[0]))
          .reduce((a, b) => a + b[1], 0)

        return acc
      }, {}),
      {
        speicherReserveUsed: Math.max(
          0,
          sum(
            year.months.filter((month) => monthRange.includes(month.month)),
            (month) =>
              month.calculated.generation.generation.speicherReserve.used
          )
        ),
      }
    )

    const byTechGroup = $techGroups.reduce((acc, cur) => {
      acc[cur.key] = cur.technologies.reduce((acc, cur) => {
        acc[cur] = filteredGeneration[cur]
        return acc
      }, {})
      return acc
    }, {})

    const importPotential = sum(
      year.months
        .filter((month) => monthRange.includes(month.month))
        .map((d) => Object.values(d.calculated.maxImportability))
        .flat()
    )

    const importData = sum(
      year.months.filter((month) => monthRange.includes(month.month)),
      (d) => d.calculated.generation.import.total
    )

    const deficit = sum(
      year.months.filter((month) => monthRange.includes(month.month)),
      (d) => d.calculated.generation.deficit
    )

    return {
      ...techData,
      import: importData,
      importPotential: importPotential - importData,
      byTechGroup,
      deficit,
    }
  })

  $: data2 = () => {
    return stack().keys(keys)(chartData)
  }

  $: demand = $calculatedData
    ? $calculatedData.getAnnualTimeSeries((year) =>
        sum(
          year.months
            .filter((month) => monthRange.includes(month.month))
            .map((d) => d.calculated.generation.demand)
        )
      )
    : []

  const domain = tweened(0, {
    easing: cubicOut,
    duration: 0,
  })

  $: $domain = Math.max(
    max(data2()[data2().length - 1], (d) => d[1]),
    max(demand),
    showImportPotential ? max(importPotentialData, (d) => d[1]) : 0
  )

  $: y = scaleLinear().domain([0, $domain]).range([h, 0])

  $: pro = area()
    .x((d, i) => x(i))
    .y0((d) => y(d[0]))
    .y1((d) => y(d[1]))
    .curve(curveCatmullRom)

  $: dem = line()
    .x((d, i) => x(i))
    .y((d) => y(d))
    .curve(curveCatmullRom)

  $: demClip = area()
    .x((_, i) => x(i))
    .y0((d) => y.range()[0])
    .y1((d) => y(d))
    .curve(curveCatmullRom)

  $: ip = area()
    .x((_, i) => x(i))
    .y0((d) => y(d[0]))
    .y1((d) => y(d[1]))
    .curve(curveCatmullRom)

  $: ipClip = area()
    .x((_, i) => x(i))
    .y0((d) => y.range()[1])
    .y1((d) => y(d))
    .curve(curveCatmullRom)

  $: def = area()
    .x((d, i) => x(i))
    .y0((d, i) => y(d[0]))
    .y1((d) => y(d[1]))
    .curve(curveCatmullRom)

  $: getColor = function (i, tech) {
    if (!$selectedTech || $selectedTech === tech) {
      let c
      if (tech === 'import') {
        return 'url(#diagonalHatch-import)'
      } else if (tech === 'importPotential') {
        return 'url(#diagonalHatch-import-potential)'
      } else if (tech === 'speicherReserveUsed') {
        return 'var(--speicherreserve-color)'
      } else if (tech === 'deficit') {
        return 'var(--color-energy-deficit)'
      }
      return $techGroups.find((a) => a.key === tech).color
    }

    return '#E0D6D3'
  }

  $: deficitData = range(2020, 2051).map((_, i) => [
    demand[i],
    data2()[9][i][1],
  ])

  $: importPotentialData = range(2020, 2051).map((_, i) => [
    Math.max(demand[i], data2()[8][i][1]),
    Math.max(demand[i], data2()[8][i][1]) + chartData[i].importPotential,
  ])

  onMount(() => ($selectedMonth = undefined))
</script>

<slot name="defs">
  <pattern
    id="diagonalHatch-import"
    patternUnits="userSpaceOnUse"
    width="4"
    height="4"
  >
    <path
      d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
      style="stroke:#000; stroke-width:1"
    />
  </pattern>

  <pattern
    id="diagonalHatch-import-potential"
    patternUnits="userSpaceOnUse"
    width="4"
    height="4"
  >
    <path
      d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
      style="stroke:#BFB7B8; stroke-width:1"
    />
  </pattern>
</slot>
<g transform="translate({margin.left}, {margin.top})">
  <text
    y={y.range()[1]}
    dx={24}
    dy={-10}
    x={w}
    style="text-anchor: start; font-size: 1.2rem;">TWh</text
  >
  {#each y.nice().ticks() as tick}
    <line y1={y(tick)} y2={y(tick)} x2={w} stroke="#f0f0f0" />
    <text
      dx={10}
      style="dominant-baseline: central; text-anchor: start;"
      class="tick"
      x={w}
      y={y(tick)}
    >
      {tick}
    </text>
  {/each}

  <g id="deficit-area">
    <clipPath id="demand-clip-path-deficit">
      <path d={demClip(demand)} />
    </clipPath>

    <path
      d={def(deficitData)}
      stroke="none"
      fill={getColor(0, 'deficit')}
      clip-path="url(#demand-clip-path-deficit)"
      on:mouseover={(e) => {
        $selectedYear = 2020 + xq(mousePosition[0])

        hoverTech = 'deficit'
        hoverTechnology = {
          key: 'deficit',
          value: chartData[$selectedYear - 2020].deficit,
        }
      }}
      on:mouseout={() => {
        hoverTech = undefined
        hoverTechnology = undefined
      }}
      on:mousemove={(e) => {
        mousePosition = pointer(e)

        $selectedYear = 2020 + xq(mousePosition[0])

        hoverTech = 'deficit'
        hoverTechnology = {
          key: 'deficit',
          value: chartData[$selectedYear - 2020].deficit,
        }
      }}
    />
    {#if hoverTech && hoverTech !== 'deficit'}
      <path d={def(deficitData)} fill="rgba(255, 255, 255, 0.6)" />
    {/if}
  </g>

  {#each data2() as d, i}
    <g
      on:mousemove={(e) => {
        mousePosition = pointer(e)
        $selectedYear = 2020 + xq(mousePosition[0])
      }}
    >
      {#if ['import', 'speicherReserveUsed'].includes(d.key)}
        <clipPath id="demand-clip-path-{d.key}">
          <path d={demClip(demand)} />
        </clipPath>

        <path
          d={pro(d)}
          fill={getColor(i, d.key)}
          stroke="rgba(0, 0, 0, 0.2)"
          stroke-width={0.5}
          clip-path="url(#demand-clip-path-{d.key})"
        />
      {:else}
        <path
          d={pro(d)}
          fill={getColor(i, d.key)}
          stroke="rgba(0, 0, 0, 0.2)"
          stroke-width={0.5}
        />
      {/if}
      {#if hoverTech && hoverTech !== d.key}
        <path
          d={pro(d)}
          fill="rgba(255, 255, 255, 0.6)"
          style="pointer-events: none;"
        />
      {/if}
    </g>
  {/each}

  {#if showImportPotential}
    <path
      d={ip(importPotentialData)}
      fill="url(#diagonalHatch-import-potential)"
      bind:this={demandLine}
    />
  {/if}

  <path
    d={dem(demand)}
    id="demand-line"
    stroke="black"
    stroke-width={3}
    fill="none"
    bind:this={demandLine}
  />

  <!-- <path d={demClip(demand)} fill="rgba(255, 0, 0, 0.3)" /> -->

  <g
    transform="rotate({demandLabelAngle}, {w / 2}, {y(
      demand[(demand.length - 1) / 2]
    )})"
  >
    <rect
      x={w / 2 - $_('page.sections.charts.energyMix.demand').length * 6 - 3}
      y={y(demand[(demand.length - 1) / 2]) - 15}
      width={$_('page.sections.charts.energyMix.demand').length * 12 + 6}
      height={30}
      fill="black"
    />
    <text
      x={w / 2}
      y={y(demand[(demand.length - 1) / 2])}
      style="text-anchor: middle; dominant-baseline: central; fill: white; font-size: 1.8rem"
      >{$_('page.sections.charts.energyMix.demand')}</text
    >
  </g>

  <g
    transform="translate({x($selectedYear - 2020) -
      w / 30 / 2 -
      1}, {y.range()[1] - 4})"
  >
    <text
      fill="red"
      dx={$selectedYearIsLocked ? (w / 30 + 2) / 2 - 6 : (w / 30 + 2) / 2}
      dy={-6}
      style="font-size: var(--scale-1); text-anchor: middle; font-weight: bold; user-select: none;"
      >{$selectedYear}</text
    >
    {#if $selectedYearIsLocked}
      <g transform="translate({24}, {-20})">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="16px"
          height="16px"
        >
          <path
            fill="red"
            d="M 12 1 C 8.6761905 1 6 3.6761905 6 7 L 6 8 C 4.9069372 8 4 8.9069372 4 10 L 4 20 C 4 21.093063 4.9069372 22 6 22 L 18 22 C 19.093063 22 20 21.093063 20 20 L 20 10 C 20 8.9069372 19.093063 8 18 8 L 18 7 C 18 3.6761905 15.32381 1 12 1 z M 12 3 C 14.27619 3 16 4.7238095 16 7 L 16 8 L 8 8 L 8 7 C 8 4.7238095 9.7238095 3 12 3 z M 6 10 L 18 10 L 18 20 L 6 20 L 6 10 z M 12 13 C 10.9 13 10 13.9 10 15 C 10 16.1 10.9 17 12 17 C 13.1 17 14 16.1 14 15 C 14 13.9 13.1 13 12 13 z"
          />
        </svg>
      </g>
    {/if}
    <rect
      height={h + 7}
      width={w / 30 + 2}
      fill="var(--accent-color)"
      opacity={0.2}
      style="outline: none; pointer-events: none;"
      rx={3}
      ry={3}
    />
  </g>

  {#each x.nice().ticks(30) as tick, i}
    <text
      dy={8}
      style="dominant-baseline: central; text-anchor: end; font-weight: {i %
        10 ===
      0
        ? 'bold'
        : 'normal'}; font-size: {i % 10 === 0
        ? 'var(--scale-2)'
        : 'var(--scale-1)'};"
      class="tick"
      fill={$selectedYear === 2020 + tick ? 'var(--accent-color)' : 'black'}
      y={h}
      x={x(tick)}
      transform="rotate(-90, {x(tick)}, {h + 10})"
    >
      {2020 + tick}
    </text>
  {/each}

  {#each data2() as d, i}
    <g
      on:mouseover={(e) => {
        hoverTech = d.key
        hoverTechnology = {
          key: d.key,
          value: d[$selectedYear - 2020].data[d.key],
        }
        if (!$selectedYearIsLocked) {
          $selectedYear = 2020 + xq(mousePosition[0])
        }
      }}
      on:mouseout={() => {
        hoverTech = undefined
        hoverTechnology = undefined
      }}
      on:mousemove={(e) => {
        mousePosition = pointer(e)
        if (!$selectedYearIsLocked) {
          $selectedYear = 2020 + xq(mousePosition[0])
        }
        hoverTechnology = {
          key: d.key,
          value: d[$selectedYear - 2020].data[d.key],
        }
      }}
      on:click={() => {
        $selectedYearIsLocked = !$selectedYearIsLocked
        $selectedYear = 2020 + xq(mousePosition[0])
      }}
    >
      <path d={pro(d)} fill="transparent" stroke-width={3} />
    </g>
  {/each}
</g>

<style>
  p {
    max-width: 680px;
  }
</style>
