<script>
  import { calculatedData } from './../stores'
  import { sum, mean, range, groups, max } from 'd3-array'
  import { stack, area, curveCatmullRom, line } from 'd3-shape'
  import { scaleLinear, scaleQuantize } from 'd3-scale'
  import OutlineText from '../components/OutlineText.svelte'
  import { _ } from 'svelte-i18n'
  import { format } from 'd3-format'
  import { pointer } from 'd3-selection'

  const keys = ['AT', 'IT', 'FR', 'DE']

  const f = format(',.2f')

  let hoverValue = undefined
  let mousePosition

  $: data = stack().keys(keys)(
    $calculatedData.getAnnualTimeSeries((year) => {
      const result = {}
      keys.forEach((country) => {
        result[country] = mean(
          year.months,
          (d) => d.calculated.importCapacity[country] / 1000
        )
      })

      return result
    })
  )

  $: minData = groups(
    $calculatedData.getMonthlyTimeSeries(),
    (d) => d.parentYear.year
  ).map((d) =>
    mean(
      d[1].map((month) =>
        sum(keys.map((key) => month.input[key].exportCapacity.min / 1000))
      )
    )
  )

  $: maxData = groups(
    $calculatedData.getMonthlyTimeSeries(),
    (d) => d.parentYear.year
  ).map((d) =>
    mean(
      d[1].map((month) =>
        sum(keys.map((key) => month.input[key].exportCapacity.max / 1000))
      )
    )
  )

  const width = 250
  const height = 150

  const margin = {
    top: 20,
    right: 20,
    bottom: 20,
    left: 30,
  }

  const w = width - margin.left - margin.right
  const h = height - margin.top - margin.bottom

  const x = scaleLinear().domain([0, 30]).range([0, w])
  const xq = scaleQuantize().domain([0, w]).range(range(31))

  $: y = scaleLinear()
    .domain([0, max(maxData)])
    .range([h, 0])

  const a = area()
    .x((d, i) => x(i))
    .y0((d) => y(d[0]))
    .y1((d) => y(d[1]))
    .curve(curveCatmullRom)
</script>

<svg {width} {height}>
  <defs>
    <marker
      id="arrow"
      viewBox="0 0 10 10"
      refX="5"
      refY="5"
      markerWidth="6"
      markerHeight="6"
      orient="auto-start-reverse"
    >
      <path d="M 0 0 L 10 5 L 0 10 z" />
    </marker>
  </defs>
  <g transform="translate({margin.left} {margin.top})">
    {#each data as d, i}
      <path
        d={a(d)}
        fill={hoverValue && hoverValue.country === d.key
          ? 'var(--accent-color)'
          : i % 2 === 0
          ? 'rgba(94, 74, 77, 0.3)'
          : 'rgba(191, 183, 184, 0.3)'}
        on:mouseover={(e) => {
          mousePosition = pointer(e)
          hoverValue = {
            country: d.key,
            value: data[i][xq(mousePosition[0])].data[d.key],
            year: 2020 + xq(mousePosition[0]),
          }
        }}
        on:mousemove={(e) => {
          mousePosition = pointer(e)
          hoverValue = {
            country: d.key,
            value: data[i][xq(mousePosition[0])].data[d.key],
            year: 2020 + xq(mousePosition[0]),
          }
        }}
        on:mouseout={() => (hoverValue = undefined)}
        on:focus={() => {}}
        on:blur={() => {}}
      />
      {#if !hoverValue}
        {#if d[25][1] - d[25][0] > 13}
          <text
            style="text-anchor: end; dominant-baseline: central; font-size: var(--scale-1);"
            dx={-2}
            x={x(25)}
            y={y((d[25][0] + d[25][1]) / 2)}>{keys[i]}</text
          >
        {/if}
      {/if}
    {/each}

    {#if hoverValue}
      <text x={w / 2} y={y.range()[1]} style="text-anchor: middle;"
        ><tspan style="font-weight: bold;"
          >{$_(
            `page.sections.userControls.neighboringCountries.capacity.countries.${hoverValue.country}`
          )}</tspan
        >: {f(hoverValue.value)} GW</text
      >
    {/if}

    <line x2={w} y1={y(0)} y2={y(0)} stroke="black" />
    <line y1={y(0)} y2={y.range()[1]} stroke="black" marker-end="url(#arrow)" />

    {#if !hoverValue}
      {#each range(2020, 2051) as year, i}
        {#if i % 5 === 0}
          <text transform="translate({x(i)}, {h})" dy={14} class="x-tick"
            >{year}</text
          >
        {/if}
      {/each}
    {:else}
      <text
        transform="translate({x(xq(mousePosition[0]))}, {h})"
        dy={14}
        class="x-tick">{hoverValue.year}</text
      >
    {/if}

    {#each y.nice().ticks(5) as tick}
      {#if tick > 0}
        <text dx={-4} class="y-tick" y={y(tick)}>{tick}</text>
      {/if}
    {/each}

    <text
      style="dominant-baseline: central; text-anchor: middle; font-size: var(--scale-1);"
      dy={-14}
      dx={-4}
      y={y.range()[1]}>GW</text
    >
  </g>
</svg>

<style>
  text {
    font-size: var(--scale-1);
  }
  svg {
    max-width: 370px;
  }
</style>
