<script>
  import {
    individualTechMapping,
    individualTechMappingReverse,
  } from './../../constants'
  import { getContext } from 'svelte'
  import { sum, range } from 'd3-array'
  import { stack } from 'd3-shape'
  import {
    selectedLCOE,
    selectedCostView,
    techGroups,
    selectedPowerPrice,
    costDetailsHover,
    calculatedData,
  } from '../../stores'
  import { scaleLinear } from 'd3-scale'
  import { format } from 'd3-format'
  import { _ } from 'svelte-i18n'
  import { trueCosts } from '../../costCalculation.js'

  export let scenario
  export let maxValues
  export let maxGeneration
  export let maxCost
  export let maxDemand

  $: techColors = $techGroups.reduce((acc, cur) => {
    cur.technologies.forEach((tech) => {
      acc[individualTechMappingReverse[tech]] = cur.color
    })

    return acc
  }, {})

  const f = format(',.2s')

  const { width, height, data } = getContext('LayerCake')

  const keys = Object.entries(individualTechMapping).filter(
    // ([T_key, _]) => !['T16', 'T17', 'T18'].includes(T_key)
    ([T_key, _]) => !['T16', 'T18'].includes(T_key)
  )

  $: result = keys.reduce((acc, [T_key, old_key]) => {
    let dummy = $selectedLCOE // just here to trigger a recalculation when the setting is changed

    if (!acc[T_key]) {
      acc[T_key] = []
    }

    range(2020 + 2, 2051).forEach((year, i) => {
      // const generation = $data.years[i].calculated.generation[old_key] * 1000
      const generation =
        $data.years[i + 2].trueCost.technologies[T_key]
          .totalCalculatedGeneration

      let costPerMWh

      if ($selectedCostView === 'Social') {
        costPerMWh =
          $data.years[i + 2].trueCost.technologies[T_key].calculated.netBenefit
            .lcoeMWh.social
      } else {
        costPerMWh =
          $data.years[i + 2].trueCost.technologies[T_key].calculated.netBenefit
            .lcoeMWh.investorTotal
      }

      const costPerMWhInvestor =
        $data.years[i + 2].trueCost.technologies[T_key].calculated.netBenefit
          .lcoeMWh.investorTotal

      const costPerMWhSocial =
        $data.years[i + 2].trueCost.technologies[T_key].calculated.netBenefit
          .lcoeMWh.social

      const costPerTechnologyInvestor =
        (generation * costPerMWhInvestor) / 1000000

      let costPerTechnologySocial = (generation * costPerMWhSocial) / 1000000

      if (T_key === 'T13' && $selectedCostView === 'Social') {
        costPerTechnologySocial =
          costPerTechnologySocial +
          (generation *
            $data.years[i + 2].trueCost.technologies['T12'].calculated
              .netBenefit.lcoeMWh.social) /
            1000000
      }

      const totalInvestorMioEUR =
        $data.years[i + 2].trueCost.technologies[T_key].calculated.netBenefit
          .lcoeMioEUR.investorTotal

      const totalMioEUR =
        $data.years[i + 2].trueCost.technologies[T_key].calculated.netBenefit
          .lcoeMioEUR.total

      acc[T_key].push({
        year,
        generation,
        costPerMWh,
        costPerMWhInvestor,
        costPerMWhSocial,
        costPerTechnology:
          $selectedCostView === 'Investor'
            ? costPerTechnologyInvestor
            : costPerTechnologyInvestor + costPerTechnologySocial,
        costPerTechnologyInvestor,
        costPerTechnologySocial,
        totalInvestorMioEUR,
        totalMioEUR,
      })
    })

    return acc
  }, {})

  $: totalImport = sum(
    $data.getMonthlyTimeSeries((month) =>
      Math.max(
        0,
        month.calculated.totalDemand.CH - month.calculated.totalProduction.CH
      )
    )
  )

  $: T6generation = sum(result.T6, (d) => d.generation)
  $: T6totalInvestorMioEUR = sum(result.T6, (d) => d.totalInvestorMioEUR)
  $: T6totalMioEur = sum(result.T6, (d) => d.totalMioEUR)
  $: T6investorCost = (T6totalInvestorMioEUR / T6generation) * 1000
  $: T6socialPluInvestorCost = (T6totalMioEur / T6generation) * 1000

  $: totalPerTechnology = Object.entries(result).reduce(
    (acc, [T_key, values]) => {
      acc[T_key] = {
        totalGeneration: sum(values, (d) => d.generation) / 1000,
        totalCostPerTechnology: sum(values, (d) => d.costPerTechnology),
      }

      return acc
    },
    {
      import: {
        totalGeneration: totalImport,
        totalCostPerTechnology:
          T6generation === 0
            ? $selectedCostView === 'Investor'
              ? (totalImport * $selectedPowerPrice * 1.75) / 1000
              : (totalImport * $selectedPowerPrice * 1.75 + 100) / 1000
            : $selectedCostView === 'Investor'
            ? (totalImport * T6investorCost) / 1000
            : (totalImport * T6socialPluInvestorCost) / 1000,
      },
    }
  )

  $: byOutput = Object.entries(totalPerTechnology).reduce(
    (acc, [technology, values]) => {
      if (!acc['generation']) {
        acc['generation'] = {}
      }

      if (!acc['cost']) {
        acc['cost'] = {}
      }

      acc['generation'][technology] = values.totalGeneration
      acc['cost'][technology] = values.totalCostPerTechnology

      return acc
    },
    {}
  )

  $: generationStack = stack().keys(['generation', 'import'])([
    {
      generation: sum(
        Object.values(totalPerTechnology).map((d) => d.totalGeneration)
      ),
      import: totalImport,
    },
  ])

  $: costStack = stack().keys(Object.keys(totalPerTechnology))([byOutput.cost])

  $: totalDemand = sum(
    $data.getAnnualTimeSeries((year) => {
      return sum(Object.values(year.calculated.demand.CH.absolute.userValues))
    })
  )

  $: maxValues = {
    generation: generationStack[generationStack.length - 1][0][1],
    cost: costStack[costStack.length - 1][0][1],
    demand: totalDemand,
  }

  $: yGeneration = scaleLinear()
    .domain([0, maxGeneration])
    .range([$height, 0])
    .clamp(true)
  $: yCost = scaleLinear().domain([0, maxCost]).range([$height, 0]).clamp(true)
  $: yDemand = scaleLinear()
    .domain([0, maxDemand])
    .range([0, $height])
    .clamp(true)
  $: ySubsidies = scaleLinear()
    .domain([0, maxCost])
    .range([0, $height])
    .clamp(true)

  $: meanCost = Math.round(
    (sum(Object.values(byOutput.cost)) /
      sum(Object.values(byOutput.generation))) *
      1000
  )

  $: subsidies =
    sum(
      $data.getAnnualTimeSeries((year) => {
        let result = 0

        Object.values(year.trueCost.technologies)
          .filter((d) => !['T3', 'T16', 'T20', 'T19', 'T18'].includes(d.key))
          .forEach((d) => {
            result +=
              Math.min(0, d.calculated.netBenefit.netBenefit.investorTotal) * -1
          })

        return result
      })
    ) / 1000

  // $: subsidies = Math.max(
  //   0,
  //   -sum(
  //     $data.getAnnualTimeSeries((year) =>
  //       sum(
  //         Object.values(year.trueCost.technologies),
  //         (d) => d.calculated.netBenefit.netBenefit.investorTotal
  //       )
  //     )
  //   ) / Math.pow(10, 3)
  // )
</script>

<g>
  <rect
    width={$width}
    height={$height}
    fill="transparent"
    on:mouseover={() =>
      ($costDetailsHover = {
        type: 'comparison',
        data: byOutput,
        single: undefined,
        demand: totalDemand,
        subsidies: subsidies,
      })}
    on:mouseout={() => ($costDetailsHover = undefined)}
  />
  <text class="mean" x={$width / 2} dy={-35}>⌀ {meanCost} CHF/MWh</text>
  <line
    x1={0}
    y1={yGeneration(0)}
    x2={$width - 90}
    y2={yGeneration(0)}
    stroke="black"
  />

  <text dy={-8} dx={-5} style="text-anchor: middle;" class="axis-label">
    {$_('page.sections.charts.cost.scenario_comparison.unit')}
  </text>
  {#each yCost.ticks() as tick}
    <text y={yCost(tick)} class="tick" dx={-5} style="text-anchor: end;">
      {f(tick)}
    </text>
  {/each}
  <text dy={-12} x={$width / 4} style="text-anchor: middle; font-weight: bold;"
    >{$_('page.sections.charts.cost.scenario_comparison.cost')}</text
  >
  {#each costStack as cost, i}
    <rect
      on:click={() => console.log(costStack)}
      x={0}
      y={1 + yCost(cost[0][1])}
      width={$width / 2}
      height={Math.max(0, yCost(cost[0][0]) - yCost(cost[0][1]) - 1)}
      fill={cost.key === 'import'
        ? 'url(#diagonalHatch-import)'
        : techColors[cost.key]}
      rx={3}
      ry={3}
    />
    <rect
      x={0}
      y={1 + yCost(cost[0][1])}
      width={$width / 2}
      height={Math.max(0, yCost(cost[0][0]) - yCost(cost[0][1]))}
      fill="transparent"
      rx={3}
      ry={3}
      on:mouseover={() =>
        ($costDetailsHover = {
          type: 'comparison',
          data: byOutput,
          single: cost.key,
          demand: totalDemand,
          subsidies: subsidies,
        })}
      on:mouseout={() => ($costDetailsHover = undefined)}
    />
  {/each}

  <text
    dy={-8}
    dx={-5}
    style="text-anchor: middle;"
    class="axis-label"
    x={$width}>TWh</text
  >
  {#each yDemand.ticks() as tick}
    <text
      x={$width}
      y={yDemand(maxDemand) - yDemand(tick)}
      class="tick"
      dx={-15}
      style="text-anchor: start;"
    >
      {f(tick)}
    </text>
  {/each}
  <text
    dy={-12}
    x={(3 * $width) / 4}
    style="text-anchor: middle; font-weight: bold;"
  >
    {$_('page.sections.charts.cost.scenario_comparison.demand')}
  </text>

  <rect
    x={(3 * $width) / 4 - 45}
    y={ySubsidies(maxCost) - ySubsidies(subsidies)}
    width={$width / 16}
    height={ySubsidies(subsidies)}
    fill="var(--color-green-700)"
    rx={3}
    ry={3}
    on:mouseover={() =>
      ($costDetailsHover = {
        type: 'comparison',
        data: byOutput,
        single: 'subsidies',
        demand: totalDemand,
        subsidies: subsidies,
      })}
    on:mouseout={() => ($costDetailsHover = undefined)}
  />
  <text
    style="font-weight: bold; pointer-events: none;"
    transform="translate({(3 * $width) / 4 - 45}, {ySubsidies(
      maxCost
    )}) rotate(-90)"
    dy={-6}
    dx={3}
  >
    {$_(`page.sections.charts.cost.scenario_comparison.subsidies`)}
  </text>

  <rect
    x={(3 * $width) / 4 + 20}
    y={yDemand(maxDemand) - yDemand(totalDemand)}
    width={$width / 16}
    height={yDemand(totalDemand)}
    fill="var(--color-gray-100)"
    rx={3}
    ry={3}
    on:mouseover={() =>
      ($costDetailsHover = {
        type: 'comparison',
        data: byOutput,
        single: 'demand',
        demand: totalDemand,
        subsidies: subsidies,
      })}
    on:mouseout={() => ($costDetailsHover = undefined)}
  />
  <line
    x1={(3 * $width) / 4 + 16}
    x2={(3 * $width) / 4 + 20 + 20 + 2}
    y1={yDemand(maxDemand) - yDemand(totalDemand)}
    y2={yDemand(maxDemand) - yDemand(totalDemand)}
    stroke="black"
    stroke-width={3}
    on:mouseover={() =>
      ($costDetailsHover = {
        type: 'comparison',
        data: byOutput,
        single: 'demand',
        demand: totalDemand,
        subsidies: subsidies,
      })}
    on:mouseout={() => ($costDetailsHover = undefined)}
  />
  <line
    x1={$width - 60}
    y1={yGeneration(0)}
    x2={$width - 20}
    y2={yGeneration(0)}
    stroke="black"
  />
</g>

<style>
  .mean {
    text-anchor: middle;
    font-size: var(--size-3);
  }
  .tick {
    dominant-baseline: middle;
    font-size: var(--scale-1);
  }

  .axis-label {
    font-size: var(--scale-1);
  }
</style>
